import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { CloseButton } from '@sorare/core/src/atoms/buttons/CloseButton';
import { Drawer } from '@sorare/core/src/atoms/layout/Drawer';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyL } from '@sorare/core/src/atoms/typography';
import { HorizontalDivider } from '@sorare/core/src/atoms/ui/Divider';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { useScreenSize } from '@sorare/core/src/hooks/device/useScreenSize';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { useGenerateDialogPath } from '@sorare/core/src/hooks/navigation/useGenerateDialogPath';
import { cart as cartMessages } from '@sorare/core/src/lib/glossary';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';
import { regularMaterial } from '@sorare/core/src/style/utils';

import { useCart } from 'hooks/useCart';

import { CartItem } from '../CartItem';
import { CartTotalSummary } from './CartTotalSummary';
import type {
  AddToCartPopinQuery,
  AddToCartPopinQueryVariables,
  AddToCartPopin_anyCard,
} from './__generated__/index.graphql';

interface Props {
  slug: string | null;
  onClose: () => void;
}

const cardFragment = gql`
  fragment AddToCartPopin_anyCard on AnyCardInterface {
    slug
    rarityTyped
    ...CartItem_anyCard
  }
  ${CartItem.fragments.anyCard}
` as TypedDocumentNode<AddToCartPopin_anyCard>;

const ADD_TO_CART_POPIN_QUERY = gql`
  query AddToCartPopinQuery($slug: String!) {
    anyCard(slug: $slug) {
      slug
      ...AddToCartPopin_anyCard
    }
  }
  ${cardFragment}
` as TypedDocumentNode<AddToCartPopinQuery, AddToCartPopinQueryVariables>;

const StyledDrawer = styled(Drawer)`
  width: 100%;
  @media ${tabletAndAbove} {
    bottom: auto;
    top: calc(8 * var(--unit));
    margin: var(--double-unit) var(--triple-unit);
    width: 480px;
  }
`;

const Popin = styled(Vertical).attrs({ gap: 2 })`
  ${regularMaterial}
  border-top-left-radius:  var(--double-unit);
  border-top-right-radius: var(--double-unit);
  border: 1px solid var(--c-nd-150);
  @media ${tabletAndAbove} {
    border-radius: var(--double-unit);
  }
`;

const CardItemWrapper = styled(Vertical)`
  width: 100%;
  padding: 0 var(--double-unit);
`;

const Title = styled(Horizontal)`
  justify-content: space-between;
  padding: var(--double-unit) var(--double-unit) 0;
`;

const SummaryWrapper = styled(Vertical).attrs({ gap: 2 })`
  padding: 0 var(--double-unit);
`;

const Actions = styled(Horizontal)`
  padding: 0 var(--double-unit) var(--double-unit);
`;

const DURATION = 4000;

export const AddToCartPopin = ({ slug, onClose }: Props) => {
  const { up: tabletOrAbove } = useScreenSize('tablet');
  const { cartCount, cart, cartTotal } = useCart();
  const generateDialogPath = useGenerateDialogPath();

  const [holdClosing, setHoldClosing] = useState(!tabletOrAbove);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const { data, loading } = useQuery(ADD_TO_CART_POPIN_QUERY, {
    variables: {
      slug: slug || '',
    },
    fetchPolicy: 'cache-first',
    skip: !slug,
  });

  const card = data?.anyCard;
  const cartItem = cart?.mainCartItems?.find(item => item.card.slug === slug);

  const onClean = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  }, [timeoutId]);

  useEffect(() => {
    if (holdClosing) {
      onClean();
      return () => {};
    }

    if (!timeoutId && !loading && slug) {
      const tId = setTimeout(onClose, DURATION);
      setTimeoutId(tId);
      return () => {
        onClean();
      };
    }
    return () => {
      onClean();
    };
  }, [holdClosing, timeoutId, setHoldClosing, onClose, loading, slug, onClean]);

  if (!slug || !card) {
    return null;
  }

  const dialogRoute = generateDialogPath(DialogKey.checkout, card.sport);

  return (
    <StyledDrawer
      open={!!slug}
      side={tabletOrAbove ? 'right' : 'bottom'}
      onBackdropClick={tabletOrAbove ? undefined : onClose}
    >
      <Popin
        onMouseEnter={() => setHoldClosing(true)}
        onMouseLeave={() => setHoldClosing(false)}
      >
        <Title>
          <BodyL bold>
            <FormattedMessage {...cartMessages.addToCart} /> ({cartCount})
          </BodyL>
          <CloseButton onClose={onClose} />
        </Title>
        <CardItemWrapper>
          <CartItem cartItem={cartItem} card={card} onAction={onClose} />
        </CardItemWrapper>
        <HorizontalDivider />
        <SummaryWrapper>
          <CartTotalSummary total={cartTotal} sport={card.sport} />
        </SummaryWrapper>
        <Actions>
          <Button
            size="medium"
            color="primary"
            onClick={onClose}
            fullWidth
            to={dialogRoute}
          >
            <FormattedMessage {...cartMessages.checkout} />
          </Button>
        </Actions>
      </Popin>
    </StyledDrawer>
  );
};

AddToCartPopin.fragments = {
  anyCard: cardFragment,
};
