import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { createFiatPayerInput } from '__generated__/globalTypes';
import { useMutation } from 'hooks/graphql/useMutation';

import {
  CreateFiatPayer,
  CreateFiatPayerVariables,
} from './__generated__/useCreatePayer.graphql';

const CREATE_FIAT_PAYER_MUTATION = gql`
  mutation CreateFiatPayer($input: createFiatPayerInput!) {
    createFiatPayer(input: $input) {
      currentUser {
        slug
        myAccounts {
          id
          accountable {
            ... on Node {
              id
            }
            ... on PrivateFiatWalletAccount {
              id
              state
            }
          }
        }
      }
      errors {
        message
        code
        path
      }
    }
  }
` as TypedDocumentNode<CreateFiatPayer, CreateFiatPayerVariables>;

export const useCreatePayer = () => {
  const [createPayer] = useMutation<CreateFiatPayer, CreateFiatPayerVariables>(
    CREATE_FIAT_PAYER_MUTATION
  );
  const submit = useCallback(
    async (input: createFiatPayerInput) => {
      return await createPayer({
        variables: {
          input,
        },
      });
    },
    [createPayer]
  );
  return [submit];
};
