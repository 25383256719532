import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Portal } from 'atoms/layout/Portal';
import { Horizontal } from 'atoms/layout/flex';
import { Backdrop } from 'atoms/loader/Backdrop';
import { useCurrentUserContext } from 'contexts/currentUser';
import {
  Highlight,
  OnboardingHighlightName,
  useOnboardingHighLightContext,
} from 'contexts/onboardingHighlight';
import { useResizeObserver } from 'hooks/ui/useResizeObserver';
import { useBodyLock } from 'hooks/useBodyLock';
import { useToggle } from 'hooks/useToggle';
import { scrollIntoViewAndWait } from 'lib/scrollIntoViewAndWait';
import { theme } from 'style/theme';

const StyledBackdrop = styled(Backdrop)<{ blur?: number }>`
  z-index: ${theme.zIndex.backdrop - 1};
  backdrop-filter: ${({ blur }) => `blur(${blur || 5}px)`};
`;

const AboveBackdropDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${theme.zIndex.modal - 1};
  height: 100%;
  width: 100%;
`;
const AbsolutelyPositionedDiv = styled(Horizontal).attrs({ center: true })`
  position: absolute;
  overflow: hidden;
  & > * {
    flex: 1;
  }
`;

const getDimensionsWithPadding = (highlight: Highlight) => {
  const { measureCallback, config } = highlight;
  const domRect = measureCallback?.();

  if (!domRect) {
    return {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      ready: false,
    };
  }

  const { padding = 0, circle } = config || {};

  if (circle) {
    const { left, top, width, height } = domRect;
    const radius = Math.max(width / 2, height / 2) + padding;
    const horizontalCenter = left + width / 2;
    const verticalCenter = top + height / 2;

    return {
      left: horizontalCenter - radius,
      top: verticalCenter - radius,
      right: horizontalCenter + radius,
      bottom: verticalCenter + radius,
      ready: true,
    };
  }

  return {
    left: domRect.left - padding,
    top: domRect.top - padding,
    right: domRect.right + padding,
    bottom: domRect.bottom + padding,
    ready: true,
  };
};

type Props = {
  highlightNames: OnboardingHighlightName[];
  blur?: number;
  onClose?: () => void;
  disableBackdropClose?: boolean;
  withDelay?: boolean;
  stayOpen?: boolean;
  extras?: {
    nextTo: OnboardingHighlightName;
    render: ({
      left,
      top,
      right,
      bottom,
      onClose,
      ready,
    }: {
      left: number;
      top: number;
      right: number;
      bottom: number;
      ready: boolean;
      onClose: () => void;
    }) => ReactNode;
  }[];
};
export const HighlightOverlay = ({
  highlightNames,
  blur,
  onClose: onCloseFromProps,
  disableBackdropClose,
  withDelay,
  extras,
  stayOpen,
}: Props) => {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [, forceRerender] = useToggle(false);
  const { currentUser } = useCurrentUserContext();
  const { highlights, setActiveHighlights } = useOnboardingHighLightContext();
  const currentHighlights = highlights.filter(highlight =>
    highlightNames.includes(highlight.name)
  );
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Lock body when the overlay is shown
  useBodyLock(show);

  useResizeObserver(ref, () => {
    forceRerender();
  });

  const { refToScroll } = currentHighlights.find(h => h.refToScroll) || {};

  useEffect(() => {
    const asyncCallback = async () => {
      if (refToScroll?.current) {
        const rect = refToScroll.current.getBoundingClientRect();
        const isFullyInViewport =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);

        if (!isFullyInViewport) {
          await scrollIntoViewAndWait(refToScroll.current);
          forceRerender();
        }
      }
      if (withDelay) {
        const timeout = setTimeout(() => {
          setShow(true);
        }, 500);
        timeoutRef.current = timeout;
      } else {
        setShow(true);
      }
    };
    asyncCallback();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [withDelay, refToScroll, forceRerender]);

  useEffect(() => {
    if (show && currentUser) {
      setActiveHighlights(highlightNames);
    }
    return () => {
      setActiveHighlights([]);
    };
  }, [currentUser, highlightNames, setActiveHighlights, show]);

  if (!show || !currentUser) return null;

  const onClose = () => {
    onCloseFromProps?.();

    if (!stayOpen) {
      setShow(false);
    }
  };
  return (
    <Portal id="onboarding-overlay">
      <StyledBackdrop ref={ref} blur={blur} />
      <AboveBackdropDiv
        onClick={
          disableBackdropClose
            ? undefined
            : e => {
                e.stopPropagation();
                onClose();
              }
        }
      >
        {currentHighlights.map((highlight, index) => {
          const { left, top, right, bottom } =
            getDimensionsWithPadding(highlight);

          const { config, children } = highlight;
          const { border, borderRadius, circle, onClick, padding, filter } =
            config || {};

          const isButton = !!onClick || !!onCloseFromProps || !stayOpen;
          return (
            <Fragment key={highlight.name}>
              <AbsolutelyPositionedDiv
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClickCapture={
                  isButton
                    ? e => {
                        e.stopPropagation();
                        e.preventDefault();
                        onClick?.();
                        onClose();
                      }
                    : undefined
                }
                role={isButton ? 'button' : undefined}
                aria-label={`Click ${highlight.name}`}
                style={{
                  top,
                  left,
                  width: right - left,
                  height: circle ? right - left : bottom - top,
                  borderRadius: circle ? '50%' : borderRadius,
                  cursor: isButton ? 'pointer' : undefined,
                  border: border ? '2px solid var(--c-white)' : 'none',
                  padding: `${padding}px`,
                  filter,
                }}
              >
                {children}
              </AbsolutelyPositionedDiv>
            </Fragment>
          );
        })}
        {extras?.map(({ nextTo, render }, index) => {
          const nextToHighlight = currentHighlights.find(
            h => h.name === nextTo
          );

          if (!nextToHighlight) return null;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              {render({
                onClose,
                ...getDimensionsWithPadding(nextToHighlight),
              })}
            </Fragment>
          );
        })}
      </AboveBackdropDiv>
    </Portal>
  );
};
