import { ReactNode } from 'react';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

const Root = styled(Vertical)`
  @media ${laptopAndAbove} {
    border-left: 1px solid var(--c-nd-100);
  }
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--double-unit);
  padding: var(--double-unit);
  @media ${laptopAndAbove} {
    padding: var(--triple-unit);
    gap: var(--triple-unit);
  }
`;

export const Aside: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Root>
      <Content>{children}</Content>
    </Root>
  );
};
