import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';

import { Maybe, PaymentMethodProvider } from '__generated__/globalTypes';
import { CurrentUserProvider_currentUser } from 'contexts/currentUser/__generated__/queries.graphql';
import { useQuery } from 'hooks/graphql/useQuery';
import { useMangopayCreditCardsEnabled } from 'hooks/useMangopayCreditCardsEnabled';

import {
  PaymentProvider_currentUserQuery,
  PaymentProvider_currentUserQueryVariables,
} from './__generated__/useCurrentUsersPaymentMethods.graphql';

const PAYMENT_PROVIDER_CURRENT_USER_QUERY = gql`
  query PaymentProvider_currentUserQuery($provider: PaymentMethodProvider) {
    currentUser {
      slug
      paymentMethods(provider: $provider) {
        id
        card {
          last4
          brand
          type
        }
      }
    }
  }
` as TypedDocumentNode<
  PaymentProvider_currentUserQuery,
  PaymentProvider_currentUserQueryVariables
>;

type PaymentProvider_currentUserQuery_currentUser_paymentMethods = NonNullable<
  PaymentProvider_currentUserQuery['currentUser']
>['paymentMethods'][number];

export const useCurrentUsersPaymentMethods = (
  currentUser: Maybe<CurrentUserProvider_currentUser> | undefined,
  specifiedProvider?: PaymentMethodProvider
): [
  PaymentProvider_currentUserQuery_currentUser_paymentMethods[] | null,
  boolean,
  () => void,
] => {
  const useMangopayCreditCards = useMangopayCreditCardsEnabled();
  const provider = useMemo(() => {
    if (specifiedProvider) return specifiedProvider;
    if (useMangopayCreditCards) return PaymentMethodProvider.MANGOPAY;
    return PaymentMethodProvider.STRIPE;
  }, [useMangopayCreditCards, specifiedProvider]);
  const { data, loading, refetch } = useQuery(
    PAYMENT_PROVIDER_CURRENT_USER_QUERY,
    {
      variables: {
        provider,
      },
      skip: !currentUser,
    }
  );

  return [data?.currentUser?.paymentMethods || null, loading, refetch];
};
