import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelL } from '@sorare/core/src/atoms/typography';

import { StepLayout } from 'components/rivals/squads/MySquadOnboarding/steps/StepLayout';

import members from './assets/members.png';
import squad from './assets/squad.png';

const Image = styled.img`
  width: calc(100% - 15 * var(--unit));
  margin: auto;
`;

export const EarnRep = () => {
  const { formatMessage } = useIntl();
  return (
    <StepLayout
      title={formatMessage({
        id: 'MySquadOnboarding.steps.EarnRep.title',
        defaultMessage: 'Earn Rep Together',
      })}
      subtitle={formatMessage({
        id: 'MySquadOnboarding.steps.EarnRep.subtitle',
        defaultMessage: 'Climb the leaderboards as a team',
      })}
      content={
        <Vertical gap={3}>
          <Image src={squad} alt="" />
          <LabelL className="text-center" bold>
            <FormattedMessage
              id="MySquadOnboarding.steps.EarnRep.content.title"
              defaultMessage="Your Squad"
            />
          </LabelL>
          <Image src={members} alt="" />
        </Vertical>
      }
      footer={formatMessage({
        id: 'MySquadOnboarding.steps.EarnRep.footer',
        defaultMessage:
          "Reputation points earned in the Arena also count towards your Squad's total",
      })}
    />
  );
};
