import { TypedDocumentNode, gql } from '@apollo/client';
import classnames from 'classnames';
import styled, { css } from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { Card } from 'components/card/Card';

import { UninteractiveBundledCardsPreview_anyCard } from './__generated__/index.graphql';
import { CardSize, cardSizes } from './types';

const WIDTH: {
  [size in (typeof cardSizes)[number]]: number;
} = {
  xs: 320,
  sm: 287,
  md: 320,
  lg: 350,
  xl: 410,
};

type Props = {
  size: CardSize;
  cards: UninteractiveBundledCardsPreview_anyCard[];
};

const Bundle = styled.div`
  display: grid;
  grid-template-columns: 1fr 10px calc(25% - 5px) 5px calc(25% - 5px);
  grid-template-rows: 1fr 5px 1fr;
  grid-template-areas:
    'main gap1 second gap3 third'
    'main gap1 gap2 gap2 gap2'
    'main gap1 fourth gap4 fifth';

  ${cardSizes.map(
    cur => css`
      &.${cur} {
        width: ${WIDTH[cur]}px;
      }
    `
  )}
  & > * {
    width: 100%;
  }
  & > :nth-child(1) {
    grid-area: main;
  }
  & > :nth-child(2) {
    align-self: start;
    grid-area: second;
  }
  & > :nth-child(3) {
    grid-area: third;
    align-self: start;
  }
  & > :nth-child(4) {
    grid-area: fourth;
    align-self: end;
  }
  & > :nth-child(5) {
    grid-area: fifth;
    align-self: end;
  }
`;

const Root = styled(Horizontal).attrs({
  gap: 1.5,
  center: true,
})<{ length: number }>`
  flex-wrap: wrap;
  max-width: 280px;
  > * {
    ${props => {
      switch (props.length) {
        case 2:
        case 4:
          return css`
            width: 120px;
          `;
        default:
          return css`
            width: 80px;
          `;
      }
    }}
  }
`;

export const UninteractiveBundledCardsPreview = ({ size, cards }: Props) => {
  if (cards.length === 5) {
    const mainToken = cards[0]; // select the first one for now
    const secondarySlugs = cards.filter(c => c.slug !== mainToken.slug);
    return (
      <Bundle className={classnames(size)} data-findme>
        <Card card={mainToken} />
        {secondarySlugs.map(card => (
          <Card key={card.slug} card={card} />
        ))}
      </Bundle>
    );
  }
  return (
    <Root length={cards.length} data-findme>
      {cards.map(card => (
        <Card key={card.slug} card={card} />
      ))}
    </Root>
  );
};

UninteractiveBundledCardsPreview.fragments = {
  anyCard: gql`
    fragment UninteractiveBundledCardsPreview_anyCard on AnyCardInterface {
      slug
      ...Card_anyCard
    }
    ${Card.fragments.anyCard}
  ` as TypedDocumentNode<UninteractiveBundledCardsPreview_anyCard>,
};
