import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from 'atoms/buttons/Button';
import { Reward } from 'components/3d/Reward';
import { UninteractiveBundledCardsPreview } from 'components/bundled/UninteractiveBundledCardsPreview';
import { CongratsScreen } from 'components/congratulations/CongratsScreen';
import { BundleWrapper } from 'components/congratulations/ui';
import { ANY_SPORT_MY_CARDS_BOXES } from 'constants/__generated__/routes';
import { useSportContext } from 'contexts/sport';
import { getCardRepresentation } from 'lib/cards';
import { congratsScreens } from 'lib/glossary';
import { isType } from 'lib/gql';
import { lazy } from 'lib/retry';
import { generateSportPath } from 'lib/routing/generateSportPath';

import { RewardTitle } from './RewardTitle';
import {
  BuyConfirmation_anyCard,
  BuyConfirmation_anyReward,
} from './__generated__/index.graphql';

const CardWithRewards = lazy(
  async () => import('@sorare/trois/src/components/CardWithRewards'),
  'CardWithRewards'
);

type Props = {
  cards: BuyConfirmation_anyCard[];
  additionalRewards?: Nullable<BuyConfirmation_anyReward[]>;
  actions?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  onClose?: () => void;
};

export const BuyConfirmation = ({
  actions: actionsProp,
  cards,
  additionalRewards: additionalRewardsProp,
  title,
  subtitle,
  onClose,
}: Props) => {
  const additionalRewards = useMemo(
    () => additionalRewardsProp ?? [],
    [additionalRewardsProp]
  );
  const isBundle = cards.length > 1;
  const card = cards[0];

  const { sport } = useSportContext();

  const hasBoxReward =
    !isBundle &&
    additionalRewards.some(x => isType(x, 'ProbabilisticBundleReward'));

  const actions =
    actionsProp || hasBoxReward ? (
      <>
        {actionsProp}
        {hasBoxReward && (
          <Button
            size="medium"
            color="transparent"
            fullWidth
            to={generateSportPath(ANY_SPORT_MY_CARDS_BOXES, {
              sport,
            })}
            onClick={onClose}
          >
            <FormattedMessage
              id="ClubShop.ItemPreviewDialog.Cta.Inventory"
              defaultMessage="Go to inventory"
            />
          </Button>
        )}
      </>
    ) : null;

  const [nbRewardsRevealed, setNbRewardsRevealed] = useState(0);
  const nextReward = useCallback(() => {
    setNbRewardsRevealed(x => Math.min(x + 1, additionalRewards.length));
  }, [additionalRewards]);

  const cardsCount = cards.length;

  return (
    <CongratsScreen
      rarityBackground={card.rarityTyped}
      christmasBackground={card.cardEditionName?.startsWith('neon_christmas')}
      title={
        additionalRewards.length > 0 && nbRewardsRevealed > 0 ? (
          <FormattedMessage
            id="buyTokenConfirmation.rewards.title"
            defaultMessage="There's{br}More"
            values={{ br: <br /> }}
          />
        ) : (
          title || (
            <FormattedMessage
              {...congratsScreens.newSigning}
              values={{ br: <br />, cardsCount }}
            />
          )
        )
      }
      subtitle={
        additionalRewards.length > 0 && nbRewardsRevealed > 0 ? (
          <RewardTitle reward={additionalRewards[nbRewardsRevealed - 1]} />
        ) : (
          subtitle || (
            <FormattedMessage
              {...(cardsCount === 1
                ? congratsScreens.welcomePlayerToYourTeam
                : congratsScreens.welcomeMultiplePlayersToYourTeam)}
              values={{
                playerDisplayName: card.anyPlayer.displayName,
                cardsCount,
              }}
            />
          )
        )
      }
      main={
        isBundle ? (
          <BundleWrapper>
            <UninteractiveBundledCardsPreview cards={cards} size="md" />
          </BundleWrapper>
        ) : (
          <CardWithRewards
            card={getCardRepresentation(card)}
            rewards={additionalRewards
              .slice(0, nbRewardsRevealed)
              .map((reward, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Reward reward={reward} key={index} />
              ))}
            style={{
              height: '500px',
            }}
          />
        )
      }
      actions={
        nbRewardsRevealed < additionalRewards.length ? (
          <Button size="medium" color="secondary" onClick={nextReward}>
            <FormattedMessage
              id="buyTokenConfirmation.buy.showRewards"
              defaultMessage="And..."
            />
          </Button>
        ) : (
          actions
        )
      }
      isBundle={isBundle}
    />
  );
};

BuyConfirmation.fragments = {
  anyCard: gql`
    fragment BuyConfirmation_anyCard on AnyCardInterface {
      slug
      sport
      cardEditionName
      anyPlayer {
        slug
        displayName
      }
      ...getCardRepresentation_anyCardInterface
      ...UninteractiveBundledCardsPreview_anyCard
    }
    ${getCardRepresentation.fragments.anyCardInterface}
    ${UninteractiveBundledCardsPreview.fragments.anyCard}
  ` as TypedDocumentNode<BuyConfirmation_anyCard>,
  anyReward: gql`
    fragment BuyConfirmation_anyReward on AnyRewardInterface {
      id
      ...Reward_AnyRewardInterface
      ...RewardTitle_AnyRewardInterface
    }
    ${Reward.fragments.AnyRewardInterface}
    ${RewardTitle.fragments.AnyRewardInterface}
  ` as TypedDocumentNode<BuyConfirmation_anyReward>,
};
