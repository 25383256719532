import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Markdown, Props as Options } from 'atoms/markdown';

export type Props = Options;

const StyledReactMarkdown = styled(Markdown)`
  word-break: break-word;
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: revert;
  }
  h1 {
    font: var(--t-display-l);
  }
  h2 {
    font: var(--t-display-m);
  }
  h3 {
    font: var(--t-display-s);
  }
  h4 {
    font: var(--t-headline-l);
  }
  h5 {
    font: var(--t-headline-m);
  }
  p,
  ul,
  li {
    font: var(--t-body-l);
  }

  table {
    margin-top: var(--double-unit);
    overflow-x: auto;
    display: block;
    border-collapse: collapse;
    word-break: normal;
  }

  [id] {
    /* Take stack-height into account when scrolling to a node
       to avoid navbar covering content */
    scroll-margin-top: var(--current-stack-height);
  }

  table td,
  table th {
    border: 1px solid;
    padding: var(--unit);
  }
  li {
    list-style-type: square;
    &:not(:last-child) {
      margin-bottom: var(--double-unit);
    }
    blockquote {
      margin-left: 0;
      margin-right: 0;
      padding: var(--double-unit);
      border-left: 3px solid grey;
      background-color: rgba(var(--c-rgb-brand-600), 0.25);
      p {
        margin: 0;
      }
      p:not(:last-child) {
        margin-bottom: var(--double-unit);
      }
    }
  }
`;

export const Content = (props: Props) => {
  const { hash } = useLocation();

  useEffect(() => {
    // if a hash link, find it
    if (hash) {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView(true);
        }
      }, 0);
    }
  }, [hash]); // do this on route change
  return <StyledReactMarkdown {...props} />;
};
