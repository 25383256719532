import { ComponentProps, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

export type Props = Pick<
  ComponentProps<typeof ReactMarkdown>,
  'components' | 'skipHtml'
> & {
  className?: string;
  gfm?: boolean;
  html?: boolean;
  children: string;
};

export const Markdown = ({ gfm, html, ...props }: Props) => {
  const rehypePlugins = useMemo(() => {
    return [
      gfm ? remarkGfm : null,
      // If we accept html in input, we need rehypeRaw
      html ? rehypeRaw : null,
    ].filter(Boolean);
  }, [html, gfm]);

  return <ReactMarkdown rehypePlugins={rehypePlugins} {...props} />;
};
