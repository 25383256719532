import { HTMLProps } from 'react';
import styled from 'styled-components';

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

type Props = HTMLProps<HTMLImageElement> & {
  fetchpriority?: 'high' | 'low' | 'auto';
};

export const Img = ({ alt, ...props }: Props) => (
  <Image {...props} alt={alt || ''} />
);
