import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { StepLayout } from 'components/rivals/squads/MySquadOnboarding/steps/StepLayout';

import squads from './assets/squads.png';

const Root = styled.div`
  height: 100%;
  background: linear-gradient(
    to bottom,
    var(--c-rivals-purple-300),
    rgba(var(--c-rgb-rivals-purple-300), 0.5),
    rgba(var(--c-rgb-black), 1) 50%
  );
`;
const Image = styled.img`
  width: 100%;
  margin: auto;
`;

export const Promotion = () => {
  const { formatMessage } = useIntl();
  return (
    <Root>
      <StepLayout
        transparentBg
        title={formatMessage({
          id: 'MySquadOnboarding.steps.Promotion.title',
          defaultMessage: 'Fight for Promotion',
        })}
        subtitle={formatMessage({
          id: 'MySquadOnboarding.steps.Promotion.subtitle',
          defaultMessage: 'Every week',
        })}
        content={<Image src={squads} alt="" />}
        footer={formatMessage({
          id: 'MySquadOnboarding.steps.Promotion.footer',
          defaultMessage:
            'Climb up the leaderboard as a team with every victory',
        })}
      />
    </Root>
  );
};
