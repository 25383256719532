import { faApplePay } from '@fortawesome/free-brands-svg-icons';
import classNames from 'classnames';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { BodyM } from 'atoms/typography';

const Root = styled(Horizontal)`
  &.column {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    gap: var(--half-unit);
    text-align: left;
    color: var(--c-nl-100-opaque);
  }
`;

const IconContainer = styled.div`
  width: 40px;
  background-color: var(--c-black);
  padding: var(--half-unit) var(--unit);
  border-radius: var(--half-unit);
  color: var(--c-nl-1000-opaque);
`;

export const ApplePayOptionLabel = ({
  column = false,
}: {
  column?: boolean;
}) => {
  return (
    <Root className={classNames({ column })} center>
      <IconContainer>
        <FontAwesomeIcon size="lg" icon={faApplePay} />
      </IconContainer>
      <BodyM>Apple Pay</BodyM>
    </Root>
  );
};
