import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineM, LabelM } from '@sorare/core/src/atoms/typography';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { MonetaryAmountParams } from '@sorare/core/src/lib/monetaryAmount';

import { useConversionCreditForPrice } from 'hooks/useConversionCreditForPrice';

const Line = styled(Horizontal)`
  align-items: baseline;
  flex-wrap: wrap;
  &.crossed {
    text-decoration: line-through;
    color: var(--c-nd-600);
  }
`;

const NoWrapLine = styled(Line)`
  flex-wrap: nowrap;
`;

const CrossedLabelM = styled(LabelM)`
  text-decoration: line-through;
`;

type Props = {
  price: MonetaryAmountParams;
  canDiscount?: boolean;
  sport: Sport;
};

export const DiscountedPrice = ({ price, canDiscount, sport }: Props) => {
  const { canUseConversionCredit, priceDiscountedAmount } =
    useConversionCreditForPrice({ price, sport });

  const { main, exponent } = useAmountWithConversion({
    monetaryAmount: price,
  });
  const { main: discountedMain, exponent: discountedExponent } =
    useAmountWithConversion({
      monetaryAmount: priceDiscountedAmount,
    });

  if (canDiscount && canUseConversionCredit) {
    return (
      <>
        <CrossedLabelM bold color="var(--c-nd-600)">
          {main}
        </CrossedLabelM>
        <NoWrapLine>
          <HeadlineM color="var(--c-score-high)">{discountedMain}</HeadlineM>
          {exponent && (
            <LabelM color="var(--c-nd-600)">{discountedExponent}</LabelM>
          )}
        </NoWrapLine>
      </>
    );
  }
  return (
    <Line>
      <HeadlineM>{main}</HeadlineM>
      {exponent && <LabelM color="var(--c-nd-600)">{exponent}</LabelM>}
    </Line>
  );
};
