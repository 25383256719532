export const namedOperations = {
  Query: {
    AnyCardSportQuery: 'AnyCardSportQuery' as const,
    MLBComposeBenchQuery: 'MLBComposeBenchQuery' as const,
    MLBPostBuyBenchObjectQuery: 'MLBPostBuyBenchObjectQuery' as const,
    MLBComposeBuyQuery: 'MLBComposeBuyQuery' as const,
    MLBComposePrintablePlayersSearchQuery: 'MLBComposePrintablePlayersSearchQuery' as const,
    BaseballGameViewDialogQuery: 'BaseballGameViewDialogQuery' as const,
    MLBLeagueTeamsQuery: 'MLBLeagueTeamsQuery' as const,
    BaseballSo5LeaderboardContenderDialogQuery: 'BaseballSo5LeaderboardContenderDialogQuery' as const,
    MLBDailyClaimButtonQuery: 'MLBDailyClaimButtonQuery' as const,
    MLBDailyClaimDialogQuery: 'MLBDailyClaimDialogQuery' as const,
    MLBDailySwingOnboardingQuery: 'MLBDailySwingOnboardingQuery' as const,
    MLBHomeOnboardingQuery: 'MLBHomeOnboardingQuery' as const,
    MLBOnboardingBuildYourClubQuery: 'MLBOnboardingBuildYourClubQuery' as const,
    BaseballProBlockQuery: 'BaseballProBlockQuery' as const,
    BaseballPlayPlayoff2023CollectionEventQuery: 'BaseballPlayPlayoff2023CollectionEventQuery' as const,
    BaseballPlayQuery: 'BaseballPlayQuery' as const,
    MLBReferralProgramConfig: 'MLBReferralProgramConfig' as const,
    MLBPrintablePlayersSearchQuery: 'MLBPrintablePlayersSearchQuery' as const,
    BaseballSecondaryMarketStackPlayerQuery: 'BaseballSecondaryMarketStackPlayerQuery' as const,
    BaseballProHistoryFixturePageQuery: 'BaseballProHistoryFixturePageQuery' as const,
    BaseballMyClubQuery: 'BaseballMyClubQuery' as const,
    MLBFixtureCompetitionTrckGrpSeasonalityPageQuery: 'MLBFixtureCompetitionTrckGrpSeasonalityPageQuery' as const,
    MLBFixtureCompetitionTrckGrpLayoutFixtureQuery: 'MLBFixtureCompetitionTrckGrpLayoutFixtureQuery' as const,
    MLBFixtureCompetitionTrckGrpLayoutTrckGrpQuery: 'MLBFixtureCompetitionTrckGrpLayoutTrckGrpQuery' as const,
    MLBFixtureCompetitionCompetitionPageQuery: 'MLBFixtureCompetitionCompetitionPageQuery' as const,
    MLBFixtureCompetitionPageQuery: 'MLBFixtureCompetitionPageQuery' as const,
    MLBEnterSeasonalityCompetitionGamesPageQuery: 'MLBEnterSeasonalityCompetitionGamesPageQuery' as const,
    MLBEnterSeasonalityCompetitionGamesPaginatedPageQuery: 'MLBEnterSeasonalityCompetitionGamesPaginatedPageQuery' as const,
    MLBEnterSeasonalityCompetitionTrckPageQuery: 'MLBEnterSeasonalityCompetitionTrckPageQuery' as const,
    MLBFixtureEnterSeasonalityCompetitionLayoutQuery: 'MLBFixtureEnterSeasonalityCompetitionLayoutQuery' as const,
    MLBPlayRewardsFixtureLayoutQuery: 'MLBPlayRewardsFixtureLayoutQuery' as const,
    MLBContenderPageQuery: 'MLBContenderPageQuery' as const,
    MLBPlayEventTyoeFixtureMatchCenterQuery: 'MLBPlayEventTyoeFixtureMatchCenterQuery' as const,
    MLBPlayEventTypeFixturePageQuery: 'MLBPlayEventTypeFixturePageQuery' as const,
    MLBComposeLeaderboardLineupPageQuery: 'MLBComposeLeaderboardLineupPageQuery' as const,
    MLBComposeLeaderboardManagerTeamPageQuery: 'MLBComposeLeaderboardManagerTeamPageQuery' as const,
    MLBComposeLeaderboardPageQuery: 'MLBComposeLeaderboardPageQuery' as const,
    MLBRankingsLineupPageQuery: 'MLBRankingsLineupPageQuery' as const,
    MLBRankingsPageQuery: 'MLBRankingsPageQuery' as const,
    MLBLineupSharingQuery: 'MLBLineupSharingQuery' as const,
    MLBLineupsPageQuery: 'MLBLineupsPageQuery' as const,
    MLBLobbyPageQuery: 'MLBLobbyPageQuery' as const,
    BaseballPlayProFixtureChaseUserGroupUserSpecificQuery: 'BaseballPlayProFixtureChaseUserGroupUserSpecificQuery' as const,
    BaseballPlayProFixtureChaseUserGroupLayoutQuery: 'BaseballPlayProFixtureChaseUserGroupLayoutQuery' as const,
    BaseballPlayProFixtureChaseLayoutQuery: 'BaseballPlayProFixtureChaseLayoutQuery' as const,
    BaseballPlayProFixtureChaseQuery: 'BaseballPlayProFixtureChaseQuery' as const,
    BaseballPlayProFixtureLeaderboardQuery: 'BaseballPlayProFixtureLeaderboardQuery' as const,
    BaseballPlayProFixtureCompetitionsPageQuery: 'BaseballPlayProFixtureCompetitionsPageQuery' as const,
    BaseballPlayProFixtureGameQuery: 'BaseballPlayProFixtureGameQuery' as const,
    BaseballPlayProFixtureGamesQuery: 'BaseballPlayProFixtureGamesQuery' as const,
    BaseballPlayProFixtureGameCurrentUserCardsQuery: 'BaseballPlayProFixtureGameCurrentUserCardsQuery' as const,
    BaseballPlayProFixtureLayoutQuery: 'BaseballPlayProFixtureLayoutQuery' as const,
    BaseballLineupPageQuery: 'BaseballLineupPageQuery' as const,
    BaseballPlayProFixtureMyLineupsQuery: 'BaseballPlayProFixtureMyLineupsQuery' as const,
    BaseballPlayProFixtureMyLineupsPaginatedQuery: 'BaseballPlayProFixtureMyLineupsPaginatedQuery' as const,
    BaseballPlayProFixturePostseasonUserGroupUserSpecificQuery: 'BaseballPlayProFixturePostseasonUserGroupUserSpecificQuery' as const,
    BaseballPlayProFixturePostseasonUserGroupLayoutQuery: 'BaseballPlayProFixturePostseasonUserGroupLayoutQuery' as const,
    BaseballPlayProFixturePostseasonLayoutQuery: 'BaseballPlayProFixturePostseasonLayoutQuery' as const,
    BaseballPlayProFixturePostseasonQuery: 'BaseballPlayProFixturePostseasonQuery' as const,
    PlayProChaseUserGroupRedirectionQuery: 'PlayProChaseUserGroupRedirectionQuery' as const,
    BaseballPlayProLayoutQuery: 'BaseballPlayProLayoutQuery' as const,
    BaseballPrizePoolContenderPageQuery: 'BaseballPrizePoolContenderPageQuery' as const,
    BaseballPrizePoolPageQuery: 'BaseballPrizePoolPageQuery' as const,
    BaseballRankingsLineupPageQuery: 'BaseballRankingsLineupPageQuery' as const,
    BaseballRankingsPageQuery: 'BaseballRankingsPageQuery' as const,
    BaseballLineupSharingQuery: 'BaseballLineupSharingQuery' as const,
    BaseballPlayRewardsFixtureLayoutQuery: 'BaseballPlayRewardsFixtureLayoutQuery' as const,
    BaseballPlayerGamesNavigatorQuery: 'BaseballPlayerGamesNavigatorQuery' as const,
    baseballPlayerCardsQuery: 'baseballPlayerCardsQuery' as const,
    BaseballPlayerPastFixturesQuery: 'BaseballPlayerPastFixturesQuery' as const,
    BaseballPlayerFutureFixturesQuery: 'BaseballPlayerFutureFixturesQuery' as const,
    BaseballSeoPlayerQuery: 'BaseballSeoPlayerQuery' as const,
    BaseballPlayerOverviewQuery: 'BaseballPlayerOverviewQuery' as const,
    baseballPlayerTransactionsQuery: 'baseballPlayerTransactionsQuery' as const,
    BaseballPlayPostseasonCollectionEventQuery: 'BaseballPlayPostseasonCollectionEventQuery' as const,
    BaseballSo5LineupQuery: 'BaseballSo5LineupQuery' as const,
    MLBScoreGameIdQuery: 'MLBScoreGameIdQuery' as const,
    MLBScoresQuery: 'MLBScoresQuery' as const,
    BaseballTeamCardsQuery: 'BaseballTeamCardsQuery' as const,
    BaseballSeoTeamQuery: 'BaseballSeoTeamQuery' as const,
    BaseballTeamOverviewQuery: 'BaseballTeamOverviewQuery' as const,
    CardFactoryQuery: 'CardFactoryQuery' as const,
    OAuthApplicationQuery: 'OAuthApplicationQuery' as const,
    RaffleTicketDialogContentQuery: 'RaffleTicketDialogContentQuery' as const,
    LowestSalesPerPlayerQuery: 'LowestSalesPerPlayerQuery' as const,
    AuthorizationStatusQuery: 'AuthorizationStatusQuery' as const,
    GetUserStatus: 'GetUserStatus' as const,
    GetSupportedCountriesQuery: 'GetSupportedCountriesQuery' as const,
    KYCDocumentRequirementsQuery: 'KYCDocumentRequirementsQuery' as const,
    AnySportPastLineupWinnerQuery: 'AnySportPastLineupWinnerQuery' as const,
    CheckNicknameAvailability: 'CheckNicknameAvailability' as const,
    FootballNavItemsQuery: 'FootballNavItemsQuery' as const,
    BaseballNavItemsQuery: 'BaseballNavItemsQuery' as const,
    NBANavItemsQuery: 'NBANavItemsQuery' as const,
    EnergyOnboardingInfoModalQuery: 'EnergyOnboardingInfoModalQuery' as const,
    AcceptCashOffersQuery: 'AcceptCashOffersQuery' as const,
    ActivityReportQuery: 'ActivityReportQuery' as const,
    BlockedUsersQuery: 'BlockedUsersQuery' as const,
    DevicesQuery: 'DevicesQuery' as const,
    ConnectedOAuthAccessTokensQuery: 'ConnectedOAuthAccessTokensQuery' as const,
    CurrentUserPaymentMethodsQuery: 'CurrentUserPaymentMethodsQuery' as const,
    DeliverableCountriesQuery: 'DeliverableCountriesQuery' as const,
    PrivacyQuery: 'PrivacyQuery' as const,
    MoonpayParamsQuery: 'MoonpayParamsQuery' as const,
    RefreshActivePromotionalEventCompletion: 'RefreshActivePromotionalEventCompletion' as const,
    WalletRecentActivityQuery: 'WalletRecentActivityQuery' as const,
    BankAccountCountryInfosQuery: 'BankAccountCountryInfosQuery' as const,
    CountryCurrencyQuery: 'CountryCurrencyQuery' as const,
    WithdrawalBankAccountsQuery: 'WithdrawalBankAccountsQuery' as const,
    WithdrawalsQuery: 'WithdrawalsQuery' as const,
    ConfigQuery: 'ConfigQuery' as const,
    PingConfigQuery: 'PingConfigQuery' as const,
    OwnYourGameQuery: 'OwnYourGameQuery' as const,
    FootballCardsInLineupQuery: 'FootballCardsInLineupQuery' as const,
    BaseballCardsInLineupQuery: 'BaseballCardsInLineupQuery' as const,
    NBACardsInLineupQuery: 'NBACardsInLineupQuery' as const,
    RestoreWalletQuery: 'RestoreWalletQuery' as const,
    NextFixturesProbablePitchersSlugs: 'NextFixturesProbablePitchersSlugs' as const,
    NoCardRoute: 'NoCardRoute' as const,
    PaymentProvider_currentUserQuery: 'PaymentProvider_currentUserQuery' as const,
    DeckQuery: 'DeckQuery' as const,
    DecksQuery: 'DecksQuery' as const,
    PrivateKeyRecoveryPayloadsQuery: 'PrivateKeyRecoveryPayloadsQuery' as const,
    FavoriteQuery: 'FavoriteQuery' as const,
    SportConversionCreditsQuery: 'SportConversionCreditsQuery' as const,
    CurrentUserAcceptedCurrenciesQuery: 'CurrentUserAcceptedCurrenciesQuery' as const,
    CheckoutTransactionStatusQuery: 'CheckoutTransactionStatusQuery' as const,
    TransactionStatusQuery: 'TransactionStatusQuery' as const,
    NotificationPreferencesQuery: 'NotificationPreferencesQuery' as const,
    CampaignConversionCreditQuery: 'CampaignConversionCreditQuery' as const,
    ReferralProgramConfigQuery: 'ReferralProgramConfigQuery' as const,
    CurrentUserBalances: 'CurrentUserBalances' as const,
    WalletChallengeQuery: 'WalletChallengeQuery' as const,
    GetCurrentUserWithdrawal: 'GetCurrentUserWithdrawal' as const,
    BundledAuctionQuery: 'BundledAuctionQuery' as const,
    FootballComposeBenchQuery: 'FootballComposeBenchQuery' as const,
    FootballPostBuyBenchObjectQuery: 'FootballPostBuyBenchObjectQuery' as const,
    FootballAdvancedComposeBuyQuery: 'FootballAdvancedComposeBuyQuery' as const,
    FixtureTimelineDialogQuery: 'FixtureTimelineDialogQuery' as const,
    BlueprintCardsQuery: 'BlueprintCardsQuery' as const,
    ChartQuery: 'ChartQuery' as const,
    LeagueTeamsQuery: 'LeagueTeamsQuery' as const,
    FootballSo5LeaderboardContenderDialogQuery: 'FootballSo5LeaderboardContenderDialogQuery' as const,
    GetCardRecommendation: 'GetCardRecommendation' as const,
    FootballOnboardingClubSelectionQuery: 'FootballOnboardingClubSelectionQuery' as const,
    ContenderBlockQuery: 'ContenderBlockQuery' as const,
    LeaderboardBlockQuery: 'LeaderboardBlockQuery' as const,
    LeaderboardGroupBlockQuery: 'LeaderboardGroupBlockQuery' as const,
    FootballForYouBlockQuery: 'FootballForYouBlockQuery' as const,
    ProBlockQuery: 'ProBlockQuery' as const,
    DeliverableItemOrdersToConfirm: 'DeliverableItemOrdersToConfirm' as const,
    ArenaGamesByLeagueQuery: 'ArenaGamesByLeagueQuery' as const,
    ArenaPageQuery: 'ArenaPageQuery' as const,
    RivalsDailyClaimButtonQuery: 'RivalsDailyClaimButtonQuery' as const,
    ClaimTicketQuery: 'ClaimTicketQuery' as const,
    RivalsDraftDailyMissionsQuery: 'RivalsDraftDailyMissionsQuery' as const,
    RivalsMyLineupsPastAndUpcoming: 'RivalsMyLineupsPastAndUpcoming' as const,
    ClubSelectionDialogQuery: 'ClubSelectionDialogQuery' as const,
    OnboardingClubSelectionQuery: 'OnboardingClubSelectionQuery' as const,
    PlayLayoutGameQuery: 'PlayLayoutGameQuery' as const,
    RivalsLeaderboardPastGamesQuery: 'RivalsLeaderboardPastGamesQuery' as const,
    DraftWithTacticQuery: 'DraftWithTacticQuery' as const,
    DraftTacticStatsQuery: 'DraftTacticStatsQuery' as const,
    GetRivalsSubstituablePlayers: 'GetRivalsSubstituablePlayers' as const,
    RivalsLineupsQuery: 'RivalsLineupsQuery' as const,
    RivalsMatchesMySquadQuery: 'RivalsMatchesMySquadQuery' as const,
    RivalsArenaLiveQuery: 'RivalsArenaLiveQuery' as const,
    RivalsArenaUpcomingQuery: 'RivalsArenaUpcomingQuery' as const,
    PastSeasonsSelectorQuery: 'PastSeasonsSelectorQuery' as const,
    CreateRivalsSquadDialogQuery: 'CreateRivalsSquadDialogQuery' as const,
    JoinSquadDialogQuery: 'JoinSquadDialogQuery' as const,
    SquadSettingsQuery: 'SquadSettingsQuery' as const,
    SquadsRedirectQuery: 'SquadsRedirectQuery' as const,
    MarketLeaderboardQuery: 'MarketLeaderboardQuery' as const,
    Last11Query: 'Last11Query' as const,
    myLastLineupQuery: 'myLastLineupQuery' as const,
    CampaignPageQuery: 'CampaignPageQuery' as const,
    CampaignsLayoutQuery: 'CampaignsLayoutQuery' as const,
    ClubPastFixturesQuery: 'ClubPastFixturesQuery' as const,
    ClubFutureFixturesQuery: 'ClubFutureFixturesQuery' as const,
    FootballSeoClubQuery: 'FootballSeoClubQuery' as const,
    ClubSquadQuery: 'ClubSquadQuery' as const,
    NationalTeamGamesNavigatorQuery: 'NationalTeamGamesNavigatorQuery' as const,
    NationalTeamPastFixturesQuery: 'NationalTeamPastFixturesQuery' as const,
    NationalTeamFutureFixturesQuery: 'NationalTeamFutureFixturesQuery' as const,
    CountryQuery: 'CountryQuery' as const,
    FootballNationalTeamOverviewQuery: 'FootballNationalTeamOverviewQuery' as const,
    NationalTeamPlayersQuery: 'NationalTeamPlayersQuery' as const,
    CraftPageQuery: 'CraftPageQuery' as const,
    CraftConfirmationCardSlugQuery: 'CraftConfirmationCardSlugQuery' as const,
    ReferralProgramConfig: 'ReferralProgramConfig' as const,
    TopPlayersLeagueQuery: 'TopPlayersLeagueQuery' as const,
    LeaguePastFixturesQuery: 'LeaguePastFixturesQuery' as const,
    LeagueFutureFixturesQuery: 'LeagueFutureFixturesQuery' as const,
    FootballSeoLeagueQuery: 'FootballSeoLeagueQuery' as const,
    FootballLeagueOverviewQuery: 'FootballLeagueOverviewQuery' as const,
    LoanTeamPageQuery: 'LoanTeamPageQuery' as const,
    CommonPacksTypePageQuery: 'CommonPacksTypePageQuery' as const,
    FootballCommonPacksQuery: 'FootballCommonPacksQuery' as const,
    So5SecondaryMarketStackPlayerQuery: 'So5SecondaryMarketStackPlayerQuery' as const,
    PVEQuery: 'PVEQuery' as const,
    FootballProHistoryFixturePageQuery: 'FootballProHistoryFixturePageQuery' as const,
    FootballProHistoryLayoutQuery: 'FootballProHistoryLayoutQuery' as const,
    FootballManagerProfileQuery: 'FootballManagerProfileQuery' as const,
    FootballLeaguesQuery: 'FootballLeaguesQuery' as const,
    FootballPlayQuery: 'FootballPlayQuery' as const,
    FixtureCompetitionTrckGrpSeasonalityPageQuery: 'FixtureCompetitionTrckGrpSeasonalityPageQuery' as const,
    FixtureCompetitionTrckGrpLayoutQuery: 'FixtureCompetitionTrckGrpLayoutQuery' as const,
    FixtureCompetitionCompetitionPageQuery: 'FixtureCompetitionCompetitionPageQuery' as const,
    FixtureCompetitionPageQuery: 'FixtureCompetitionPageQuery' as const,
    FootballEnterSeasonalityCompetitionGamesPageQuery: 'FootballEnterSeasonalityCompetitionGamesPageQuery' as const,
    FootballEnterSeasonalityCompetitionGamesPaginatedPageQuery: 'FootballEnterSeasonalityCompetitionGamesPaginatedPageQuery' as const,
    FootballEnterSeasonalityCompetitionTrckPageQuery: 'FootballEnterSeasonalityCompetitionTrckPageQuery' as const,
    FootballFixtureEnterSeasonalityCompetitionLayoutQuery: 'FootballFixtureEnterSeasonalityCompetitionLayoutQuery' as const,
    FootballContenderPageQuery: 'FootballContenderPageQuery' as const,
    FootballPlayProFixtureMatchCenterQuery: 'FootballPlayProFixtureMatchCenterQuery' as const,
    FootballPlayProFixturePageQuery: 'FootballPlayProFixturePageQuery' as const,
    FootballComposeLeaderboardLineupPageQuery: 'FootballComposeLeaderboardLineupPageQuery' as const,
    FootballComposeLeaderboardManagerTeamPageQuery: 'FootballComposeLeaderboardManagerTeamPageQuery' as const,
    FootballComposeLeaderboardPageQuery: 'FootballComposeLeaderboardPageQuery' as const,
    FootballRankingsLineupPageQuery: 'FootballRankingsLineupPageQuery' as const,
    FootballRankingsPageQuery: 'FootballRankingsPageQuery' as const,
    FootballLineupSharingQuery: 'FootballLineupSharingQuery' as const,
    LineupRedirectQuery: 'LineupRedirectQuery' as const,
    GetCurrentUserOnboardingTasksQuery: 'GetCurrentUserOnboardingTasksQuery' as const,
    FootballOnboardingComposePageQuery: 'FootballOnboardingComposePageQuery' as const,
    PreFootballOnboardingComposePageQuery: 'PreFootballOnboardingComposePageQuery' as const,
    FootballPlayRewardsFixtureLayoutQuery: 'FootballPlayRewardsFixtureLayoutQuery' as const,
    RivalsMatchMarketQuery: 'RivalsMatchMarketQuery' as const,
    RivalsLeaderboardSlugArenaQuery: 'RivalsLeaderboardSlugArenaQuery' as const,
    LeaderboardSelectedUserQuery: 'LeaderboardSelectedUserQuery' as const,
    RivalsSeasonLeaderboardQuery: 'RivalsSeasonLeaderboardQuery' as const,
    SquadVsSquadArenaPageQuery: 'SquadVsSquadArenaPageQuery' as const,
    SquadVsSquadUserLayoutQuery: 'SquadVsSquadUserLayoutQuery' as const,
    SquadVsSquadSelectedSquadQuery: 'SquadVsSquadSelectedSquadQuery' as const,
    SquadsVsSquadsLeaderboardQuery: 'SquadsVsSquadsLeaderboardQuery' as const,
    GetCurrentSeasonSlugQuery: 'GetCurrentSeasonSlugQuery' as const,
    RivalsDraftSubstitutesGameIdQuery: 'RivalsDraftSubstitutesGameIdQuery' as const,
    RivalsDraftSubstitutesQuery: 'RivalsDraftSubstitutesQuery' as const,
    RivalsGameRedirectQuery: 'RivalsGameRedirectQuery' as const,
    PlayRivalsTabsQuery: 'PlayRivalsTabsQuery' as const,
    FetchGameStatusQuery: 'FetchGameStatusQuery' as const,
    FootballRivalsMyLineupsQuery: 'FootballRivalsMyLineupsQuery' as const,
    CreateSquadLayoutQuery: 'CreateSquadLayoutQuery' as const,
    MySquadLeaderboardSeasonSlugUserSlugArenaQuery: 'MySquadLeaderboardSeasonSlugUserSlugArenaQuery' as const,
    MySquadLeaderboardSeasonSlugUserSlugQuery: 'MySquadLeaderboardSeasonSlugUserSlugQuery' as const,
    FootballRivalsMySquadLeaderboardQuery: 'FootballRivalsMySquadLeaderboardQuery' as const,
    GameSimulationQuery: 'GameSimulationQuery' as const,
    JoinRivalsSquadQuery: 'JoinRivalsSquadQuery' as const,
    PlayerGamesNavigatorQuery: 'PlayerGamesNavigatorQuery' as const,
    SeasonStatsQuery: 'SeasonStatsQuery' as const,
    PlayerPastFixturesQuery: 'PlayerPastFixturesQuery' as const,
    PlayerFutureFixturesQuery: 'PlayerFutureFixturesQuery' as const,
    FootballSeoPlayerQuery: 'FootballSeoPlayerQuery' as const,
    FootballPlayerOverviewQuery: 'FootballPlayerOverviewQuery' as const,
    CardCollectionQuery: 'CardCollectionQuery' as const,
    ChallengeSharingQuery: 'ChallengeSharingQuery' as const,
    FootballRivalsInviteRenderingQuery: 'FootballRivalsInviteRenderingQuery' as const,
    RivalsSquadInviteQuery: 'RivalsSquadInviteQuery' as const,
    FootballSo5LineupQuery: 'FootballSo5LineupQuery' as const,
    RewardBoxPageQuery: 'RewardBoxPageQuery' as const,
    LoggedOutRivalsLeaguesPrizePoolPageQuery: 'LoggedOutRivalsLeaguesPrizePoolPageQuery' as const,
    ScoutingCenterSo5LeaderboardGroupPageQuery: 'ScoutingCenterSo5LeaderboardGroupPageQuery' as const,
    FootballScoutingCenterSo5CompetitionPage: 'FootballScoutingCenterSo5CompetitionPage' as const,
    FootballSeasonStandingsQuery: 'FootballSeasonStandingsQuery' as const,
    ClubGamesNavigatorQuery: 'ClubGamesNavigatorQuery' as const,
    FootballClubOverviewQuery: 'FootballClubOverviewQuery' as const,
    FootballClubStandingsQuery: 'FootballClubStandingsQuery' as const,
    LeaderboardGroupQuery: 'LeaderboardGroupQuery' as const,
    MatchViewPlayerDetailsQuery: 'MatchViewPlayerDetailsQuery' as const,
    MatchViewFormationsQuery: 'MatchViewFormationsQuery' as const,
    RevealedCardsSummaryQuery: 'RevealedCardsSummaryQuery' as const,
    GetNewsQuery: 'GetNewsQuery' as const,
    GetNotificationsNewsQuery: 'GetNotificationsNewsQuery' as const,
    GetNotificationsQuery: 'GetNotificationsQuery' as const,
    GetSpecificNewsQuery: 'GetSpecificNewsQuery' as const,
    CardWithTokenPageContentQuery: 'CardWithTokenPageContentQuery' as const,
    ChaseNoLineupsQuery: 'ChaseNoLineupsQuery' as const,
    CollectionsQuery: 'CollectionsQuery' as const,
    CollectionCardsQuery: 'CollectionCardsQuery' as const,
    CollectionLeaderboardQuery: 'CollectionLeaderboardQuery' as const,
    CollectionLeaderboardPageQuery: 'CollectionLeaderboardPageQuery' as const,
    NewCollectionLayoutQuery: 'NewCollectionLayoutQuery' as const,
    ComposeBuyPacksQuery: 'ComposeBuyPacksQuery' as const,
    TokenPriceQuery: 'TokenPriceQuery' as const,
    PreFetchSo5BenchMarketplaceQuery: 'PreFetchSo5BenchMarketplaceQuery' as const,
    ContenderDrawerQuery: 'ContenderDrawerQuery' as const,
    EssenceOddsDialogQuery: 'EssenceOddsDialogQuery' as const,
    FixtureSelectorQuery: 'FixtureSelectorQuery' as const,
    UpcomingFixtureLeagueQuery: 'UpcomingFixtureLeagueQuery' as const,
    ImportLineupQuery: 'ImportLineupQuery' as const,
    ReferralListQuery: 'ReferralListQuery' as const,
    referralLeaderboardQuery: 'referralLeaderboardQuery' as const,
    CumulativeCurrentGWLeaderboardQuery: 'CumulativeCurrentGWLeaderboardQuery' as const,
    CumulativePaginatedRankingsQuery: 'CumulativePaginatedRankingsQuery' as const,
    PastLeaderboardListQuery: 'PastLeaderboardListQuery' as const,
    CumulativeRankRewardsQuery: 'CumulativeRankRewardsQuery' as const,
    LazyRankRewardsQuery: 'LazyRankRewardsQuery' as const,
    PaginatedRankingsQuery: 'PaginatedRankingsQuery' as const,
    LoanStatusQuery: 'LoanStatusQuery' as const,
    YourNextStepQuery: 'YourNextStepQuery' as const,
    LazyWarningDialogQuery: 'LazyWarningDialogQuery' as const,
    LeaderboardComposableQuery: 'LeaderboardComposableQuery' as const,
    LineupRewardsDistancesQuery: 'LineupRewardsDistancesQuery' as const,
    DropChallengePickerDialogQuery: 'DropChallengePickerDialogQuery' as const,
    MissionProbabilisticBundleQuery: 'MissionProbabilisticBundleQuery' as const,
    DecisivePlayerPickerDialogQuery: 'DecisivePlayerPickerDialogQuery' as const,
    CardsPerGameQuery: 'CardsPerGameQuery' as const,
    CardsBoostSummaryQuery: 'CardsBoostSummaryQuery' as const,
    CardsDropSummaryQuery: 'CardsDropSummaryQuery' as const,
    MyClubCardsPageQuery: 'MyClubCardsPageQuery' as const,
    MyClubNavItemQuery: 'MyClubNavItemQuery' as const,
    FootballNavQuery: 'FootballNavQuery' as const,
    BaseballNavQuery: 'BaseballNavQuery' as const,
    NBANavQuery: 'NBANavQuery' as const,
    NetworkFollowersQuery: 'NetworkFollowersQuery' as const,
    NetworkFollowingQuery: 'NetworkFollowingQuery' as const,
    NetworkRecommendedQuery: 'NetworkRecommendedQuery' as const,
    NoCardEntryRegisterDialogConfiQuery: 'NoCardEntryRegisterDialogConfiQuery' as const,
    InGameNotificationQuery: 'InGameNotificationQuery' as const,
    FixtureScoresQuery: 'FixtureScoresQuery' as const,
    PlayerDetailsQuery: 'PlayerDetailsQuery' as const,
    RepresentedByQuery: 'RepresentedByQuery' as const,
    RenderingGalleryQuery: 'RenderingGalleryQuery' as const,
    CardOpeningOnboardingDialogQuery: 'CardOpeningOnboardingDialogQuery' as const,
    LazyPrizePoolDrawerQuery: 'LazyPrizePoolDrawerQuery' as const,
    FixtureRewardsQuery: 'FixtureRewardsQuery' as const,
    LazyBoxOverviewDialogQuery: 'LazyBoxOverviewDialogQuery' as const,
    UnclaimedDeliveryItemOrders: 'UnclaimedDeliveryItemOrders' as const,
    StarterPackCardsQuery: 'StarterPackCardsQuery' as const,
    UnclaimedDeliverableItemOrder: 'UnclaimedDeliverableItemOrder' as const,
    ShopItemPickerQuery: 'ShopItemPickerQuery' as const,
    LeagueDetailsStepQuery: 'LeagueDetailsStepQuery' as const,
    WinPrizesStepQuery: 'WinPrizesStepQuery' as const,
    UnclaimedDeliverableItemOrders: 'UnclaimedDeliverableItemOrders' as const,
    TestLastFixtureStory: 'TestLastFixtureStory' as const,
    BenchObjectDrawerQuery: 'BenchObjectDrawerQuery' as const,
    LazyFixtureChartQuery: 'LazyFixtureChartQuery' as const,
    PlayerGameScoreDialogQuery: 'PlayerGameScoreDialogQuery' as const,
    PlayerGameScoreDrawerQuery: 'PlayerGameScoreDrawerQuery' as const,
    AppearanceDialogQuery: 'AppearanceDialogQuery' as const,
    BasicFixtureLayoutQuery: 'BasicFixtureLayoutQuery' as const,
    FixtureEnterSeasonalityLayoutQuery: 'FixtureEnterSeasonalityLayoutQuery' as const,
    FixtureLayoutQuery: 'FixtureLayoutQuery' as const,
    MyClubSlugLayoutQuery: 'MyClubSlugLayoutQuery' as const,
    ProHistoryFixtureLayoutQuery: 'ProHistoryFixtureLayoutQuery' as const,
    InventoryQuery: 'InventoryQuery' as const,
    RevealableCampaignQuery: 'RevealableCampaignQuery' as const,
    InventoryBlueprintTypeQuery: 'InventoryBlueprintTypeQuery' as const,
    BoxesLayoutQuery: 'BoxesLayoutQuery' as const,
    EssenceLayoutQuery: 'EssenceLayoutQuery' as const,
    MyCardsCardsLayoutQuery: 'MyCardsCardsLayoutQuery' as const,
    MyCardsLayoutQuery: 'MyCardsLayoutQuery' as const,
    MyClubCardsLayoutQuery: 'MyClubCardsLayoutQuery' as const,
    RewardsEssenceLayoutQuery: 'RewardsEssenceLayoutQuery' as const,
    RewardsFixtureLayoutQuery: 'RewardsFixtureLayoutQuery' as const,
    MatchCenterPaginatedQuery: 'MatchCenterPaginatedQuery' as const,
    PlayPageBaseQuery: 'PlayPageBaseQuery' as const,
    PrizePoolContenderPageQuery: 'PrizePoolContenderPageQuery' as const,
    InventoryBoxesTypeQuery: 'InventoryBoxesTypeQuery' as const,
    InventoryShardsPageQuery: 'InventoryShardsPageQuery' as const,
    ClubEditorPageQuery: 'ClubEditorPageQuery' as const,
    RenderingBaseballDeckQuery: 'RenderingBaseballDeckQuery' as const,
    RenderingFootballDeckQuery: 'RenderingFootballDeckQuery' as const,
    RenderingNbaDeckQuery: 'RenderingNbaDeckQuery' as const,
    RenderingBaseballManagerIdQuery: 'RenderingBaseballManagerIdQuery' as const,
    RenderingFootballManagerIdQuery: 'RenderingFootballManagerIdQuery' as const,
    RenderingNBAManagerIdQuery: 'RenderingNBAManagerIdQuery' as const,
    RewardsEssenceQualityRarityPageQuery: 'RewardsEssenceQualityRarityPageQuery' as const,
    RewardsFixtureLeagueQualityRarityPageQuery: 'RewardsFixtureLeagueQualityRarityPageQuery' as const,
    MarketBannerQuery: 'MarketBannerQuery' as const,
    TokenAuctionLeaderQuery: 'TokenAuctionLeaderQuery' as const,
    CalendarTooltipQuery: 'CalendarTooltipQuery' as const,
    BidPaymentFlowQuery: 'BidPaymentFlowQuery' as const,
    TokenAuctionBestBidQuery: 'TokenAuctionBestBidQuery' as const,
    BuyPaymentFlowQuery: 'BuyPaymentFlowQuery' as const,
    BuyTokenConfirmationQueryById: 'BuyTokenConfirmationQueryById' as const,
    KlarnaMinimumAmountQuery: 'KlarnaMinimumAmountQuery' as const,
    RefreshBillingAddress: 'RefreshBillingAddress' as const,
    ExchangeRateQuery: 'ExchangeRateQuery' as const,
    AddToCartPopinQuery: 'AddToCartPopinQuery' as const,
    CartBuyerQuery: 'CartBuyerQuery' as const,
    RefreshCartPriceAndSignedAmount: 'RefreshCartPriceAndSignedAmount' as const,
    CheckoutQuery: 'CheckoutQuery' as const,
    LazyCollectionUpsellElementQuery: 'LazyCollectionUpsellElementQuery' as const,
    MissingCardsForCollectionQuery: 'MissingCardsForCollectionQuery' as const,
    BuyGemsDialogQuery: 'BuyGemsDialogQuery' as const,
    DeckEditorQuery: 'DeckEditorQuery' as const,
    CardsForDeckQuery: 'CardsForDeckQuery' as const,
    NewOfferCardsQuery: 'NewOfferCardsQuery' as const,
    LoanWelcomeScreenQuery: 'LoanWelcomeScreenQuery' as const,
    TopPlayerFollowQuery: 'TopPlayerFollowQuery' as const,
    AllTokenAuctionsQuery: 'AllTokenAuctionsQuery' as const,
    LostTokenAuctionsQuery: 'LostTokenAuctionsQuery' as const,
    OpenTokenAuctionsQuery: 'OpenTokenAuctionsQuery' as const,
    WonTokenAuctionsQuery: 'WonTokenAuctionsQuery' as const,
    CurrentUserFollowsQuery: 'CurrentUserFollowsQuery' as const,
    MyNewsQuery: 'MyNewsQuery' as const,
    OfferQuery: 'OfferQuery' as const,
    BoughtPrimaryOffersQuery: 'BoughtPrimaryOffersQuery' as const,
    PurchasesQuery: 'PurchasesQuery' as const,
    AllSaleOffersQuery: 'AllSaleOffersQuery' as const,
    NoBuyerSaleOffersQuery: 'NoBuyerSaleOffersQuery' as const,
    OpenSaleOffersQuery: 'OpenSaleOffersQuery' as const,
    SoldSaleOffersQuery: 'SoldSaleOffersQuery' as const,
    FollowsForNotificationsQuery: 'FollowsForNotificationsQuery' as const,
    SpentFiatPaymentIntentsQuery: 'SpentFiatPaymentIntentsQuery' as const,
    UserAccountEntriesQuery: 'UserAccountEntriesQuery' as const,
    GetCounterOfferQuery: 'GetCounterOfferQuery' as const,
    MyOffersQuery: 'MyOffersQuery' as const,
    CancelSalePopinQuery: 'CancelSalePopinQuery' as const,
    OfferDialogQuery: 'OfferDialogQuery' as const,
    LazyAllLastOwnedCardsQuery: 'LazyAllLastOwnedCardsQuery' as const,
    LazyAllLastOwnedCardsBySo5CompetitionQuery: 'LazyAllLastOwnedCardsBySo5CompetitionQuery' as const,
    PlayerMarketDrawerContentQuery: 'PlayerMarketDrawerContentQuery' as const,
    PlayerPriceGraphQuery: 'PlayerPriceGraphQuery' as const,
    AvailablePrimaryOfferForAPlayerQuery: 'AvailablePrimaryOfferForAPlayerQuery' as const,
    BuyPrimaryBuyConfirmationQueryById: 'BuyPrimaryBuyConfirmationQueryById' as const,
    PrimaryBuyPaymentFlowQuery: 'PrimaryBuyPaymentFlowQuery' as const,
    RefreshSignedAmountQuery: 'RefreshSignedAmountQuery' as const,
    PrimaryBuyModalGridQuery: 'PrimaryBuyModalGridQuery' as const,
    MarketFeesOfferQuery: 'MarketFeesOfferQuery' as const,
    RarityVaultBannerQuery: 'RarityVaultBannerQuery' as const,
    RarityVaultHowItWorksDialogQuery: 'RarityVaultHowItWorksDialogQuery' as const,
    SealCardsDialogQuery: 'SealCardsDialogQuery' as const,
    SealingRecapQuery: 'SealingRecapQuery' as const,
    UnsealCardDialogQuery: 'UnsealCardDialogQuery' as const,
    UnsealingAnimationQuery: 'UnsealingAnimationQuery' as const,
    AuctionOwnershipDetailsQuery: 'AuctionOwnershipDetailsQuery' as const,
    DirectOfferOwnershipDetailsQuery: 'DirectOfferOwnershipDetailsQuery' as const,
    AlternativeStackedTokensByIdsQuery: 'AlternativeStackedTokensByIdsQuery' as const,
    TokensRowsByObjectIds: 'TokensRowsByObjectIds' as const,
    UsedInLineupValidatorQuery: 'UsedInLineupValidatorQuery' as const,
    EthereumTokensQuery: 'EthereumTokensQuery' as const,
    CurrentMarketplaceUserQuery: 'CurrentMarketplaceUserQuery' as const,
    NextRelayBatchNonceQuery: 'NextRelayBatchNonceQuery' as const,
    currentBlockHeightQuery: 'currentBlockHeightQuery' as const,
    PollInGameCurrencyPackDelivery: 'PollInGameCurrencyPackDelivery' as const,
    PrintablePlayerFacetValuesSearchQuery: 'PrintablePlayerFacetValuesSearchQuery' as const,
    UserCardsSearchFacetValuesQuery: 'UserCardsSearchFacetValuesQuery' as const,
    UserCardsSearchQuery: 'UserCardsSearchQuery' as const,
    PriceHistoryQuery: 'PriceHistoryQuery' as const,
    PrimaryOfferBuyerQuery: 'PrimaryOfferBuyerQuery' as const,
    ScoutingCenterQuery: 'ScoutingCenterQuery' as const,
    CardsQuery: 'CardsQuery' as const,
    AdvancedPlayersSearchQuery: 'AdvancedPlayersSearchQuery' as const,
    NbaPlayerGamesNavigatorQuery: 'NbaPlayerGamesNavigatorQuery' as const,
    NBAComposeBenchQuery: 'NBAComposeBenchQuery' as const,
    NbaPostBuyBenchObjectQuery: 'NbaPostBuyBenchObjectQuery' as const,
    NBAComposeBuyQuery: 'NBAComposeBuyQuery' as const,
    NBAComposePrintablePlayersSearchQuery: 'NBAComposePrintablePlayersSearchQuery' as const,
    NBAHomeOnboardingQuery: 'NBAHomeOnboardingQuery' as const,
    NbaLeagueTeamsQuery: 'NbaLeagueTeamsQuery' as const,
    NBASo5LeaderboardContenderDialogQuery: 'NBASo5LeaderboardContenderDialogQuery' as const,
    BoostedTipoffRewardExplainedQuery: 'BoostedTipoffRewardExplainedQuery' as const,
    NBADailyHoopsOnboardingQuery: 'NBADailyHoopsOnboardingQuery' as const,
    NBAOnboardingBuildYourClubQuery: 'NBAOnboardingBuildYourClubQuery' as const,
    NBAOnboardingSelectStarPlayerQuery: 'NBAOnboardingSelectStarPlayerQuery' as const,
    TierDetailsQuery: 'TierDetailsQuery' as const,
    TiersOnboardingQuery: 'TiersOnboardingQuery' as const,
    NBAProBlockQuery: 'NBAProBlockQuery' as const,
    DailyClaimButtonQuery: 'DailyClaimButtonQuery' as const,
    NBADAilyClaimDialogQuery: 'NBADAilyClaimDialogQuery' as const,
    useIsUserDailyPickupEligibleQuery: 'useIsUserDailyPickupEligibleQuery' as const,
    useIsUserProEligibleQuery: 'useIsUserProEligibleQuery' as const,
    GameOfBasketballQuery: 'GameOfBasketballQuery' as const,
    NBAPlayQuery: 'NBAPlayQuery' as const,
    NBAReferralProgramConfig: 'NBAReferralProgramConfig' as const,
    NBAPrintablePlayersSearchQuery: 'NBAPrintablePlayersSearchQuery' as const,
    NBASecondaryMarketStackPlayerQuery: 'NBASecondaryMarketStackPlayerQuery' as const,
    PVENBAQuery: 'PVENBAQuery' as const,
    NBAProHistoryFixturePageQuery: 'NBAProHistoryFixturePageQuery' as const,
    NBAProHistoryLayoutQuery: 'NBAProHistoryLayoutQuery' as const,
    NbaMyClubQuery: 'NbaMyClubQuery' as const,
    NBAEnterSeasonalityCompetitionGamesPageQuery: 'NBAEnterSeasonalityCompetitionGamesPageQuery' as const,
    NBAEnterSeasonalityCompetitionGamesPaginatedPageQuery: 'NBAEnterSeasonalityCompetitionGamesPaginatedPageQuery' as const,
    NBAEnterSeasonalityCompetitionTrckPageQuery: 'NBAEnterSeasonalityCompetitionTrckPageQuery' as const,
    NBAFixtureEnterSeasonalityCompetitionLayoutQuery: 'NBAFixtureEnterSeasonalityCompetitionLayoutQuery' as const,
    NbaPlayRewardsFixtureLayoutQuery: 'NbaPlayRewardsFixtureLayoutQuery' as const,
    NBAContenderPageQuery: 'NBAContenderPageQuery' as const,
    NBAPlayEventTyoeFixtureMatchCenterQuery: 'NBAPlayEventTyoeFixtureMatchCenterQuery' as const,
    NBAPlayEventTypeFixturePageQuery: 'NBAPlayEventTypeFixturePageQuery' as const,
    NBAComposeLeaderboardLineupPageQuery: 'NBAComposeLeaderboardLineupPageQuery' as const,
    NBAComposeLeaderboardManagerTeamPageQuery: 'NBAComposeLeaderboardManagerTeamPageQuery' as const,
    NBAComposeLeaderboardPageQuery: 'NBAComposeLeaderboardPageQuery' as const,
    NBARankingsLineupPageQuery: 'NBARankingsLineupPageQuery' as const,
    NBARankingsPageQuery: 'NBARankingsPageQuery' as const,
    NBALineupSharingQuery: 'NBALineupSharingQuery' as const,
    NBALineupsPageQuery: 'NBALineupsPageQuery' as const,
    NBALobbyPageQuery: 'NBALobbyPageQuery' as const,
    NBARootPlayPageQuery: 'NBARootPlayPageQuery' as const,
    NBARootPlayPageAdditionalQuery: 'NBARootPlayPageAdditionalQuery' as const,
    NbaPlayerCardsQuery: 'NbaPlayerCardsQuery' as const,
    NbaPlayerPastFixturesQuery: 'NbaPlayerPastFixturesQuery' as const,
    NbaPlayerFutureFixturesQuery: 'NbaPlayerFutureFixturesQuery' as const,
    NbaSeoPlayerQuery: 'NbaSeoPlayerQuery' as const,
    NBAPlayerOverviewQuery: 'NBAPlayerOverviewQuery' as const,
    NbaPlayerTransactionsQuery: 'NbaPlayerTransactionsQuery' as const,
    NBARewardBoxPageQuery: 'NBARewardBoxPageQuery' as const,
    ScoreGameIdQuery: 'ScoreGameIdQuery' as const,
    NBAScoresQuery: 'NBAScoresQuery' as const,
    NbaTeamCardQuery: 'NbaTeamCardQuery' as const,
    NbaSeoTeamQuery: 'NbaSeoTeamQuery' as const,
    NbaTeamOverviewQuery: 'NbaTeamOverviewQuery' as const,
    CoverageQuery: 'CoverageQuery' as const,
    FootballCompetitionQuery: 'FootballCompetitionQuery' as const,
    LicensedClubsQuery: 'LicensedClubsQuery' as const,
    NetworkUserQuery: 'NetworkUserQuery' as const,
    AdminLeaderboardRewardsPageQuery: 'AdminLeaderboardRewardsPageQuery' as const,
    contentCreatorRevealQuery: 'contentCreatorRevealQuery' as const
  },
  Mutation: {
    ConsumeEphemeralTokenMutation: 'ConsumeEphemeralTokenMutation' as const,
    EnergyGiftedMutation: 'EnergyGiftedMutation' as const,
    MLBOnboardingCommonCardDeckMutation: 'MLBOnboardingCommonCardDeckMutation' as const,
    SignInWithOAuthMutation: 'SignInWithOAuthMutation' as const,
    VisitCampaignPage: 'VisitCampaignPage' as const,
    ValidateApplePayMerchantSession: 'ValidateApplePayMerchantSession' as const,
    confirmDeviceWithCodeMutation: 'confirmDeviceWithCodeMutation' as const,
    AddDeviceMutation: 'AddDeviceMutation' as const,
    UpdateUserSettingsDiscoverableMutation: 'UpdateUserSettingsDiscoverableMutation' as const,
    ValidateFiatWalletMutation: 'ValidateFiatWalletMutation' as const,
    UnblockWalletMutation: 'UnblockWalletMutation' as const,
    UpdatePartnerOffersAgreementMutation: 'UpdatePartnerOffersAgreementMutation' as const,
    UnblockUserMutation: 'UnblockUserMutation' as const,
    DestroyUserAccountMutation: 'DestroyUserAccountMutation' as const,
    revokeDeviceMutation: 'revokeDeviceMutation' as const,
    AddEthereumAccountMutation: 'AddEthereumAccountMutation' as const,
    LogOutAllDevicesMutation: 'LogOutAllDevicesMutation' as const,
    UpdateUserSettingsMutation: 'UpdateUserSettingsMutation' as const,
    RevokeOAuthAccessTokenForApplicationMutation: 'RevokeOAuthAccessTokenForApplicationMutation' as const,
    DetachPaymentMethodMutation: 'DetachPaymentMethodMutation' as const,
    DeletePostalAddressMutation: 'DeletePostalAddressMutation' as const,
    UpdateUserPostalAddressMutation: 'UpdateUserPostalAddressMutation' as const,
    UpdateUserSettingsRewardCurrencyMutation: 'UpdateUserSettingsRewardCurrencyMutation' as const,
    UnblockEmailMutation: 'UnblockEmailMutation' as const,
    UpdateUserSettingsLocaleMutation: 'UpdateUserSettingsLocaleMutation' as const,
    DisconnectOmniauthProviderMutation: 'DisconnectOmniauthProviderMutation' as const,
    CheckPhoneNumberMutation: 'CheckPhoneNumberMutation' as const,
    VerifyPhoneNumberMutation: 'VerifyPhoneNumberMutation' as const,
    DepositWithApplePay: 'DepositWithApplePay' as const,
    CreateWireTransferDeposit: 'CreateWireTransferDeposit' as const,
    CreateDepositBankAccount: 'CreateDepositBankAccount' as const,
    CancelFastWithdrawalMutation: 'CancelFastWithdrawalMutation' as const,
    createWithdrawalBankAccountMutation: 'createWithdrawalBankAccountMutation' as const,
    deleteWithdrawalBankAccount: 'deleteWithdrawalBankAccount' as const,
    createFiatWithdrawalMutation: 'createFiatWithdrawalMutation' as const,
    CancelWithdrawalMutation: 'CancelWithdrawalMutation' as const,
    CreatePendingDepositMutation: 'CreatePendingDepositMutation' as const,
    GetTokenDepositInfoMutation: 'GetTokenDepositInfoMutation' as const,
    PrepareEthDepositMutation: 'PrepareEthDepositMutation' as const,
    UpdateUserSettingsLastWeb3ProviderMutation: 'UpdateUserSettingsLastWeb3ProviderMutation' as const,
    ReportTelemetry: 'ReportTelemetry' as const,
    AcceptTermsMutation: 'AcceptTermsMutation' as const,
    SignInMutation: 'SignInMutation' as const,
    ResendConfirmationInstructionsMutation: 'ResendConfirmationInstructionsMutation' as const,
    ResetPrivateKeyMutation: 'ResetPrivateKeyMutation' as const,
    UpdateExternalEthDeposit: 'UpdateExternalEthDeposit' as const,
    UpdateUserEmailMutation: 'UpdateUserEmailMutation' as const,
    FetchEncryptedPrivateKey: 'FetchEncryptedPrivateKey' as const,
    SignUpMutationWithUser: 'SignUpMutationWithUser' as const,
    CreateCardAuthorization: 'CreateCardAuthorization' as const,
    CreateCardRegistration: 'CreateCardRegistration' as const,
    CreateCheckoutCardAuthorization: 'CreateCheckoutCardAuthorization' as const,
    CreateFiatPayer: 'CreateFiatPayer' as const,
    PrepareDeposit: 'PrepareDeposit' as const,
    AddTokensToDeckMutation: 'AddTokensToDeckMutation' as const,
    CreateDeckMutation: 'CreateDeckMutation' as const,
    DeleteDeckMutation: 'DeleteDeckMutation' as const,
    EditDeckMutation: 'EditDeckMutation' as const,
    RemoveTokenFromDeckMutation: 'RemoveTokenFromDeckMutation' as const,
    UseMutationTestingMutation: 'UseMutationTestingMutation' as const,
    PrepareIdealDeposit: 'PrepareIdealDeposit' as const,
    MarkNotificationsAsReadMutation: 'MarkNotificationsAsReadMutation' as const,
    PreparePaypalDeposit: 'PreparePaypalDeposit' as const,
    ActivateWalletRecoveryEmailMutation: 'ActivateWalletRecoveryEmailMutation' as const,
    AddWalletRecoveryEmailMutation: 'AddWalletRecoveryEmailMutation' as const,
    DeactivateWalletRecoveryEmailMutation: 'DeactivateWalletRecoveryEmailMutation' as const,
    ResendVerifationCodeForRecoveryEmailMutation: 'ResendVerifationCodeForRecoveryEmailMutation' as const,
    SendWalletRecoveryMutation: 'SendWalletRecoveryMutation' as const,
    ClaimSo5ReferralRewardMutation: 'ClaimSo5ReferralRewardMutation' as const,
    DestroyReferralMutation: 'DestroyReferralMutation' as const,
    CreateEthMigrationMutation: 'CreateEthMigrationMutation' as const,
    CreateSubscriptionMutation: 'CreateSubscriptionMutation' as const,
    DeleteSubscriptionMutation: 'DeleteSubscriptionMutation' as const,
    UpdateSubscriptionMutation: 'UpdateSubscriptionMutation' as const,
    UpdateLifecycleMutation: 'UpdateLifecycleMutation' as const,
    UpdateUserSettingsHideBalanceMutation: 'UpdateUserSettingsHideBalanceMutation' as const,
    Enable2FAMutation: 'Enable2FAMutation' as const,
    Disable2FAMutation: 'Disable2FAMutation' as const,
    GenerateOtpProvisioningUriMutation: 'GenerateOtpProvisioningUriMutation' as const,
    GenerateOtpBackupCodesMutation: 'GenerateOtpBackupCodesMutation' as const,
    UpdateUserSettingsCurrencyMutation: 'UpdateUserSettingsCurrencyMutation' as const,
    UpdateSportProfileMutation: 'UpdateSportProfileMutation' as const,
    UpdateUserProfileMutation: 'UpdateUserProfileMutation' as const,
    BlockUserMutation: 'BlockUserMutation' as const,
    CreateJWTToken: 'CreateJWTToken' as const,
    CreateEphemeralLink: 'CreateEphemeralLink' as const,
    CreateFiatWalletMutation: 'CreateFiatWalletMutation' as const,
    UpdateFiatWalletMutation: 'UpdateFiatWalletMutation' as const,
    EnableWalletsMutation: 'EnableWalletsMutation' as const,
    CreateFastWithdrawalMutation: 'CreateFastWithdrawalMutation' as const,
    PrepareFastWithdrawalMutation: 'PrepareFastWithdrawalMutation' as const,
    CreateWithdrawMutation: 'CreateWithdrawMutation' as const,
    unlockProModeMutation: 'unlockProModeMutation' as const,
    FootballOnboardingUpdateFavoriteTeamMutation: 'FootballOnboardingUpdateFavoriteTeamMutation' as const,
    ClaimMissionTaskMutation: 'ClaimMissionTaskMutation' as const,
    RivalsUpdateFavoriteTeam: 'RivalsUpdateFavoriteTeam' as const,
    CreateCompetitionSubscriptionMutation: 'CreateCompetitionSubscriptionMutation' as const,
    DeleteCompetitionSubscriptionMutation: 'DeleteCompetitionSubscriptionMutation' as const,
    footballRivalsRemindGameMutation: 'footballRivalsRemindGameMutation' as const,
    UpsertRivalsDraftLineupMutation: 'UpsertRivalsDraftLineupMutation' as const,
    sendNudgeMutation: 'sendNudgeMutation' as const,
    CreateSquadMutation: 'CreateSquadMutation' as const,
    UpdateSquadVisibilityMutation: 'UpdateSquadVisibilityMutation' as const,
    TransferCaptaincyMutation: 'TransferCaptaincyMutation' as const,
    joinSquadMutation: 'joinSquadMutation' as const,
    RemoveUserFromSquad: 'RemoveUserFromSquad' as const,
    FootballLoadPreviousLineupMutation: 'FootballLoadPreviousLineupMutation' as const,
    RivalsAppearanceSubstituteMutation: 'RivalsAppearanceSubstituteMutation' as const,
    RevealCard: 'RevealCard' as const,
    SpendShards: 'SpendShards' as const,
    CancelImportLineup: 'CancelImportLineup' as const,
    ConfirmImportLineup: 'ConfirmImportLineup' as const,
    hideMyFeaturedSo5LeaderboardGroup: 'hideMyFeaturedSo5LeaderboardGroup' as const,
    claimManagerProgressionTask: 'claimManagerProgressionTask' as const,
    claimTaskMutation: 'claimTaskMutation' as const,
    ClaimDailyPackMutation: 'ClaimDailyPackMutation' as const,
    completeDropChallengeMutation: 'completeDropChallengeMutation' as const,
    claimDailyMissionTaskMutation: 'claimDailyMissionTaskMutation' as const,
    ClaimMissionMutation: 'ClaimMissionMutation' as const,
    UpsertTaskAppearances: 'UpsertTaskAppearances' as const,
    AcceptSo5NoCardLineupMutation: 'AcceptSo5NoCardLineupMutation' as const,
    DeclineSo5NoCardLineupMutation: 'DeclineSo5NoCardLineupMutation' as const,
    ConfirmSo5NoCardLineupMutation: 'ConfirmSo5NoCardLineupMutation' as const,
    AcceptNoCardDraftableAppearanceMutation: 'AcceptNoCardDraftableAppearanceMutation' as const,
    GenerateSo5NoCardLineupMutation: 'GenerateSo5NoCardLineupMutation' as const,
    RequestSo5NoCardDraftableAppearanceMutation: 'RequestSo5NoCardDraftableAppearanceMutation' as const,
    ResetNoCardDraftedAppearancesMutation: 'ResetNoCardDraftedAppearancesMutation' as const,
    openRewardBoxMutation: 'openRewardBoxMutation' as const,
    StarterPackOpeningMutation: 'StarterPackOpeningMutation' as const,
    ClaimDeliverableItemOrder: 'ClaimDeliverableItemOrder' as const,
    BuyClubShopItemMutation: 'BuyClubShopItemMutation' as const,
    BuyDeliverableShopItemMutation: 'BuyDeliverableShopItemMutation' as const,
    ClaimWarmupObjectiveMutation: 'ClaimWarmupObjectiveMutation' as const,
    ClaimRewardsMutation: 'ClaimRewardsMutation' as const,
    ConfirmSo5LineupsMutation: 'ConfirmSo5LineupsMutation' as const,
    CreateOrUpdateSo5LineupMutation: 'CreateOrUpdateSo5LineupMutation' as const,
    DeleteSo5LineupMutation: 'DeleteSo5LineupMutation' as const,
    UpdateManagerTeamMutation: 'UpdateManagerTeamMutation' as const,
    MarkStoriesAsReadMutation: 'MarkStoriesAsReadMutation' as const,
    ClaimFootballManagerTaskMutation: 'ClaimFootballManagerTaskMutation' as const,
    RevealAllCards: 'RevealAllCards' as const,
    MarkShardsThresholdAsSeen: 'MarkShardsThresholdAsSeen' as const,
    BuyCardPackMutation: 'BuyCardPackMutation' as const,
    DropAndAddCards: 'DropAndAddCards' as const,
    dropPackCardsMutation: 'dropPackCardsMutation' as const,
    GenerateAccountingExtractMutation: 'GenerateAccountingExtractMutation' as const,
    SetTokenTradeStatus: 'SetTokenTradeStatus' as const,
    CreateOrUpdateSingleBuyOfferMinPriceMutation: 'CreateOrUpdateSingleBuyOfferMinPriceMutation' as const,
    SealingCardsMutation: 'SealingCardsMutation' as const,
    UnsealingCardsMutation: 'UnsealingCardsMutation' as const,
    GetTransferRequestMutation: 'GetTransferRequestMutation' as const,
    CreateTokenWithdrawalMutation: 'CreateTokenWithdrawalMutation' as const,
    AddAuctionReminderMutation: 'AddAuctionReminderMutation' as const,
    BidWithWalletMutation: 'BidWithWalletMutation' as const,
    PrepareAcceptTradeWithWalletAndCreditCard: 'PrepareAcceptTradeWithWalletAndCreditCard' as const,
    PrepareBidMutation: 'PrepareBidMutation' as const,
    RemoveAuctionReminderMutation: 'RemoveAuctionReminderMutation' as const,
    StopAutoBidMutation: 'StopAutoBidMutation' as const,
    AcceptOfferMutation: 'AcceptOfferMutation' as const,
    ApproveMigratorMutation: 'ApproveMigratorMutation' as const,
    CancelOfferMutation: 'CancelOfferMutation' as const,
    CreateDirectOfferMutation: 'CreateDirectOfferMutation' as const,
    CreateSingleSaleOfferMutation: 'CreateSingleSaleOfferMutation' as const,
    PrepareAcceptOfferMutation: 'PrepareAcceptOfferMutation' as const,
    PrepareOfferMutation: 'PrepareOfferMutation' as const,
    RejectOfferMutation: 'RejectOfferMutation' as const,
    PrepareBuyInGameCurrencyPackWithCreditCardMutation: 'PrepareBuyInGameCurrencyPackWithCreditCardMutation' as const,
    AddToCartMutation: 'AddToCartMutation' as const,
    RemoveFromCartMutation: 'RemoveFromCartMutation' as const,
    SaveForLaterMutation: 'SaveForLaterMutation' as const,
    RefreshCartMutation: 'RefreshCartMutation' as const,
    LevelUpCardsWithXpCurrencyMutation: 'LevelUpCardsWithXpCurrencyMutation' as const,
    VerifyIdentityMutation: 'VerifyIdentityMutation' as const,
    UpdateSavedFiltersMutation: 'UpdateSavedFiltersMutation' as const,
    NBAOnboardingCommonCardDeckMutation: 'NBAOnboardingCommonCardDeckMutation' as const,
    ConfirmEmailMutation: 'ConfirmEmailMutation' as const,
    confirmDevice: 'confirmDevice' as const,
    ClaimConversionCreditMutation: 'ClaimConversionCreditMutation' as const
  },
  Subscription: {
    onDeviceWasUpdated: 'onDeviceWasUpdated' as const,
    onCurrentUserWasUpdated: 'onCurrentUserWasUpdated' as const,
    onMatchViewDataUpdated: 'onMatchViewDataUpdated' as const,
    onOfferUpdated: 'onOfferUpdated' as const,
    TokenAuctionUpdate: 'TokenAuctionUpdate' as const,
    TokenOfferUpdate: 'TokenOfferUpdate' as const,
    PrimaryOfferUpdate: 'PrimaryOfferUpdate' as const
  },
  Fragment: {
    ChaseUserLineups_membership: 'ChaseUserLineups_membership' as const,
    ChaseUserLineups_so5UserGroup: 'ChaseUserLineups_so5UserGroup' as const,
    CollectionEventProgress_cardCollection: 'CollectionEventProgress_cardCollection' as const,
    TeamLogoWithSeasonOverlay_team: 'TeamLogoWithSeasonOverlay_team' as const,
    MLBBasicBenchCard_composeTeamBenchObjectInterface: 'MLBBasicBenchCard_composeTeamBenchObjectInterface' as const,
    MLBBasicBenchCard_appearanceRule: 'MLBBasicBenchCard_appearanceRule' as const,
    MLBBasicFilters_so5Leaderboard: 'MLBBasicFilters_so5Leaderboard' as const,
    MLBBasicMarketCard_anyCardInterface: 'MLBBasicMarketCard_anyCardInterface' as const,
    MLBBasicMarketCard_appearanceRule: 'MLBBasicMarketCard_appearanceRule' as const,
    MLBCommonMarketBench_benchObject: 'MLBCommonMarketBench_benchObject' as const,
    MLBCommonMarketBench_appearanceRule: 'MLBCommonMarketBench_appearanceRule' as const,
    MLBCompose_composeTeamBenchObjectInterface: 'MLBCompose_composeTeamBenchObjectInterface' as const,
    MLBCompose_so5Leaderboard: 'MLBCompose_so5Leaderboard' as const,
    MLBCompose_so5Lineup: 'MLBCompose_so5Lineup' as const,
    MLBCompose_managerTeam: 'MLBCompose_managerTeam' as const,
    MLBFieldCard_composeTeamBenchObjectInterface: 'MLBFieldCard_composeTeamBenchObjectInterface' as const,
    MLBFieldCard_appearanceRule: 'MLBFieldCard_appearanceRule' as const,
    GameStatsCells_PlayerGameScoreInterface: 'GameStatsCells_PlayerGameScoreInterface' as const,
    PlayerRow_So5Appearance: 'PlayerRow_So5Appearance' as const,
    PlayerRow_GameOfBaseball: 'PlayerRow_GameOfBaseball' as const,
    PlayerRow_BaseballPlayer: 'PlayerRow_BaseballPlayer' as const,
    GameBoxPlayersTable_So5Appearance: 'GameBoxPlayersTable_So5Appearance' as const,
    GameBoxPlayersTable_GameOfBaseball: 'GameBoxPlayersTable_GameOfBaseball' as const,
    GameBoxPlayersTable_BaseballPlayer: 'GameBoxPlayersTable_BaseballPlayer' as const,
    GameBoxScore_BaseballPlayer: 'GameBoxScore_BaseballPlayer' as const,
    GameBoxScore_So5Appearance: 'GameBoxScore_So5Appearance' as const,
    GameBoxScore_GameOfBaseball: 'GameBoxScore_GameOfBaseball' as const,
    GameTime_GameOfBaseball: 'GameTime_GameOfBaseball' as const,
    TeamBox_TeamInterface: 'TeamBox_TeamInterface' as const,
    TeamPlayers_BaseballPlayer: 'TeamPlayers_BaseballPlayer' as const,
    TeamPlayers_So5Appearance: 'TeamPlayers_So5Appearance' as const,
    TeamPlayers_TeamInterface: 'TeamPlayers_TeamInterface' as const,
    TeamProbablePitcher_So5Appearance: 'TeamProbablePitcher_So5Appearance' as const,
    TeamProbablePitcher_TeamInterface: 'TeamProbablePitcher_TeamInterface' as const,
    TeamProbablePitcher_BaseballPlayer: 'TeamProbablePitcher_BaseballPlayer' as const,
    UpcomingGameView_GameOfBaseball: 'UpcomingGameView_GameOfBaseball' as const,
    UpcomingGameView_BaseballPlayer: 'UpcomingGameView_BaseballPlayer' as const,
    GameView_GameOfBaseball: 'GameView_GameOfBaseball' as const,
    BaseballLegacySo5Lineup_so5LeaderboardContender: 'BaseballLegacySo5Lineup_so5LeaderboardContender' as const,
    MLBLineupLayout_so5LeaderboardContender: 'MLBLineupLayout_so5LeaderboardContender' as const,
    BaseballSo5LineupSharing_so5Lineup: 'BaseballSo5LineupSharing_so5Lineup' as const,
    UpcomingLeaderboards_So5Leaderboard: 'UpcomingLeaderboards_So5Leaderboard' as const,
    FirstWeekClaim_mlbManagerTask: 'FirstWeekClaim_mlbManagerTask' as const,
    MLBOnboarding_CommonPlayerConnection: 'MLBOnboarding_CommonPlayerConnection' as const,
    BaseballPlayPage_TokenRoot: 'BaseballPlayPage_TokenRoot' as const,
    BaseballPlayPage_so5Root: 'BaseballPlayPage_so5Root' as const,
    BaseballPlayPage_currentUser: 'BaseballPlayPage_currentUser' as const,
    BaseballPlayPage_so5Config: 'BaseballPlayPage_so5Config' as const,
    BaseballProBlock_so5Root: 'BaseballProBlock_so5Root' as const,
    HandInfoChip_BaseballPlayer: 'HandInfoChip_BaseballPlayer' as const,
    BaseballPlayerSearch_anyPlayerInterface: 'BaseballPlayerSearch_anyPlayerInterface' as const,
    BaseballTeamSearch_teamInterface: 'BaseballTeamSearch_teamInterface' as const,
    getTeamStyleVariables_TeamInterface: 'getTeamStyleVariables_TeamInterface' as const,
    CardPicture_screenshottableCard: 'CardPicture_screenshottableCard' as const,
    Normal_card: 'Normal_card' as const,
    PlayerPicture_card: 'PlayerPicture_card' as const,
    Serial_card: 'Serial_card' as const,
    PicturePosition_card: 'PicturePosition_card' as const,
    BaseballCard2022_card: 'BaseballCard2022_card' as const,
    BaseballCard2023Awards_card: 'BaseballCard2023Awards_card' as const,
    BaseballCard2023Default_card: 'BaseballCard2023Default_card' as const,
    BaseballCard2023PostSeason_card: 'BaseballCard2023PostSeason_card' as const,
    BaseballCard2023_card: 'BaseballCard2023_card' as const,
    BaseballCard2024Default_card: 'BaseballCard2024Default_card' as const,
    BaseballCardNormal2024Default_card: 'BaseballCardNormal2024Default_card' as const,
    BaseballCardNormal2024_card: 'BaseballCardNormal2024_card' as const,
    BaseballCard2024Postseason_card: 'BaseballCard2024Postseason_card' as const,
    PostseasonNormal_card: 'PostseasonNormal_card' as const,
    BaseballCard2024_card: 'BaseballCard2024_card' as const,
    BaseballCard2025Default_card: 'BaseballCard2025Default_card' as const,
    BaseballCard2025_card: 'BaseballCard2025_card' as const,
    BaseballCardNormal2025_card: 'BaseballCardNormal2025_card' as const,
    FootballCard2018_card: 'FootballCard2018_card' as const,
    FootballCard2019_card: 'FootballCard2019_card' as const,
    FootballCard2020_card: 'FootballCard2020_card' as const,
    FootballCard2021_card: 'FootballCard2021_card' as const,
    FootballCard2022_card: 'FootballCard2022_card' as const,
    FootballCard2023Unlicensed_card: 'FootballCard2023Unlicensed_card' as const,
    FootballCard2023_card: 'FootballCard2023_card' as const,
    FootballNormal2023_card: 'FootballNormal2023_card' as const,
    FootballCardDefault2024_card: 'FootballCardDefault2024_card' as const,
    FootballCardEarlyAccess2024_card: 'FootballCardEarlyAccess2024_card' as const,
    FootballCard2024_card: 'FootballCard2024_card' as const,
    FootballNormal2024_card: 'FootballNormal2024_card' as const,
    CardTemplate_card: 'CardTemplate_card' as const,
    FootballCardGC22_card: 'FootballCardGC22_card' as const,
    CardInfo_card: 'CardInfo_card' as const,
    FootballLegend_card: 'FootballLegend_card' as const,
    NBACard2022_card: 'NBACard2022_card' as const,
    NBACard2023Default_card: 'NBACard2023Default_card' as const,
    NBACard2023Draft_card: 'NBACard2023Draft_card' as const,
    NBACard2023_card: 'NBACard2023_card' as const,
    NBACardDefault2024_card: 'NBACardDefault2024_card' as const,
    NBACardEarlyAccess2024_card: 'NBACardEarlyAccess2024_card' as const,
    NBACard2024_card: 'NBACard2024_card' as const,
    NBACard2024Normal_card: 'NBACard2024Normal_card' as const,
    NBACard2023Normal_card: 'NBACard2023Normal_card' as const,
    Card3DWithFallback_anyCardInterface: 'Card3DWithFallback_anyCardInterface' as const,
    Reward_AnyRewardInterface: 'Reward_AnyRewardInterface' as const,
    PvEImplementation_anyCardInterface: 'PvEImplementation_anyCardInterface' as const,
    PvETeam_anyCard: 'PvETeam_anyCard' as const,
    Content_RaffleShopItem: 'Content_RaffleShopItem' as const,
    UninteractiveBundledCardsPreview_anyCard: 'UninteractiveBundledCardsPreview_anyCard' as const,
    RewardTitle_AnyRewardInterface: 'RewardTitle_AnyRewardInterface' as const,
    BuyConfirmation_anyCard: 'BuyConfirmation_anyCard' as const,
    BuyConfirmation_anyReward: 'BuyConfirmation_anyReward' as const,
    CreditCard_creditCard: 'CreditCard_creditCard' as const,
    CardAvatar_anyCard: 'CardAvatar_anyCard' as const,
    Card_anyCard: 'Card_anyCard' as const,
    Card_so5Appearance: 'Card_so5Appearance' as const,
    Card_composeTeamBenchObjectInterface: 'Card_composeTeamBenchObjectInterface' as const,
    CardMeta_AnyCardInterface: 'CardMeta_AnyCardInterface' as const,
    CardMeta_anyPlayerInterface: 'CardMeta_anyPlayerInterface' as const,
    LoanWrapper_anyCard: 'LoanWrapper_anyCard' as const,
    baseballStatsOverlay2025_AnyCardInterface: 'baseballStatsOverlay2025_AnyCardInterface' as const,
    drawHightlightedStat_AnyCardInterface: 'drawHightlightedStat_AnyCardInterface' as const,
    drawObtainedInfos_AnyCardInterface: 'drawObtainedInfos_AnyCardInterface' as const,
    drawOwner_AnyCardInterface: 'drawOwner_AnyCardInterface' as const,
    drawPlayerName_AnyCardInterface: 'drawPlayerName_AnyCardInterface' as const,
    footballStatsOverlay2024_AnyCardInterface: 'footballStatsOverlay2024_AnyCardInterface' as const,
    nbaStatsOverlay2024_AnyCardInterface: 'nbaStatsOverlay2024_AnyCardInterface' as const,
    celebrationOverlay_AnyCardInterface: 'celebrationOverlay_AnyCardInterface' as const,
    celebrationNormalOverlay_AnyCardInterface: 'celebrationNormalOverlay_AnyCardInterface' as const,
    earlyAccessOverlay_AnyCardInterface: 'earlyAccessOverlay_AnyCardInterface' as const,
    statsOverlay_AnyCardInterface: 'statsOverlay_AnyCardInterface' as const,
    UnsealableOverlay_anyCard: 'UnsealableOverlay_anyCard' as const,
    PlayerAppearance_so5Appearance: 'PlayerAppearance_so5Appearance' as const,
    StackedCards_anyCard: 'StackedCards_anyCard' as const,
    TeamAvatar_team: 'TeamAvatar_team' as const,
    CollectionGameHelper_cardCollection: 'CollectionGameHelper_cardCollection' as const,
    CollectionLeaderboardRow_PublicUserInfoInterface: 'CollectionLeaderboardRow_PublicUserInfoInterface' as const,
    CollectionLeaderboardRow_UserSportProfileInterface: 'CollectionLeaderboardRow_UserSportProfileInterface' as const,
    SplitProgressBar_cardCollection: 'SplitProgressBar_cardCollection' as const,
    SplitProgressBar_userCardCollection: 'SplitProgressBar_userCardCollection' as const,
    DropCardsWarningDialog_anyCardInterface: 'DropCardsWarningDialog_anyCardInterface' as const,
    ProbabilisticBundle_probabilisticBundleReward: 'ProbabilisticBundle_probabilisticBundleReward' as const,
    useFormatBoxForDisplay_ProbabilisticBundle: 'useFormatBoxForDisplay_ProbabilisticBundle' as const,
    FavoriteHeartButton_subscribable: 'FavoriteHeartButton_subscribable' as const,
    AnySportPastWinner_so5Leaderboard: 'AnySportPastWinner_so5Leaderboard' as const,
    LeaderboardRow_publicUserInfoInterface: 'LeaderboardRow_publicUserInfoInterface' as const,
    DivisionLogo_so5Leaderboard: 'DivisionLogo_so5Leaderboard' as const,
    WarningRow_anyCard: 'WarningRow_anyCard' as const,
    AccountEntry_userAccountEntry: 'AccountEntry_userAccountEntry' as const,
    AccountEntryState_userAccountEntry: 'AccountEntryState_userAccountEntry' as const,
    BankWithdrawal_userAccountEntry: 'BankWithdrawal_userAccountEntry' as const,
    Deposit_userAccountEntry: 'Deposit_userAccountEntry' as const,
    Withdrawal_userAccountEntry: 'Withdrawal_userAccountEntry' as const,
    MyClubPageHeader_user: 'MyClubPageHeader_user' as const,
    NavItems_currentUser: 'NavItems_currentUser' as const,
    InjuryTable_injury: 'InjuryTable_injury' as const,
    InjuryTagTemplate_injury: 'InjuryTagTemplate_injury' as const,
    NewsUpdates_PlayerNewsUpdate: 'NewsUpdates_PlayerNewsUpdate' as const,
    PlayerHandsInfo_AnyPlayerInterface: 'PlayerHandsInfo_AnyPlayerInterface' as const,
    NotificationPreference_subscription: 'NotificationPreference_subscription' as const,
    PrivacyPolicyDialog_dataPartner: 'PrivacyPolicyDialog_dataPartner' as const,
    Follower_subscription: 'Follower_subscription' as const,
    Follower_dataPartner: 'Follower_dataPartner' as const,
    ClaimReferralRewardsDialog_referralReward: 'ClaimReferralRewardsDialog_referralReward' as const,
    InProgressReferralBar_Referral: 'InProgressReferralBar_Referral' as const,
    ReadyToClaimReferralBar_ReferralReward: 'ReadyToClaimReferralBar_ReferralReward' as const,
    ReferralBar_currentUser: 'ReferralBar_currentUser' as const,
    RivalsAvatar_footballRivalsManager: 'RivalsAvatar_footballRivalsManager' as const,
    addAvatarToRanking_user: 'addAvatarToRanking_user' as const,
    BenchObjectScore_composeTeamBenchObjectInterface: 'BenchObjectScore_composeTeamBenchObjectInterface' as const,
    PlayerAppearance_anyPlayer: 'PlayerAppearance_anyPlayer' as const,
    PlayerGameScoreScore_playerGameScoreInterface: 'PlayerGameScoreScore_playerGameScoreInterface' as const,
    PlayerScore_anyPlayer: 'PlayerScore_anyPlayer' as const,
    Score_anyGameInterface: 'Score_anyGameInterface' as const,
    Header_subscription: 'Header_subscription' as const,
    Header_dataPartner: 'Header_dataPartner' as const,
    CardSupply_anyPlayerInterface: 'CardSupply_anyPlayerInterface' as const,
    Departures_TeamInterface: 'Departures_TeamInterface' as const,
    Devices_UserDevice: 'Devices_UserDevice' as const,
    OAuthApplication_oAuthApplication: 'OAuthApplication_oAuthApplication' as const,
    PostalAddressForm_userSettings: 'PostalAddressForm_userSettings' as const,
    TokenMetas_anyCard: 'TokenMetas_anyCard' as const,
    ActiveUserAvatar_user: 'ActiveUserAvatar_user' as const,
    ActivityIndicator_user: 'ActivityIndicator_user' as const,
    Avatar_user: 'Avatar_user' as const,
    Avatar_publicUserInfoInterface: 'Avatar_publicUserInfoInterface' as const,
    Avatar_anonymousUser: 'Avatar_anonymousUser' as const,
    Avatar_ethereumAccount: 'Avatar_ethereumAccount' as const,
    Avatar_starkwareAccount: 'Avatar_starkwareAccount' as const,
    BlockButton_publicUserInfoInterface: 'BlockButton_publicUserInfoInterface' as const,
    DiscordUser_userProfile: 'DiscordUser_userProfile' as const,
    MyClubLink_publicUserInfoInterface: 'MyClubLink_publicUserInfoInterface' as const,
    Nickname_publicUserInfoInterface: 'Nickname_publicUserInfoInterface' as const,
    SocialShare_Content_socialPictureDerivative: 'SocialShare_Content_socialPictureDerivative' as const,
    TwitterUser_userProfile: 'TwitterUser_userProfile' as const,
    UserName_publicUserInfoInterface: 'UserName_publicUserInfoInterface' as const,
    FastWithdrawal_fastWithdrawalWithRates: 'FastWithdrawal_fastWithdrawalWithRates' as const,
    PendingDeposit_pendingDeposit: 'PendingDeposit_pendingDeposit' as const,
    RecentActivity_userAccountEntry: 'RecentActivity_userAccountEntry' as const,
    BankAccount_bankAccount: 'BankAccount_bankAccount' as const,
    ReviewWithdrawFiat_bankAccount: 'ReviewWithdrawFiat_bankAccount' as const,
    getAccountIdentifier_bankAccount: 'getAccountIdentifier_bankAccount' as const,
    Withdrawal_withdrawalWithRates: 'Withdrawal_withdrawalWithRates' as const,
    UserBalances: 'UserBalances' as const,
    ConfigProvider_TeamsPlayingNextGameWeek: 'ConfigProvider_TeamsPlayingNextGameWeek' as const,
    ScoutableSo5Competitions_query: 'ScoutableSo5Competitions_query' as const,
    CurrentUserProvider_userSportProfile: 'CurrentUserProvider_userSportProfile' as const,
    CurrentUseProvider_sportProfile: 'CurrentUseProvider_sportProfile' as const,
    CurrentUserProvider_walletRecovery: 'CurrentUserProvider_walletRecovery' as const,
    CurrentUseProvider_ethereumAccounts: 'CurrentUseProvider_ethereumAccounts' as const,
    CurrentUseProvider_fiatAccounts: 'CurrentUseProvider_fiatAccounts' as const,
    CurrentUserProvider_managerProgression: 'CurrentUserProvider_managerProgression' as const,
    CurrentUserProvider_coinBalances: 'CurrentUserProvider_coinBalances' as const,
    CurrentUserProvider_myCart: 'CurrentUserProvider_myCart' as const,
    CurrentUserProvider_completablePromotionalEventCompletion: 'CurrentUserProvider_completablePromotionalEventCompletion' as const,
    CurrentUserProvider_inventoryBalances: 'CurrentUserProvider_inventoryBalances' as const,
    CurrentUserProvider_currentSportUnreadNotificationsCount: 'CurrentUserProvider_currentSportUnreadNotificationsCount' as const,
    commonCardMarketplace_currentUser: 'commonCardMarketplace_currentUser' as const,
    CurrentUserProvider_currentUser: 'CurrentUserProvider_currentUser' as const,
    So5ContendersFragment_So5LeaderboardContender: 'So5ContendersFragment_So5LeaderboardContender' as const,
    deckFragments_deck: 'deckFragments_deck' as const,
    PrivateKeyRecoveryPayloads_CurrentUser: 'PrivateKeyRecoveryPayloads_CurrentUser' as const,
    useClaimReferralReward_referralReward: 'useClaimReferralReward_referralReward' as const,
    useReferralRewards_referralReward: 'useReferralRewards_referralReward' as const,
    useAcceptedCurrencies_publicUserInfoInterface: 'useAcceptedCurrencies_publicUserInfoInterface' as const,
    useAuctionConversionCredit_auction: 'useAuctionConversionCredit_auction' as const,
    useConversionCredits_conversionCredits: 'useConversionCredits_conversionCredits' as const,
    CurrentUser_conversionCredits: 'CurrentUser_conversionCredits' as const,
    useNotificationPreferences_notificationPreference: 'useNotificationPreferences_notificationPreference' as const,
    useNotificationPreferences_userSettings: 'useNotificationPreferences_userSettings' as const,
    useProbabilisticBundleConfigSummary_ProbabilisticBundleConfig: 'useProbabilisticBundleConfigSummary_ProbabilisticBundleConfig' as const,
    useProbableRewardConfigSummary_ProbableRewardConfig: 'useProbableRewardConfigSummary_ProbableRewardConfig' as const,
    useTokenBelongsToUser_anyCard: 'useTokenBelongsToUser_anyCard' as const,
    useTokenOfferBelongsToUser_offer: 'useTokenOfferBelongsToUser_offer' as const,
    useActivityIndicator_user: 'useActivityIndicator_user' as const,
    Algolia_CardHit_anyCard: 'Algolia_CardHit_anyCard' as const,
    getHumanReadableSerialNumber_anyCard: 'getHumanReadableSerialNumber_anyCard' as const,
    getName_anyCard: 'getName_anyCard' as const,
    isTokenListedOnMarket_anyCard: 'isTokenListedOnMarket_anyCard' as const,
    isMyCardListedOnMarket_anyCard: 'isMyCardListedOnMarket_anyCard' as const,
    isTokenSentInDirectOffer_anyCard: 'isTokenSentInDirectOffer_anyCard' as const,
    isSentInDirectOffer_anyCard: 'isSentInDirectOffer_anyCard' as const,
    getAnimatedCardVideoSources_anyCardInterface: 'getAnimatedCardVideoSources_anyCardInterface' as const,
    getAnimatedCardVideoSources_so5Appearance: 'getAnimatedCardVideoSources_so5Appearance' as const,
    getCardRepresentation_anyCardInterface: 'getCardRepresentation_anyCardInterface' as const,
    getShieldScoreRequirement_skinShopItem: 'getShieldScoreRequirement_skinShopItem' as const,
    getCollectionsShield_cardCollection: 'getCollectionsShield_cardCollection' as const,
    MonetaryAmountFragment_monetaryAmount: 'MonetaryAmountFragment_monetaryAmount' as const,
    getSortedAppearancesFromRules_so5Lineup: 'getSortedAppearancesFromRules_so5Lineup' as const,
    getSortedAppearancesFromRules_so5Leaderboard: 'getSortedAppearancesFromRules_so5Leaderboard' as const,
    getAppearancePercentage_anyPlayerInterface: 'getAppearancePercentage_anyPlayerInterface' as const,
    AverageScores_anyPlayer: 'AverageScores_anyPlayer' as const,
    getRewardBoxRepresentation_ProbabilisticBundle: 'getRewardBoxRepresentation_ProbabilisticBundle' as const,
    rewardsOverviewToSimpleRewards_rewardsOverview: 'rewardsOverviewToSimpleRewards_rewardsOverview' as const,
    parseRankingRewards_so5RewardConfig: 'parseRankingRewards_so5RewardConfig' as const,
    getAnyRewardConfigRarity_anyRewardConfigInterface: 'getAnyRewardConfigRarity_anyRewardConfigInterface' as const,
    getTreeSchemaFromSo5Fixture_so5Fixture: 'getTreeSchemaFromSo5Fixture_so5Fixture' as const,
    getTreeSelection_so5League: 'getTreeSelection_so5League' as const,
    formatLineupDisplayName_so5Lineup: 'formatLineupDisplayName_so5Lineup' as const,
    nationalTeamPictureUrl_nationalTeam: 'nationalTeamPictureUrl_nationalTeam' as const,
    teamPictureUrl_teamInterface: 'teamPictureUrl_teamInterface' as const,
    smallTeamPictureUrl_teamInterface: 'smallTeamPictureUrl_teamInterface' as const,
    BundledAuctionTitle_card: 'BundledAuctionTitle_card' as const,
    CampaignActions_so5Campaign: 'CampaignActions_so5Campaign' as const,
    CampaignPage_so5Root: 'CampaignPage_so5Root' as const,
    Leaderboard_so5Leaderboard: 'Leaderboard_so5Leaderboard' as const,
    Leaderboard_previousSo5Leaderboard: 'Leaderboard_previousSo5Leaderboard' as const,
    NextAction_so5Campaign: 'NextAction_so5Campaign' as const,
    RelatedClub_club: 'RelatedClub_club' as const,
    RelatedNationalTeam_nationalTeam: 'RelatedNationalTeam_nationalTeam' as const,
    RelatedTeam_team: 'RelatedTeam_team' as const,
    TeamPicture_team: 'TeamPicture_team' as const,
    CommonDropTab_composeTeamBenchObjectInterface: 'CommonDropTab_composeTeamBenchObjectInterface' as const,
    CommonDropTab_so5Leaderboard: 'CommonDropTab_so5Leaderboard' as const,
    FootballAdvancedBenchCard_composeTeamBenchObjectInterface: 'FootballAdvancedBenchCard_composeTeamBenchObjectInterface' as const,
    FootballAdvancedMarketCard_anyCardInterface: 'FootballAdvancedMarketCard_anyCardInterface' as const,
    FootballBasicBenchCard_composeTeamBenchObjectInterface: 'FootballBasicBenchCard_composeTeamBenchObjectInterface' as const,
    FootballBasicMarketCard_anyCardInterface: 'FootballBasicMarketCard_anyCardInterface' as const,
    FootballBenchObjectMetasCell_composeTeamBenchObjectInterface: 'FootballBenchObjectMetasCell_composeTeamBenchObjectInterface' as const,
    FootballCardMetasCell_anyCardInterface: 'FootballCardMetasCell_anyCardInterface' as const,
    FootballCompose_composeTeamBenchObjectInterface: 'FootballCompose_composeTeamBenchObjectInterface' as const,
    FootballCompose_so5Leaderboard: 'FootballCompose_so5Leaderboard' as const,
    FootballCompose_so5Lineup: 'FootballCompose_so5Lineup' as const,
    FootballCompose_managerTeam: 'FootballCompose_managerTeam' as const,
    BaseObjectiveBar_so5Leaderboard: 'BaseObjectiveBar_so5Leaderboard' as const,
    FootballCompose_anyPlayerInterface: 'FootballCompose_anyPlayerInterface' as const,
    sharedBenchObjectFragment_composeTeamBenchObjectInterface: 'sharedBenchObjectFragment_composeTeamBenchObjectInterface' as const,
    FootballComposeBench_so5Root: 'FootballComposeBench_so5Root' as const,
    FootballComposeBench_benchSo5Leaderboard: 'FootballComposeBench_benchSo5Leaderboard' as const,
    FootballComposeBench_so5Leaderboard: 'FootballComposeBench_so5Leaderboard' as const,
    FootballComposeBench_composeTeamBenchObjectInterface: 'FootballComposeBench_composeTeamBenchObjectInterface' as const,
    FootballComposeBench_composeTeamBenchObjectInterfaceConnection: 'FootballComposeBench_composeTeamBenchObjectInterfaceConnection' as const,
    FootballComposeBench_so5RuleFeedback: 'FootballComposeBench_so5RuleFeedback' as const,
    FootballFieldCard_composeTeamBenchObjectInterface: 'FootballFieldCard_composeTeamBenchObjectInterface' as const,
    FootballFieldCard_appearanceRule: 'FootballFieldCard_appearanceRule' as const,
    FootballBasicFilters_so5Leaderboard: 'FootballBasicFilters_so5Leaderboard' as const,
    RivalsDumbCard_player: 'RivalsDumbCard_player' as const,
    WithOnboardingComponents_currentUser: 'WithOnboardingComponents_currentUser' as const,
    FootballLineupLayout_so5LeaderboardContender: 'FootballLineupLayout_so5LeaderboardContender' as const,
    FootballSo5Lineup_so5LeaderboardContender: 'FootballSo5Lineup_so5LeaderboardContender' as const,
    FootballSo5LineupSharing_so5Lineup: 'FootballSo5LineupSharing_so5Lineup' as const,
    OnboardingPromoRecapDialog_so5OnboardingPromotionalEvent: 'OnboardingPromoRecapDialog_so5OnboardingPromotionalEvent' as const,
    PlayNowOrLater_teamInterface: 'PlayNowOrLater_teamInterface' as const,
    PlayNowOrLater_userSportProfileInterface: 'PlayNowOrLater_userSportProfileInterface' as const,
    FixtureLineupsRecap_so5Fixture: 'FixtureLineupsRecap_so5Fixture' as const,
    LineupsWidgetRecap_so5Fixture: 'LineupsWidgetRecap_so5Fixture' as const,
    FixtureRewardsRecap_so5Fixture: 'FixtureRewardsRecap_so5Fixture' as const,
    LineupsWidget_so5Root: 'LineupsWidget_so5Root' as const,
    LineupsWidget_currentUser: 'LineupsWidget_currentUser' as const,
    NewPlayPage_so5Root: 'NewPlayPage_so5Root' as const,
    NewPlayPage_tokenRoot: 'NewPlayPage_tokenRoot' as const,
    NewPlayPage_so5Config: 'NewPlayPage_so5Config' as const,
    NewPlayPage_currentUser: 'NewPlayPage_currentUser' as const,
    ForYouBlock_footballRivalsRoot: 'ForYouBlock_footballRivalsRoot' as const,
    ProBlock_so5Root: 'ProBlock_so5Root' as const,
    PlayPage_TokenRoot: 'PlayPage_TokenRoot' as const,
    PlayPage_footballRoot: 'PlayPage_footballRoot' as const,
    PlayPage_so5Root: 'PlayPage_so5Root' as const,
    PlayPage_currentUser: 'PlayPage_currentUser' as const,
    PlayPage_so5Config: 'PlayPage_so5Config' as const,
    UnclaimedDeliverableItemOrderButton_deliverableItemOrder: 'UnclaimedDeliverableItemOrderButton_deliverableItemOrder' as const,
    Scores_footballRivalsContestant: 'Scores_footballRivalsContestant' as const,
    TacticPreview_footballRivalsLineup: 'TacticPreview_footballRivalsLineup' as const,
    ArenaStoryElement_footballRivalsChallenge: 'ArenaStoryElement_footballRivalsChallenge' as const,
    ArenaStory_footballRivalsAggregatedArenaStory: 'ArenaStory_footballRivalsAggregatedArenaStory' as const,
    ArenaStory_footballRivalsCurrentManager: 'ArenaStory_footballRivalsCurrentManager' as const,
    ArenaGame_footballRivalsGame: 'ArenaGame_footballRivalsGame' as const,
    ArenaOverview_footballRivalsManager: 'ArenaOverview_footballRivalsManager' as const,
    Task_footballManagerTask: 'Task_footballManagerTask' as const,
    DailyMissions_contentTileInterface: 'DailyMissions_contentTileInterface' as const,
    Row_footballRivalsDivisionRanking: 'Row_footballRivalsDivisionRanking' as const,
    LeaderboardOverview_footballRivalsManager: 'LeaderboardOverview_footballRivalsManager' as const,
    MissionOnboardingDialog_footballManagerTask: 'MissionOnboardingDialog_footballManagerTask' as const,
    useBrandedPlayButtonStatus_footballRivalsCurrentManager: 'useBrandedPlayButtonStatus_footballRivalsCurrentManager' as const,
    DailyClaim_footballRivalsCurrentManager: 'DailyClaim_footballRivalsCurrentManager' as const,
    FirstWeekClaim_footballManagerTask: 'FirstWeekClaim_footballManagerTask' as const,
    GameHeader_game: 'GameHeader_game' as const,
    GameSelection_footballRivalsLeagueGame: 'GameSelection_footballRivalsLeagueGame' as const,
    GamesByLeague_footballRivalsLeagueGame: 'GamesByLeague_footballRivalsLeagueGame' as const,
    HeaderTeamsMatchup_game: 'HeaderTeamsMatchup_game' as const,
    Appearance_pollingFootballRivalsAppearance: 'Appearance_pollingFootballRivalsAppearance' as const,
    Appearance_footballRivalsAppearance: 'Appearance_footballRivalsAppearance' as const,
    Lineup_pollingFootballRivalsLineup: 'Lineup_pollingFootballRivalsLineup' as const,
    Lineup_footballRivalsLineup: 'Lineup_footballRivalsLineup' as const,
    ManagerDivision_footballRivalsDivisionLeaderboardDivisionConfig: 'ManagerDivision_footballRivalsDivisionLeaderboardDivisionConfig' as const,
    ManagerDivision_footballRivalsDivisionRanking: 'ManagerDivision_footballRivalsDivisionRanking' as const,
    ManagerPoints_pollingFootballRivalsLineup: 'ManagerPoints_pollingFootballRivalsLineup' as const,
    ManagerPoints_footballRivalsLineup: 'ManagerPoints_footballRivalsLineup' as const,
    DailyChallenge_managerProgressionMission: 'DailyChallenge_managerProgressionMission' as const,
    GameFooter_footballRivalsGame: 'GameFooter_footballRivalsGame' as const,
    GameFooter_pollingFootballRivalsGame: 'GameFooter_pollingFootballRivalsGame' as const,
    MyLineups_pastAndUpcomingFootballRivalsSeason: 'MyLineups_pastAndUpcomingFootballRivalsSeason' as const,
    MyLineups_footballRivalsSeason: 'MyLineups_footballRivalsSeason' as const,
    LearnTheBasics_footballRivalsGame: 'LearnTheBasics_footballRivalsGame' as const,
    WelcomeToSorare_club: 'WelcomeToSorare_club' as const,
    AnimatedGameDetails_game: 'AnimatedGameDetails_game' as const,
    AnimatedTactic_footballRivalsLineup: 'AnimatedTactic_footballRivalsLineup' as const,
    AnimatedScoreDetails_game: 'AnimatedScoreDetails_game' as const,
    AnimatedScoreDetails_footballRivalsLineup: 'AnimatedScoreDetails_footballRivalsLineup' as const,
    GameSimulation_footballRivalsLineup: 'GameSimulation_footballRivalsLineup' as const,
    GameSimulation_footballRivalsGame: 'GameSimulation_footballRivalsGame' as const,
    PlayHeader_game: 'PlayHeader_game' as const,
    ProfileLayoutArena_rivalsRoot: 'ProfileLayoutArena_rivalsRoot' as const,
    ProfileLayout_footballRivalsRoot: 'ProfileLayout_footballRivalsRoot' as const,
    Promoted_footballRivalsPromotionRelegationStory: 'Promoted_footballRivalsPromotionRelegationStory' as const,
    Relegated_footballRivalsPromotionRelegationStory: 'Relegated_footballRivalsPromotionRelegationStory' as const,
    Stable_footballRivalsPromotionRelegationStory: 'Stable_footballRivalsPromotionRelegationStory' as const,
    PromotionRelegationStory_footballRivalsPromotionRelegationStory: 'PromotionRelegationStory_footballRivalsPromotionRelegationStory' as const,
    RepBoostedBanner_footballRivalsGame: 'RepBoostedBanner_footballRivalsGame' as const,
    RepDifference_footballRivalsGame: 'RepDifference_footballRivalsGame' as const,
    RivalsAppearanceScore_footballRivalsAppearance: 'RivalsAppearanceScore_footballRivalsAppearance' as const,
    FeaturedGame_footballRivalsGame: 'FeaturedGame_footballRivalsGame' as const,
    RepBoostedGameBottom_footballRivalsGame: 'RepBoostedGameBottom_footballRivalsGame' as const,
    RepBoostedGameWrapper_footballRivalsGame: 'RepBoostedGameWrapper_footballRivalsGame' as const,
    RepBoostedGame_footballRivalsGame: 'RepBoostedGame_footballRivalsGame' as const,
    MyAvailableCards_footballRivalsGame: 'MyAvailableCards_footballRivalsGame' as const,
    TopRightArea_footballRivalsGame: 'TopRightArea_footballRivalsGame' as const,
    RivalsLayoutGame_footballRivalsGame: 'RivalsLayoutGame_footballRivalsGame' as const,
    LeagueSectionAccordion_footballRivalsLeagueGame: 'LeagueSectionAccordion_footballRivalsLeagueGame' as const,
    LiveGame_pollingFootballRivalsGame: 'LiveGame_pollingFootballRivalsGame' as const,
    LiveGame_footballRivalsGame: 'LiveGame_footballRivalsGame' as const,
    StartingSoonGame_footballRivalsGame: 'StartingSoonGame_footballRivalsGame' as const,
    UpcomingGameFooter_footballRivalsGame: 'UpcomingGameFooter_footballRivalsGame' as const,
    LeaderboardStats_footballRivalsManager: 'LeaderboardStats_footballRivalsManager' as const,
    RivalsProfile_footballRivalsRoot: 'RivalsProfile_footballRivalsRoot' as const,
    FootballManagerTaskContentTile_footballManagerTaskContentTile: 'FootballManagerTaskContentTile_footballManagerTaskContentTile' as const,
    FootballRivalsChallengeContentTile_footballRivalsChallengeContentTile: 'FootballRivalsChallengeContentTile_footballRivalsChallengeContentTile' as const,
    FootballRivalsGameContentTile_footballRivalsGameContentTile: 'FootballRivalsGameContentTile_footballRivalsGameContentTile' as const,
    ManagerProgressionMissionContentTile_managerProgressionMissionContentTile: 'ManagerProgressionMissionContentTile_managerProgressionMissionContentTile' as const,
    RivalsTile_contentTileInterface: 'RivalsTile_contentTileInterface' as const,
    SetReminder_footballRivalsGame: 'SetReminder_footballRivalsGame' as const,
    Promoted_footballRivalsSquadPromotionRelegationStory: 'Promoted_footballRivalsSquadPromotionRelegationStory' as const,
    Relegated_footballRivalsSquadPromotionRelegationStory: 'Relegated_footballRivalsSquadPromotionRelegationStory' as const,
    Stable_footballRivalsSquadPromotionRelegationStory: 'Stable_footballRivalsSquadPromotionRelegationStory' as const,
    SquadPromotionRelegationStory_footballRivalsSquadPromotionRelegationStory: 'SquadPromotionRelegationStory_footballRivalsSquadPromotionRelegationStory' as const,
    ArenaJoined_game: 'ArenaJoined_game' as const,
    RivalsDraftCard_footballRivalsDraftableObjectInterface: 'RivalsDraftCard_footballRivalsDraftableObjectInterface' as const,
    CaptainSelection_footballRivalsDraftableObjectInterface: 'CaptainSelection_footballRivalsDraftableObjectInterface' as const,
    CaptainSelection_footballRivalsCurrentManager: 'CaptainSelection_footballRivalsCurrentManager' as const,
    CaptainSelection_footballRivalsLineupTactic: 'CaptainSelection_footballRivalsLineupTactic' as const,
    CaptainSelection_game: 'CaptainSelection_game' as const,
    CompactBottomContent_footballRivalsLineupTactic: 'CompactBottomContent_footballRivalsLineupTactic' as const,
    CompactBottomContent_footballRivalsDraftableObjectInterface: 'CompactBottomContent_footballRivalsDraftableObjectInterface' as const,
    CompactPageHeader_game: 'CompactPageHeader_game' as const,
    CompactPositionSelector_footballRivalsDraftableObjectInterface: 'CompactPositionSelector_footballRivalsDraftableObjectInterface' as const,
    CompactPositionWithTactic_footballRivalsDraftableObjectInterface: 'CompactPositionWithTactic_footballRivalsDraftableObjectInterface' as const,
    CompactPositionWithTactic_footballRivalsLineupTactic: 'CompactPositionWithTactic_footballRivalsLineupTactic' as const,
    CompactTacticSelector_footballRivalsLineupTactic: 'CompactTacticSelector_footballRivalsLineupTactic' as const,
    CompactTacticSelector_footballRivalsDraftableObjectInterface: 'CompactTacticSelector_footballRivalsDraftableObjectInterface' as const,
    CompactTeamHeader_game: 'CompactTeamHeader_game' as const,
    CompactDraft_footballRivalsLineupTactic: 'CompactDraft_footballRivalsLineupTactic' as const,
    CompactDraft_game: 'CompactDraft_game' as const,
    CompactDraft_footballRivalsDraftableObjectInterface: 'CompactDraft_footballRivalsDraftableObjectInterface' as const,
    CompactPlayerDetails_footballRivalsDraftableObjectInterface: 'CompactPlayerDetails_footballRivalsDraftableObjectInterface' as const,
    ContinueButton_footballRivalsDraftableObjectInterface: 'ContinueButton_footballRivalsDraftableObjectInterface' as const,
    DraftCardInfo_footballRivalsDraftableObjectInterface: 'DraftCardInfo_footballRivalsDraftableObjectInterface' as const,
    DraftCardInfo_footballRivalsLineupTactic: 'DraftCardInfo_footballRivalsLineupTactic' as const,
    ExpandedBottomContent_footballRivalsLineupTactic: 'ExpandedBottomContent_footballRivalsLineupTactic' as const,
    ExpandedBottomContent_footballRivalsDraftableObjectInterface: 'ExpandedBottomContent_footballRivalsDraftableObjectInterface' as const,
    ExpandedPlayerDetails_footballRivalsDraftableObjectInterface: 'ExpandedPlayerDetails_footballRivalsDraftableObjectInterface' as const,
    ExpandedPositionSelector_footballRivalsDraftableObjectInterface: 'ExpandedPositionSelector_footballRivalsDraftableObjectInterface' as const,
    ExpandedPositionSelector_footballRivalsLineupTactic: 'ExpandedPositionSelector_footballRivalsLineupTactic' as const,
    ExpandedTeamHeader_game: 'ExpandedTeamHeader_game' as const,
    ExpandedDraft_footballRivalsLineupTactic: 'ExpandedDraft_footballRivalsLineupTactic' as const,
    ExpandedDraft_footballRivalsDraftableObjectInterface: 'ExpandedDraft_footballRivalsDraftableObjectInterface' as const,
    ExpandedDraft_game: 'ExpandedDraft_game' as const,
    PlayerPicker_footballRivalsDraftableObjectInterface: 'PlayerPicker_footballRivalsDraftableObjectInterface' as const,
    PlayerPicker_footballRivalsLineupTactic: 'PlayerPicker_footballRivalsLineupTactic' as const,
    RivalsCard_footballRivalsDraftableObjectInterface: 'RivalsCard_footballRivalsDraftableObjectInterface' as const,
    RivalsCardWithInfo_footballRivalsDraftableObjectInterface: 'RivalsCardWithInfo_footballRivalsDraftableObjectInterface' as const,
    RivalsCardWithInfo_footballRivalsLineupTactic: 'RivalsCardWithInfo_footballRivalsLineupTactic' as const,
    Steps_footballRivalsLineupTactic: 'Steps_footballRivalsLineupTactic' as const,
    Steps_footballRivalsDraftableObjectInterface: 'Steps_footballRivalsDraftableObjectInterface' as const,
    Steps_game: 'Steps_game' as const,
    Steps_footballRivalsCurrentManager: 'Steps_footballRivalsCurrentManager' as const,
    TacticBar_footballRivalsDraftableObjectInterface: 'TacticBar_footballRivalsDraftableObjectInterface' as const,
    TacticBar_footballRivalsLineupTactic: 'TacticBar_footballRivalsLineupTactic' as const,
    TacticSelector_footballRivalsLineupTactic: 'TacticSelector_footballRivalsLineupTactic' as const,
    TeamRank_game: 'TeamRank_game' as const,
    DraftWithTactic_footballRivalsGame: 'DraftWithTactic_footballRivalsGame' as const,
    DraftWithTactic_footballRivalsCurrentManager: 'DraftWithTactic_footballRivalsCurrentManager' as const,
    RivalsPicker_footballRivalsDraftableObjectInterface: 'RivalsPicker_footballRivalsDraftableObjectInterface' as const,
    RemainingPoints_footballRivalsDraftableObjectInterface: 'RemainingPoints_footballRivalsDraftableObjectInterface' as const,
    OldDraftCardInfo_footballRivalsDraftableObjectInterface: 'OldDraftCardInfo_footballRivalsDraftableObjectInterface' as const,
    OldDraftCardInfo_footballRivalsLineupTactic: 'OldDraftCardInfo_footballRivalsLineupTactic' as const,
    RivalsSlideInCards_footballRivalsDraftableObjectInterface: 'RivalsSlideInCards_footballRivalsDraftableObjectInterface' as const,
    RivalsSlideInCards_footballRivalsLineupTactic: 'RivalsSlideInCards_footballRivalsLineupTactic' as const,
    SubstitutesRecap_player: 'SubstitutesRecap_player' as const,
    getRemainingBudgetForCurrentPosition_footballRivalsDraftableObjectInterface: 'getRemainingBudgetForCurrentPosition_footballRivalsDraftableObjectInterface' as const,
    RivalsDraftSubstitutes_team: 'RivalsDraftSubstitutes_team' as const,
    ChallengeHeader_footballRivalsChallenge: 'ChallengeHeader_footballRivalsChallenge' as const,
    ChallengeHeader_footballRivalsGame: 'ChallengeHeader_footballRivalsGame' as const,
    Challenge_pollingFootballRivalsChallenge: 'Challenge_pollingFootballRivalsChallenge' as const,
    Challenge_footballRivalsChallenge: 'Challenge_footballRivalsChallenge' as const,
    Challenge_footballRivalsGame: 'Challenge_footballRivalsGame' as const,
    RivalsPlayerCard_footballRivalsAppearance: 'RivalsPlayerCard_footballRivalsAppearance' as const,
    TacticProgress_pollingFootballRivalsLineup: 'TacticProgress_pollingFootballRivalsLineup' as const,
    TacticProgress_footballRivalsLineup: 'TacticProgress_footballRivalsLineup' as const,
    UpcomingLineup_footballRivalsSquadMembershipChallenge: 'UpcomingLineup_footballRivalsSquadMembershipChallenge' as const,
    DesktopArenaLive_footballRivalsLineup: 'DesktopArenaLive_footballRivalsLineup' as const,
    DesktopArenaLive_footballRivalsContestant: 'DesktopArenaLive_footballRivalsContestant' as const,
    DesktopArenaLive_footballRivalsGame: 'DesktopArenaLive_footballRivalsGame' as const,
    LiveResults_footballRivalsLineup: 'LiveResults_footballRivalsLineup' as const,
    MobileArenaLive_footballRivalsLineup: 'MobileArenaLive_footballRivalsLineup' as const,
    MobileArenaLive_footballRivalsGame: 'MobileArenaLive_footballRivalsGame' as const,
    BoxOddsDialog_footballRivalsDivisionLeaderboardRankingReward: 'BoxOddsDialog_footballRivalsDivisionLeaderboardRankingReward' as const,
    BoxOddsDialog_footballRivalsDivisionLeaderboardDivisionConfig: 'BoxOddsDialog_footballRivalsDivisionLeaderboardDivisionConfig' as const,
    BoxOddsDialog_footballRivalsDivisionLeaderboardConfig: 'BoxOddsDialog_footballRivalsDivisionLeaderboardConfig' as const,
    BoxOddsDialog_footballRivalsDivisionSquadLeaderboardConfig: 'BoxOddsDialog_footballRivalsDivisionSquadLeaderboardConfig' as const,
    RivalsLeaderboardGame_footballRivalsGame: 'RivalsLeaderboardGame_footballRivalsGame' as const,
    SeasonName_footballRivalsSeason: 'SeasonName_footballRivalsSeason' as const,
    RivalsLeaderboardMatches_footballRivalsGame: 'RivalsLeaderboardMatches_footballRivalsGame' as const,
    PodiumAvatar_user: 'PodiumAvatar_user' as const,
    SeasonLeaderboardHeader_footballRivalsDivisionLeaderboardConfig: 'SeasonLeaderboardHeader_footballRivalsDivisionLeaderboardConfig' as const,
    SeasonLeaderboardHeader_footballRivalsDivisionLeaderboard: 'SeasonLeaderboardHeader_footballRivalsDivisionLeaderboard' as const,
    SeasonLeaderboardList_footballRivalsDivisionRanking: 'SeasonLeaderboardList_footballRivalsDivisionRanking' as const,
    SeasonLeaderboardPodium_footballRivalsDivisionLeaderboard: 'SeasonLeaderboardPodium_footballRivalsDivisionLeaderboard' as const,
    SeasonLeaderboardPodium_footballRivalsDivisionLeaderboardConfig: 'SeasonLeaderboardPodium_footballRivalsDivisionLeaderboardConfig' as const,
    SeasonLeaderboard_footballRivalsDivisionLeaderboard: 'SeasonLeaderboard_footballRivalsDivisionLeaderboard' as const,
    SeasonLeaderboard_footballRivalsDivisionLeaderboardConfig: 'SeasonLeaderboard_footballRivalsDivisionLeaderboardConfig' as const,
    SeasonProgressGraph_footballRivalsPublicManagerInterface: 'SeasonProgressGraph_footballRivalsPublicManagerInterface' as const,
    SeasonProgressGraphWithLegend_footballRivalsPublicManagerInterface: 'SeasonProgressGraphWithLegend_footballRivalsPublicManagerInterface' as const,
    UserSeasonEntry_footballRivalsSeason: 'UserSeasonEntry_footballRivalsSeason' as const,
    SeasonSelector_footballRivalsSeason: 'SeasonSelector_footballRivalsSeason' as const,
    SeasonsHistory_footballRivalsSeason: 'SeasonsHistory_footballRivalsSeason' as const,
    DropdownDivisionPicker_footballRivalsDivisionLeaderboardDivisionConfig: 'DropdownDivisionPicker_footballRivalsDivisionLeaderboardDivisionConfig' as const,
    ScrollableDivisionPicker_footballRivalsDivisionLeaderboardDivisionConfig: 'ScrollableDivisionPicker_footballRivalsDivisionLeaderboardDivisionConfig' as const,
    VisibilitySettings_userGroupInterface: 'VisibilitySettings_userGroupInterface' as const,
    InviteDialog_userGroupInterface: 'InviteDialog_userGroupInterface' as const,
    SquadOverview_footballRivalsSquad: 'SquadOverview_footballRivalsSquad' as const,
    JoinSquad_footballRivalsRoot: 'JoinSquad_footballRivalsRoot' as const,
    SquadPreviewBlock_userGroupInterface: 'SquadPreviewBlock_userGroupInterface' as const,
    MembersRecap_userGroupInterface: 'MembersRecap_userGroupInterface' as const,
    MultipleMembersSquad_footballRivalsSeasonSquad: 'MultipleMembersSquad_footballRivalsSeasonSquad' as const,
    MySquad_mySquadOverview: 'MySquad_mySquadOverview' as const,
    JoinPublicSquadDialog_footballRivalsSquad: 'JoinPublicSquadDialog_footballRivalsSquad' as const,
    PublicSquads_footballRivalsSquad: 'PublicSquads_footballRivalsSquad' as const,
    SeasonSquadLeaderboardFooter_footballRivalsSeasonSquad: 'SeasonSquadLeaderboardFooter_footballRivalsSeasonSquad' as const,
    SeasonSquadLeaderboard_footballRivalsSeasonSquad: 'SeasonSquadLeaderboard_footballRivalsSeasonSquad' as const,
    LeaveSquad_footballRivalsSquad: 'LeaveSquad_footballRivalsSquad' as const,
    Members_footballRivalsSquad: 'Members_footballRivalsSquad' as const,
    MembersListRowActions_user: 'MembersListRowActions_user' as const,
    MembersList_footballRivalsSquad: 'MembersList_footballRivalsSquad' as const,
    Visibility_footballRivalsSquad: 'Visibility_footballRivalsSquad' as const,
    Settings_footballRivalsSquad: 'Settings_footballRivalsSquad' as const,
    SingleMemberSquad_userGroupInterface: 'SingleMemberSquad_userGroupInterface' as const,
    SquadRecap_footballRivalsSeasonSquad: 'SquadRecap_footballRivalsSeasonSquad' as const,
    SquadLeaderboardRow_footballRivalsSquadsLeaderboardRanking: 'SquadLeaderboardRow_footballRivalsSquadsLeaderboardRanking' as const,
    SquadRewardsPreview_footballRivalsSquadsLeaderboardRanking: 'SquadRewardsPreview_footballRivalsSquadsLeaderboardRanking' as const,
    SquadVersusSquadHeader_footballRivalsSeasonSquad: 'SquadVersusSquadHeader_footballRivalsSeasonSquad' as const,
    SquadVersusSquadHeader_footballRivalsDivisionSquadLeaderboardConfig: 'SquadVersusSquadHeader_footballRivalsDivisionSquadLeaderboardConfig' as const,
    SquadVersusSquadLeaderboard_footballRivalsSquadsLeaderboard: 'SquadVersusSquadLeaderboard_footballRivalsSquadsLeaderboard' as const,
    LeagueRewardItem_so5RewardConfig: 'LeagueRewardItem_so5RewardConfig' as const,
    LeagueRewardItemDescription_anyRewardConfigInterface: 'LeagueRewardItemDescription_anyRewardConfigInterface' as const,
    LeagueRewardItemTitle_anyRewardConfigInterface: 'LeagueRewardItemTitle_anyRewardConfigInterface' as const,
    ScoutingCenterHeaderCompetitionButton_competition: 'ScoutingCenterHeaderCompetitionButton_competition' as const,
    ScoutingCenterHeader_so5LeaderboardGroup: 'ScoutingCenterHeader_so5LeaderboardGroup' as const,
    ScoutingCenterHeader_so5Competition: 'ScoutingCenterHeader_so5Competition' as const,
    ScoutingCenterHeader_competition: 'ScoutingCenterHeader_competition' as const,
    Layout_footballRivalsCurrentManager: 'Layout_footballRivalsCurrentManager' as const,
    ProfileOverview_footballRivalsCurrentManager: 'ProfileOverview_footballRivalsCurrentManager' as const,
    RankChange_footballRivalsAggregatedArenaStory: 'RankChange_footballRivalsAggregatedArenaStory' as const,
    RankChange_footballRivalsCurrentManager: 'RankChange_footballRivalsCurrentManager' as const,
    RepChange_FootballRivalsAggregatedArenaStory: 'RepChange_FootballRivalsAggregatedArenaStory' as const,
    RepChange_footballRivalsCurrentManager: 'RepChange_footballRivalsCurrentManager' as const,
    useLiveGamePollInterval_game: 'useLiveGamePollInterval_game' as const,
    splitLeaguesByFavorite_footballRivalsLeagueGame: 'splitLeaguesByFavorite_footballRivalsLeagueGame' as const,
    findLeaderboardConfigByDivisionId_footballRivalsDivisionLeaderboardConfig: 'findLeaderboardConfigByDivisionId_footballRivalsDivisionLeaderboardConfig' as const,
    findLeaderboardConfigByDivisionId_footballRivalsDivisionSquadLeaderboardConfig: 'findLeaderboardConfigByDivisionId_footballRivalsDivisionSquadLeaderboardConfig' as const,
    hasJoinedSquad_footballRivalsCurrentManager: 'hasJoinedSquad_footballRivalsCurrentManager' as const,
    TopPlayers_player: 'TopPlayers_player' as const,
    SeasonsLeaderboardSeasonSlugUserSlugLayout_footballRivalsSeason: 'SeasonsLeaderboardSeasonSlugUserSlugLayout_footballRivalsSeason' as const,
    CareerStats_player: 'CareerStats_player' as const,
    UpcomingGame_game: 'UpcomingGame_game' as const,
    UpcomingGame_player: 'UpcomingGame_player' as const,
    CardCollection_cardCollection: 'CardCollection_cardCollection' as const,
    CardCollection_user: 'CardCollection_user' as const,
    Contestant_footballRivalsContestant: 'Contestant_footballRivalsContestant' as const,
    playerFootballGameToGeneric_game: 'playerFootballGameToGeneric_game' as const,
    DomesticLeagueRanking_anyGameInterface: 'DomesticLeagueRanking_anyGameInterface' as const,
    DomesticLeagueRanking_teamInterface: 'DomesticLeagueRanking_teamInterface' as const,
    GameWeekProjection_PlayerGameScoreInterface: 'GameWeekProjection_PlayerGameScoreInterface' as const,
    GameWeekProjection_AnyPlayerInterface: 'GameWeekProjection_AnyPlayerInterface' as const,
    CanComposeEmptyState_so5LeaderboardGroupInterface: 'CanComposeEmptyState_so5LeaderboardGroupInterface' as const,
    FootballBench_anyPlayer: 'FootballBench_anyPlayer' as const,
    FootballField_game: 'FootballField_game' as const,
    Formation_game: 'Formation_game' as const,
    Overview_game: 'Overview_game' as const,
    Player_anyPlayer: 'Player_anyPlayer' as const,
    MatchView_game: 'MatchView_game' as const,
    PlayerChanged_AnyCardInterface: 'PlayerChanged_AnyCardInterface' as const,
    RevealCeremony_AnyCardInterface: 'RevealCeremony_AnyCardInterface' as const,
    Announcement_announcement: 'Announcement_announcement' as const,
    DumbNotification_userAvatar: 'DumbNotification_userAvatar' as const,
    DumbNotification_cardPicture: 'DumbNotification_cardPicture' as const,
    AnnouncementNotification_announcementNotification: 'AnnouncementNotification_announcementNotification' as const,
    Rewards_anyRewardInterface: 'Rewards_anyRewardInterface' as const,
    AuctionNotification_auctionNotification: 'AuctionNotification_auctionNotification' as const,
    AuctionLink_auction: 'AuctionLink_auction' as const,
    TokenAuction_auctionReminderNotification: 'TokenAuction_auctionReminderNotification' as const,
    ReminderNotification_auctionReminderNotification: 'ReminderNotification_auctionReminderNotification' as const,
    AutoClaimNotification_autoClaimNotification: 'AutoClaimNotification_autoClaimNotification' as const,
    CardCollectionNotification_cardCollectionNotification: 'CardCollectionNotification_cardCollectionNotification' as const,
    CardNotification_cardNotification: 'CardNotification_cardNotification' as const,
    ConversionCreditNotification_ConversionCreditNotification: 'ConversionCreditNotification_ConversionCreditNotification' as const,
    DecisivePurchaseNotification_decisivePurchaseNotification: 'DecisivePurchaseNotification_decisivePurchaseNotification' as const,
    ExternalDepositNotification_externalDepositNotification: 'ExternalDepositNotification_externalDepositNotification' as const,
    FootballRivalsGameNotification_footballRivalsGameNotification: 'FootballRivalsGameNotification_footballRivalsGameNotification' as const,
    InGameCurrencyNotification_inGameCurrencyNotification: 'InGameCurrencyNotification_inGameCurrencyNotification' as const,
    KycRequestNotification_kycRequestNotification: 'KycRequestNotification_kycRequestNotification' as const,
    LoanAvailableNotification_loanNotification: 'LoanAvailableNotification_loanNotification' as const,
    ManagerProgressionNotification_managerProgressionNotification: 'ManagerProgressionNotification_managerProgressionNotification' as const,
    MarketingNotification_marketingNotification: 'MarketingNotification_marketingNotification' as const,
    MissedLineupNotification_missedLineupNotification: 'MissedLineupNotification_missedLineupNotification' as const,
    OfferNotification_offerNotification: 'OfferNotification_offerNotification' as const,
    ReferralRewardNotification_referralRewardNotification: 'ReferralRewardNotification_referralRewardNotification' as const,
    SaleNotification_saleNotification: 'SaleNotification_saleNotification' as const,
    ShopItemNotification_shopItemNotification: 'ShopItemNotification_shopItemNotification' as const,
    So5LineupNotification_so5LineupNotification: 'So5LineupNotification_so5LineupNotification' as const,
    TaskNotification_taskNotification: 'TaskNotification_taskNotification' as const,
    UserRelationNotification_userRelationNotification: 'UserRelationNotification_userRelationNotification' as const,
    Notification_notificationInterface: 'Notification_notificationInterface' as const,
    Notification_notification: 'Notification_notification' as const,
    So5AppearanceScore_so5Appearance: 'So5AppearanceScore_so5Appearance' as const,
    Matchups_playerGameScoreInterface: 'Matchups_playerGameScoreInterface' as const,
    MatchupsWithPlayedInfo_playerGameScoreInterface: 'MatchupsWithPlayedInfo_playerGameScoreInterface' as const,
    SlotEligibleCardCount_userCardsCount: 'SlotEligibleCardCount_userCardsCount' as const,
    SlotEligibleCardCount_appearanceRule: 'SlotEligibleCardCount_appearanceRule' as const,
    CardPage_ItemEligibility_card: 'CardPage_ItemEligibility_card' as const,
    CardLevelPreview_card: 'CardLevelPreview_card' as const,
    Level_card: 'Level_card' as const,
    Bonuses_card: 'Bonuses_card' as const,
    SimpleCollectionPreview_cardCollection: 'SimpleCollectionPreview_cardCollection' as const,
    CollectionInfo_card: 'CollectionInfo_card' as const,
    AllStepsProgressBar_cardCollection: 'AllStepsProgressBar_cardCollection' as const,
    CardPreview_cardCollectionCard: 'CardPreview_cardCollectionCard' as const,
    CardPreview_cardCollection: 'CardPreview_cardCollection' as const,
    CollectionBonuses_cardCollectionCard: 'CollectionBonuses_cardCollectionCard' as const,
    LeaderboardRow_userCardCollection: 'LeaderboardRow_userCardCollection' as const,
    CollectionLeaderboard_userCardCollection: 'CollectionLeaderboard_userCardCollection' as const,
    CollectionPreviewStats_userCardCollection: 'CollectionPreviewStats_userCardCollection' as const,
    CollectionPreview_cardCollection: 'CollectionPreview_cardCollection' as const,
    CollectionSlots_anyPlayer: 'CollectionSlots_anyPlayer' as const,
    CollectionSlots_userCardCollectionSlot: 'CollectionSlots_userCardCollectionSlot' as const,
    CollectionSlots_cardCollectionSlot: 'CollectionSlots_cardCollectionSlot' as const,
    CollectionSlots_cardCollection: 'CollectionSlots_cardCollection' as const,
    CollectionsFilters_cardCollectionConnection: 'CollectionsFilters_cardCollectionConnection' as const,
    DetailsDialog_cardCollection: 'DetailsDialog_cardCollection' as const,
    FulfilledSlot_userCardCollectionSlot: 'FulfilledSlot_userCardCollectionSlot' as const,
    FulfilledSlot_cardCollection: 'FulfilledSlot_cardCollection' as const,
    NewHeader_cardCollection: 'NewHeader_cardCollection' as const,
    NewHeader_userCardCollection: 'NewHeader_userCardCollection' as const,
    ProgressBar_cardCollection: 'ProgressBar_cardCollection' as const,
    SlotsPreview_cardCollection: 'SlotsPreview_cardCollection' as const,
    getTeamStyleVariables_team: 'getTeamStyleVariables_team' as const,
    AdvancedBench_so5Leaderboard: 'AdvancedBench_so5Leaderboard' as const,
    AdvancedBench_appearanceRule: 'AdvancedBench_appearanceRule' as const,
    AdvancedMarket_searchPlayers: 'AdvancedMarket_searchPlayers' as const,
    AsideEnergyBalance_So5LineupPreview: 'AsideEnergyBalance_So5LineupPreview' as const,
    AsideEnergyBalance_So5Leaderboard: 'AsideEnergyBalance_So5Leaderboard' as const,
    AsideHeader_so5Leaderboard: 'AsideHeader_so5Leaderboard' as const,
    AsideHeader_composeTeamBenchObjectInterface: 'AsideHeader_composeTeamBenchObjectInterface' as const,
    BasicBench_so5Leaderboard: 'BasicBench_so5Leaderboard' as const,
    BasicBench_appearanceRule: 'BasicBench_appearanceRule' as const,
    BenchExtra_so5Lineup: 'BenchExtra_so5Lineup' as const,
    BenchExtra_composeTeamBenchObjectInterface: 'BenchExtra_composeTeamBenchObjectInterface' as const,
    BenchExtraTooltip_so5Lineup: 'BenchExtraTooltip_so5Lineup' as const,
    BenchExtraTooltip_composeTeamBenchObjectInterface: 'BenchExtraTooltip_composeTeamBenchObjectInterface' as const,
    getBenchObjectDropPrice_composeTeamBenchObjectInterface: 'getBenchObjectDropPrice_composeTeamBenchObjectInterface' as const,
    BenchObjectFooter_composeTeamBenchObjectInterface: 'BenchObjectFooter_composeTeamBenchObjectInterface' as const,
    ComposeCardLockerOverlay_anyPlayer: 'ComposeCardLockerOverlay_anyPlayer' as const,
    FieldCard_composeTeamBenchObjectInterface: 'FieldCard_composeTeamBenchObjectInterface' as const,
    FieldCard_appearanceRule: 'FieldCard_appearanceRule' as const,
    FieldHeader_so5Leaderboard: 'FieldHeader_so5Leaderboard' as const,
    FieldHeader_managerTeam: 'FieldHeader_managerTeam' as const,
    FieldHeader_so5RuleFeedback: 'FieldHeader_so5RuleFeedback' as const,
    FieldHeader_benchSo5RewardConfig: 'FieldHeader_benchSo5RewardConfig' as const,
    FieldHeader_composeTeamBenchObjectInterface: 'FieldHeader_composeTeamBenchObjectInterface' as const,
    LeaderboardHeader_so5Leaderboard: 'LeaderboardHeader_so5Leaderboard' as const,
    LeaderboardHeader_managerTeam: 'LeaderboardHeader_managerTeam' as const,
    LeaderboardHeader_benchSo5RewardConfig: 'LeaderboardHeader_benchSo5RewardConfig' as const,
    MarketCardFooter_anyCardInterface: 'MarketCardFooter_anyCardInterface' as const,
    MobileFooterActions_so5RuleFeedback: 'MobileFooterActions_so5RuleFeedback' as const,
    MobileFooterActions_so5Leaderboard: 'MobileFooterActions_so5Leaderboard' as const,
    MobileSlot_composeTeamBenchObjectInterface: 'MobileSlot_composeTeamBenchObjectInterface' as const,
    MobileSlot_appearanceRule: 'MobileSlot_appearanceRule' as const,
    PrimaryOfferWrapper_anyCardInterface: 'PrimaryOfferWrapper_anyCardInterface' as const,
    RewardBoostDropdown_so5Leaderboard: 'RewardBoostDropdown_so5Leaderboard' as const,
    RulesDropdown_so5Leaderboard: 'RulesDropdown_so5Leaderboard' as const,
    RulesDropdown_so5RuleFeedback: 'RulesDropdown_so5RuleFeedback' as const,
    RulesPrizePoolDrawer_so5Leaderboard: 'RulesPrizePoolDrawer_so5Leaderboard' as const,
    RulesPrizePoolDrawer_so5RuleFeedback: 'RulesPrizePoolDrawer_so5RuleFeedback' as const,
    UnlockableRewards_so5RewardConfigFragment: 'UnlockableRewards_so5RewardConfigFragment' as const,
    UnlockableRewards_benchSo5RewardConfig: 'UnlockableRewards_benchSo5RewardConfig' as const,
    UnlockableRewards_so5RewardConfig: 'UnlockableRewards_so5RewardConfig' as const,
    AdvancedStarterOddsFilter_so5Leaderboard: 'AdvancedStarterOddsFilter_so5Leaderboard' as const,
    RaritiesFilter_so5Leaderboard: 'RaritiesFilter_so5Leaderboard' as const,
    SortFilter_so5Leaderboard: 'SortFilter_so5Leaderboard' as const,
    StarterOddsFilter_so5Leaderboard: 'StarterOddsFilter_so5Leaderboard' as const,
    StarterOddsFilterWrapper_so5Leaderboard: 'StarterOddsFilterWrapper_so5Leaderboard' as const,
    LineupSetAnimation_so5Leaderboard: 'LineupSetAnimation_so5Leaderboard' as const,
    TokenPrice_anyCard: 'TokenPrice_anyCard' as const,
    PostCraftCardExplanationDialog_anyCard: 'PostCraftCardExplanationDialog_anyCard' as const,
    PlayerRotowireStatsProjection_PlayerGameScoreInterface: 'PlayerRotowireStatsProjection_PlayerGameScoreInterface' as const,
    PlayerRotowireStatsProjection_AnyPlayerInterface: 'PlayerRotowireStatsProjection_AnyPlayerInterface' as const,
    CongratsContent_anyCard: 'CongratsContent_anyCard' as const,
    PostBuyExplanatoryDialog_anyCard: 'PostBuyExplanatoryDialog_anyCard' as const,
    Thresholds_so5Leaderboard: 'Thresholds_so5Leaderboard' as const,
    CompactContender_so5LeaderboardContender: 'CompactContender_so5LeaderboardContender' as const,
    ContenderDrawerContent_so5LeaderboardContender: 'ContenderDrawerContent_so5LeaderboardContender' as const,
    ContenderDrawer_so5LeaderboardContender: 'ContenderDrawer_so5LeaderboardContender' as const,
    CraftedConfirmation_anyCard: 'CraftedConfirmation_anyCard' as const,
    CraftingExperience_cardShardsChest: 'CraftingExperience_cardShardsChest' as const,
    CraftingMachine_cardShardsChest: 'CraftingMachine_cardShardsChest' as const,
    EmptyState_so5Fixture: 'EmptyState_so5Fixture' as const,
    FixtureSelector_so5Fixture: 'FixtureSelector_so5Fixture' as const,
    FixtureStatus_so5Fixture: 'FixtureStatus_so5Fixture' as const,
    FixtureStatus_so5Lineup: 'FixtureStatus_so5Lineup' as const,
    FixtureStatus_composeTeamBenchObjectInterface: 'FixtureStatus_composeTeamBenchObjectInterface' as const,
    FixtureOverviewTitle_so5Fixture: 'FixtureOverviewTitle_so5Fixture' as const,
    FixtureOverview_anyRewardConfigInterface: 'FixtureOverview_anyRewardConfigInterface' as const,
    FixtureOverview_so5RewardOrConfig: 'FixtureOverview_so5RewardOrConfig' as const,
    FixtureOverview_so5Fixture: 'FixtureOverview_so5Fixture' as const,
    UpcomingFixtureLeague_so5LeaderboardGroupInterface: 'UpcomingFixtureLeague_so5LeaderboardGroupInterface' as const,
    UpcomingFixtureOverview_so5Fixture: 'UpcomingFixtureOverview_so5Fixture' as const,
    FixtureTeamsOverview_so5Fixture: 'FixtureTeamsOverview_so5Fixture' as const,
    FixtureTimelineGame_game: 'FixtureTimelineGame_game' as const,
    FixtureTimeline_game: 'FixtureTimeline_game' as const,
    FixtureTimeline_so5Fixture: 'FixtureTimeline_so5Fixture' as const,
    FixtureTimeline_composeTeamBenchObjectInterface: 'FixtureTimeline_composeTeamBenchObjectInterface' as const,
    FixtureTimeline_so5Lineup: 'FixtureTimeline_so5Lineup' as const,
    LeaguesCarousel_so5Fixture: 'LeaguesCarousel_so5Fixture' as const,
    LobbyFixturePickerItem_so5Fixture: 'LobbyFixturePickerItem_so5Fixture' as const,
    MatchCenterBanner_so5Fixture: 'MatchCenterBanner_so5Fixture' as const,
    FeaturedPlayersList_team: 'FeaturedPlayersList_team' as const,
    FeaturedPlayerListItem_anyPlayerInterface: 'FeaturedPlayerListItem_anyPlayerInterface' as const,
    GameTeamsMatchup_pollingAnyGameInterface: 'GameTeamsMatchup_pollingAnyGameInterface' as const,
    GameTeamsMatchup_anyGameInterface: 'GameTeamsMatchup_anyGameInterface' as const,
    Game_anyGameInterface: 'Game_anyGameInterface' as const,
    ByGameTime_AnyGameInterface: 'ByGameTime_AnyGameInterface' as const,
    ByGameTime_So5Appearance: 'ByGameTime_So5Appearance' as const,
    GroupedByTeam_AnyGameInterface: 'GroupedByTeam_AnyGameInterface' as const,
    GroupedByTeam_AnyCardInterface: 'GroupedByTeam_AnyCardInterface' as const,
    GroupedByTeam_So5Appearance: 'GroupedByTeam_So5Appearance' as const,
    anyCardFragment_AnyCardInterface: 'anyCardFragment_AnyCardInterface' as const,
    Gameplay_CardInLineupListItem_AnyCardInterface: 'Gameplay_CardInLineupListItem_AnyCardInterface' as const,
    CardInLineupListItem_So5Appearance: 'CardInLineupListItem_So5Appearance' as const,
    TeamScheduleMenuItem_TeamInterface: 'TeamScheduleMenuItem_TeamInterface' as const,
    TeamScheduleMenuItem_AnyGameInterface: 'TeamScheduleMenuItem_AnyGameInterface' as const,
    Gameplay_TeamScheduleMenuItem_AnyCardInterface: 'Gameplay_TeamScheduleMenuItem_AnyCardInterface' as const,
    TeamScheduleMenuItem_So5Appearance: 'TeamScheduleMenuItem_So5Appearance' as const,
    GameSelectionView_So5Fixture: 'GameSelectionView_So5Fixture' as const,
    GameSelectionView_AnyCardInterface: 'GameSelectionView_AnyCardInterface' as const,
    GameTime_AnyGameInterface: 'GameTime_AnyGameInterface' as const,
    HorizontalGame_anyGameInterface: 'HorizontalGame_anyGameInterface' as const,
    SquaredFeaturedPlayer_anyPlayerInterface: 'SquaredFeaturedPlayer_anyPlayerInterface' as const,
    TeamBox_team: 'TeamBox_team' as const,
    TeamTableStatsBox_team: 'TeamTableStatsBox_team' as const,
    FeaturedCompetitionBlock_so5Fixture: 'FeaturedCompetitionBlock_so5Fixture' as const,
    MarketBlock_promotionalEventInterface: 'MarketBlock_promotionalEventInterface' as const,
    UpcomingFixtureBlock_so5Fixture: 'UpcomingFixtureBlock_so5Fixture' as const,
    ForYouWidget_so5Root: 'ForYouWidget_so5Root' as const,
    ForYouWidget_tokenRoot: 'ForYouWidget_tokenRoot' as const,
    LiveScoresGame_teamInterface: 'LiveScoresGame_teamInterface' as const,
    LiveScoresGame_gameWithCardsPreview: 'LiveScoresGame_gameWithCardsPreview' as const,
    LiveScoresWidget_so5Root: 'LiveScoresWidget_so5Root' as const,
    MatchCenter_so5Fixture: 'MatchCenter_so5Fixture' as const,
    Referral_config: 'Referral_config' as const,
    RewardsPool_so5Fixture: 'RewardsPool_so5Fixture' as const,
    ForYou_referral: 'ForYou_referral' as const,
    DeletedLineups_so5Lineup: 'DeletedLineups_so5Lineup' as const,
    ImportedLineups_so5LineupImport: 'ImportedLineups_so5LineupImport' as const,
    So5LeaderboardGroupWrapper_so5LeaderboardGroupInterface: 'So5LeaderboardGroupWrapper_so5LeaderboardGroupInterface' as const,
    ImportLineupContent_so5LineupImportsBatch: 'ImportLineupContent_so5LineupImportsBatch' as const,
    useHierarchiedLineups_so5LineupImport: 'useHierarchiedLineups_so5LineupImport' as const,
    useHierarchiedSo5Lineups_so5Lineup: 'useHierarchiedSo5Lineups_so5Lineup' as const,
    OfflineLanding_config: 'OfflineLanding_config' as const,
    CardsEarned_currentUser: 'CardsEarned_currentUser' as const,
    CreditsEarned_currentUser: 'CreditsEarned_currentUser' as const,
    ReferralItem_referral: 'ReferralItem_referral' as const,
    ReferralSpecialEvents_currentUser: 'ReferralSpecialEvents_currentUser' as const,
    OnlineLanding_currentUser: 'OnlineLanding_currentUser' as const,
    OnlineLanding_config: 'OnlineLanding_config' as const,
    ScoringMatrixRules_so5Leaderboard: 'ScoringMatrixRules_so5Leaderboard' as const,
    SpecialEventTitleAndDesc_so5Fixture: 'SpecialEventTitleAndDesc_so5Fixture' as const,
    SpecialEventTitleAndDesc_so5League: 'SpecialEventTitleAndDesc_so5League' as const,
    SpecialEventTitleAndDesc_so5LeaderboardGroupInterface: 'SpecialEventTitleAndDesc_so5LeaderboardGroupInterface' as const,
    ComposeRules_so5Leaderboard: 'ComposeRules_so5Leaderboard' as const,
    ComposeRules_so5RuleFeedback: 'ComposeRules_so5RuleFeedback' as const,
    CumulativeRankReward_so5RewardConfig: 'CumulativeRankReward_so5RewardConfig' as const,
    CumulativeRankReward_So5UserGroupMembership: 'CumulativeRankReward_So5UserGroupMembership' as const,
    CumulativeRanking_So5UserGroupMembership: 'CumulativeRanking_So5UserGroupMembership' as const,
    DivisionChangeConfigBar_so5Leaderboard: 'DivisionChangeConfigBar_so5Leaderboard' as const,
    DivisionChangeConfigBar_so5Lineup: 'DivisionChangeConfigBar_so5Lineup' as const,
    DivisionSelector_so5Leaderboard: 'DivisionSelector_so5Leaderboard' as const,
    LeaderboardComposable_so5Leaderboard: 'LeaderboardComposable_so5Leaderboard' as const,
    LeaderboardComposable_so5LeagueTrack: 'LeaderboardComposable_so5LeagueTrack' as const,
    LeaderboardEligibleCards_so5Leaderboard: 'LeaderboardEligibleCards_so5Leaderboard' as const,
    LeaderboardInlineInfo_so5Leaderboard: 'LeaderboardInlineInfo_so5Leaderboard' as const,
    LeaderboardRewards_so5Leaderboard: 'LeaderboardRewards_so5Leaderboard' as const,
    OnboardingComposeRules_so5Leaderboard: 'OnboardingComposeRules_so5Leaderboard' as const,
    PaginatedRankings_so5Ranking: 'PaginatedRankings_so5Ranking' as const,
    CumulativePrizePool_so5Leaderboard: 'CumulativePrizePool_so5Leaderboard' as const,
    CumulativePrizePool_so5LeaderboardContender: 'CumulativePrizePool_so5LeaderboardContender' as const,
    DefaultPrizePool_so5Leaderboard: 'DefaultPrizePool_so5Leaderboard' as const,
    DefaultPrizePool_so5LeaderboardContender: 'DefaultPrizePool_so5LeaderboardContender' as const,
    PrizePoolWidget_so5Leaderboard: 'PrizePoolWidget_so5Leaderboard' as const,
    PrizePoolWidget_so5LeaderboardContender: 'PrizePoolWidget_so5LeaderboardContender' as const,
    RankReward_so5Leaderboard: 'RankReward_so5Leaderboard' as const,
    RankReward_so5RewardConfig: 'RankReward_so5RewardConfig' as const,
    RankReward_so5Ranking: 'RankReward_so5Ranking' as const,
    RankRewardsRanking_so5Ranking: 'RankRewardsRanking_so5Ranking' as const,
    RankRewards_so5Leaderboard: 'RankRewards_so5Leaderboard' as const,
    RankRewards_so5Ranking: 'RankRewards_so5Ranking' as const,
    RankingLeaderboardRow_so5Ranking: 'RankingLeaderboardRow_so5Ranking' as const,
    ScoreRewards_so5RewardConfig: 'ScoreRewards_so5RewardConfig' as const,
    ScoreRewards_so5League: 'ScoreRewards_so5League' as const,
    ScoreRewards_so5Ranking: 'ScoreRewards_so5Ranking' as const,
    ThresholdProgressBar_so5Leaderboard: 'ThresholdProgressBar_so5Leaderboard' as const,
    ThresholdProgressBar_so5Lineup: 'ThresholdProgressBar_so5Lineup' as const,
    ThresholdRewards_so5RewardConfig: 'ThresholdRewards_so5RewardConfig' as const,
    ThresholdRewards_so5League: 'ThresholdRewards_so5League' as const,
    ExtraCardDialog_anyCard: 'ExtraCardDialog_anyCard' as const,
    LoanHeader_so5Leaderboard: 'LoanHeader_so5Leaderboard' as const,
    PrizePoolDialog_so5Leaderboard: 'PrizePoolDialog_so5Leaderboard' as const,
    TeamPreview_loanableRoster: 'TeamPreview_loanableRoster' as const,
    LoanTeamSelection_currentUser: 'LoanTeamSelection_currentUser' as const,
    AddTeam_so5LeaderboardGroupInterface: 'AddTeam_so5LeaderboardGroupInterface' as const,
    AddTeamLayout_so5LeaderboardGroupInterface: 'AddTeamLayout_so5LeaderboardGroupInterface' as const,
    CompetitionUnlockedWithCard_so5LeaderboardGroupInterface: 'CompetitionUnlockedWithCard_so5LeaderboardGroupInterface' as const,
    DiscoverNewCompetition_so5Fixture: 'DiscoverNewCompetition_so5Fixture' as const,
    EligibleCards_so5LeaderboardGroupInterface: 'EligibleCards_so5LeaderboardGroupInterface' as const,
    FeaturedBanner_so5LeaderboardGroupInterface: 'FeaturedBanner_so5LeaderboardGroupInterface' as const,
    LeagueDescription_so5Leaderboard: 'LeagueDescription_so5Leaderboard' as const,
    LeagueDescription_so5LeaderboardGroupInterface: 'LeagueDescription_so5LeaderboardGroupInterface' as const,
    LeagueDot_so5LeaderboardGroupInterface: 'LeagueDot_so5LeaderboardGroupInterface' as const,
    LeagueOverview_so5LeagueTrack: 'LeagueOverview_so5LeagueTrack' as const,
    LeaguePromoBanner_so5LeaderboardGroupInterface: 'LeaguePromoBanner_so5LeaderboardGroupInterface' as const,
    LeagueSelect_so5LeaderboardGroupInterface: 'LeagueSelect_so5LeaderboardGroupInterface' as const,
    Rewards_so5LeaderboardGroupInterface: 'Rewards_so5LeaderboardGroupInterface' as const,
    LeagueSquareLink_so5LeaderboardGroupInterface: 'LeagueSquareLink_so5LeaderboardGroupInterface' as const,
    LeagueTrckGroupSelect_so5LeagueTrackGroup: 'LeagueTrckGroupSelect_so5LeagueTrackGroup' as const,
    MatchesPlayedStat_so5LeaderboardGroupInterface: 'MatchesPlayedStat_so5LeaderboardGroupInterface' as const,
    SeasonalCard_so5LeaderboardGroupInterface: 'SeasonalCard_so5LeaderboardGroupInterface' as const,
    LeagueTrckSlot_so5LeaderboardContender: 'LeagueTrckSlot_so5LeaderboardContender' as const,
    LeagueTrckSlots_so5LeagueTrack: 'LeagueTrckSlots_so5LeagueTrack' as const,
    LeagueTrckTab_so5LeagueTrack: 'LeagueTrckTab_so5LeagueTrack' as const,
    AppearanceRuleIcon_appearanceRule: 'AppearanceRuleIcon_appearanceRule' as const,
    AppearanceAvatar_so5Appearance: 'AppearanceAvatar_so5Appearance' as const,
    CardBlock_so5Appearance: 'CardBlock_so5Appearance' as const,
    AppearanceBonusBreakdown_so5Appearance: 'AppearanceBonusBreakdown_so5Appearance' as const,
    LineupBonusDetails_so5Lineup: 'LineupBonusDetails_so5Lineup' as const,
    ManagerTeamUpdateDialog_managerTeam: 'ManagerTeamUpdateDialog_managerTeam' as const,
    LineupButtons_so5Lineup: 'LineupButtons_so5Lineup' as const,
    LineupButtons_managerTeam: 'LineupButtons_managerTeam' as const,
    LineupButtons_so5Leaderboard: 'LineupButtons_so5Leaderboard' as const,
    LineupDate_fixture: 'LineupDate_fixture' as const,
    LobbyLineupHeader_so5LeaderboardContender: 'LobbyLineupHeader_so5LeaderboardContender' as const,
    LineupSharing_so5Lineup: 'LineupSharing_so5Lineup' as const,
    LiveLineupLink_so5LeaderboardContender: 'LiveLineupLink_so5LeaderboardContender' as const,
    PlayerCard_so5Appearance: 'PlayerCard_so5Appearance' as const,
    PlayerCardPlaceholder_so5Lineup: 'PlayerCardPlaceholder_so5Lineup' as const,
    PlayerCardPlaceholder_managerTeam: 'PlayerCardPlaceholder_managerTeam' as const,
    PlayerCardPlaceholder_so5Leaderboard: 'PlayerCardPlaceholder_so5Leaderboard' as const,
    So5Lineup_so5LeaderboardContender: 'So5Lineup_so5LeaderboardContender' as const,
    WarningDialog_anyCard: 'WarningDialog_anyCard' as const,
    CompetitionRow_So5Leaderboard: 'CompetitionRow_So5Leaderboard' as const,
    CompetitionRow_So5LeagueTrack: 'CompetitionRow_So5LeagueTrack' as const,
    Competitions_So5LeaderboardGroup: 'Competitions_So5LeaderboardGroup' as const,
    ManagerTeamBadge_so5Leaderboard: 'ManagerTeamBadge_so5Leaderboard' as const,
    ManagerTeamBadge_so5Ranking: 'ManagerTeamBadge_so5Ranking' as const,
    ManagerTeamBadge_managerTeam: 'ManagerTeamBadge_managerTeam' as const,
    ManagerTeamBadgeIcon_so5Leaderboard: 'ManagerTeamBadgeIcon_so5Leaderboard' as const,
    ManagerTeamBadgeIcon_managerTeam: 'ManagerTeamBadgeIcon_managerTeam' as const,
    Status_so5Ranking: 'Status_so5Ranking' as const,
    ClaimDailyPackDialog_taskInterface: 'ClaimDailyPackDialog_taskInterface' as const,
    DailyMissionTimeLeft_so5Config: 'DailyMissionTimeLeft_so5Config' as const,
    DailyPackClaim_taskInterface: 'DailyPackClaim_taskInterface' as const,
    DecisivePlayerPickerTaskStatus_taskInterface: 'DecisivePlayerPickerTaskStatus_taskInterface' as const,
    DecisivePlayerPickerTaskStatus_so5Config: 'DecisivePlayerPickerTaskStatus_so5Config' as const,
    DropChallengePickerDialog_dropChallengeTask: 'DropChallengePickerDialog_dropChallengeTask' as const,
    DropChallengePickerDialog_so5Config: 'DropChallengePickerDialog_so5Config' as const,
    DropChallengeRules_so5RuleInterface: 'DropChallengeRules_so5RuleInterface' as const,
    DropChallengeButton_dropChallengeTask: 'DropChallengeButton_dropChallengeTask' as const,
    DropChallengeButton_so5Config: 'DropChallengeButton_so5Config' as const,
    DailyMissionAction_taskInterface: 'DailyMissionAction_taskInterface' as const,
    DailyMissionAction_so5Config: 'DailyMissionAction_so5Config' as const,
    DailyMissionLine_taskInterface: 'DailyMissionLine_taskInterface' as const,
    DailyMissionLine_so5Config: 'DailyMissionLine_so5Config' as const,
    DailyMissionRewardPreview_taskInterface: 'DailyMissionRewardPreview_taskInterface' as const,
    DailyMissionTile_taskInterface: 'DailyMissionTile_taskInterface' as const,
    DailyMissionTile_so5Config: 'DailyMissionTile_so5Config' as const,
    DailyMissions_task: 'DailyMissions_task' as const,
    DailyMissions_so5Config: 'DailyMissions_so5Config' as const,
    GameWeekly_gameWeeklyTask: 'GameWeekly_gameWeeklyTask' as const,
    RewardPreview_managerTaskRewardInterface: 'RewardPreview_managerTaskRewardInterface' as const,
    RewardPreview_rewardConfig: 'RewardPreview_rewardConfig' as const,
    Mission_managerProgressionMission: 'Mission_managerProgressionMission' as const,
    RewardUnlockedDialog_taskInterface: 'RewardUnlockedDialog_taskInterface' as const,
    Rewards_anyRewardConfig: 'Rewards_anyRewardConfig' as const,
    DetailsDialog_thresholdStreakTask: 'DetailsDialog_thresholdStreakTask' as const,
    StreakProgress_managerTaskInterface: 'StreakProgress_managerTaskInterface' as const,
    Title_managerTaskInterface: 'Title_managerTaskInterface' as const,
    TutoRewards_thresholdStreakTask: 'TutoRewards_thresholdStreakTask' as const,
    getMissionStatus_managerTaskInterface: 'getMissionStatus_managerTaskInterface' as const,
    Streaks_thresholdsStreakTask: 'Streaks_thresholdsStreakTask' as const,
    TaskButton_taskInterface: 'TaskButton_taskInterface' as const,
    Missions_currentUser: 'Missions_currentUser' as const,
    Missions_so5Config: 'Missions_so5Config' as const,
    BaseSlot_anyCardInterface: 'BaseSlot_anyCardInterface' as const,
    NoCardState_game: 'NoCardState_game' as const,
    DecisivePlayerPickerDialog_taskAppearances: 'DecisivePlayerPickerDialog_taskAppearances' as const,
    DecisivePlayerPickerDialog_so5Config: 'DecisivePlayerPickerDialog_so5Config' as const,
    DesicivePickerSlot_taskAppearance: 'DesicivePickerSlot_taskAppearance' as const,
    DesicivePickerSlot_card: 'DesicivePickerSlot_card' as const,
    TaskPickerDialogLayout_task: 'TaskPickerDialogLayout_task' as const,
    TaskPickerDialogLayout_so5Config: 'TaskPickerDialogLayout_so5Config' as const,
    CardOrCollectionRaritySwitcher_user: 'CardOrCollectionRaritySwitcher_user' as const,
    MyClubNavItem_UserSportProfileInterface: 'MyClubNavItem_UserSportProfileInterface' as const,
    useNavData_currentUser: 'useNavData_currentUser' as const,
    Network_user: 'Network_user' as const,
    UserNetworkInfo_publicUserInfoInterface: 'UserNetworkInfo_publicUserInfoInterface' as const,
    UserNetwork_user: 'UserNetwork_user' as const,
    PoolDetail_noCardRoute: 'PoolDetail_noCardRoute' as const,
    ImportantNotificationDialog_notificationInterface: 'ImportantNotificationDialog_notificationInterface' as const,
    AnnouncementNotificationDialog_announcementNotification: 'AnnouncementNotificationDialog_announcementNotification' as const,
    AuctionWonContent_tokenAuction: 'AuctionWonContent_tokenAuction' as const,
    AuctionNotificationContent_anyReward: 'AuctionNotificationContent_anyReward' as const,
    NotificationDialog_auctionNotification: 'NotificationDialog_auctionNotification' as const,
    DecisivePurchaseNotificationDialog_decisivePurchaseNotification: 'DecisivePurchaseNotificationDialog_decisivePurchaseNotification' as const,
    LoanNotification_loanNotification: 'LoanNotification_loanNotification' as const,
    NotificationDialog_notificationInterface: 'NotificationDialog_notificationInterface' as const,
    Notifications_currentUser: 'Notifications_currentUser' as const,
    UsSportsPlayerAvatar_player: 'UsSportsPlayerAvatar_player' as const,
    GameplayPlayerAvatar_anyPlayer: 'GameplayPlayerAvatar_anyPlayer' as const,
    BuyingFloorPricePlayerDetails_anyPlayer: 'BuyingFloorPricePlayerDetails_anyPlayer' as const,
    BuyingPlayerDetailsFooter_anyPlayer: 'BuyingPlayerDetailsFooter_anyPlayer' as const,
    PlayerInjuryReport_anyPlayer: 'PlayerInjuryReport_anyPlayer' as const,
    PlayerNewsUpdates_anyPlayer: 'PlayerNewsUpdates_anyPlayer' as const,
    PlayerProperties_anyPlayer: 'PlayerProperties_anyPlayer' as const,
    PlayerProperties_anyCard: 'PlayerProperties_anyCard' as const,
    PlayerUnavailabilityPanel_anyPlayer: 'PlayerUnavailabilityPanel_anyPlayer' as const,
    PlayerUpcomingGames_game: 'PlayerUpcomingGames_game' as const,
    PlayerListItem_player: 'PlayerListItem_player' as const,
    PlayerUnavailabilityBadge_suspension: 'PlayerUnavailabilityBadge_suspension' as const,
    PlayerUnavailabilityBadge_injury: 'PlayerUnavailabilityBadge_injury' as const,
    GalleryCards_anyCard: 'GalleryCards_anyCard' as const,
    Gallery_anyCard: 'Gallery_anyCard' as const,
    Gallery_deck: 'Gallery_deck' as const,
    Gallery_publicUserInfoInterface: 'Gallery_publicUserInfoInterface' as const,
    ClubEditor_userSportProfile: 'ClubEditor_userSportProfile' as const,
    Share_socialPictureDerivative: 'Share_socialPictureDerivative' as const,
    FormattedStatistic_UserSportStatistic: 'FormattedStatistic_UserSportStatistic' as const,
    ManagerIDStat_userSportStatistic: 'ManagerIDStat_userSportStatistic' as const,
    ManagerIDHeader_user: 'ManagerIDHeader_user' as const,
    ManagerIDHeader_userSportProfile: 'ManagerIDHeader_userSportProfile' as const,
    CardCounts_user: 'CardCounts_user' as const,
    Deck_user: 'Deck_user' as const,
    Deck_deck: 'Deck_deck' as const,
    Infos_user: 'Infos_user' as const,
    ManagerIdRendering_user: 'ManagerIdRendering_user' as const,
    ManagerIdRendering_userSportProfile: 'ManagerIdRendering_userSportProfile' as const,
    So5RankingRewards_so5Ranking: 'So5RankingRewards_so5Ranking' as const,
    AnyCardReward_anyCardReward: 'AnyCardReward_anyCardReward' as const,
    AnyRewardConfigImage_anyRewardConfigInterface: 'AnyRewardConfigImage_anyRewardConfigInterface' as const,
    AnyRewardConfigText_anyRewardConfigInterface: 'AnyRewardConfigText_anyRewardConfigInterface' as const,
    RewardMultiplier_lineup: 'RewardMultiplier_lineup' as const,
    AnyRewardConfig_anyRewardConfigInterface: 'AnyRewardConfig_anyRewardConfigInterface' as const,
    BoxOdds_probabilisticBundleConfig: 'BoxOdds_probabilisticBundleConfig' as const,
    BoxOdds_so5League: 'BoxOdds_so5League' as const,
    ArenaTicketOpeningOnboardingDialog_probabilisticBundleArenaTicketSlotItem: 'ArenaTicketOpeningOnboardingDialog_probabilisticBundleArenaTicketSlotItem' as const,
    CardTransaction_tokenPrice: 'CardTransaction_tokenPrice' as const,
    Price_tokenPrice: 'Price_tokenPrice' as const,
    Price_anyCard: 'Price_anyCard' as const,
    Rarity_anyCard: 'Rarity_anyCard' as const,
    SerialNumber_anyCard: 'SerialNumber_anyCard' as const,
    Step_anyCard: 'Step_anyCard' as const,
    CardOpeningOnboardingDialog_probabilisticBundle: 'CardOpeningOnboardingDialog_probabilisticBundle' as const,
    EssenceOpeningOnboardingDialog_probabilisticBundleSlotCardShardItem: 'EssenceOpeningOnboardingDialog_probabilisticBundleSlotCardShardItem' as const,
    BoxOpeningOnboardingDialog_probabilisticBundle: 'BoxOpeningOnboardingDialog_probabilisticBundle' as const,
    useBoxOpeningOnboardingDialog_probabilisticBundle: 'useBoxOpeningOnboardingDialog_probabilisticBundle' as const,
    BoxOverviewDialog_probabilisticBundleConfig: 'BoxOverviewDialog_probabilisticBundleConfig' as const,
    BoxOverviewDialog_so5League: 'BoxOverviewDialog_so5League' as const,
    ClickableAnyRewardConfig_anyRewardConfigInterface: 'ClickableAnyRewardConfig_anyRewardConfigInterface' as const,
    ClickableAnyRewardConfig_so5League: 'ClickableAnyRewardConfig_so5League' as const,
    LivePastLeaderboard_so5Leaderboard: 'LivePastLeaderboard_so5Leaderboard' as const,
    So5LeaderboardHeader_so5Leaderboard: 'So5LeaderboardHeader_so5Leaderboard' as const,
    So5LeaderboardWrapper_so5Leaderboard: 'So5LeaderboardWrapper_so5Leaderboard' as const,
    UpcomingLeaderboard_so5Leaderboard: 'UpcomingLeaderboard_so5Leaderboard' as const,
    FixtureRewardsLayout_So5Fixture: 'FixtureRewardsLayout_So5Fixture' as const,
    LazyBoxOverviewDialog_so5League: 'LazyBoxOverviewDialog_so5League' as const,
    NeonRewards_anyRewardConfigInterface: 'NeonRewards_anyRewardConfigInterface' as const,
    NeonRewards_so5RewardOrConfig: 'NeonRewards_so5RewardOrConfig' as const,
    NewRewardsBanner_so5Reward: 'NewRewardsBanner_so5Reward' as const,
    PlayerWithSupply_playerWithSupply: 'PlayerWithSupply_playerWithSupply' as const,
    PlayerWithSupply_so5League: 'PlayerWithSupply_so5League' as const,
    ArenaTicketInRewardBox_ProbabilisticBundleSlotItemInterface: 'ArenaTicketInRewardBox_ProbabilisticBundleSlotItemInterface' as const,
    CardInRewardBox_ProbabilisticBundleSlotItemInterface: 'CardInRewardBox_ProbabilisticBundleSlotItemInterface' as const,
    ConversionCreditInRewardBox_ProbabilisticBundleSlotItemInterface: 'ConversionCreditInRewardBox_ProbabilisticBundleSlotItemInterface' as const,
    DeliverableItemInRewardBox_CurrentUser: 'DeliverableItemInRewardBox_CurrentUser' as const,
    DeliverableItemInRewardBox_ProbabilisticBundleSlotItemInterface: 'DeliverableItemInRewardBox_ProbabilisticBundleSlotItemInterface' as const,
    EssenceInRewardBox_ProbabilisticBundleSlotItemInterface: 'EssenceInRewardBox_ProbabilisticBundleSlotItemInterface' as const,
    ShopItemInRewardBox_ProbabilisticBundleSlotItemInterface: 'ShopItemInRewardBox_ProbabilisticBundleSlotItemInterface' as const,
    RewardBoxDialog_ProbabilisticBundle: 'RewardBoxDialog_ProbabilisticBundle' as const,
    RewardBoxContent_ProbabilisticBundleSlotInterface: 'RewardBoxContent_ProbabilisticBundleSlotInterface' as const,
    useFormatBox_ProbabilisticBundle: 'useFormatBox_ProbabilisticBundle' as const,
    RewardBoxDialogWithOnboarding_probabilisticBundle: 'RewardBoxDialogWithOnboarding_probabilisticBundle' as const,
    RewardBoxFallback_ProbabilisticBundle: 'RewardBoxFallback_ProbabilisticBundle' as const,
    TotalRewardsOverview_rewardsOverview: 'TotalRewardsOverview_rewardsOverview' as const,
    JerseyRewardPreview_JerseyDetails: 'JerseyRewardPreview_JerseyDetails' as const,
    DeliverableItemOrderFormDialog_deliverableItemOrderFragment: 'DeliverableItemOrderFormDialog_deliverableItemOrderFragment' as const,
    DeliverableItemPreview_deliverableItem: 'DeliverableItemPreview_deliverableItem' as const,
    DeliveryConfirmed_DeliverableItem: 'DeliveryConfirmed_DeliverableItem' as const,
    Header_shopItem: 'Header_shopItem' as const,
    BuyButton_clubShopItem: 'BuyButton_clubShopItem' as const,
    Congrats_shopItem: 'Congrats_shopItem' as const,
    ConsumableCta_shopItem: 'ConsumableCta_shopItem' as const,
    AppearanceBoostContent_clubShopItem: 'AppearanceBoostContent_clubShopItem' as const,
    CommonCardSwapContent_clubShopItem: 'CommonCardSwapContent_clubShopItem' as const,
    DefaultContent_clubShopItem: 'DefaultContent_clubShopItem' as const,
    DeliverableWithNoVariantContent_clubShopItem: 'DeliverableWithNoVariantContent_clubShopItem' as const,
    ExtraSwapContent_clubShopItem: 'ExtraSwapContent_clubShopItem' as const,
    ExtraTeamsContent_clubShopItem: 'ExtraTeamsContent_clubShopItem' as const,
    SizeSelectionStep_clubShopItem: 'SizeSelectionStep_clubShopItem' as const,
    JerseyContent_clubShopItem: 'JerseyContent_clubShopItem' as const,
    LevelUpContent_clubShopItem: 'LevelUpContent_clubShopItem' as const,
    LogoContent_clubShopItem: 'LogoContent_clubShopItem' as const,
    PostalAdressForm_shopItem: 'PostalAdressForm_shopItem' as const,
    RaffleContent_clubShopItem: 'RaffleContent_clubShopItem' as const,
    SkinContent_clubShopItem: 'SkinContent_clubShopItem' as const,
    Details_shopItem: 'Details_shopItem' as const,
    CollectionRequirements_shopItem: 'CollectionRequirements_shopItem' as const,
    ItemRequirements_clubShopItem: 'ItemRequirements_clubShopItem' as const,
    Requirements_clubShopItem: 'Requirements_clubShopItem' as const,
    ItemPreviewDialog_shopItem: 'ItemPreviewDialog_shopItem' as const,
    AppearanceBoost_appearanceBoostShopItem: 'AppearanceBoost_appearanceBoostShopItem' as const,
    CommonCardSwap_commonCardSwapShopItem: 'CommonCardSwap_commonCardSwapShopItem' as const,
    DefaultListing_shopItem: 'DefaultListing_shopItem' as const,
    DefaultListing_userSportProfile: 'DefaultListing_userSportProfile' as const,
    DeliverableWithNoVariantShopItemListing_deliverableWithNoVariantShopItem: 'DeliverableWithNoVariantShopItemListing_deliverableWithNoVariantShopItem' as const,
    ExtraSwapShopItemListing_ExtraSwapShopItem: 'ExtraSwapShopItemListing_ExtraSwapShopItem' as const,
    JerseyShopItemListing_JerseyShopItem: 'JerseyShopItemListing_JerseyShopItem' as const,
    LevelUpShopItemListing_LevelUpShopItem: 'LevelUpShopItemListing_LevelUpShopItem' as const,
    RaffleShopItemListing_raffleShopItem: 'RaffleShopItemListing_raffleShopItem' as const,
    BriefGame_teamInterface: 'BriefGame_teamInterface' as const,
    BriefGame_anyGameInterface: 'BriefGame_anyGameInterface' as const,
    ComposeTeamTutorial_so5Leaderboard: 'ComposeTeamTutorial_so5Leaderboard' as const,
    GameStatusLabel_anyGameInterface: 'GameStatusLabel_anyGameInterface' as const,
    CardLayout_so5lineup: 'CardLayout_so5lineup' as const,
    LevelUp_cardXpGradeThreshold: 'LevelUp_cardXpGradeThreshold' as const,
    LevelUp_leveledUpCard: 'LevelUp_leveledUpCard' as const,
    CardLeveledUp_cardXpGradeThreshold: 'CardLeveledUp_cardXpGradeThreshold' as const,
    CardLeveledUp_so5Fixture: 'CardLeveledUp_so5Fixture' as const,
    CardLeveledup_leveledUpCard: 'CardLeveledup_leveledUpCard' as const,
    CardReveal_anyCard: 'CardReveal_anyCard' as const,
    CardReward_so5Reward: 'CardReward_so5Reward' as const,
    CardRewardsReveal_so5Reward: 'CardRewardsReveal_so5Reward' as const,
    CardRewardsReveal_so5Fixture: 'CardRewardsReveal_so5Fixture' as const,
    CompletedOnboardingTask_so5Fixture: 'CompletedOnboardingTask_so5Fixture' as const,
    CompletedOnboardingTask_taskInterface: 'CompletedOnboardingTask_taskInterface' as const,
    StreakProgressAnimated_thresholdsStreakTask: 'StreakProgressAnimated_thresholdsStreakTask' as const,
    StreakCard_thresholdsStreakTask: 'StreakCard_thresholdsStreakTask' as const,
    CompletedStreakTask_so5Fixture: 'CompletedStreakTask_so5Fixture' as const,
    CompletedStreakTask_thresholdsStreakTask: 'CompletedStreakTask_thresholdsStreakTask' as const,
    Intro_so5Reward: 'Intro_so5Reward' as const,
    Intro_so5Fixture: 'Intro_so5Fixture' as const,
    Title_so5Fixture: 'Title_so5Fixture' as const,
    LeaderboardDivisionChangeRecap_so5Ranking: 'LeaderboardDivisionChangeRecap_so5Ranking' as const,
    Promotion_so5Fixture: 'Promotion_so5Fixture' as const,
    Relegation_so5Fixture: 'Relegation_so5Fixture' as const,
    RewardsFlow_thresholdsStreakTask: 'RewardsFlow_thresholdsStreakTask' as const,
    RewardsFlow_taskInterface: 'RewardsFlow_taskInterface' as const,
    RewardsFlow_cardXpGradeThreshold: 'RewardsFlow_cardXpGradeThreshold' as const,
    RewardsFlow_leveledUpCard: 'RewardsFlow_leveledUpCard' as const,
    RewardsFlow_so5Reward: 'RewardsFlow_so5Reward' as const,
    RewardsFlow_so5Fixture: 'RewardsFlow_so5Fixture' as const,
    DeliverableItemBlock_deliverableItemReward: 'DeliverableItemBlock_deliverableItemReward' as const,
    DeliverableItemBlock_deliverableItemOrder: 'DeliverableItemBlock_deliverableItemOrder' as const,
    TaskRewardBlock_anyRewardConfigInterface: 'TaskRewardBlock_anyRewardConfigInterface' as const,
    RewardsRecap_so5Reward: 'RewardsRecap_so5Reward' as const,
    RewardsRecap_taskInterface: 'RewardsRecap_taskInterface' as const,
    RewardsRecap_so5Fixture: 'RewardsRecap_so5Fixture' as const,
    useRewardsVerification_so5Reward: 'useRewardsVerification_so5Reward' as const,
    LastFixtureStory_cardXpGradeThreshold: 'LastFixtureStory_cardXpGradeThreshold' as const,
    LastFixtureStory_story: 'LastFixtureStory_story' as const,
    useDisplayFixtureStory_story: 'useDisplayFixtureStory_story' as const,
    BenchObjectDrawer_playerGameScoreInterface: 'BenchObjectDrawer_playerGameScoreInterface' as const,
    BenchObjectDrawer_anyPlayerInterface: 'BenchObjectDrawer_anyPlayerInterface' as const,
    BenchObjectDrawer_anyCardInterface: 'BenchObjectDrawer_anyCardInterface' as const,
    BriefPlayerScore_anyCardInterface: 'BriefPlayerScore_anyCardInterface' as const,
    BriefPlayerScore_anyPlayerInterface: 'BriefPlayerScore_anyPlayerInterface' as const,
    BriefPlayerScore_playerGameScoreInterface: 'BriefPlayerScore_playerGameScoreInterface' as const,
    BriefSo5AppearanceScore_so5Appearance: 'BriefSo5AppearanceScore_so5Appearance' as const,
    InlineGame_game: 'InlineGame_game' as const,
    FixtureChart_playerGameScoreInterface: 'FixtureChart_playerGameScoreInterface' as const,
    FixtureChart_anyPlayer: 'FixtureChart_anyPlayer' as const,
    LastScores_playerGameScoreInterface: 'LastScores_playerGameScoreInterface' as const,
    LastScores_anyPlayer: 'LastScores_anyPlayer' as const,
    LastScores_anyCard: 'LastScores_anyCard' as const,
    CategoryHeader_playerGameScoreInterface: 'CategoryHeader_playerGameScoreInterface' as const,
    CategoryStat_statScore: 'CategoryStat_statScore' as const,
    CategoryStat_playerGameScoreInterface: 'CategoryStat_playerGameScoreInterface' as const,
    Category_playerGameScoreInterface: 'Category_playerGameScoreInterface' as const,
    Category_statScore: 'Category_statScore' as const,
    PlayerGameScoreStats_playerGameScoreInterface: 'PlayerGameScoreStats_playerGameScoreInterface' as const,
    PlayerGameScoreStatusIcon_playerGameScoreInterface: 'PlayerGameScoreStatusIcon_playerGameScoreInterface' as const,
    So5AppearenceInlineGames_so5Appearance: 'So5AppearenceInlineGames_so5Appearance' as const,
    So5AppearenceInlineGamesDots_so5Appearance: 'So5AppearenceInlineGamesDots_so5Appearance' as const,
    GamesStanding_TeamInterface: 'GamesStanding_TeamInterface' as const,
    MissingCardsMessage_so5Leaderboard: 'MissingCardsMessage_so5Leaderboard' as const,
    getCommonDraftCampaignStatus_so5Leaderboard: 'getCommonDraftCampaignStatus_so5Leaderboard' as const,
    getMissingCards_validity: 'getMissingCards_validity' as const,
    useBenchObjectsTotalScore_so5Leaderboard: 'useBenchObjectsTotalScore_so5Leaderboard' as const,
    useBenchObjectsTotalScore_composeTeamBenchObjectInterface: 'useBenchObjectsTotalScore_composeTeamBenchObjectInterface' as const,
    useComposeActions_so5Leaderboard: 'useComposeActions_so5Leaderboard' as const,
    useComposeActions_composeTeamBenchObjectInterface: 'useComposeActions_composeTeamBenchObjectInterface' as const,
    useComposeActions_so5Lineup: 'useComposeActions_so5Lineup' as const,
    useComposeActions_managerTeam: 'useComposeActions_managerTeam' as const,
    useComposeBuy_anyCardInterface: 'useComposeBuy_anyCardInterface' as const,
    useComposeInit_so5Leaderboard: 'useComposeInit_so5Leaderboard' as const,
    useContenderStatus_so5LeaderboardContender: 'useContenderStatus_so5LeaderboardContender' as const,
    useGetSuspensionsAndInjuries_playerGameScoreInterface: 'useGetSuspensionsAndInjuries_playerGameScoreInterface' as const,
    useGetSuspensionsAndInjuries_anyPlayer: 'useGetSuspensionsAndInjuries_anyPlayer' as const,
    FixtureEnterSeasonalityCompetitionLayout_so5LeaderboardGroupInterface: 'FixtureEnterSeasonalityCompetitionLayout_so5LeaderboardGroupInterface' as const,
    RightButton_so5Fixture: 'RightButton_so5Fixture' as const,
    RightButton_ongoingSo5Fixture: 'RightButton_ongoingSo5Fixture' as const,
    GenericLeaderboardPrizePoolLayout_so5Leaderboard: 'GenericLeaderboardPrizePoolLayout_so5Leaderboard' as const,
    GenericLeaderboardPrizePoolLayout_so5LeaderboardContender: 'GenericLeaderboardPrizePoolLayout_so5LeaderboardContender' as const,
    HideTeamDialog_managerTeam: 'HideTeamDialog_managerTeam' as const,
    HideTeamDialog_so5Leaderboard: 'HideTeamDialog_so5Leaderboard' as const,
    LineupFooter_so5LeaderboardContender: 'LineupFooter_so5LeaderboardContender' as const,
    LineupLayoutHeader_so5LeaderboardContender: 'LineupLayoutHeader_so5LeaderboardContender' as const,
    LineupRanking_so5LeaderboardContender: 'LineupRanking_so5LeaderboardContender' as const,
    LineupStatus_so5LeaderboardContender: 'LineupStatus_so5LeaderboardContender' as const,
    LeftLiveLineupFooter_so5LeaderboardContender: 'LeftLiveLineupFooter_so5LeaderboardContender' as const,
    RightLiveLineupFooter_so5LeaderboardContender: 'RightLiveLineupFooter_so5LeaderboardContender' as const,
    LiveLineupFooter_so5LeaderboardContender: 'LiveLineupFooter_so5LeaderboardContender' as const,
    LineupLayout_so5LeaderboardContender: 'LineupLayout_so5LeaderboardContender' as const,
    TeamLineupFooter_so5LeaderboardContender: 'TeamLineupFooter_so5LeaderboardContender' as const,
    TeamLineupLayout_so5LeaderboardContender: 'TeamLineupLayout_so5LeaderboardContender' as const,
    WithFixtureHeaderLayout_so5Fixture: 'WithFixtureHeaderLayout_so5Fixture' as const,
    BlueprintCountByRarity_CurrentUser: 'BlueprintCountByRarity_CurrentUser' as const,
    RevealableCardcampaign_RevealableCardCampaign: 'RevealableCardcampaign_RevealableCardCampaign' as const,
    UnopenedRewardBoxes_CurrentUser: 'UnopenedRewardBoxes_CurrentUser' as const,
    anyGameToGenericGame_anyGameInterface: 'anyGameToGenericGame_anyGameInterface' as const,
    anyPlayerGameStatsToGenericGame_anyPlayerGameStats: 'anyPlayerGameStatsToGenericGame_anyPlayerGameStats' as const,
    getContenderStatus_so5LeaderboardContender: 'getContenderStatus_so5LeaderboardContender' as const,
    getContenderRightFooterState_so5LeaderboardContender: 'getContenderRightFooterState_so5LeaderboardContender' as const,
    getContenderLeftFooterState_so5LeaderboardContender: 'getContenderLeftFooterState_so5LeaderboardContender' as const,
    getCurrentSportClubName_PublicUserInfoInterface: 'getCurrentSportClubName_PublicUserInfoInterface' as const,
    getNbDecks_publicUserInfoInterface: 'getNbDecks_publicUserInfoInterface' as const,
    getLineupDisplayName_so5Leaderboard: 'getLineupDisplayName_so5Leaderboard' as const,
    getLineupDisplayName_so5Lineup: 'getLineupDisplayName_so5Lineup' as const,
    getLineupDisplayName_managerTeam: 'getLineupDisplayName_managerTeam' as const,
    playerHasPlayedInLeaderboard_anyPlayer: 'playerHasPlayedInLeaderboard_anyPlayer' as const,
    getRewardImage_anyRewardConfigInterface: 'getRewardImage_anyRewardConfigInterface' as const,
    isFixtureStarted_so5Fixture: 'isFixtureStarted_so5Fixture' as const,
    isFixtureOpened_so5Fixture: 'isFixtureOpened_so5Fixture' as const,
    isFixtureLive_so5Fixture: 'isFixtureLive_so5Fixture' as const,
    isFixtureClosed_so5Fixture: 'isFixtureClosed_so5Fixture' as const,
    hasGamesStarted_so5Lineup: 'hasGamesStarted_so5Lineup' as const,
    getFixtureState_so5Fixture: 'getFixtureState_so5Fixture' as const,
    getDecisiveActions_playerGameScoreInterface: 'getDecisiveActions_playerGameScoreInterface' as const,
    getTeamName_team: 'getTeamName_team' as const,
    getCompetitionPictureUrl_competition: 'getCompetitionPictureUrl_competition' as const,
    isCardUsed_anyCard: 'isCardUsed_anyCard' as const,
    hasLeagueTrackExtraSlot_so5LeagueTrack: 'hasLeagueTrackExtraSlot_so5LeagueTrack' as const,
    hasLeaderboardGroupExtraSlot_so5LeaderboardGroupInterface: 'hasLeaderboardGroupExtraSlot_so5LeaderboardGroupInterface' as const,
    getAddTeamPath_so5LeaderboardGroupInterface: 'getAddTeamPath_so5LeaderboardGroupInterface' as const,
    isAppearanceLocked_so5Appearance: 'isAppearanceLocked_so5Appearance' as const,
    getLeaguesWithContenders_so5LeaderboardGroupInterface: 'getLeaguesWithContenders_so5LeaderboardGroupInterface' as const,
    getComposeTeamSuccessRoute_so5Leaderboard: 'getComposeTeamSuccessRoute_so5Leaderboard' as const,
    ContenderPage_so5LeaderboardContender: 'ContenderPage_so5LeaderboardContender' as const,
    EnterSeasonalityCompetitionGamesPage_so5LeaderboardGroupInterface: 'EnterSeasonalityCompetitionGamesPage_so5LeaderboardGroupInterface' as const,
    EnterSeasonalityCompetitionGamesPages_paginatedSo5LeaderboardGroupInterface: 'EnterSeasonalityCompetitionGamesPages_paginatedSo5LeaderboardGroupInterface' as const,
    EnterSeasonalityCompetitionTrckPage_so5LeaderboardGroupInterface: 'EnterSeasonalityCompetitionTrckPage_so5LeaderboardGroupInterface' as const,
    MatchCenterGame_gameWithPlayerGameScoresPreview: 'MatchCenterGame_gameWithPlayerGameScoresPreview' as const,
    MatchCenterPage_so5Fixture: 'MatchCenterPage_so5Fixture' as const,
    PlayProFixturePage_nextSo5Fixture: 'PlayProFixturePage_nextSo5Fixture' as const,
    PlayProFixturePage_so5Fixture: 'PlayProFixturePage_so5Fixture' as const,
    RankingsPage_so5Leaderboard: 'RankingsPage_so5Leaderboard' as const,
    RankingsPage_so5LeaderboardContender: 'RankingsPage_so5LeaderboardContender' as const,
    ConfirmationDialogContent_anyCard: 'ConfirmationDialogContent_anyCard' as const,
    Actions_card: 'Actions_card' as const,
    BlueprintDisclaimer_AnyCardInterface: 'BlueprintDisclaimer_AnyCardInterface' as const,
    CardAttributes_anyCard: 'CardAttributes_anyCard' as const,
    CompetitionLogo_so5Leaderboard: 'CompetitionLogo_so5Leaderboard' as const,
    EligibleCompetition_so5Leaderboard: 'EligibleCompetition_so5Leaderboard' as const,
    CompetitionsEligibility_anyCard: 'CompetitionsEligibility_anyCard' as const,
    useCompetitionsEligibility_anyCard: 'useCompetitionsEligibility_anyCard' as const,
    LiveOffer_tokenOffer: 'LiveOffer_tokenOffer' as const,
    MyOffers_anyCard: 'MyOffers_anyCard' as const,
    PlayerLoyaltyBoost_AnyCardInterface: 'PlayerLoyaltyBoost_AnyCardInterface' as const,
    RevealDelayedWarning_anyCard: 'RevealDelayedWarning_anyCard' as const,
    GenericCardPage_anyCard: 'GenericCardPage_anyCard' as const,
    ItemOwner_user: 'ItemOwner_user' as const,
    ItemOwnerSince_anyCard: 'ItemOwnerSince_anyCard' as const,
    ItemSold_anyCard: 'ItemSold_anyCard' as const,
    Team_anyCard: 'Team_anyCard' as const,
    Team_anyPlayerInterface: 'Team_anyPlayerInterface' as const,
    OwnerMyClubLink_User: 'OwnerMyClubLink_User' as const,
    TokenOwner_anyCard: 'TokenOwner_anyCard' as const,
    TradeButton_publicUserInfoInterface: 'TradeButton_publicUserInfoInterface' as const,
    AuctionBidsInfo_auction: 'AuctionBidsInfo_auction' as const,
    AuctionWinner_auction: 'AuctionWinner_auction' as const,
    AuctionDetails_auction: 'AuctionDetails_auction' as const,
    AuctionImg_auction: 'AuctionImg_auction' as const,
    Auction_auction: 'Auction_auction' as const,
    AuctionEligibility_tokenAuction: 'AuctionEligibility_tokenAuction' as const,
    AuctionReminder_auction: 'AuctionReminder_auction' as const,
    AuctionStatus_auction: 'AuctionStatus_auction' as const,
    AuctionsActions_auction: 'AuctionsActions_auction' as const,
    Bid_tokenBid: 'Bid_tokenBid' as const,
    BidHistory_tokenBidConnection: 'BidHistory_tokenBidConnection' as const,
    BidInfos_auction: 'BidInfos_auction' as const,
    BidsCount_auction: 'BidsCount_auction' as const,
    BundledAuctionPreview_anyCard: 'BundledAuctionPreview_anyCard' as const,
    BundledAuctionPreview_auction: 'BundledAuctionPreview_auction' as const,
    MyBidsBanner_auctionConnection: 'MyBidsBanner_auctionConnection' as const,
    OpenAuction_auction: 'OpenAuction_auction' as const,
    UsePollAuction_auction: 'UsePollAuction_auction' as const,
    AnyStatsIconCell_anyPlayer: 'AnyStatsIconCell_anyPlayer' as const,
    CommonPlayerMetas_anyPlayer: 'CommonPlayerMetas_anyPlayer' as const,
    GradeCell_playerGameScoreProjection: 'GradeCell_playerGameScoreProjection' as const,
    BenchObjectPlayerCell_anyPlayer: 'BenchObjectPlayerCell_anyPlayer' as const,
    BenchObjectRow_composeTeamBenchObjectInterface: 'BenchObjectRow_composeTeamBenchObjectInterface' as const,
    AveragePlayerScoreCell_anyPlayer: 'AveragePlayerScoreCell_anyPlayer' as const,
    BreakdownPlayerScoreCell_anyPlayer: 'BreakdownPlayerScoreCell_anyPlayer' as const,
    ProjectionCell_anyPlayer: 'ProjectionCell_anyPlayer' as const,
    StatCell_anyPlayer: 'StatCell_anyPlayer' as const,
    BenchObjectStatsCells_anyPlayer: 'BenchObjectStatsCells_anyPlayer' as const,
    CalendarTooltip_anyPlayer: 'CalendarTooltip_anyPlayer' as const,
    TooltipGame_anyGame: 'TooltipGame_anyGame' as const,
    BenchObjectUpcomingGames_anyPlayer: 'BenchObjectUpcomingGames_anyPlayer' as const,
    MultipleMatchupItem_anyGame: 'MultipleMatchupItem_anyGame' as const,
    SingleMatchupItem_anyGame: 'SingleMatchupItem_anyGame' as const,
    StartingPriceCell_anyPlayer: 'StartingPriceCell_anyPlayer' as const,
    BidBundleSummary_auction: 'BidBundleSummary_auction' as const,
    BidResume_tokenAuction: 'BidResume_tokenAuction' as const,
    BidConfirmedDialogContent_tokenAuction: 'BidConfirmedDialogContent_tokenAuction' as const,
    BidPaymentModal_auction: 'BidPaymentModal_auction' as const,
    usePollAuctionBestBid_auction: 'usePollAuctionBestBid_auction' as const,
    EndedAuctionPaymentBox_tokenAuction: 'EndedAuctionPaymentBox_tokenAuction' as const,
    BidField_auction: 'BidField_auction' as const,
    BidTokenSummary_anyCard: 'BidTokenSummary_anyCard' as const,
    BuyField_anyCard: 'BuyField_anyCard' as const,
    BuyTokenConfirmation_anyCard: 'BuyTokenConfirmation_anyCard' as const,
    CancelOffer_anyCard: 'CancelOffer_anyCard' as const,
    InSeasonWarning_anyCard: 'InSeasonWarning_anyCard' as const,
    AuctionMetas_auction: 'AuctionMetas_auction' as const,
    BidContent_auction: 'BidContent_auction' as const,
    EligibilityTooltip_anyCard: 'EligibilityTooltip_anyCard' as const,
    CardOverview_anyCard: 'CardOverview_anyCard' as const,
    PaymentProvider_auction: 'PaymentProvider_auction' as const,
    TokenSummary_anyCard: 'TokenSummary_anyCard' as const,
    TokensSummary_anyCard: 'TokensSummary_anyCard' as const,
    BonusBreakdownTooltip_powerBreakdown: 'BonusBreakdownTooltip_powerBreakdown' as const,
    BonusChip_powerBreakdown: 'BonusChip_powerBreakdown' as const,
    BoostButton_anyCard: 'BoostButton_anyCard' as const,
    CardBonus_anyCard: 'CardBonus_anyCard' as const,
    CardFooter_anyCardInterface: 'CardFooter_anyCardInterface' as const,
    CardProperties_anyCard: 'CardProperties_anyCard' as const,
    CardPropertiesWithStats_anyCard: 'CardPropertiesWithStats_anyCard' as const,
    CardRow_anyCardBaseData: 'CardRow_anyCardBaseData' as const,
    CardRow_anyCard: 'CardRow_anyCard' as const,
    DirectOffer_CardRow_anyCard: 'DirectOffer_CardRow_anyCard' as const,
    CollectionPrediction_anyCard: 'CollectionPrediction_anyCard' as const,
    CollectionUpsellCard_anyCard: 'CollectionUpsellCard_anyCard' as const,
    IneligibleIndicator_anyPlayer: 'IneligibleIndicator_anyPlayer' as const,
    ItemEligibility_card: 'ItemEligibility_card' as const,
    useCardXpProgressBarSprings_cardXpGradeThreshold: 'useCardXpProgressBarSprings_cardXpGradeThreshold' as const,
    useCardXpProgressBarSprings_leveledUpCard: 'useCardXpProgressBarSprings_leveledUpCard' as const,
    useSimpleCardXpProgressBar_anyCard: 'useSimpleCardXpProgressBar_anyCard' as const,
    AddToCart_anyCard: 'AddToCart_anyCard' as const,
    AddToCartPopin_anyCard: 'AddToCartPopin_anyCard' as const,
    CartItem_anyCard: 'CartItem_anyCard' as const,
    CartItem_cartItem: 'CartItem_cartItem' as const,
    CartItemStateWarning_cartItem: 'CartItemStateWarning_cartItem' as const,
    CartPayment_cart: 'CartPayment_cart' as const,
    CollectionUpsellTitle_cardCollection: 'CollectionUpsellTitle_cardCollection' as const,
    CollectionUpsellContent_CardCollectionScoreIncrease: 'CollectionUpsellContent_CardCollectionScoreIncrease' as const,
    LazyCollectionElement_CardCollectionScoreIncrease: 'LazyCollectionElement_CardCollectionScoreIncrease' as const,
    MissingCardsForCollection_cardCollection: 'MissingCardsForCollection_cardCollection' as const,
    MissingCardsForCollection_userCardCollection: 'MissingCardsForCollection_userCardCollection' as const,
    useSortPrimaryBuys_anyCard: 'useSortPrimaryBuys_anyCard' as const,
    CollectionUpsell_CardCollectionScoreIncrease: 'CollectionUpsell_CardCollectionScoreIncrease' as const,
    MainCartItems_cartItem: 'MainCartItems_cartItem' as const,
    SavedForLaterItem_anyCard: 'SavedForLaterItem_anyCard' as const,
    SavedForLaterItem_cartItem: 'SavedForLaterItem_cartItem' as const,
    SavedForLaterItems_cartItem: 'SavedForLaterItems_cartItem' as const,
    BuyCommonBenchObjectCongratsScreen_composeTeamBenchObjectInterface: 'BuyCommonBenchObjectCongratsScreen_composeTeamBenchObjectInterface' as const,
    BuyCommonCard_composeTeamBenchObjectInterface: 'BuyCommonCard_composeTeamBenchObjectInterface' as const,
    BuyCommonPlayer_anyPlayer: 'BuyCommonPlayer_anyPlayer' as const,
    BuyCommonPlayerCongratsScreen_anyPlayer: 'BuyCommonPlayerCongratsScreen_anyPlayer' as const,
    BuyGemsDialog_inGameCurrencyPack: 'BuyGemsDialog_inGameCurrencyPack' as const,
    HighlightedPackAttributes_cardPack: 'HighlightedPackAttributes_cardPack' as const,
    HighlightedPackAttributes_cardPackGroup: 'HighlightedPackAttributes_cardPackGroup' as const,
    BuyPackForm_cardPackGroup: 'BuyPackForm_cardPackGroup' as const,
    BuyPackForm_inGameCurrencyPack: 'BuyPackForm_inGameCurrencyPack' as const,
    CardPack_cardPack: 'CardPack_cardPack' as const,
    CardPackGroup_cardPackGroup: 'CardPackGroup_cardPackGroup' as const,
    GemsPack_inGameCurrencyPack: 'GemsPack_inGameCurrencyPack' as const,
    GemsPacks_inGameCurrencyPack: 'GemsPacks_inGameCurrencyPack' as const,
    PackOpeningDialog_cardPack: 'PackOpeningDialog_cardPack' as const,
    PackOpeningDialog_inGameCurrencyPack: 'PackOpeningDialog_inGameCurrencyPack' as const,
    BuyPacks_cardPackGroup: 'BuyPacks_cardPackGroup' as const,
    BuyPacks_inGameCurrencyPack: 'BuyPacks_inGameCurrencyPack' as const,
    BuyPacks_currentUser: 'BuyPacks_currentUser' as const,
    CommonCardSummary_composeTeamBenchObjectInterface: 'CommonCardSummary_composeTeamBenchObjectInterface' as const,
    CommonCardsGrid_composeTeamBenchObjectInterface: 'CommonCardsGrid_composeTeamBenchObjectInterface' as const,
    AnyPlayerProperties_anyPlayer: 'AnyPlayerProperties_anyPlayer' as const,
    CommonPlayerPreview_composeTeamBenchObjectInterface: 'CommonPlayerPreview_composeTeamBenchObjectInterface' as const,
    CommonPlayerSummary_anyPlayer: 'CommonPlayerSummary_anyPlayer' as const,
    PacksTypePage_marketRoot: 'PacksTypePage_marketRoot' as const,
    PackRecap_anyCardInterface: 'PackRecap_anyCardInterface' as const,
    RevealPackCardsDialog_anyCardInterface: 'RevealPackCardsDialog_anyCardInterface' as const,
    ScrollablePacks_cardPackGroup: 'ScrollablePacks_cardPackGroup' as const,
    SellCommonCard_anyCard: 'SellCommonCard_anyCard' as const,
    CardInfos_anyCardInterface: 'CardInfos_anyCardInterface' as const,
    TinderCardSwipe_anyCardInterface: 'TinderCardSwipe_anyCardInterface' as const,
    AddTokenToDeck_anyCard: 'AddTokenToDeck_anyCard' as const,
    CounterOfferBuilder_tokenOffer: 'CounterOfferBuilder_tokenOffer' as const,
    MakeOffer_anyCard: 'MakeOffer_anyCard' as const,
    MinimumPrice_anyCard: 'MinimumPrice_anyCard' as const,
    TradePaymentMethods_publicUserInfoInterface: 'TradePaymentMethods_publicUserInfoInterface' as const,
    OfferBuilderBuildingPage_baseCard: 'OfferBuilderBuildingPage_baseCard' as const,
    OfferBuilderBuildingPage_publicUserInfoInterface: 'OfferBuilderBuildingPage_publicUserInfoInterface' as const,
    OfferBuilderBuildingPage_anyCard: 'OfferBuilderBuildingPage_anyCard' as const,
    CardPicker_anyCard: 'CardPicker_anyCard' as const,
    OfferSide_anyCard: 'OfferSide_anyCard' as const,
    OfferUser_publicUserInfoInterface: 'OfferUser_publicUserInfoInterface' as const,
    OfferUser_user: 'OfferUser_user' as const,
    OfferBuilderSummary_anyCard: 'OfferBuilderSummary_anyCard' as const,
    NewOfferBuilder_anyCard: 'NewOfferBuilder_anyCard' as const,
    NewOfferBuilder_publicUserInfoInterface: 'NewOfferBuilder_publicUserInfoInterface' as const,
    useOnSubmit_anyCard: 'useOnSubmit_anyCard' as const,
    NewPrefilledOfferBuilder_tokenOffer: 'NewPrefilledOfferBuilder_tokenOffer' as const,
    NewPrefilledOfferBuilder_publicUserInfoInterface: 'NewPrefilledOfferBuilder_publicUserInfoInterface' as const,
    PromotionalEvent_PromotionalEvent: 'PromotionalEvent_PromotionalEvent' as const,
    PromotionalEvents_PromotionalEvent: 'PromotionalEvents_PromotionalEvent' as const,
    PlayerGameStatsPrediction_playingStatusOdds: 'PlayerGameStatsPrediction_playingStatusOdds' as const,
    PromoBanner_PromotionalEvent: 'PromoBanner_PromotionalEvent' as const,
    PromoBanner_ProbabilisticBundleConfig: 'PromoBanner_ProbabilisticBundleConfig' as const,
    PromoBanner_ProbableRewardConfig: 'PromoBanner_ProbableRewardConfig' as const,
    PromoBanners_PromotionalEventsInterface: 'PromoBanners_PromotionalEventsInterface' as const,
    PlayerNotifications_subscription: 'PlayerNotifications_subscription' as const,
    AccountEntry_authorizationRequest: 'AccountEntry_authorizationRequest' as const,
    Auction_authorizationRequest: 'Auction_authorizationRequest' as const,
    Bid_authorizationRequest: 'Bid_authorizationRequest' as const,
    Offer_authorizationRequest: 'Offer_authorizationRequest' as const,
    PrimaryOffer_authorizationRequest: 'PrimaryOffer_authorizationRequest' as const,
    Bid_userAccountEntry: 'Bid_userAccountEntry' as const,
    CardWithdrawal_userAccountEntry: 'CardWithdrawal_userAccountEntry' as const,
    Offer_userAccountEntry: 'Offer_userAccountEntry' as const,
    PrimaryOffer_userAccountEntry: 'PrimaryOffer_userAccountEntry' as const,
    DumbSo5Reward_userAccountEntry: 'DumbSo5Reward_userAccountEntry' as const,
    Reward_userAccountEntry: 'Reward_userAccountEntry' as const,
    TransactionsBid_tokenBid: 'TransactionsBid_tokenBid' as const,
    TransactionsOffer_tokenOffer: 'TransactionsOffer_tokenOffer' as const,
    TransactionsPrimaryOfferTitle_tokenPrimaryOffer: 'TransactionsPrimaryOfferTitle_tokenPrimaryOffer' as const,
    TransactionsPrimaryOffer_tokenPrimaryOffer: 'TransactionsPrimaryOffer_tokenPrimaryOffer' as const,
    AnyPlayerDescription_anyPlayer: 'AnyPlayerDescription_anyPlayer' as const,
    CardsChangedOffer_tokenOffer: 'CardsChangedOffer_tokenOffer' as const,
    CardsChanged_tokenOffer: 'CardsChanged_tokenOffer' as const,
    CardsChanged_user: 'CardsChanged_user' as const,
    CounteredOffer_publicUserInfoInterface: 'CounteredOffer_publicUserInfoInterface' as const,
    CounterpartUser_publicUserInfoInterface: 'CounterpartUser_publicUserInfoInterface' as const,
    CounterpartUser_tokenOffer: 'CounterpartUser_tokenOffer' as const,
    MySorareDirectOfferBody_anyCard: 'MySorareDirectOfferBody_anyCard' as const,
    MySorareDirectOfferBody_tokenOffer: 'MySorareDirectOfferBody_tokenOffer' as const,
    MySorareDirectOfferBody_publicUserInfoInterface: 'MySorareDirectOfferBody_publicUserInfoInterface' as const,
    AcceptOfferDialog_tokenOffer: 'AcceptOfferDialog_tokenOffer' as const,
    MySorareDirectOfferStatus_tokenOffer: 'MySorareDirectOfferStatus_tokenOffer' as const,
    useCheckOfferFairness_tokenOffer: 'useCheckOfferFairness_tokenOffer' as const,
    OfferSummary_tokenOffer: 'OfferSummary_tokenOffer' as const,
    RejectionReason_tokenOffer: 'RejectionReason_tokenOffer' as const,
    RejectionReason_publicUserInfoInterface: 'RejectionReason_publicUserInfoInterface' as const,
    MySorareDirectOffer_tokenOffer: 'MySorareDirectOffer_tokenOffer' as const,
    FollowDescription_club: 'FollowDescription_club' as const,
    FollowDescription_country: 'FollowDescription_country' as const,
    FollowDescription_anyPlayer: 'FollowDescription_anyPlayer' as const,
    Follow_subscription: 'Follow_subscription' as const,
    MySorarePrimaryOffer_primaryOffer: 'MySorarePrimaryOffer_primaryOffer' as const,
    Subscriptions_subscription: 'Subscriptions_subscription' as const,
    AvailableForTrade_anyCard: 'AvailableForTrade_anyCard' as const,
    CancelSalePopin_anyCard: 'CancelSalePopin_anyCard' as const,
    CurrentOwner_anyCard: 'CurrentOwner_anyCard' as const,
    DelayedSettlementHelpers_anyCard: 'DelayedSettlementHelpers_anyCard' as const,
    LoanDetails_anyCard: 'LoanDetails_anyCard' as const,
    NewSaleDialog_anyCard: 'NewSaleDialog_anyCard' as const,
    OfferDealSummary_anyCard: 'OfferDealSummary_anyCard' as const,
    OfferDialog_anyCard: 'OfferDialog_anyCard' as const,
    OwnerAccount_account: 'OwnerAccount_account' as const,
    SellCard_anyCard: 'SellCard_anyCard' as const,
    SetupMinimumPriceDialog_anyCard: 'SetupMinimumPriceDialog_anyCard' as const,
    SingleSaleOffer_anyCard: 'SingleSaleOffer_anyCard' as const,
    AnyPlayerStatsIcon_anyPlayer: 'AnyPlayerStatsIcon_anyPlayer' as const,
    GradeHexagon_playerGameScoreProjection: 'GradeHexagon_playerGameScoreProjection' as const,
    InjuryTag_anyPlayer: 'InjuryTag_anyPlayer' as const,
    LastOwnedCards_anyPlayer: 'LastOwnedCards_anyPlayer' as const,
    LastOwnedCardsBySo5Competition_cards: 'LastOwnedCardsBySo5Competition_cards' as const,
    LastOwnedCardsBySo5Competition_so5Competition: 'LastOwnedCardsBySo5Competition_so5Competition' as const,
    OwnedCardRow_anyCard: 'OwnedCardRow_anyCard' as const,
    PlayerMarketDrawerAuctions_anyCard: 'PlayerMarketDrawerAuctions_anyCard' as const,
    useSortSecondaryOfferSlugs_anyCard: 'useSortSecondaryOfferSlugs_anyCard' as const,
    PlayerMarketDrawerManagerSales_anyCard: 'PlayerMarketDrawerManagerSales_anyCard' as const,
    useManagerSalesTag_anyCard: 'useManagerSalesTag_anyCard' as const,
    Transactions_anyPlayerInterface: 'Transactions_anyPlayerInterface' as const,
    LazyAllLastOwnedCardsQuery_anyCard: 'LazyAllLastOwnedCardsQuery_anyCard' as const,
    AnyCardPriceHistory_anyCard: 'AnyCardPriceHistory_anyCard' as const,
    PriceGraph_anyCard: 'PriceGraph_anyCard' as const,
    PriceGraph_user: 'PriceGraph_user' as const,
    PriceHistoryTooltip_anyCard: 'PriceHistoryTooltip_anyCard' as const,
    TransactionLabel_tokenDeal: 'TransactionLabel_tokenDeal' as const,
    BuyPrimaryBuyConfirmation_anyCard: 'BuyPrimaryBuyConfirmation_anyCard' as const,
    BuyPrimaryBuyConfirmation_anyReward: 'BuyPrimaryBuyConfirmation_anyReward' as const,
    OpenPrimaryBuy_anyCard: 'OpenPrimaryBuy_anyCard' as const,
    PrimaryOfferPrice_primaryOffer: 'PrimaryOfferPrice_primaryOffer' as const,
    MobileAppPrimaryOfferBuyFieldWrapper_anyCard: 'MobileAppPrimaryOfferBuyFieldWrapper_anyCard' as const,
    WebPrimaryOfferBuyFieldWrapper_anyCard: 'WebPrimaryOfferBuyFieldWrapper_anyCard' as const,
    PrimaryOfferBuyFieldWrapper_anyCard: 'PrimaryOfferBuyFieldWrapper_anyCard' as const,
    PrimaryBuyBuyField_anyCard: 'PrimaryBuyBuyField_anyCard' as const,
    PrimaryBuyDetails_anyCard: 'PrimaryBuyDetails_anyCard' as const,
    PrimaryBuyGrid_teamInterface: 'PrimaryBuyGrid_teamInterface' as const,
    PrimaryBuyPreview_anyCard: 'PrimaryBuyPreview_anyCard' as const,
    PrimaryOfferTokensPreview_anyCard: 'PrimaryOfferTokensPreview_anyCard' as const,
    PromotionalEventBlock_PromotionalEventInterface: 'PromotionalEventBlock_PromotionalEventInterface' as const,
    EndedSaleDetails_anyCard: 'EndedSaleDetails_anyCard' as const,
    EndedSaleDetails_offer: 'EndedSaleDetails_offer' as const,
    LiveSaleDetails_anyCard: 'LiveSaleDetails_anyCard' as const,
    LiveSaleDetails_offer: 'LiveSaleDetails_offer' as const,
    SalePriceFeesTooltip_offer: 'SalePriceFeesTooltip_offer' as const,
    SalePrice_offer: 'SalePrice_offer' as const,
    SaleWinner_offer: 'SaleWinner_offer' as const,
    SaleDetails_anyCard: 'SaleDetails_anyCard' as const,
    SaleDetails_offer: 'SaleDetails_offer' as const,
    Sale_offer: 'Sale_offer' as const,
    RarityVaultBanner_boostLevel: 'RarityVaultBanner_boostLevel' as const,
    RarityVaultBanner_sealingConfig: 'RarityVaultBanner_sealingConfig' as const,
    RarityVaultBanner_rewardBoost: 'RarityVaultBanner_rewardBoost' as const,
    RarityVaultHowItWorksDialog_sealingConfig: 'RarityVaultHowItWorksDialog_sealingConfig' as const,
    SealCardsDialog_anyCard: 'SealCardsDialog_anyCard' as const,
    SealCardsDialog_sealingConfig: 'SealCardsDialog_sealingConfig' as const,
    SealingRecap_anyCard: 'SealingRecap_anyCard' as const,
    UnsealCardDialog_anyCard: 'UnsealCardDialog_anyCard' as const,
    UnsealCardDialog_sealingConfig: 'UnsealCardDialog_sealingConfig' as const,
    UnsealingAnimation_anyCard: 'UnsealingAnimation_anyCard' as const,
    CardPreview_anyCard: 'CardPreview_anyCard' as const,
    CardDescription_anyCard: 'CardDescription_anyCard' as const,
    FlexCard_anyCard: 'FlexCard_anyCard' as const,
    Ownership_tokenOwner: 'Ownership_tokenOwner' as const,
    OwnershipHistory_anyCard: 'OwnershipHistory_anyCard' as const,
    PlayerDrawerTokenActions_anyCard: 'PlayerDrawerTokenActions_anyCard' as const,
    PlayerDrawerTokenDetails_anyCard: 'PlayerDrawerTokenDetails_anyCard' as const,
    MarketCreditCornerBadge_anyCard: 'MarketCreditCornerBadge_anyCard' as const,
    PlayerDrawerToken_anyCard: 'PlayerDrawerToken_anyCard' as const,
    FootballRivalsDraftToken_card: 'FootballRivalsDraftToken_card' as const,
    RivalsDraftToken_anyCard: 'RivalsDraftToken_anyCard' as const,
    StackedToken_anyCard: 'StackedToken_anyCard' as const,
    TokenBoostDetails_anyCard: 'TokenBoostDetails_anyCard' as const,
    TokenContent_anyCard: 'TokenContent_anyCard' as const,
    TokenOfferDetails_anyCard: 'TokenOfferDetails_anyCard' as const,
    TokenDetails_anyCard: 'TokenDetails_anyCard' as const,
    TokenImg_anyCard: 'TokenImg_anyCard' as const,
    TokenLoyaltyBoost_anyCard: 'TokenLoyaltyBoost_anyCard' as const,
    TokenProperties_anyCard: 'TokenProperties_anyCard' as const,
    TokenRoot_anyCard: 'TokenRoot_anyCard' as const,
    Token_anyCard: 'Token_anyCard' as const,
    TokenName_anyCard: 'TokenName_anyCard' as const,
    TokenNameCancelSalePopin_anyCard: 'TokenNameCancelSalePopin_anyCard' as const,
    TokenOwnerPrice_tokenOwner: 'TokenOwnerPrice_tokenOwner' as const,
    TokenOwnerTransfer_owner: 'TokenOwnerTransfer_owner' as const,
    BlockchainInfo_anyCard: 'BlockchainInfo_anyCard' as const,
    TokenId_anyCard: 'TokenId_anyCard' as const,
    TokensAvailableOnPrimaryWhenInsufficientFundsInWallet_anyCard: 'TokensAvailableOnPrimaryWhenInsufficientFundsInWallet_anyCard' as const,
    TokensRows_anyCard: 'TokensRows_anyCard' as const,
    TokenStatsIcon_anyCard: 'TokenStatsIcon_anyCard' as const,
    getSo5LineupUrl_so5Lineup: 'getSo5LineupUrl_so5Lineup' as const,
    UsedInLineupValidator_anyCard: 'UsedInLineupValidator_anyCard' as const,
    TokenTransferValidator_anyCard: 'TokenTransferValidator_anyCard' as const,
    TokenWithdrawal_anyCard: 'TokenWithdrawal_anyCard' as const,
    TokenWithdrawalDialog_anyCard: 'TokenWithdrawalDialog_anyCard' as const,
    TokenWithdrawalInfo_anyCard: 'TokenWithdrawalInfo_anyCard' as const,
    TokenWithdrawalInfo_transferRequest: 'TokenWithdrawalInfo_transferRequest' as const,
    SmallUser_user: 'SmallUser_user' as const,
    SmallUser_anonymousUser: 'SmallUser_anonymousUser' as const,
    EthereumCard_anyCard: 'EthereumCard_anyCard' as const,
    CurrentMarketplaceUser_currentUser: 'CurrentMarketplaceUser_currentUser' as const,
    SingleSaleOfferContext_anyCard: 'SingleSaleOfferContext_anyCard' as const,
    UseBestBidBelongsToUser_bestBid: 'UseBestBidBelongsToUser_bestBid' as const,
    useBidWithWallet_auction: 'useBidWithWallet_auction' as const,
    useStopAutoBid_bid: 'useStopAutoBid_bid' as const,
    useClickBidEvent_tokenAuction: 'useClickBidEvent_tokenAuction' as const,
    useClickBundleEvent_anyCard: 'useClickBundleEvent_anyCard' as const,
    useClickBuyEvent_anyCard: 'useClickBuyEvent_anyCard' as const,
    useClickCardEvent_anyCard: 'useClickCardEvent_anyCard' as const,
    useAcceptOffer_anyCard: 'useAcceptOffer_anyCard' as const,
    useApproveMigrator_anyCard: 'useApproveMigrator_anyCard' as const,
    useCannotBuy_anyCard: 'useCannotBuy_anyCard' as const,
    useCannotSell_anyCard: 'useCannotSell_anyCard' as const,
    useCannotSettleInFiat_anyCard: 'useCannotSettleInFiat_anyCard' as const,
    useCreateDirectOffer_anyCard: 'useCreateDirectOffer_anyCard' as const,
    useCreateSingleSaleOffer_anyCard: 'useCreateSingleSaleOffer_anyCard' as const,
    useGetAuctionDetails_auction: 'useGetAuctionDetails_auction' as const,
    useGetTokenSingleSaleDetails_anyCard: 'useGetTokenSingleSaleDetails_anyCard' as const,
    useMigrateCards_anyCard: 'useMigrateCards_anyCard' as const,
    useTokenTakesPartPromotionalEvent_anyCard: 'useTokenTakesPartPromotionalEvent_anyCard' as const,
    useIsPrimaryBuyLive_anyCard: 'useIsPrimaryBuyLive_anyCard' as const,
    useCardsSearchClient_searchCardFacetValues: 'useCardsSearchClient_searchCardFacetValues' as const,
    useCardsSearchClient_searchCards: 'useCardsSearchClient_searchCards' as const,
    usePrintablePlayersSearchClient_searchPrintablePlayers: 'usePrintablePlayersSearchClient_searchPrintablePlayers' as const,
    useOwnerAccount_account: 'useOwnerAccount_account' as const,
    useCart_anyCard: 'useCart_anyCard' as const,
    useCart_cartItem: 'useCart_cartItem' as const,
    useCart_cart: 'useCart_cart' as const,
    useGetAuthorizationApprovals_authorizationRequest: 'useGetAuthorizationApprovals_authorizationRequest' as const,
    useGetPriceHistory_anyCard: 'useGetPriceHistory_anyCard' as const,
    useIsBoostAffordable_anyCard: 'useIsBoostAffordable_anyCard' as const,
    useIsCardDroppable_anyCard: 'useIsCardDroppable_anyCard' as const,
    useMarketFeesHelperStatus_anyCard: 'useMarketFeesHelperStatus_anyCard' as const,
    usePollPrimaryOfferBuyer_primaryOffer: 'usePollPrimaryOfferBuyer_primaryOffer' as const,
    auctionCurrentPrice_auction: 'auctionCurrentPrice_auction' as const,
    BidderFragment: 'BidderFragment' as const,
    CardFragment: 'CardFragment' as const,
    TokenAuctionUpdateFragment: 'TokenAuctionUpdateFragment' as const,
    OfferSideFragment: 'OfferSideFragment' as const,
    TokenOfferUpdateFragment: 'TokenOfferUpdateFragment' as const,
    PrimaryOfferUpdateFragment: 'PrimaryOfferUpdateFragment' as const,
    CardPreviewGrid_card: 'CardPreviewGrid_card' as const,
    CardResultsFromGraphQL_card: 'CardResultsFromGraphQL_card' as const,
    DeckEditorDialog_deck: 'DeckEditorDialog_deck' as const,
    DeckEditorDialog_anyCard: 'DeckEditorDialog_anyCard' as const,
    NBABasicBenchCard_composeTeamBenchObjectInterface: 'NBABasicBenchCard_composeTeamBenchObjectInterface' as const,
    NBABasicFilters_so5Leaderboard: 'NBABasicFilters_so5Leaderboard' as const,
    NBABasicMarketCard_anyCardInterface: 'NBABasicMarketCard_anyCardInterface' as const,
    NBACommonMarketBench_benchObject: 'NBACommonMarketBench_benchObject' as const,
    NBACompose_composeTeamBenchObjectInterface: 'NBACompose_composeTeamBenchObjectInterface' as const,
    NBACompose_anyPlayerInterface: 'NBACompose_anyPlayerInterface' as const,
    NBACompose_so5Leaderboard: 'NBACompose_so5Leaderboard' as const,
    NBACompose_so5Lineup: 'NBACompose_so5Lineup' as const,
    NBACompose_managerTeam: 'NBACompose_managerTeam' as const,
    NBAFieldCard_composeTeamBenchObjectInterface: 'NBAFieldCard_composeTeamBenchObjectInterface' as const,
    NBAFieldCard_appearanceRule: 'NBAFieldCard_appearanceRule' as const,
    DailyHoopsBanner_so5Fixture: 'DailyHoopsBanner_so5Fixture' as const,
    TodaysCompetition_Event: 'TodaysCompetition_Event' as const,
    GameHeader_anyGame: 'GameHeader_anyGame' as const,
    GameTime_anyGame: 'GameTime_anyGame' as const,
    LiveCardsInLineup_so5Fixture: 'LiveCardsInLineup_so5Fixture' as const,
    LiveCardsInLineup_basketballPlayerGameScore: 'LiveCardsInLineup_basketballPlayerGameScore' as const,
    LiveCardsInLineup_anyCard: 'LiveCardsInLineup_anyCard' as const,
    LiveCardsInLineup_so5Appearance: 'LiveCardsInLineup_so5Appearance' as const,
    TeamPlayersStats_basketballPlayerGameScore: 'TeamPlayersStats_basketballPlayerGameScore' as const,
    TeamPlayersStats_team: 'TeamPlayersStats_team' as const,
    TeamPlayersStats_anyCard: 'TeamPlayersStats_anyCard' as const,
    TeamPlayersStats_so5Appearance: 'TeamPlayersStats_so5Appearance' as const,
    TopFieldPlayers_basketballPlayerGameScore: 'TopFieldPlayers_basketballPlayerGameScore' as const,
    TopFieldPlayers_anyCard: 'TopFieldPlayers_anyCard' as const,
    TopFieldPlayers_so5Appearance: 'TopFieldPlayers_so5Appearance' as const,
    LiveGameView_basketballPlayerGameScore: 'LiveGameView_basketballPlayerGameScore' as const,
    LiveGameView_anyGame: 'LiveGameView_anyGame' as const,
    LiveGameView_so5Fixture: 'LiveGameView_so5Fixture' as const,
    LiveGameView_anyCard: 'LiveGameView_anyCard' as const,
    FeaturedPlayersOutperformers_basketballPlayerGameScore: 'FeaturedPlayersOutperformers_basketballPlayerGameScore' as const,
    FeaturedPlayersOutperformers_team: 'FeaturedPlayersOutperformers_team' as const,
    PastGameView_basketballPlayerGameScore: 'PastGameView_basketballPlayerGameScore' as const,
    PastGameView_anyGame: 'PastGameView_anyGame' as const,
    PastGameView_so5Fixture: 'PastGameView_so5Fixture' as const,
    PastGameView_anyCard: 'PastGameView_anyCard' as const,
    PlayerInFixtureLinkBox_anyCard: 'PlayerInFixtureLinkBox_anyCard' as const,
    PlayerInLineupLinkBox_so5Fixture: 'PlayerInLineupLinkBox_so5Fixture' as const,
    PlayerInLineupLinkBox_so5Appearance: 'PlayerInLineupLinkBox_so5Appearance' as const,
    TeamPlayers_anyPlayer: 'TeamPlayers_anyPlayer' as const,
    TeamPlayers_team: 'TeamPlayers_team' as const,
    TeamPlayers_so5Fixture: 'TeamPlayers_so5Fixture' as const,
    TeamPlayers_so5Appearance: 'TeamPlayers_so5Appearance' as const,
    TeamPlayers_anyCard: 'TeamPlayers_anyCard' as const,
    UpcomingGameView_anyGame: 'UpcomingGameView_anyGame' as const,
    UpcomingGameView_anyPlayer: 'UpcomingGameView_anyPlayer' as const,
    UpcomingGameView_so5Fixture: 'UpcomingGameView_so5Fixture' as const,
    UpcomingGameView_anyCard: 'UpcomingGameView_anyCard' as const,
    GameView_basketballPlayerGameScore: 'GameView_basketballPlayerGameScore' as const,
    GameView_anyGame: 'GameView_anyGame' as const,
    GameView_anyPlayer: 'GameView_anyPlayer' as const,
    GameView_so5Fixture: 'GameView_so5Fixture' as const,
    GameView_anyCard: 'GameView_anyCard' as const,
    NBAHomeDailyBlock_so5Root: 'NBAHomeDailyBlock_so5Root' as const,
    NBAHomeProBlock_so5Root: 'NBAHomeProBlock_so5Root' as const,
    NbaLineupLayout_so5LeaderboardContender: 'NbaLineupLayout_so5LeaderboardContender' as const,
    PlayerCardScore_so5Fixture: 'PlayerCardScore_so5Fixture' as const,
    PlayerCardScore_so5Appearance: 'PlayerCardScore_so5Appearance' as const,
    LivePlayerCardScore_anyPlayer: 'LivePlayerCardScore_anyPlayer' as const,
    LivePlayerCardScore_anyGame: 'LivePlayerCardScore_anyGame' as const,
    UpcomingPlayerCardScore_anyPlayer: 'UpcomingPlayerCardScore_anyPlayer' as const,
    UpcomingPlayerCardScore_anyGame: 'UpcomingPlayerCardScore_anyGame' as const,
    NBAPlayPage_TokenRoot: 'NBAPlayPage_TokenRoot' as const,
    NBAPlayPage_so5Root: 'NBAPlayPage_so5Root' as const,
    NBAPlayPage_currentUser: 'NBAPlayPage_currentUser' as const,
    NBAPlayPage_so5Config: 'NBAPlayPage_so5Config' as const,
    NBAProBlock_so5Root: 'NBAProBlock_so5Root' as const,
    NBAPlayerSearch_anyPlayerInterface: 'NBAPlayerSearch_anyPlayerInterface' as const,
    FirstWeekClaim_nbaManagerTask: 'FirstWeekClaim_nbaManagerTask' as const,
    NBATeamSearch_team: 'NBATeamSearch_team' as const,
    NBARootPlayPageQuery_so5Fixture: 'NBARootPlayPageQuery_so5Fixture' as const,
    FootballClubs_club: 'FootballClubs_club' as const,
    PartnersList_club: 'PartnersList_club' as const
  }
}