import { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Shine } from 'atoms/ui/Shine';
import { CARD_BORDER_RADIUS } from 'lib/cards';

const CardShine = styled(Shine)`
  aspect-ratio: var(--card-aspect-ratio);
`;

type Props = {
  path: string;
  'aria-label': string;
  shine?: boolean;
  radius?: string;
} & HTMLAttributes<HTMLElement>;

const Image = styled.img`
  aspect-ratio: var(--card-aspect-ratio);
  width: 235px;
  max-width: 100%;
`;

export const DumbCardBack = ({
  path,
  'aria-label': ariaLabel,
  shine,
  radius = CARD_BORDER_RADIUS,
  ...divAttributes
}: Props) => {
  return (
    <CardShine
      disabled={!shine}
      borderRadius={radius}
      aria-label={ariaLabel}
      {...divAttributes}
    >
      <Image src={path} />
    </CardShine>
  );
};
