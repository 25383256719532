import { TypedDocumentNode, gql } from '@apollo/client';

import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import { BuyConfirmationDialog } from 'components/buyActions/BuyConfirmationDialog';
import { PrimaryBuyConfirmationOptions } from 'contexts/buyingConfirmation';

import { BuyPrimaryBuyConfirmation } from '../BuyPrimaryBuyConfirmation';
import {
  BuyPrimaryBuyConfirmationQueryById,
  BuyPrimaryBuyConfirmationQueryByIdVariables,
} from './__generated__/index.graphql';

const BUY_PRIMARY_BUY_CONFIRMATION_QUERY = gql`
  query BuyPrimaryBuyConfirmationQueryById($id: String!) {
    tokens {
      primaryOffer(id: $id) {
        id
        anyCards {
          slug
          ...BuyPrimaryBuyConfirmation_anyCard
        }
        rewards {
          id
          ...BuyPrimaryBuyConfirmation_anyReward
        }
      }
    }
  }
  ${BuyPrimaryBuyConfirmation.fragments.anyCard}
  ${BuyPrimaryBuyConfirmation.fragments.anyReward}
` as TypedDocumentNode<
  BuyPrimaryBuyConfirmationQueryById,
  BuyPrimaryBuyConfirmationQueryByIdVariables
>;

type Props = {
  primaryBuyId: string;
  options?: PrimaryBuyConfirmationOptions;
  onClose: () => void;
  open: boolean;
};

export const BuyPrimaryBuyConfirmationDialogById = ({
  primaryBuyId,
  options,
  onClose,
  open,
}: Props) => {
  const { data, loading } = useQuery(BUY_PRIMARY_BUY_CONFIRMATION_QUERY, {
    variables: {
      id: idFromObject(primaryBuyId),
    },
    skip: !primaryBuyId && !open,
  });

  if (!data || loading) return null;

  const {
    tokens: { primaryOffer },
  } = data;

  if (!primaryOffer) return null;

  const cards = primaryOffer.anyCards;
  const { rewards } = primaryOffer;

  if (!cards.length) return null;

  return (
    <BuyConfirmationDialog open={open} onClose={onClose}>
      <BuyPrimaryBuyConfirmation
        cards={cards}
        rewards={rewards}
        options={options}
        onClose={onClose}
      />
    </BuyConfirmationDialog>
  );
};
