import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyM } from '@sorare/core/src/atoms/typography';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';
import { cart as cartMessages } from '@sorare/core/src/lib/glossary';
import { MonetaryAmountParams } from '@sorare/core/src/lib/monetaryAmount';

import { AmountSaved } from 'components/marketCredit/AmountSaved';
import { DiscountedPrice } from 'components/marketCredit/DiscountedPrice';

const Total = styled(Horizontal)`
  justify-content: space-between;
  align-items: flex-end;
`;
const TotalVertical = styled(Vertical).attrs({ gap: 0.5 })`
  align-items: flex-end;
`;

const Price = styled(Horizontal)`
  align-items: baseline;
`;

const GreenChip = styled(Chip)`
  background: var(--c-score-high);
  color: var(--c-black);
`;

type Props = {
  total?: MonetaryAmountParams;
  sport: Sport;
};

export const CartTotalSummary = ({ total, sport }: Props) => {
  if (!total) return null;
  return (
    <Total>
      <BodyM bold>
        <FormattedMessage {...cartMessages.total} />
      </BodyM>
      <TotalVertical>
        <AmountSaved Wrapper={GreenChip} price={total} sport={sport} />
        <Price gap={0.5}>
          <DiscountedPrice price={total} sport={sport} canDiscount />
        </Price>
      </TotalVertical>
    </Total>
  );
};
