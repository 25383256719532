import { SVGProps } from 'react';

import { unitMapping } from 'lib/style';

type Props = {
  className?: string;
  size?: keyof typeof unitMapping;
  inverted?: boolean;
} & Pick<SVGProps<SVGPathElement>, 'fill'>;

export const HexagonShape = ({
  className,
  fill: fillProp,
  size = 4,
  inverted,
}: Props) => {
  const fill = fillProp || `var(--c-brand-600)`;

  return (
    <svg
      className={className}
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6417 1.1892L26.8682 6.37754C28.501 7.29572 29.5 8.98777 29.5 10.8117V21.1883C29.5 23.0122 28.501 24.7043 26.8682 25.6225L17.6417 30.8108C16.0075 31.7297 13.9925 31.7297 12.3583 30.8108L3.13183 25.6225C1.49903 24.7043 0.5 23.0122 0.5 21.1883V10.8117C0.5 8.98777 1.49903 7.29572 3.13183 6.37754L12.3583 1.1892C13.9925 0.270267 16.0075 0.270267 17.6417 1.1892Z"
        fill={inverted ? 'var(--c-black)' : fill}
        stroke={fill}
      />
    </svg>
  );
};
