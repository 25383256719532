import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

export const Grid = styled.div`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  padding: var(--double-unit);
  gap: var(--double-unit);
  @media ${laptopAndAbove} {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 400px;
    padding: 0;
    gap: 0;
  }
`;

export const NewGrid = styled(Grid)`
  @media ${laptopAndAbove} {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--double-unit);
  overflow: hidden;
  padding: var(--double-unit);
  @media ${laptopAndAbove} {
    padding: var(--triple-unit);
    gap: var(--triple-unit);
  }
`;

export const FlexGrowBlock = styled(Vertical)`
  flex: 1;
  & > * {
    flex: 1;
  }
`;
