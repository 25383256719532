import { ReactNode } from 'react';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Dialog } from '@sorare/core/src/components/dialog';
import { useScreenSize } from '@sorare/core/src/hooks/device/useScreenSize';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

const Body = styled(Horizontal).attrs({ center: false })`
  position: relative;
  isolation: isolate;
  min-height: 100%;
  @media ${tabletAndAbove} {
    height: auto;
  }
`;

const Content = styled(Vertical)`
  height: 100%;
  width: 100%;
  padding: var(--quadruple-unit) 0;
`;

type Props = {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
};

export const BuyConfirmationDialog = ({ children, open, onClose }: Props) => {
  const { up: upTablet } = useScreenSize('tablet');
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      hideHeader
      fullScreen={!upTablet}
    >
      <Body>
        <Content>{children}</Content>
      </Body>
    </Dialog>
  );
};
