import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';

import { StepLayout } from 'components/rivals/squads/MySquadOnboarding/steps/StepLayout';
import { BrandedInfo } from 'components/stories/rivals/leaderboards/BrandedInfo';

import box from './assets/box.png';
import story from './assets/story.png';

const Background = styled.div`
  width: 100%;
  background:
    linear-gradient(
      to bottom,
      rgba(var(--c-rgb-black), 0.4) 10%,
      transparent,
      rgba(var(--c-rgb-black), 0.4) 90%
    ),
    no-repeat center / cover url(${story});
`;
const FullWidthBrandedInfo = styled(BrandedInfo)`
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translateX(-45%);
  width: 90%;
`;

export const Rewards = () => {
  const { formatMessage } = useIntl();
  return (
    <Background>
      <StepLayout
        transparentBg
        title={formatMessage({
          id: 'MySquadOnboarding.steps.Rewards.title',
          defaultMessage: 'Get Rewarded',
        })}
        content={
          <FullWidthBrandedInfo fillColor="#2D003D" fillOpacity={1}>
            <Horizontal gap={2}>
              <FormattedMessage
                id="MySquadOnboarding.steps.Rewards.content"
                defaultMessage="You won a reward box!"
              />
              <img width={24} src={box} alt="" />
            </Horizontal>
          </FullWidthBrandedInfo>
        }
        footer={formatMessage({
          id: 'MySquadOnboarding.steps.Rewards.footer',
          defaultMessage: 'Better performing squad members earn better rewards',
        })}
      />
    </Background>
  );
};
