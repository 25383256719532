import { faEthereum } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { Dots } from '@sorare/core/src/atoms/ui/Dots';

import { useEthBalance } from './useEthBalance';

const fallback = '0 ETH';

type Props = { collapsed?: boolean };
export const EthBalance = ({ collapsed }: Props) => {
  const { hideBalance, balanceInEth } = useEthBalance();

  if (collapsed) {
    return (
      <Horizontal center>
        <FontAwesomeIcon icon={faEthereum} />
      </Horizontal>
    );
  }

  return hideBalance ? (
    <Horizontal center>
      <FontAwesomeIcon icon={faEthereum} />
      <Horizontal gap={0.5}>
        <Dots count={3} size="small" />
      </Horizontal>
    </Horizontal>
  ) : (
    <LabelM bold>{balanceInEth || fallback}</LabelM>
  );
};
