import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { useWalletDrawerContext } from '@sorare/core/src/contexts/walletDrawer';

import { EthBalance } from './EthBalance';
import { FiatBalance } from './FiatBalance';

const Root = styled(Horizontal).attrs({ gap: 0 })`
  border: 1px solid var(--c-nd-150);
  border-radius: var(--unit);

  & > * {
    flex: 1;
    padding: var(--unit);
    text-align: center;
  }
  .sidebarCollapsed & {
    flex-direction: column;

    & > * {
      width: 100%;
      padding: var(--unit) var(--half-unit);
    }

    & > :first-child {
      border-bottom: 1px solid var(--c-nd-150);
      border-right: none;
    }
  }

  & > :first-child {
    border-right: 1px solid var(--c-nd-150);
  }
  &:hover {
    background-color: var(--c-nd-50);
  }

  transition:
    300ms background-color ease-in-out,
    300ms color ease-in-out;
`;

type Props = { sidebarCollapsed?: boolean };
export const MoneyBalances = ({ sidebarCollapsed }: Props) => {
  const { toggleDrawer } = useWalletDrawerContext();

  return (
    <Root as="button" onClick={toggleDrawer}>
      <FiatBalance collapsed={sidebarCollapsed} />
      <EthBalance collapsed={sidebarCollapsed} />
    </Root>
  );
};
