import { TypedDocumentNode, gql } from '@apollo/client';

import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import { BuyTokenConfirmation } from 'components/buyActions/BuyTokenConfirmation';

import { BuyConfirmationDialog } from '../BuyConfirmationDialog';
import {
  BuyTokenConfirmationQueryById,
  BuyTokenConfirmationQueryByIdVariables,
} from './__generated__/index.graphql';

const BUY_TOKEN_CONFIRMATION_QUERY = gql`
  query BuyTokenConfirmationQueryById($id: String!) {
    tokens {
      offer(id: $id) {
        id
        senderSide {
          id
          anyCards {
            slug
            ...BuyTokenConfirmation_anyCard
          }
        }
      }
    }
  }
  ${BuyTokenConfirmation.fragments.anyCard}
` as TypedDocumentNode<
  BuyTokenConfirmationQueryById,
  BuyTokenConfirmationQueryByIdVariables
>;

type Props = {
  tokenOfferId: string;
  onClose: () => void;
  open: boolean;
};

export const BuyTokenConfirmationDialogById = ({
  tokenOfferId,
  onClose,
  open,
}: Props) => {
  const { data, loading } = useQuery(BUY_TOKEN_CONFIRMATION_QUERY, {
    variables: {
      id: idFromObject(tokenOfferId)!,
    },
    fetchPolicy: 'cache-first',
    skip: !tokenOfferId && !open,
  });

  if (loading || !data) return null;

  const {
    tokens: { offer },
  } = data;

  if (!offer) return null;

  const { senderSide } = offer;
  const card = senderSide.anyCards[0];

  return (
    <BuyConfirmationDialog open={open && !loading} onClose={onClose}>
      <BuyTokenConfirmation card={card} onClose={onClose} />
    </BuyConfirmationDialog>
  );
};
