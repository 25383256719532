import classNames from 'classnames';
import styled from 'styled-components';

import paypalLogo from 'assets/paypal.svg';
import { Horizontal } from 'atoms/layout/flex';
import { BodyM } from 'atoms/typography';

const PayPalLogoContainer = styled.div`
  display: flex;
  height: 30px;
  background-color: var(--c-nd-900);
  padding: var(--half-unit) var(--half-unit);
  border-radius: var(--half-unit);
`;

const Root = styled(Horizontal)`
  &.column {
    flex-direction: column;
    gap: var(--half-unit);
  }
`;

export const Paypal = ({ column }: { column?: boolean }) => {
  return (
    <Root className={classNames({ column })}>
      <PayPalLogoContainer>
        <img src={paypalLogo} alt="PayPal" width="38px" />
      </PayPalLogoContainer>
      <BodyM color="var(--c-white)">PayPal</BodyM>
    </Root>
  );
};
