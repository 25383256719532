import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import TurndownService from 'turndown';

import { ErrorBoundary } from '@sorare/error-boundary';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Markdown } from '@sorare/core/src/atoms/markdown';
import {
  BodyM,
  HeadlineL,
  HeadlineM,
  HeadlineS,
  HeadlineXL,
  LabelL,
  LabelM,
  LabelS,
} from '@sorare/core/src/atoms/typography';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { stripPrefix } from '@sorare/core/src/i18n/useTranslations';
import { getFormattedSport } from '@sorare/core/src/lib/sports';
import { toRelative } from '@sorare/core/src/lib/urls';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { Announcement_announcement } from './__generated__/index.graphql';

type Props = {
  announcement: Announcement_announcement;
  inDialog: boolean;
};

const turndownService = new TurndownService();
turndownService.keep(['button']);

const Img = styled.img`
  max-width: 100%;
  max-height: 30vh;
  display: block;
  margin: auto;
  &.inDialog {
    max-height: none;
    height: 222px;
    object-fit: contain;
  }
`;
const Content = styled(Vertical).attrs({ gap: 2 })`
  a:not([role='button']) {
    color: var(--c-link);
    text-decoration: underline;
  }
`;
const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  gap: var(--unit);
  * {
    width: 100%;
  }
  @media ${tabletAndAbove} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const Announcement = ({ announcement, inDialog }: Props) => {
  const { formatDistanceToNow } = useIntlContext();

  const { title, content, createdAt, pictureUrl, sport } = announcement;
  const markdown = /\[markdown\]/.test(content)
    ? content
        .replace(
          /(<div(?:\s+class="trix-content")?>|<\/div>|<br>|\[markdown\])/g,
          '\n'
        )
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    : turndownService.turndown(content);

  return (
    <Vertical gap={2} id={idFromObject(announcement.id)}>
      {!!pictureUrl && (
        <Img className={classNames({ inDialog })} src={pictureUrl} alt="" />
      )}
      <div>
        <HeadlineL brand25>{title}</HeadlineL>
        <LabelS color="var(--c-nd-600)">
          {sport ? (
            getFormattedSport(sport)
          ) : (
            <FormattedMessage
              id="Notification.global"
              defaultMessage="Global"
            />
          )}
          {' • '}
          {formatDistanceToNow(createdAt)}
        </LabelS>
      </div>
      <ErrorBoundary fallback={null}>
        <Content>
          <Markdown
            html
            gfm
            components={{
              a(props) {
                const { node, href, children, ...rest } = props;
                return (
                  <a
                    href={href ? stripPrefix(toRelative(href)) : undefined}
                    {...rest}
                  >
                    {children}
                  </a>
                );
              },
              p(props: object) {
                return <BodyM {...props} />;
              },
              h1(props: object) {
                return <HeadlineXL {...props} />;
              },
              h2(props: object) {
                return <HeadlineL {...props} />;
              },
              h3(props: object) {
                return <HeadlineM {...props} />;
              },
              h4(props: object) {
                return <HeadlineS {...props} />;
              },
              h5(props: object) {
                return <LabelL {...props} />;
              },
              h6(props: object) {
                return <LabelM {...props} />;
              },
              footer(props: object) {
                return <Footer {...props} />;
              },
              button(props: unknown) {
                const {
                  node,
                  size = 'medium',
                  fullwidth = false,
                  to,
                  ...rest
                } = props as {
                  size?: 'small' | 'medium';
                  fullwidth?: boolean;
                  to?: string;
                  node: unknown;
                };
                return (
                  <Horizontal>
                    <Button
                      size={size}
                      fullWidth={fullwidth !== false}
                      to={to ? stripPrefix(toRelative(to)) : undefined}
                      {...rest}
                    />
                  </Horizontal>
                );
              },
            }}
          >
            {markdown}
          </Markdown>
        </Content>
      </ErrorBoundary>
    </Vertical>
  );
};

Announcement.fragments = {
  announcement: gql`
    fragment Announcement_announcement on Announcement {
      id
      pictureUrl
      title
      content
      createdAt
      sport
    }
  ` as TypedDocumentNode<Announcement_announcement>,
};
