import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { CloseButton } from 'atoms/buttons/CloseButton';
import { Vertical } from 'atoms/layout/flex';
import { Backdrop } from 'atoms/loader/Backdrop';
import { theme } from 'style/theme';

const popupCenter = ({ url, title }: { url: string; title: string }) => {
  const w = 440;
  const h = 640;
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth || document.documentElement.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom},
    height=${h / systemZoom},
    top=${top},
    left=${left}
    `
  );

  return newWindow;
};

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: var(--double-unit);
  left: var(--double-unit);
`;

const StyledBackdrop = styled(Backdrop)`
  text-align: center;
  z-index: ${theme.zIndex.backdrop + 1};
  backdrop-filter: blur(4px);
`;

const messages = defineMessages({
  reopen: {
    id: 'AddFundsToFiatWallet.Summary.reopen',
    defaultMessage: 'Re-open',
  },
});

const PopupBackground = ({
  children,
  visible,
  onClick,
  onClose,
}: {
  children?: ReactNode;
  visible: boolean;
  onClick: () => void;
  onClose: () => void;
}) => {
  if (!visible) return null;
  return (
    <StyledBackdrop onClick={onClick}>
      <Vertical gap={2}>
        {children}
        <Button size="small" color="primary">
          <FormattedMessage {...messages.reopen} />
        </Button>
      </Vertical>
      <StyledCloseButton
        onClose={e => {
          e.preventDefault();
          onClose();
        }}
      />
    </StyledBackdrop>
  );
};

export const useChildWindow = () => {
  const popupRef = useRef<Window | null>(null);
  const [intervalId, setIntervalId] = useState<number>();
  const [windowOpen, setWindowOpen] = useState(false);

  const onFocus = useCallback(() => {
    popupRef.current?.focus();
  }, []);

  const close = () => {
    popupRef.current?.close();
    setWindowOpen(false);
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('focus', onFocus);
      if (intervalId) clearInterval(intervalId);
    };
  }, [onFocus, intervalId]);

  const openPopup = (url: string, title: string) => {
    const popup = popupCenter({
      url,
      title,
    });
    if (!popup) return undefined;
    setWindowOpen(true);
    popupRef.current = popup;
    const interval = window.setInterval(() => {
      if (popup == null || popup.closed) {
        window.clearInterval(interval);
        setWindowOpen(false);
        popupRef.current = null;
      }
    }, 500);
    setIntervalId(interval);
    window.addEventListener('focus', onFocus, false);
    return popup;
  };

  return {
    openPopup,
    close,
    background: ({ children }: { children: ReactNode }) => (
      <PopupBackground visible={windowOpen} onClose={close} onClick={onFocus}>
        {children}
      </PopupBackground>
    ),
  };
};
