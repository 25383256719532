import { TypedDocumentNode, gql } from '@apollo/client';
import { ApplePayButtonType } from '@stripe/stripe-js';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { useMutation } from 'hooks/graphql/useMutation';
import { MonetaryAmountOutput } from 'hooks/useMonetaryAmount';
import { useFiatBalance } from 'hooks/wallets/useFiatBalance';

import {
  ValidateApplePayMerchantSession,
  ValidateApplePayMerchantSessionVariables,
} from './__generated__/index.graphql';

const VALIDATE_APPLE_PAY_MERCHANT_SESSION_MUTATION = gql`
  mutation ValidateApplePayMerchantSession(
    $input: validateApplePayMerchantSessionInput!
  ) {
    validateApplePayMerchantSession(input: $input) {
      applePaySessionData
      errors {
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  ValidateApplePayMerchantSession,
  ValidateApplePayMerchantSessionVariables
>;

// https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons_using_css
const Button = styled.div<{ label?: ApplePayButtonType }>`
  @supports (-webkit-appearance: -apple-pay-button) {
    display: inline-block;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: -apple-pay-button;
    ${({ label }) => css`
      -apple-pay-button-type: ${label || 'buy'};
    `}
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    > * {
      display: none;
    }
    -apple-pay-button-style: black;
  }

  @supports not (-webkit-appearance: -apple-pay-button) {
    --apple-pay-scale: 1; /* (height / 32) */
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 40px;
    border-radius: 20px;
    max-height: 64px;
    background-color: black;
    color: white;
    .apple-pay-button-white-with-line-with-text {
      background-color: white;
      color: black;
      border: 0.5px solid black;
    }
    > .logo {
      /* stylelint-disable-next-line function-no-unknown, declaration-property-value-no-unknown */
      background-image: -webkit-named-image(apple-pay-logo-white);
      background-color: black;
      width: calc(35px * var(--scale));
      height: 100%;
      background-size: 100% 60%;
      background-repeat: no-repeat;
      background-position: 0 50%;
      margin-left: calc(2px * var(--apple-pay-scale));
      border: none;
    }
    > .text {
      font-family: -apple-system;
      font-size: calc(1em * var(--apple-pay-scale));
      font-weight: 300;
      align-self: center;
      margin-right: calc(2px * var(--apple-pay-scale));
    }
  }
`;

export type Props = {
  monetaryAmount: MonetaryAmountOutput;
  handleToken: (
    token: ApplePayJS.ApplePayPaymentToken
  ) => Promise<{ errors?: string[] }>;
  onSuccess?: () => void;
  label?: ApplePayButtonType;
};

export const ApplePayButton = ({
  monetaryAmount,
  handleToken,
  onSuccess,
  label,
}: Props) => {
  const { locale } = useIntl();
  const { fiatCurrencyForMonetaryAmount } = useFiatBalance();
  const [validate] = useMutation(VALIDATE_APPLE_PAY_MERCHANT_SESSION_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  const [enabled, setEnabled] = useState(true);

  const onClick = () => {
    if (!enabled) return;
    setEnabled(false);
    const session = new ApplePaySession(3, {
      countryCode: 'FR',
      currencyCode: fiatCurrencyForMonetaryAmount.toUpperCase(),
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['masterCard', 'visa'],
      total: {
        label: 'Total',
        amount: (
          monetaryAmount[fiatCurrencyForMonetaryAmount] / 100
        ).toString(),
      },
    });
    session.onvalidatemerchant = async event => {
      const results = await validate({
        variables: {
          input: {
            merchantValidationUrl: event.validationURL,
          },
        },
      });
      if (results.data?.validateApplePayMerchantSession?.applePaySessionData) {
        session.completeMerchantValidation(
          JSON.parse(
            results.data.validateApplePayMerchantSession.applePaySessionData
          )
        );
      }
    };
    session.onpaymentauthorized = async event => {
      const result = await handleToken(event.payment.token);
      if (!result.errors?.length) {
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
        onSuccess?.();
        return;
      }
      session.completePayment(ApplePaySession.STATUS_FAILURE);
    };
    session.oncancel = () => {
      setEnabled(true);
    };
    session.begin();
  };
  return (
    <Button label={label} lang={locale} onClick={onClick}>
      <span>
        <FormattedMessage id="ApplePayButton.label" defaultMessage="Buy" />
      </span>
      <span className="logo" />
    </Button>
  );
};
