// Please, do not create analytics events here
// Use the protobuf event repository instead
// packages/core/src/protos/events/so5/web/events.ts
// packages/core/src/protos/events/so5/shared/events.ts
// We will slowly migrate all self-defined events to the protobuf repository

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { generateUseEvents } from '@sorare/core/src/lib/events/generateUseEvents';

import { TokenTransferValidatorProps } from 'components/token/TokenTransferValidator/types';

type MarketplaceEventTypes = {
  'Click List Card': {
    cardSlug: string;
    hasWarnings: boolean;
  };
  'Starter Bundles Payment successful': void;
  'Starter Bundles Dialog Open': {
    sport: Sport;
  };
  'Starter Bundles Click Use Your Cards': {
    sport: Sport;
  };
  'Toggle Transfer Consent Message': {
    value: boolean;
    transferContext: TokenTransferValidatorProps['transferContext'];
  };
  'View Introduction Tutorial Video': void;
  'View Marketplace Tutorial Video': void;
  'View Competitions Tutorial Video': void;
  'View Score Tutorial Video': void;
  'View Pro Tutorial Video': void;
  'Close Marketplace Tutorial Video': void;
  'Open Video Tutorial On Marketplace': void;
};

export const useMarketplaceEvents = generateUseEvents<MarketplaceEventTypes>();

export const CELEBRATION_2024_BLOG_POST_URL =
  'https://sorare.com/football/blog/celebration-unlock-the-magic-of-footballs-defining-moments';
