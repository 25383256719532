import { Navigate, generatePath } from 'react-router-dom';

import { So5FixtureEvent } from '@sorare/core/src/__generated__/globalTypes';
import { NBA_PLAY_LINEUPS_EVENTTYPE_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';

const LineupsPage = () => {
  const defaultFixture = 'ongoing';

  return (
    <Navigate
      to={generatePath(NBA_PLAY_LINEUPS_EVENTTYPE_FIXTURE, {
        eventType: So5FixtureEvent.DAILY.toLowerCase(),
        fixture: defaultFixture,
      })}
      replace
    />
  );
};

export default LineupsPage;
