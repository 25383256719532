import { useMangopayCreditCardsEnabled } from 'hooks/useMangopayCreditCardsEnabled';

declare global {
  interface Window {
    ApplePaySession?: any;
  }
}

export const useCanUseApplePay = () => {
  const useApplepay = useMangopayCreditCardsEnabled();
  return (
    useApplepay &&
    // eslint-disable-next-line no-restricted-properties
    window.location.protocol === 'https:' &&
    !!window.ApplePaySession &&
    ApplePaySession.canMakePayments()
  );
};
