import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import {
  getBestConversionCredit,
  useConversionCredits,
} from '@sorare/core/src/hooks/useConversionCredits';
import { useMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { MonetaryAmountParams } from '@sorare/core/src/lib/monetaryAmount';

import { useCalculateAmounts } from 'hooks/useCalculateAmounts';

export const useConversionCreditForPrice = ({
  price,
  sport,
  forceRefetch,
}: {
  price: MonetaryAmountParams;
  sport: Sport;
  forceRefetch?: boolean;
}) => {
  const { toMonetaryAmount } = useMonetaryAmount();

  const monetaryAmount = toMonetaryAmount(price);

  const { conversionCredits } = useConversionCredits({ sport, forceRefetch });

  const filteredConversionCredits =
    conversionCredits?.filter(cc => !cc.disabled) || [];

  const canUseConversionCredit = filteredConversionCredits?.length > 0;

  const bestConversionCredit = getBestConversionCredit(
    monetaryAmount,
    filteredConversionCredits
  );

  const {
    totalMonetaryAmount: priceDiscountedAmount,
    conversionCreditMonetaryAmount,
    absoluteConversionCreditMonetaryAmount,
    maxDiscountMonetary,
  } = useCalculateAmounts({
    monetaryAmount,
    conversionCreditToUse: bestConversionCredit,
    referenceCurrency: price.referenceCurrency,
    isFiat: false,
    creditCardFee: 0,
    activeFee: false,
  });

  return {
    canUseConversionCredit,
    bestConversionCredit,
    priceDiscountedAmount,
    price,
    conversionCreditMonetaryAmount,
    absoluteConversionCreditMonetaryAmount,
    maxDiscountMonetary,
  };
};
