import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MangopayTransactionStatus } from '__generated__/globalTypes';
import { ORIGIN } from 'config';
import { useChildWindow } from 'hooks/useChildWindow';
import { useFetchTransactionStatus } from 'hooks/useFetchTransactionStatus';
import { useIsMobileApp } from 'hooks/useIsMobileApp';

export const useExternalPaymentMethodReturnUrl = () => {
  const location = useLocation();
  return `${ORIGIN}/transaction-confirmed-popup.html?redirectPath=${encodeURIComponent(
    location.pathname
  )}`;
};

export const useExternalPaymentMethod = ({
  onSuccess,
  getRedirectUrl,
}: {
  loadWalletFirst?: boolean;
  getRedirectUrl: () => Promise<{
    errors: {
      message: string;
    }[];
    data:
      | {
          redirectUrl: string | null;
          errors: {
            message: string;
          }[];
        }
      | null
      | undefined;
  }>;
  onSuccess: () => void;
}) => {
  const { isMobileApp } = useIsMobileApp();
  const [fetchTransactionStatus] = useFetchTransactionStatus();

  const [errors, setErrors] = useState<string[]>([]);

  const { openPopup, close: closeWindow, background } = useChildWindow();

  const onMessage = useCallback(
    async (e: MessageEvent) => {
      if (e.data.eventId === 'sorare:payment:transaction-complete') {
        const params = new URL(e.data.data.location).searchParams;
        const transactionId = params.get('transactionId');
        if (transactionId) {
          const result = await fetchTransactionStatus({
            variables: {
              transactionId,
            },
          });

          if (
            result.data?.mangopay.remoteTransactionStatus?.status &&
            [
              MangopayTransactionStatus.CREATED,
              MangopayTransactionStatus.SUCCEEDED,
            ].includes(result.data.mangopay.remoteTransactionStatus.status)
          ) {
            onSuccess();
          }

          if (
            result.data?.mangopay?.remoteTransactionStatus?.message &&
            result.data?.mangopay?.remoteTransactionStatus?.status ===
              MangopayTransactionStatus.FAILED
          ) {
            setErrors([result.data.mangopay.remoteTransactionStatus.message]);
          }
          closeWindow();
        }
      }
    },
    [onSuccess, closeWindow, fetchTransactionStatus]
  );

  useEffect(() => {
    window.addEventListener('message', onMessage, false);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  const startPaypalPayment = useCallback(async () => {
    setErrors([]);
    // Opening popup first to avoid popup blockers : https://www.mikepalmer.dev/blog/open-a-new-window-without-triggering-pop-up-blockers
    // Opening popup on a loading screen
    const popup = isMobileApp
      ? undefined
      : openPopup(
          `${ORIGIN}/transaction-confirmed-popup.html`,
          'paypalDepositWindow'
        );
    const result = await getRedirectUrl();
    if ((result.errors && result.errors.length > 0) || !result.data) {
      setErrors(result.errors.map(e => e.message));
      closeWindow();
      return;
    }
    if (result.data?.redirectUrl) {
      if (popup) {
        popup.location.href = result.data.redirectUrl;
      } else {
        window.open(result.data.redirectUrl, '_self');
      }
    }
  }, [isMobileApp, openPopup, getRedirectUrl, closeWindow]);
  return {
    errors,
    node: background,
    startPaypalPayment,
  };
};
