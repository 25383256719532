/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'channel.webview';

export enum SnackNotificationLevel {
  INFO = 0,
  WARN = 1,
  ERROR = 2,
  SUCCESS = 3,
  UNRECOGNIZED = -1,
}

export function snackNotificationLevelFromJSON(
  object: any
): SnackNotificationLevel {
  switch (object) {
    case 0:
    case 'INFO':
      return SnackNotificationLevel.INFO;
    case 1:
    case 'WARN':
      return SnackNotificationLevel.WARN;
    case 2:
    case 'ERROR':
      return SnackNotificationLevel.ERROR;
    case 3:
    case 'SUCCESS':
      return SnackNotificationLevel.SUCCESS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SnackNotificationLevel.UNRECOGNIZED;
  }
}

export function snackNotificationLevelToJSON(
  object: SnackNotificationLevel
): string {
  switch (object) {
    case SnackNotificationLevel.INFO:
      return 'INFO';
    case SnackNotificationLevel.WARN:
      return 'WARN';
    case SnackNotificationLevel.ERROR:
      return 'ERROR';
    case SnackNotificationLevel.SUCCESS:
      return 'SUCCESS';
    default:
      return 'UNKNOWN';
  }
}

export enum Collection {
  COLLECTION_UNKNOWN = 0,
  BASEBALL = 1,
  FOOTBALL = 2,
  FOOTBALL_NATIONAL_SERIES = 3,
  NBA = 4,
  UNRECOGNIZED = -1,
}

export function collectionFromJSON(object: any): Collection {
  switch (object) {
    case 0:
    case 'COLLECTION_UNKNOWN':
      return Collection.COLLECTION_UNKNOWN;
    case 1:
    case 'BASEBALL':
      return Collection.BASEBALL;
    case 2:
    case 'FOOTBALL':
      return Collection.FOOTBALL;
    case 3:
    case 'FOOTBALL_NATIONAL_SERIES':
      return Collection.FOOTBALL_NATIONAL_SERIES;
    case 4:
    case 'NBA':
      return Collection.NBA;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Collection.UNRECOGNIZED;
  }
}

export function collectionToJSON(object: Collection): string {
  switch (object) {
    case Collection.COLLECTION_UNKNOWN:
      return 'COLLECTION_UNKNOWN';
    case Collection.BASEBALL:
      return 'BASEBALL';
    case Collection.FOOTBALL:
      return 'FOOTBALL';
    case Collection.FOOTBALL_NATIONAL_SERIES:
      return 'FOOTBALL_NATIONAL_SERIES';
    case Collection.NBA:
      return 'NBA';
    default:
      return 'UNKNOWN';
  }
}

export enum Rarity {
  RARITY_UNKNOWN = 0,
  COMMON = 1,
  CUSTOM_SERIES = 2,
  LIMITED = 3,
  RARE = 4,
  SUPER_RARE = 5,
  UNIQUE = 6,
  UNRECOGNIZED = -1,
}

export function rarityFromJSON(object: any): Rarity {
  switch (object) {
    case 0:
    case 'RARITY_UNKNOWN':
      return Rarity.RARITY_UNKNOWN;
    case 1:
    case 'COMMON':
      return Rarity.COMMON;
    case 2:
    case 'CUSTOM_SERIES':
      return Rarity.CUSTOM_SERIES;
    case 3:
    case 'LIMITED':
      return Rarity.LIMITED;
    case 4:
    case 'RARE':
      return Rarity.RARE;
    case 5:
    case 'SUPER_RARE':
      return Rarity.SUPER_RARE;
    case 6:
    case 'UNIQUE':
      return Rarity.UNIQUE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Rarity.UNRECOGNIZED;
  }
}

export function rarityToJSON(object: Rarity): string {
  switch (object) {
    case Rarity.RARITY_UNKNOWN:
      return 'RARITY_UNKNOWN';
    case Rarity.COMMON:
      return 'COMMON';
    case Rarity.CUSTOM_SERIES:
      return 'CUSTOM_SERIES';
    case Rarity.LIMITED:
      return 'LIMITED';
    case Rarity.RARE:
      return 'RARE';
    case Rarity.SUPER_RARE:
      return 'SUPER_RARE';
    case Rarity.UNIQUE:
      return 'UNIQUE';
    default:
      return 'UNKNOWN';
  }
}

/** Payload for channel postMessage: WebView -> native app */
export interface ChannelMessage {
  logout: Logout | undefined;
  socialShare: SocialShare | undefined;
  managerShare: ManagerShare | undefined;
  debugScreen: DebugScreen | undefined;
  clickButton: ClickButton | undefined;
  displaySnackNotification: DisplaySnackNotification | undefined;
  loaded: Loaded | undefined;
  instantBuy: InstantBuy | undefined;
  rewardClaimed: RewardClaimed | undefined;
  managerProgressionTaskClaimed: ManagerProgressionTaskClaimed | undefined;
  animateScore: AnimateScore | undefined;
  userEngagementScoreIncrement: UserEngagementScoreIncrement | undefined;
  enablePushNotifications: EnablePushNotifications | undefined;
  signUpWithOauth: SignUpWithOAuth | undefined;
  setBottomTabsVisibility: SetBottomTabsVisibility | undefined;
  showPrivacyPreferences: ShowPrivacyPreferences | undefined;
  clickSearch: ClickSearch | undefined;
  trackInAppEvent: TrackInAppEvent | undefined;
  loginWithEphemeralToken: LoginWithEphemeralToken | undefined;
  loginWithJwtToken: LoginWithJWTToken | undefined;
  showCardScreen: ShowCardScreen | undefined;
  footballOnboardingEvent: FootballOnboardingEvent | undefined;
  openBrowser: OpenBrowser | undefined;
  nbaOnboardingEventComplete: NBAOnboardingEventComplete | undefined;
  mlbOnboardingEventComplete: MLBOnboardingEventComplete | undefined;
  buyInGameCurrencyPack: BuyInGameCurrencyPack | undefined;
}

/**
 * Command to logout of app.
 * Triggered by logout button in webview.
 */
export interface Logout {
  isExplicit: boolean;
}

/**
 * Command to all social share.
 * Triggered by share button on card detail, lineup, my club pages.
 */
export interface SocialShare {
  title: string | undefined;
  description: string | undefined;
  url: string | undefined;
  image: SharingImage | undefined;
  message: string | undefined;
  trackingEventName: string | undefined;
  trackingEventContext: string | undefined;
  sharedItemId: string | undefined;
  sharedItem: string | undefined;
}

/**
 * Command to share manager profile.
 * Triggered by share button on manager profile page.
 */
export interface ManagerShare {
  profile: SocialShare | undefined;
  gallery: SocialShare | undefined;
  highlightedDeck: SocialShare | undefined;
}

/**
 * Command to open native Debug screen.
 * Triggered by Debug Screen button in the webview menu.
 */
export interface DebugScreen {}

/**
 * Command to start the in-app-purchase flow for a card.
 * Triggered by tapping an InstantBuy card in the marketplace.
 */
export interface InstantBuy {
  cardSlug: string | undefined;
  playerSlug: string | undefined;
  rarity: Rarity;
  collection: Collection;
}

export interface SharingImage {
  post: string | undefined;
  story: string | undefined;
  square: string | undefined;
}

export interface ClickButton {}

export interface AnimateScore {
  score: number;
  previousScore: number;
}

export interface Loaded {
  page: PageResource | undefined;
  iframe: IFrameResource | undefined;
  app: AppResource | undefined;
}

export interface UserEngagementScoreIncrement {
  score: number;
}

export interface PageResource {
  url: string;
}

export interface IFrameResource {
  url: string;
}

export interface ShowPrivacyPreferences {}

export interface AppResource {
  progress: number;
}

export interface DisplaySnackNotification {
  message: string | undefined;
  level: SnackNotificationLevel;
  url: string | undefined;
}

export interface RewardClaimed {}

export interface ManagerProgressionTaskClaimed {}

export interface EnablePushNotifications {}

export interface SignUpWithOAuth {
  platform: string | undefined;
  acceptAgeLimit: boolean;
  acceptGameRules: boolean;
  acceptPrivacyPolicy: boolean;
  acceptTerms: boolean;
  agreedToReceiveOffersFromPartners: boolean;
  agreedToReceiveOffersFromPartnersSpecific: string[];
  nickname: string | undefined;
  sport: SignUpWithOAuth_Sport;
  impactClickId: string | undefined;
  googleClickId: string | undefined;
  facebookClickId: string | undefined;
  twitterClickId: string | undefined;
  tikTokClickId: string | undefined;
  redditClickId: string | undefined;
}

export enum SignUpWithOAuth_Sport {
  FOOTBALL = 0,
  BASEBALL = 1,
  NBA = 2,
  UNRECOGNIZED = -1,
}

export function signUpWithOAuth_SportFromJSON(
  object: any
): SignUpWithOAuth_Sport {
  switch (object) {
    case 0:
    case 'FOOTBALL':
      return SignUpWithOAuth_Sport.FOOTBALL;
    case 1:
    case 'BASEBALL':
      return SignUpWithOAuth_Sport.BASEBALL;
    case 2:
    case 'NBA':
      return SignUpWithOAuth_Sport.NBA;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SignUpWithOAuth_Sport.UNRECOGNIZED;
  }
}

export function signUpWithOAuth_SportToJSON(
  object: SignUpWithOAuth_Sport
): string {
  switch (object) {
    case SignUpWithOAuth_Sport.FOOTBALL:
      return 'FOOTBALL';
    case SignUpWithOAuth_Sport.BASEBALL:
      return 'BASEBALL';
    case SignUpWithOAuth_Sport.NBA:
      return 'NBA';
    default:
      return 'UNKNOWN';
  }
}

export interface SetBottomTabsVisibility {
  isVisible: boolean;
}

export interface ClickSearch {}

export interface TrackInAppEvent {
  event: string | undefined;
  jsonProperties: string | undefined;
}

export interface LoginWithEphemeralToken {
  token: string | undefined;
}

export interface LoginWithJWTToken {
  token: string | undefined;
  userId: string | undefined;
  email: string | undefined;
  expiredAt: number;
  sport: LoginWithJWTToken_Sport;
}

export enum LoginWithJWTToken_Sport {
  FOOTBALL = 0,
  BASEBALL = 1,
  NBA = 2,
  UNRECOGNIZED = -1,
}

export function loginWithJWTToken_SportFromJSON(
  object: any
): LoginWithJWTToken_Sport {
  switch (object) {
    case 0:
    case 'FOOTBALL':
      return LoginWithJWTToken_Sport.FOOTBALL;
    case 1:
    case 'BASEBALL':
      return LoginWithJWTToken_Sport.BASEBALL;
    case 2:
    case 'NBA':
      return LoginWithJWTToken_Sport.NBA;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return LoginWithJWTToken_Sport.UNRECOGNIZED;
  }
}

export function loginWithJWTToken_SportToJSON(
  object: LoginWithJWTToken_Sport
): string {
  switch (object) {
    case LoginWithJWTToken_Sport.FOOTBALL:
      return 'FOOTBALL';
    case LoginWithJWTToken_Sport.BASEBALL:
      return 'BASEBALL';
    case LoginWithJWTToken_Sport.NBA:
      return 'NBA';
    default:
      return 'UNKNOWN';
  }
}

/**
 * Command to show the in-app card screen
 * Triggered by tapping a card in the marketplace grid.
 */
export interface ShowCardScreen {
  cardSlug: string | undefined;
  collection: Collection;
}

/**
 * Command to share user action on the onboard flow.
 * Triggered by tapping a button in the football onboarding flow.
 */
export interface FootballOnboardingEvent {
  skipOnboarding: FootballSkipOnboarding | undefined;
  onboardingComplete: FootballOnboardingComplete | undefined;
}

/** Trigger by finishing the NBA onboarding flow */
export interface NBAOnboardingEventComplete {}

/** Trigger by finishing the MLB onboarding flow */
export interface MLBOnboardingEventComplete {}

export interface FootballSkipOnboarding {}

export interface FootballOnboardingComplete {}

export interface OpenBrowser {
  url: string | undefined;
}

/**
 * Command to start the in-app-purchase flow for an InGameCurrencyPack.
 * Triggered by tapping an InGameCurrencyPack in the marketplace.
 */
export interface BuyInGameCurrencyPack {
  packId: string | undefined;
}

function createBaseChannelMessage(): ChannelMessage {
  return {
    logout: undefined,
    socialShare: undefined,
    managerShare: undefined,
    debugScreen: undefined,
    clickButton: undefined,
    displaySnackNotification: undefined,
    loaded: undefined,
    instantBuy: undefined,
    rewardClaimed: undefined,
    managerProgressionTaskClaimed: undefined,
    animateScore: undefined,
    userEngagementScoreIncrement: undefined,
    enablePushNotifications: undefined,
    signUpWithOauth: undefined,
    setBottomTabsVisibility: undefined,
    showPrivacyPreferences: undefined,
    clickSearch: undefined,
    trackInAppEvent: undefined,
    loginWithEphemeralToken: undefined,
    loginWithJwtToken: undefined,
    showCardScreen: undefined,
    footballOnboardingEvent: undefined,
    openBrowser: undefined,
    nbaOnboardingEventComplete: undefined,
    mlbOnboardingEventComplete: undefined,
    buyInGameCurrencyPack: undefined,
  };
}

export const ChannelMessage = {
  fromJSON(object: any): ChannelMessage {
    return {
      logout: isSet(object.logout) ? Logout.fromJSON(object.logout) : undefined,
      socialShare: isSet(object.social_share)
        ? SocialShare.fromJSON(object.social_share)
        : undefined,
      managerShare: isSet(object.manager_share)
        ? ManagerShare.fromJSON(object.manager_share)
        : undefined,
      debugScreen: isSet(object.debug_screen)
        ? DebugScreen.fromJSON(object.debug_screen)
        : undefined,
      clickButton: isSet(object.click_button)
        ? ClickButton.fromJSON(object.click_button)
        : undefined,
      displaySnackNotification: isSet(object.display_snack_notification)
        ? DisplaySnackNotification.fromJSON(object.display_snack_notification)
        : undefined,
      loaded: isSet(object.loaded) ? Loaded.fromJSON(object.loaded) : undefined,
      instantBuy: isSet(object.instant_buy)
        ? InstantBuy.fromJSON(object.instant_buy)
        : undefined,
      rewardClaimed: isSet(object.reward_claimed)
        ? RewardClaimed.fromJSON(object.reward_claimed)
        : undefined,
      managerProgressionTaskClaimed: isSet(
        object.manager_progression_task_claimed
      )
        ? ManagerProgressionTaskClaimed.fromJSON(
            object.manager_progression_task_claimed
          )
        : undefined,
      animateScore: isSet(object.animate_score)
        ? AnimateScore.fromJSON(object.animate_score)
        : undefined,
      userEngagementScoreIncrement: isSet(
        object.user_engagement_score_increment
      )
        ? UserEngagementScoreIncrement.fromJSON(
            object.user_engagement_score_increment
          )
        : undefined,
      enablePushNotifications: isSet(object.enable_push_notifications)
        ? EnablePushNotifications.fromJSON(object.enable_push_notifications)
        : undefined,
      signUpWithOauth: isSet(object.sign_up_with_oauth)
        ? SignUpWithOAuth.fromJSON(object.sign_up_with_oauth)
        : undefined,
      setBottomTabsVisibility: isSet(object.set_bottom_tabs_visibility)
        ? SetBottomTabsVisibility.fromJSON(object.set_bottom_tabs_visibility)
        : undefined,
      showPrivacyPreferences: isSet(object.show_privacy_preferences)
        ? ShowPrivacyPreferences.fromJSON(object.show_privacy_preferences)
        : undefined,
      clickSearch: isSet(object.click_search)
        ? ClickSearch.fromJSON(object.click_search)
        : undefined,
      trackInAppEvent: isSet(object.track_in_app_event)
        ? TrackInAppEvent.fromJSON(object.track_in_app_event)
        : undefined,
      loginWithEphemeralToken: isSet(object.login_with_ephemeral_token)
        ? LoginWithEphemeralToken.fromJSON(object.login_with_ephemeral_token)
        : undefined,
      loginWithJwtToken: isSet(object.login_with_jwt_token)
        ? LoginWithJWTToken.fromJSON(object.login_with_jwt_token)
        : undefined,
      showCardScreen: isSet(object.show_card_screen)
        ? ShowCardScreen.fromJSON(object.show_card_screen)
        : undefined,
      footballOnboardingEvent: isSet(object.football_onboarding_event)
        ? FootballOnboardingEvent.fromJSON(object.football_onboarding_event)
        : undefined,
      openBrowser: isSet(object.open_browser)
        ? OpenBrowser.fromJSON(object.open_browser)
        : undefined,
      nbaOnboardingEventComplete: isSet(object.nba_onboarding_event_complete)
        ? NBAOnboardingEventComplete.fromJSON(
            object.nba_onboarding_event_complete
          )
        : undefined,
      mlbOnboardingEventComplete: isSet(object.mlb_onboarding_event_complete)
        ? MLBOnboardingEventComplete.fromJSON(
            object.mlb_onboarding_event_complete
          )
        : undefined,
      buyInGameCurrencyPack: isSet(object.buy_in_game_currency_pack)
        ? BuyInGameCurrencyPack.fromJSON(object.buy_in_game_currency_pack)
        : undefined,
    };
  },

  toJSON(message: ChannelMessage): unknown {
    const obj: any = {};
    message.logout !== undefined &&
      (obj.logout = message.logout ? Logout.toJSON(message.logout) : undefined);
    message.socialShare !== undefined &&
      (obj.social_share = message.socialShare
        ? SocialShare.toJSON(message.socialShare)
        : undefined);
    message.managerShare !== undefined &&
      (obj.manager_share = message.managerShare
        ? ManagerShare.toJSON(message.managerShare)
        : undefined);
    message.debugScreen !== undefined &&
      (obj.debug_screen = message.debugScreen
        ? DebugScreen.toJSON(message.debugScreen)
        : undefined);
    message.clickButton !== undefined &&
      (obj.click_button = message.clickButton
        ? ClickButton.toJSON(message.clickButton)
        : undefined);
    message.displaySnackNotification !== undefined &&
      (obj.display_snack_notification = message.displaySnackNotification
        ? DisplaySnackNotification.toJSON(message.displaySnackNotification)
        : undefined);
    message.loaded !== undefined &&
      (obj.loaded = message.loaded ? Loaded.toJSON(message.loaded) : undefined);
    message.instantBuy !== undefined &&
      (obj.instant_buy = message.instantBuy
        ? InstantBuy.toJSON(message.instantBuy)
        : undefined);
    message.rewardClaimed !== undefined &&
      (obj.reward_claimed = message.rewardClaimed
        ? RewardClaimed.toJSON(message.rewardClaimed)
        : undefined);
    message.managerProgressionTaskClaimed !== undefined &&
      (obj.manager_progression_task_claimed =
        message.managerProgressionTaskClaimed
          ? ManagerProgressionTaskClaimed.toJSON(
              message.managerProgressionTaskClaimed
            )
          : undefined);
    message.animateScore !== undefined &&
      (obj.animate_score = message.animateScore
        ? AnimateScore.toJSON(message.animateScore)
        : undefined);
    message.userEngagementScoreIncrement !== undefined &&
      (obj.user_engagement_score_increment =
        message.userEngagementScoreIncrement
          ? UserEngagementScoreIncrement.toJSON(
              message.userEngagementScoreIncrement
            )
          : undefined);
    message.enablePushNotifications !== undefined &&
      (obj.enable_push_notifications = message.enablePushNotifications
        ? EnablePushNotifications.toJSON(message.enablePushNotifications)
        : undefined);
    message.signUpWithOauth !== undefined &&
      (obj.sign_up_with_oauth = message.signUpWithOauth
        ? SignUpWithOAuth.toJSON(message.signUpWithOauth)
        : undefined);
    message.setBottomTabsVisibility !== undefined &&
      (obj.set_bottom_tabs_visibility = message.setBottomTabsVisibility
        ? SetBottomTabsVisibility.toJSON(message.setBottomTabsVisibility)
        : undefined);
    message.showPrivacyPreferences !== undefined &&
      (obj.show_privacy_preferences = message.showPrivacyPreferences
        ? ShowPrivacyPreferences.toJSON(message.showPrivacyPreferences)
        : undefined);
    message.clickSearch !== undefined &&
      (obj.click_search = message.clickSearch
        ? ClickSearch.toJSON(message.clickSearch)
        : undefined);
    message.trackInAppEvent !== undefined &&
      (obj.track_in_app_event = message.trackInAppEvent
        ? TrackInAppEvent.toJSON(message.trackInAppEvent)
        : undefined);
    message.loginWithEphemeralToken !== undefined &&
      (obj.login_with_ephemeral_token = message.loginWithEphemeralToken
        ? LoginWithEphemeralToken.toJSON(message.loginWithEphemeralToken)
        : undefined);
    message.loginWithJwtToken !== undefined &&
      (obj.login_with_jwt_token = message.loginWithJwtToken
        ? LoginWithJWTToken.toJSON(message.loginWithJwtToken)
        : undefined);
    message.showCardScreen !== undefined &&
      (obj.show_card_screen = message.showCardScreen
        ? ShowCardScreen.toJSON(message.showCardScreen)
        : undefined);
    message.footballOnboardingEvent !== undefined &&
      (obj.football_onboarding_event = message.footballOnboardingEvent
        ? FootballOnboardingEvent.toJSON(message.footballOnboardingEvent)
        : undefined);
    message.openBrowser !== undefined &&
      (obj.open_browser = message.openBrowser
        ? OpenBrowser.toJSON(message.openBrowser)
        : undefined);
    message.nbaOnboardingEventComplete !== undefined &&
      (obj.nba_onboarding_event_complete = message.nbaOnboardingEventComplete
        ? NBAOnboardingEventComplete.toJSON(message.nbaOnboardingEventComplete)
        : undefined);
    message.mlbOnboardingEventComplete !== undefined &&
      (obj.mlb_onboarding_event_complete = message.mlbOnboardingEventComplete
        ? MLBOnboardingEventComplete.toJSON(message.mlbOnboardingEventComplete)
        : undefined);
    message.buyInGameCurrencyPack !== undefined &&
      (obj.buy_in_game_currency_pack = message.buyInGameCurrencyPack
        ? BuyInGameCurrencyPack.toJSON(message.buyInGameCurrencyPack)
        : undefined);
    return obj;
  },
};

function createBaseLogout(): Logout {
  return { isExplicit: false };
}

export const Logout = {
  fromJSON(object: any): Logout {
    return {
      isExplicit: isSet(object.is_explicit)
        ? Boolean(object.is_explicit)
        : false,
    };
  },

  toJSON(message: Logout): unknown {
    const obj: any = {};
    message.isExplicit !== undefined && (obj.is_explicit = message.isExplicit);
    return obj;
  },
};

function createBaseSocialShare(): SocialShare {
  return {
    title: undefined,
    description: undefined,
    url: undefined,
    image: undefined,
    message: undefined,
    trackingEventName: undefined,
    trackingEventContext: undefined,
    sharedItemId: undefined,
    sharedItem: undefined,
  };
}

export const SocialShare = {
  fromJSON(object: any): SocialShare {
    return {
      title: isSet(object.title) ? String(object.title) : undefined,
      description: isSet(object.description)
        ? String(object.description)
        : undefined,
      url: isSet(object.url) ? String(object.url) : undefined,
      image: isSet(object.image)
        ? SharingImage.fromJSON(object.image)
        : undefined,
      message: isSet(object.message) ? String(object.message) : undefined,
      trackingEventName: isSet(object.tracking_event_name)
        ? String(object.tracking_event_name)
        : undefined,
      trackingEventContext: isSet(object.tracking_event_context)
        ? String(object.tracking_event_context)
        : undefined,
      sharedItemId: isSet(object.shared_item_id)
        ? String(object.shared_item_id)
        : undefined,
      sharedItem: isSet(object.shared_item)
        ? String(object.shared_item)
        : undefined,
    };
  },

  toJSON(message: SocialShare): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined &&
      (obj.description = message.description);
    message.url !== undefined && (obj.url = message.url);
    message.image !== undefined &&
      (obj.image = message.image
        ? SharingImage.toJSON(message.image)
        : undefined);
    message.message !== undefined && (obj.message = message.message);
    message.trackingEventName !== undefined &&
      (obj.tracking_event_name = message.trackingEventName);
    message.trackingEventContext !== undefined &&
      (obj.tracking_event_context = message.trackingEventContext);
    message.sharedItemId !== undefined &&
      (obj.shared_item_id = message.sharedItemId);
    message.sharedItem !== undefined && (obj.shared_item = message.sharedItem);
    return obj;
  },
};

function createBaseManagerShare(): ManagerShare {
  return { profile: undefined, gallery: undefined, highlightedDeck: undefined };
}

export const ManagerShare = {
  fromJSON(object: any): ManagerShare {
    return {
      profile: isSet(object.profile)
        ? SocialShare.fromJSON(object.profile)
        : undefined,
      gallery: isSet(object.gallery)
        ? SocialShare.fromJSON(object.gallery)
        : undefined,
      highlightedDeck: isSet(object.highlighted_deck)
        ? SocialShare.fromJSON(object.highlighted_deck)
        : undefined,
    };
  },

  toJSON(message: ManagerShare): unknown {
    const obj: any = {};
    message.profile !== undefined &&
      (obj.profile = message.profile
        ? SocialShare.toJSON(message.profile)
        : undefined);
    message.gallery !== undefined &&
      (obj.gallery = message.gallery
        ? SocialShare.toJSON(message.gallery)
        : undefined);
    message.highlightedDeck !== undefined &&
      (obj.highlighted_deck = message.highlightedDeck
        ? SocialShare.toJSON(message.highlightedDeck)
        : undefined);
    return obj;
  },
};

function createBaseDebugScreen(): DebugScreen {
  return {};
}

export const DebugScreen = {
  fromJSON(_: any): DebugScreen {
    return {};
  },

  toJSON(_: DebugScreen): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseInstantBuy(): InstantBuy {
  return {
    cardSlug: undefined,
    playerSlug: undefined,
    rarity: 0,
    collection: 0,
  };
}

export const InstantBuy = {
  fromJSON(object: any): InstantBuy {
    return {
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : undefined,
      playerSlug: isSet(object.player_slug)
        ? String(object.player_slug)
        : undefined,
      rarity: isSet(object.rarity) ? rarityFromJSON(object.rarity) : 0,
      collection: isSet(object.collection)
        ? collectionFromJSON(object.collection)
        : 0,
    };
  },

  toJSON(message: InstantBuy): unknown {
    const obj: any = {};
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.playerSlug !== undefined && (obj.player_slug = message.playerSlug);
    message.rarity !== undefined && (obj.rarity = rarityToJSON(message.rarity));
    message.collection !== undefined &&
      (obj.collection = collectionToJSON(message.collection));
    return obj;
  },
};

function createBaseSharingImage(): SharingImage {
  return { post: undefined, story: undefined, square: undefined };
}

export const SharingImage = {
  fromJSON(object: any): SharingImage {
    return {
      post: isSet(object.post) ? String(object.post) : undefined,
      story: isSet(object.story) ? String(object.story) : undefined,
      square: isSet(object.square) ? String(object.square) : undefined,
    };
  },

  toJSON(message: SharingImage): unknown {
    const obj: any = {};
    message.post !== undefined && (obj.post = message.post);
    message.story !== undefined && (obj.story = message.story);
    message.square !== undefined && (obj.square = message.square);
    return obj;
  },
};

function createBaseClickButton(): ClickButton {
  return {};
}

export const ClickButton = {
  fromJSON(_: any): ClickButton {
    return {};
  },

  toJSON(_: ClickButton): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseAnimateScore(): AnimateScore {
  return { score: 0, previousScore: 0 };
}

export const AnimateScore = {
  fromJSON(object: any): AnimateScore {
    return {
      score: isSet(object.score) ? Number(object.score) : 0,
      previousScore: isSet(object.previous_score)
        ? Number(object.previous_score)
        : 0,
    };
  },

  toJSON(message: AnimateScore): unknown {
    const obj: any = {};
    message.score !== undefined && (obj.score = message.score);
    message.previousScore !== undefined &&
      (obj.previous_score = message.previousScore);
    return obj;
  },
};

function createBaseLoaded(): Loaded {
  return { page: undefined, iframe: undefined, app: undefined };
}

export const Loaded = {
  fromJSON(object: any): Loaded {
    return {
      page: isSet(object.page) ? PageResource.fromJSON(object.page) : undefined,
      iframe: isSet(object.iframe)
        ? IFrameResource.fromJSON(object.iframe)
        : undefined,
      app: isSet(object.app) ? AppResource.fromJSON(object.app) : undefined,
    };
  },

  toJSON(message: Loaded): unknown {
    const obj: any = {};
    message.page !== undefined &&
      (obj.page = message.page ? PageResource.toJSON(message.page) : undefined);
    message.iframe !== undefined &&
      (obj.iframe = message.iframe
        ? IFrameResource.toJSON(message.iframe)
        : undefined);
    message.app !== undefined &&
      (obj.app = message.app ? AppResource.toJSON(message.app) : undefined);
    return obj;
  },
};

function createBaseUserEngagementScoreIncrement(): UserEngagementScoreIncrement {
  return { score: 0 };
}

export const UserEngagementScoreIncrement = {
  fromJSON(object: any): UserEngagementScoreIncrement {
    return {
      score: isSet(object.score) ? Number(object.score) : 0,
    };
  },

  toJSON(message: UserEngagementScoreIncrement): unknown {
    const obj: any = {};
    message.score !== undefined && (obj.score = Math.round(message.score));
    return obj;
  },
};

function createBasePageResource(): PageResource {
  return { url: '' };
}

export const PageResource = {
  fromJSON(object: any): PageResource {
    return {
      url: isSet(object.url) ? String(object.url) : '',
    };
  },

  toJSON(message: PageResource): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },
};

function createBaseIFrameResource(): IFrameResource {
  return { url: '' };
}

export const IFrameResource = {
  fromJSON(object: any): IFrameResource {
    return {
      url: isSet(object.url) ? String(object.url) : '',
    };
  },

  toJSON(message: IFrameResource): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },
};

function createBaseShowPrivacyPreferences(): ShowPrivacyPreferences {
  return {};
}

export const ShowPrivacyPreferences = {
  fromJSON(_: any): ShowPrivacyPreferences {
    return {};
  },

  toJSON(_: ShowPrivacyPreferences): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseAppResource(): AppResource {
  return { progress: 0 };
}

export const AppResource = {
  fromJSON(object: any): AppResource {
    return {
      progress: isSet(object.progress) ? Number(object.progress) : 0,
    };
  },

  toJSON(message: AppResource): unknown {
    const obj: any = {};
    message.progress !== undefined && (obj.progress = message.progress);
    return obj;
  },
};

function createBaseDisplaySnackNotification(): DisplaySnackNotification {
  return { message: undefined, level: 0, url: undefined };
}

export const DisplaySnackNotification = {
  fromJSON(object: any): DisplaySnackNotification {
    return {
      message: isSet(object.message) ? String(object.message) : undefined,
      level: isSet(object.level)
        ? snackNotificationLevelFromJSON(object.level)
        : 0,
      url: isSet(object.url) ? String(object.url) : undefined,
    };
  },

  toJSON(message: DisplaySnackNotification): unknown {
    const obj: any = {};
    message.message !== undefined && (obj.message = message.message);
    message.level !== undefined &&
      (obj.level = snackNotificationLevelToJSON(message.level));
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },
};

function createBaseRewardClaimed(): RewardClaimed {
  return {};
}

export const RewardClaimed = {
  fromJSON(_: any): RewardClaimed {
    return {};
  },

  toJSON(_: RewardClaimed): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseManagerProgressionTaskClaimed(): ManagerProgressionTaskClaimed {
  return {};
}

export const ManagerProgressionTaskClaimed = {
  fromJSON(_: any): ManagerProgressionTaskClaimed {
    return {};
  },

  toJSON(_: ManagerProgressionTaskClaimed): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseEnablePushNotifications(): EnablePushNotifications {
  return {};
}

export const EnablePushNotifications = {
  fromJSON(_: any): EnablePushNotifications {
    return {};
  },

  toJSON(_: EnablePushNotifications): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseSignUpWithOAuth(): SignUpWithOAuth {
  return {
    platform: undefined,
    acceptAgeLimit: false,
    acceptGameRules: false,
    acceptPrivacyPolicy: false,
    acceptTerms: false,
    agreedToReceiveOffersFromPartners: false,
    agreedToReceiveOffersFromPartnersSpecific: [],
    nickname: undefined,
    sport: 0,
    impactClickId: undefined,
    googleClickId: undefined,
    facebookClickId: undefined,
    twitterClickId: undefined,
    tikTokClickId: undefined,
    redditClickId: undefined,
  };
}

export const SignUpWithOAuth = {
  fromJSON(object: any): SignUpWithOAuth {
    return {
      platform: isSet(object.platform) ? String(object.platform) : undefined,
      acceptAgeLimit: isSet(object.accept_age_limit)
        ? Boolean(object.accept_age_limit)
        : false,
      acceptGameRules: isSet(object.accept_game_rules)
        ? Boolean(object.accept_game_rules)
        : false,
      acceptPrivacyPolicy: isSet(object.accept_privacy_policy)
        ? Boolean(object.accept_privacy_policy)
        : false,
      acceptTerms: isSet(object.accept_terms)
        ? Boolean(object.accept_terms)
        : false,
      agreedToReceiveOffersFromPartners: isSet(
        object.agreed_to_receive_offers_from_partners
      )
        ? Boolean(object.agreed_to_receive_offers_from_partners)
        : false,
      agreedToReceiveOffersFromPartnersSpecific: Array.isArray(
        object?.agreed_to_receive_offers_from_partners_specific
      )
        ? object.agreed_to_receive_offers_from_partners_specific.map((e: any) =>
            String(e)
          )
        : [],
      nickname: isSet(object.nickname) ? String(object.nickname) : undefined,
      sport: isSet(object.sport)
        ? signUpWithOAuth_SportFromJSON(object.sport)
        : 0,
      impactClickId: isSet(object.impact_click_id)
        ? String(object.impact_click_id)
        : undefined,
      googleClickId: isSet(object.google_click_id)
        ? String(object.google_click_id)
        : undefined,
      facebookClickId: isSet(object.facebook_click_id)
        ? String(object.facebook_click_id)
        : undefined,
      twitterClickId: isSet(object.twitter_click_id)
        ? String(object.twitter_click_id)
        : undefined,
      tikTokClickId: isSet(object.tik_tok_click_id)
        ? String(object.tik_tok_click_id)
        : undefined,
      redditClickId: isSet(object.reddit_click_id)
        ? String(object.reddit_click_id)
        : undefined,
    };
  },

  toJSON(message: SignUpWithOAuth): unknown {
    const obj: any = {};
    message.platform !== undefined && (obj.platform = message.platform);
    message.acceptAgeLimit !== undefined &&
      (obj.accept_age_limit = message.acceptAgeLimit);
    message.acceptGameRules !== undefined &&
      (obj.accept_game_rules = message.acceptGameRules);
    message.acceptPrivacyPolicy !== undefined &&
      (obj.accept_privacy_policy = message.acceptPrivacyPolicy);
    message.acceptTerms !== undefined &&
      (obj.accept_terms = message.acceptTerms);
    message.agreedToReceiveOffersFromPartners !== undefined &&
      (obj.agreed_to_receive_offers_from_partners =
        message.agreedToReceiveOffersFromPartners);
    if (message.agreedToReceiveOffersFromPartnersSpecific) {
      obj.agreed_to_receive_offers_from_partners_specific =
        message.agreedToReceiveOffersFromPartnersSpecific.map(e => e);
    } else {
      obj.agreed_to_receive_offers_from_partners_specific = [];
    }
    message.nickname !== undefined && (obj.nickname = message.nickname);
    message.sport !== undefined &&
      (obj.sport = signUpWithOAuth_SportToJSON(message.sport));
    message.impactClickId !== undefined &&
      (obj.impact_click_id = message.impactClickId);
    message.googleClickId !== undefined &&
      (obj.google_click_id = message.googleClickId);
    message.facebookClickId !== undefined &&
      (obj.facebook_click_id = message.facebookClickId);
    message.twitterClickId !== undefined &&
      (obj.twitter_click_id = message.twitterClickId);
    message.tikTokClickId !== undefined &&
      (obj.tik_tok_click_id = message.tikTokClickId);
    message.redditClickId !== undefined &&
      (obj.reddit_click_id = message.redditClickId);
    return obj;
  },
};

function createBaseSetBottomTabsVisibility(): SetBottomTabsVisibility {
  return { isVisible: false };
}

export const SetBottomTabsVisibility = {
  fromJSON(object: any): SetBottomTabsVisibility {
    return {
      isVisible: isSet(object.is_visible) ? Boolean(object.is_visible) : false,
    };
  },

  toJSON(message: SetBottomTabsVisibility): unknown {
    const obj: any = {};
    message.isVisible !== undefined && (obj.is_visible = message.isVisible);
    return obj;
  },
};

function createBaseClickSearch(): ClickSearch {
  return {};
}

export const ClickSearch = {
  fromJSON(_: any): ClickSearch {
    return {};
  },

  toJSON(_: ClickSearch): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseTrackInAppEvent(): TrackInAppEvent {
  return { event: undefined, jsonProperties: undefined };
}

export const TrackInAppEvent = {
  fromJSON(object: any): TrackInAppEvent {
    return {
      event: isSet(object.event) ? String(object.event) : undefined,
      jsonProperties: isSet(object.json_properties)
        ? String(object.json_properties)
        : undefined,
    };
  },

  toJSON(message: TrackInAppEvent): unknown {
    const obj: any = {};
    message.event !== undefined && (obj.event = message.event);
    message.jsonProperties !== undefined &&
      (obj.json_properties = message.jsonProperties);
    return obj;
  },
};

function createBaseLoginWithEphemeralToken(): LoginWithEphemeralToken {
  return { token: undefined };
}

export const LoginWithEphemeralToken = {
  fromJSON(object: any): LoginWithEphemeralToken {
    return {
      token: isSet(object.token) ? String(object.token) : undefined,
    };
  },

  toJSON(message: LoginWithEphemeralToken): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    return obj;
  },
};

function createBaseLoginWithJWTToken(): LoginWithJWTToken {
  return {
    token: undefined,
    userId: undefined,
    email: undefined,
    expiredAt: 0,
    sport: 0,
  };
}

export const LoginWithJWTToken = {
  fromJSON(object: any): LoginWithJWTToken {
    return {
      token: isSet(object.token) ? String(object.token) : undefined,
      userId: isSet(object.user_id) ? String(object.user_id) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      expiredAt: isSet(object.expired_at) ? Number(object.expired_at) : 0,
      sport: isSet(object.sport)
        ? loginWithJWTToken_SportFromJSON(object.sport)
        : 0,
    };
  },

  toJSON(message: LoginWithJWTToken): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    message.userId !== undefined && (obj.user_id = message.userId);
    message.email !== undefined && (obj.email = message.email);
    message.expiredAt !== undefined &&
      (obj.expired_at = Math.round(message.expiredAt));
    message.sport !== undefined &&
      (obj.sport = loginWithJWTToken_SportToJSON(message.sport));
    return obj;
  },
};

function createBaseShowCardScreen(): ShowCardScreen {
  return { cardSlug: undefined, collection: 0 };
}

export const ShowCardScreen = {
  fromJSON(object: any): ShowCardScreen {
    return {
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : undefined,
      collection: isSet(object.collection)
        ? collectionFromJSON(object.collection)
        : 0,
    };
  },

  toJSON(message: ShowCardScreen): unknown {
    const obj: any = {};
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.collection !== undefined &&
      (obj.collection = collectionToJSON(message.collection));
    return obj;
  },
};

function createBaseFootballOnboardingEvent(): FootballOnboardingEvent {
  return { skipOnboarding: undefined, onboardingComplete: undefined };
}

export const FootballOnboardingEvent = {
  fromJSON(object: any): FootballOnboardingEvent {
    return {
      skipOnboarding: isSet(object.skip_onboarding)
        ? FootballSkipOnboarding.fromJSON(object.skip_onboarding)
        : undefined,
      onboardingComplete: isSet(object.onboarding_complete)
        ? FootballOnboardingComplete.fromJSON(object.onboarding_complete)
        : undefined,
    };
  },

  toJSON(message: FootballOnboardingEvent): unknown {
    const obj: any = {};
    message.skipOnboarding !== undefined &&
      (obj.skip_onboarding = message.skipOnboarding
        ? FootballSkipOnboarding.toJSON(message.skipOnboarding)
        : undefined);
    message.onboardingComplete !== undefined &&
      (obj.onboarding_complete = message.onboardingComplete
        ? FootballOnboardingComplete.toJSON(message.onboardingComplete)
        : undefined);
    return obj;
  },
};

function createBaseNBAOnboardingEventComplete(): NBAOnboardingEventComplete {
  return {};
}

export const NBAOnboardingEventComplete = {
  fromJSON(_: any): NBAOnboardingEventComplete {
    return {};
  },

  toJSON(_: NBAOnboardingEventComplete): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseMLBOnboardingEventComplete(): MLBOnboardingEventComplete {
  return {};
}

export const MLBOnboardingEventComplete = {
  fromJSON(_: any): MLBOnboardingEventComplete {
    return {};
  },

  toJSON(_: MLBOnboardingEventComplete): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseFootballSkipOnboarding(): FootballSkipOnboarding {
  return {};
}

export const FootballSkipOnboarding = {
  fromJSON(_: any): FootballSkipOnboarding {
    return {};
  },

  toJSON(_: FootballSkipOnboarding): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseFootballOnboardingComplete(): FootballOnboardingComplete {
  return {};
}

export const FootballOnboardingComplete = {
  fromJSON(_: any): FootballOnboardingComplete {
    return {};
  },

  toJSON(_: FootballOnboardingComplete): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseOpenBrowser(): OpenBrowser {
  return { url: undefined };
}

export const OpenBrowser = {
  fromJSON(object: any): OpenBrowser {
    return {
      url: isSet(object.url) ? String(object.url) : undefined,
    };
  },

  toJSON(message: OpenBrowser): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },
};

function createBaseBuyInGameCurrencyPack(): BuyInGameCurrencyPack {
  return { packId: undefined };
}

export const BuyInGameCurrencyPack = {
  fromJSON(object: any): BuyInGameCurrencyPack {
    return {
      packId: isSet(object.pack_id) ? String(object.pack_id) : undefined,
    };
  },

  toJSON(message: BuyInGameCurrencyPack): unknown {
    const obj: any = {};
    message.packId !== undefined && (obj.pack_id = message.packId);
    return obj;
  },
};

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
