import { differenceInMinutes } from 'date-fns';
import styled from 'styled-components';

import { useTickerContext } from '@sorare/core/src/contexts/ticker';
import { TimeLeft } from '@sorare/core/src/contexts/ticker/TimeLeft';

interface Props {
  endDate: Date;
  forceInlineLayout?: boolean;
  withExplicitTime?: boolean;
}

const Layout = styled.span`
  text-align: left;
  white-space: nowrap;
  text-transform: lowercase;
`;

const ExpiringLayout = styled(Layout)`
  color: var(--c-red-800);
`;

export const AuctionTimeLeft = ({
  endDate,
  forceInlineLayout,
  withExplicitTime = true,
}: Props) => {
  const { now } = useTickerContext();

  const expiring = differenceInMinutes(endDate, now) < 2;

  return (
    <TimeLeft
      time={endDate}
      Layout={expiring ? ExpiringLayout : undefined}
      forceInlineLayout={forceInlineLayout}
      withExplicitTime={withExplicitTime && !expiring}
    />
  );
};
