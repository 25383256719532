import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';

import { GridBackground } from '@sorare/gameplay/src/components/rivals/GridBackground';

import { StepLayout } from 'components/rivals/squads/MySquadOnboarding/steps/StepLayout';

import cards from './assets/cards.png';
import challenge from './assets/challenge.png';

const Root = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const Content = styled(Vertical)`
  padding: var(--double-unit);
`;
const Image = styled.img`
  width: 100%;
`;

export const Play = () => {
  const { formatMessage } = useIntl();

  return (
    <Root>
      <GridBackground
        color="#ff0974"
        customGradient="linear-gradient(to bottom, rgba(var(--c-rgb-rivals-arena), 0.4), rgba(var(--c-rgb-black), 0.9))"
        height="100%"
      >
        <StepLayout
          transparentBg
          title={formatMessage({
            id: 'MySquadOnboarding.steps.Play.title',
            defaultMessage: 'Play 1v1',
          })}
          content={
            <Content>
              <Image src={cards} alt="" />
              <Image src={challenge} alt="" />
            </Content>
          }
          footer={formatMessage({
            id: 'MySquadOnboarding.steps.Play.footer',
            defaultMessage:
              'Compete head to head against Rivals around the world',
          })}
        />
      </GridBackground>
    </Root>
  );
};
