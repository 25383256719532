import { ReactNode, Suspense, useCallback, useState } from 'react';

import { BuyTokenConfirmationDialogById } from 'components/buyActions/BuyTokenConfirmationDialogById';
import { BuyPrimaryBuyConfirmationDialogById } from 'components/primaryBuy/BuyPrimaryBuyConfirmationDialogById';

import {
  BuyConfirmationProvider as BuyConfirmationContextProvider,
  BuyConfirmationProviderStateProps,
} from '.';

type Props = {
  children?: ReactNode;
};

const BuyConfirmationDialog = (
  props:
    | (Omit<BuyConfirmationProviderStateProps, 'onClose'> & {
        showBuyingConfirmation: boolean;
        onClose: () => void;
      })
    | null
) => {
  const {
    tokenOfferId,
    primaryBuyId,
    primaryBuyConfirmationOptions,
    onClose,
    showBuyingConfirmation,
  } = props || { onClose: () => {} };

  if (tokenOfferId) {
    return (
      <BuyTokenConfirmationDialogById
        open={!!showBuyingConfirmation}
        tokenOfferId={tokenOfferId}
        onClose={onClose}
      />
    );
  }
  if (primaryBuyId && primaryBuyConfirmationOptions) {
    return (
      <BuyPrimaryBuyConfirmationDialogById
        open={!!showBuyingConfirmation}
        primaryBuyId={primaryBuyId}
        options={primaryBuyConfirmationOptions}
        onClose={onClose}
      />
    );
  }
  return null;
};
export const BuyConfirmationProvider = ({ children }: Props) => {
  const [buyConfirmationProps, setBuyConfirmationProps] =
    useState<BuyConfirmationProviderStateProps | null>(null);

  const [showBuyingConfirmation, setShowBuyingConfirmation] =
    useState<boolean>(false);

  const { onClose: doOnClose, beforeOnClose } = buyConfirmationProps || {};

  const onClose = useCallback(() => {
    beforeOnClose?.();
    setBuyConfirmationProps(null);
    setShowBuyingConfirmation(false);
    doOnClose?.();
  }, [beforeOnClose, doOnClose]);

  return (
    <BuyConfirmationContextProvider
      value={{
        showBuyingConfirmation,
        setBuyConfirmationProps,
        setShowBuyingConfirmation,
      }}
    >
      <Suspense fallback={null}>
        <BuyConfirmationDialog
          {...buyConfirmationProps}
          showBuyingConfirmation={showBuyingConfirmation}
          onClose={onClose}
        />
      </Suspense>
      {children}
    </BuyConfirmationContextProvider>
  );
};
