import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { DisplayS, LabelL, LabelM } from '@sorare/core/src/atoms/typography';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

const Root = styled(Vertical).attrs({ gap: 1 })`
  width: 100%;
  height: 100%;
  position: relative;
  padding: calc(9 * var(--unit)) var(--double-unit);
  justify-content: space-between;

  &:not(.transparentBg) {
    background-color: var(--c-black);
  }

  @media ${tabletAndAbove} {
    overflow: hidden;
  }
`;
const Content = styled(Vertical).attrs({ center: true })`
  flex: 1;
`;
const FooterWrapper = styled.div`
  padding: var(--double-unit);
`;
const Footer = styled(Vertical)`
  text-align: center;
  padding: var(--double-unit) calc(6 * var(--unit));
  background-color: var(--c-black);
  border-radius: var(--double-unit);
`;

type Props = {
  title: string;
  subtitle?: string;
  content: ReactNode;
  footer: string;
  transparentBg?: boolean;
};
export const StepLayout = ({
  title,
  subtitle,
  content,
  footer,
  transparentBg,
}: Props) => {
  return (
    <Root className={classNames({ transparentBg })}>
      <Vertical center className="text-center">
        <DisplayS>{title}</DisplayS>
        {subtitle && <LabelM>{subtitle}</LabelM>}
      </Vertical>
      <Content>{content}</Content>
      <FooterWrapper>
        <Footer>
          <LabelL>{footer}</LabelL>
        </Footer>
      </FooterWrapper>
    </Root>
  );
};
