import { useEffect } from 'react';

import { IS_PRERENDER } from '@sorare/core/src/config';

const loadScript = async (src: string, id?: string) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById('ze-snippet')) {
      resolve(undefined);
      return;
    }

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = src;
    if (id) script.setAttribute('data-id', id);
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

declare global {
  interface Window {
    zESettings: any;
    zE: any;
    quidget: {
      init: () => void;
      destroy: () => void;
    };
  }
}

export const openWidget = () => {
  if (window.zE) window.zE('webWidget', 'open');
};

export const Form = () => {
  useEffect(() => {
    if (IS_PRERENDER) return () => {};

    if (window.quidget?.init) {
      window.quidget.init();
    } else {
      loadScript(
        'https://w.quidget.ai/quidget.loader.js',
        'c8aa4d39-a732-4196-bb29-8bcd29f0c9fb'
      );
    }

    return () => {
      if (window.quidget?.destroy) {
        window.quidget.destroy();
      }
    };
  }, []);

  return null;
};
