import { faCartShopping } from '@fortawesome/pro-solid-svg-icons';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useGenerateDialogPath } from '@sorare/core/src/hooks/navigation/useGenerateDialogPath';
import { useFeatureFlags } from '@sorare/core/src/hooks/useFeatureFlags';

import { useCart } from 'hooks/useCart';

export const NavigationCart = () => {
  const {
    flags: { useBulkBuy },
  } = useFeatureFlags();
  const { cartCount } = useCart();
  const { sport } = useSportContext();
  const generateDialogPath = useGenerateDialogPath();
  const dialogRoute = generateDialogPath(DialogKey.checkout, sport);

  if (!useBulkBuy) {
    return null;
  }

  if (!cartCount) {
    return (
      <IconButton icon={faCartShopping} color="tertiary" to={dialogRoute} />
    );
  }

  return (
    <Button size="medium" color="tertiary" to={dialogRoute}>
      <FontAwesomeIcon icon={faCartShopping} />
      {cartCount}
    </Button>
  );
};
