import { OnboardingStepDialog } from '@sorare/core/src/components/rivals/OnboardingStepDialog';
import { OneTimeDialog } from '@sorare/core/src/contexts/oneTimeDialog/Provider';
import { LIFECYCLE } from '@sorare/core/src/hooks/useLifecycle';

import { EarnRep } from './steps/EarnRep';
import { Play } from './steps/Play';
import { Promotion } from './steps/Promotion';
import { Rewards } from './steps/Rewards';

export const MySquadOnboarding = () => {
  return (
    <OneTimeDialog dialogId={LIFECYCLE.sawMySquadOnboarding}>
      {({ onClose, open }) => (
        <OnboardingStepDialog open={open} onClose={onClose}>
          <Play />
          <EarnRep />
          <Promotion />
          <Rewards />
        </OnboardingStepDialog>
      )}
    </OneTimeDialog>
  );
};
