// Generated by the protocol buffer compiler.
/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import {
  Sport,
  sportFromJSON,
  sportToJSON,
} from '../../../events/shared/events';

export const protobufPackage = 'events.platform.web';

export enum CashWalletStatus {
  PAYER = 0,
  OWNER = 1,
  VALIDATED_OWNER = 2,
  UNRECOGNIZED = -1,
}

export function cashWalletStatusFromJSON(object: any): CashWalletStatus {
  switch (object) {
    case 0:
    case 'PAYER':
      return CashWalletStatus.PAYER;
    case 1:
    case 'OWNER':
      return CashWalletStatus.OWNER;
    case 2:
    case 'VALIDATED_OWNER':
      return CashWalletStatus.VALIDATED_OWNER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CashWalletStatus.UNRECOGNIZED;
  }
}

export function cashWalletStatusToJSON(object: CashWalletStatus): string {
  switch (object) {
    case CashWalletStatus.PAYER:
      return 'PAYER';
    case CashWalletStatus.OWNER:
      return 'OWNER';
    case CashWalletStatus.VALIDATED_OWNER:
      return 'VALIDATED_OWNER';
    default:
      return 'UNKNOWN';
  }
}

export enum Wallet {
  FIAT = 0,
  ETH = 1,
  UNRECOGNIZED = -1,
}

export function walletFromJSON(object: any): Wallet {
  switch (object) {
    case 0:
    case 'FIAT':
      return Wallet.FIAT;
    case 1:
    case 'ETH':
      return Wallet.ETH;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Wallet.UNRECOGNIZED;
  }
}

export function walletToJSON(object: Wallet): string {
  switch (object) {
    case Wallet.FIAT:
      return 'FIAT';
    case Wallet.ETH:
      return 'ETH';
    default:
      return 'UNKNOWN';
  }
}

export enum DepositMethod {
  CREDIT_CARD = 0,
  NEW_CREDIT_CARD = 1,
  PAYPAL = 2,
  IDEAL = 3,
  WIRE = 4,
  APPLE_PAY = 5,
  UNRECOGNIZED = -1,
}

export function depositMethodFromJSON(object: any): DepositMethod {
  switch (object) {
    case 0:
    case 'CREDIT_CARD':
      return DepositMethod.CREDIT_CARD;
    case 1:
    case 'NEW_CREDIT_CARD':
      return DepositMethod.NEW_CREDIT_CARD;
    case 2:
    case 'PAYPAL':
      return DepositMethod.PAYPAL;
    case 3:
    case 'IDEAL':
      return DepositMethod.IDEAL;
    case 4:
    case 'WIRE':
      return DepositMethod.WIRE;
    case 5:
    case 'APPLE_PAY':
      return DepositMethod.APPLE_PAY;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DepositMethod.UNRECOGNIZED;
  }
}

export function depositMethodToJSON(object: DepositMethod): string {
  switch (object) {
    case DepositMethod.CREDIT_CARD:
      return 'CREDIT_CARD';
    case DepositMethod.NEW_CREDIT_CARD:
      return 'NEW_CREDIT_CARD';
    case DepositMethod.PAYPAL:
      return 'PAYPAL';
    case DepositMethod.IDEAL:
      return 'IDEAL';
    case DepositMethod.WIRE:
      return 'WIRE';
    case DepositMethod.APPLE_PAY:
      return 'APPLE_PAY';
    default:
      return 'UNKNOWN';
  }
}

export enum FiatCurrency {
  UNKNOWN_CURRENCY = 0,
  EUR = 1,
  USD = 2,
  GBP = 3,
  UNRECOGNIZED = -1,
}

export function fiatCurrencyFromJSON(object: any): FiatCurrency {
  switch (object) {
    case 0:
    case 'UNKNOWN_CURRENCY':
      return FiatCurrency.UNKNOWN_CURRENCY;
    case 1:
    case 'EUR':
      return FiatCurrency.EUR;
    case 2:
    case 'USD':
      return FiatCurrency.USD;
    case 3:
    case 'GBP':
      return FiatCurrency.GBP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FiatCurrency.UNRECOGNIZED;
  }
}

export function fiatCurrencyToJSON(object: FiatCurrency): string {
  switch (object) {
    case FiatCurrency.UNKNOWN_CURRENCY:
      return 'UNKNOWN_CURRENCY';
    case FiatCurrency.EUR:
      return 'EUR';
    case FiatCurrency.USD:
      return 'USD';
    case FiatCurrency.GBP:
      return 'GBP';
    default:
      return 'UNKNOWN';
  }
}

export interface ReferralCardBought {
  referralId: string;
  sport: Sport;
  /**
   * card_slug should be used
   *
   * @deprecated
   */
  cardSlugs: string[];
  cardSlug: string;
  /**
   * player_slug should be used
   *
   * @deprecated
   */
  playerSlugs: string[];
  playerSlug: string;
  positions: string[];
  season: number;
  teamSlug: string;
  cardCount: number;
  limited: number;
  rare: number;
  superRare: number;
  unique: number;
  auctionId: string;
  primaryOfferId: string;
  count: number;
  ethAmount: number;
  eurAmount: number;
  conversionCreditEthAmount: number;
  conversionCreditEurAmount: number;
  fiatPayment: boolean;
  secondary: boolean;
  footballCardsBought: number;
  baseballCardsBought: number;
  nbaCardsBought: number;
}

export interface ReferralCompleted {
  referralId: string;
  sport: Sport;
  footballCardsBought: number;
  baseballCardsBought: number;
  nbaCardsBought: number;
}

export interface ReferralRewardSent {
  referralId: string;
  sport: Sport;
  cardSlug: string;
  playerSlug: string;
  positions: string[];
  scarcity: string;
  season: number;
  serialNumber: number;
  teamSlug: string;
  position: string;
  conversionCreditEurAmount: number;
  conversionCreditPercentageDiscount: number;
  conversionCreditDuration: number;
}

export interface ClickListAgain {
  cardSlug: string;
}

export interface UserFlagToggled {
  flag: string;
}

export interface ClickTrade {
  interactionContext: string;
  offerId: string;
}

export interface ChooseSignupSport {
  sport: Sport;
}

export interface ChooseSignupManagerName {}

/** manager name invalid or already taken */
export interface FailSignupManagerName {}

export interface ClickSignupByEmail {}

/** invalid email or password */
export interface FailSignupByEmail {}

export interface ClickSignupByOAuth {
  provider: string;
}

export interface SubmitSignUpForm {
  interactionContext: string;
}

export interface ClearMarketFilters {
  interactionContext: string;
  sport: Sport;
  filtersCount: number;
}

export interface RemoveMarketFilterChip {
  interactionContext: string;
  sport: Sport;
  filterName: string;
  filterValue: string;
}

export interface SetDefaultMarketFilters {
  interactionContext: string;
  sport: Sport;
  filters: { [key: string]: string };
}

export interface SetDefaultMarketFilters_FiltersEntry {
  key: string;
  value: string;
}

export interface ClearDefaultMarketFilters {
  interactionContext: string;
  sport: Sport;
}

export interface ConfirmPurchaseForm {
  interactionContext: string;
  ethAmount: number;
  eurAmount: number;
  fiatPayment: boolean;
  sport: Sport;
  offerId: string;
  isV2?: boolean | undefined;
}

export interface ClickAnnouncementLink {
  announcementId: string;
  announcementTitle: string;
  linkHref: string;
}

export interface CreateSavedSearch {
  name: string;
  filters: { [key: string]: string };
  interactionContext: string;
  sport: Sport;
}

export interface CreateSavedSearch_FiltersEntry {
  key: string;
  value: string;
}

export interface SelectSavedSearch {
  name: string;
  filters: { [key: string]: string };
  interactionContext: string;
  sport: Sport;
}

export interface SelectSavedSearch_FiltersEntry {
  key: string;
  value: string;
}

export interface DeleteSavedSearch {
  name: string;
  filters: { [key: string]: string };
  interactionContext: string;
  sport: Sport;
}

export interface DeleteSavedSearch_FiltersEntry {
  key: string;
  value: string;
}

export interface SwitchStackedView {
  stacked: boolean;
  sport: Sport;
}

export interface ClickStack {
  playerSlug: string;
  scarcity: string;
  season: number;
  teamSlug: string;
  interactionContext: string;
  sport: Sport;
}

export interface ClickMarketTrend {
  timeframe: string;
  trend: string;
  slug: string;
  interactionContext: string;
  sport: Sport;
}

export interface LoadMarketTrends {
  timeframe: string;
  sales: string[];
  onTheRise: string[];
  volumes: string[];
  interactionContext: string;
  sport: Sport;
}

export interface UserCategoryUpdated {
  category: string;
}

export interface KycDocumentSubmitted {
  country: string;
  documentType: string;
}

export interface KycDocumentRefused {
  country: string;
  documentType: string;
  reasonType: string;
}

export interface FailCardRegistration {
  cardType: string;
  interactionContext: string;
  sport: Sport;
}

export interface ClickReferralBanner {
  interactionContext: string;
  sport: Sport;
}

export interface CampaignPageVisited {
  url: string;
  fingerprint: string;
  identifierType: string;
}

export interface ClickScoutCards {
  interactionContext: string;
  sport: Sport;
  playerSlug: string;
}

export interface ClickSeeAllAvailableCards {
  interactionContext: string;
  sport: Sport;
  eventName: string;
  eventType: string;
}

export interface ClickSeeOtherListingsFromEndedAuction {}

export interface ViewBlogPost {
  slug: string;
}

export interface ReadBlogPost {
  slug: string;
}

export interface ClickHelp {
  interactionContext: string;
  sport: Sport;
}

export interface ViewHelpCenterArticle {
  id: string;
  locale: string;
  title: string;
}

export interface ClickOpenMobileApp {
  interactionContext: string;
  sport: Sport;
  platform: string;
}

export interface ClosePromoteMobileApp {
  interactionContext: string;
  sport: Sport;
  platform: string;
}

export interface ClickManagerProgressionCategory {
  interactionContext: string;
  sport: Sport;
  categoryName: string;
  claimableTasks: number;
}

export interface ClickManagerProgressionTask {
  interactionContext: string;
  sport: Sport;
  taskName: string;
  categoryName: string;
}

export interface EnterCashWalletKYCFlow {
  interactionContext: string;
  sport: Sport;
  source: EnterCashWalletKYCFlow_Source;
  target: EnterCashWalletKYCFlow_Target;
  cashWalletStatus: CashWalletStatus;
  fiatCurrency: string;
}

export enum EnterCashWalletKYCFlow_Source {
  WALLET_CTA = 0,
  WALLET_DEPOSIT_HELPER = 1,
  WALLET_WITHDRAW_HELPER = 2,
  WALLET_FIAT_ONLY_ADD_FUNDS_CTA = 4,
  WALLET_FIAT_ONLY_WITHDRAW_CTA = 5,
  SETTINGS_CASH_AND_ETH_RECEIVED_PAYMENT_HELPER = 6,
  SETTINGS_CASH_ONLY_RECEIVED_PAYMENT_HELPER = 7,
  SETTINGS_CASH_COMPETITION_REWARDS_HELPER = 8,
  LIST_A_CARD = 9,
  FIRST_CASH_REWARD = 10,
  MIGRATION_MODAL = 11,
  REJECTED_KYC_NOTIFICATION = 12,
  REJECTED_KYC_EMAIL = 13,
  ACCEPT_TRADE_OFFER = 14,
  COUNTEROFFER_TRADE_OFFER = 15,
  CUSTOM_LEAGUE_ONBOARDING = 16,
  UNRECOGNIZED = -1,
}

export function enterCashWalletKYCFlow_SourceFromJSON(
  object: any
): EnterCashWalletKYCFlow_Source {
  switch (object) {
    case 0:
    case 'WALLET_CTA':
      return EnterCashWalletKYCFlow_Source.WALLET_CTA;
    case 1:
    case 'WALLET_DEPOSIT_HELPER':
      return EnterCashWalletKYCFlow_Source.WALLET_DEPOSIT_HELPER;
    case 2:
    case 'WALLET_WITHDRAW_HELPER':
      return EnterCashWalletKYCFlow_Source.WALLET_WITHDRAW_HELPER;
    case 4:
    case 'WALLET_FIAT_ONLY_ADD_FUNDS_CTA':
      return EnterCashWalletKYCFlow_Source.WALLET_FIAT_ONLY_ADD_FUNDS_CTA;
    case 5:
    case 'WALLET_FIAT_ONLY_WITHDRAW_CTA':
      return EnterCashWalletKYCFlow_Source.WALLET_FIAT_ONLY_WITHDRAW_CTA;
    case 6:
    case 'SETTINGS_CASH_AND_ETH_RECEIVED_PAYMENT_HELPER':
      return EnterCashWalletKYCFlow_Source.SETTINGS_CASH_AND_ETH_RECEIVED_PAYMENT_HELPER;
    case 7:
    case 'SETTINGS_CASH_ONLY_RECEIVED_PAYMENT_HELPER':
      return EnterCashWalletKYCFlow_Source.SETTINGS_CASH_ONLY_RECEIVED_PAYMENT_HELPER;
    case 8:
    case 'SETTINGS_CASH_COMPETITION_REWARDS_HELPER':
      return EnterCashWalletKYCFlow_Source.SETTINGS_CASH_COMPETITION_REWARDS_HELPER;
    case 9:
    case 'LIST_A_CARD':
      return EnterCashWalletKYCFlow_Source.LIST_A_CARD;
    case 10:
    case 'FIRST_CASH_REWARD':
      return EnterCashWalletKYCFlow_Source.FIRST_CASH_REWARD;
    case 11:
    case 'MIGRATION_MODAL':
      return EnterCashWalletKYCFlow_Source.MIGRATION_MODAL;
    case 12:
    case 'REJECTED_KYC_NOTIFICATION':
      return EnterCashWalletKYCFlow_Source.REJECTED_KYC_NOTIFICATION;
    case 13:
    case 'REJECTED_KYC_EMAIL':
      return EnterCashWalletKYCFlow_Source.REJECTED_KYC_EMAIL;
    case 14:
    case 'ACCEPT_TRADE_OFFER':
      return EnterCashWalletKYCFlow_Source.ACCEPT_TRADE_OFFER;
    case 15:
    case 'COUNTEROFFER_TRADE_OFFER':
      return EnterCashWalletKYCFlow_Source.COUNTEROFFER_TRADE_OFFER;
    case 16:
    case 'CUSTOM_LEAGUE_ONBOARDING':
      return EnterCashWalletKYCFlow_Source.CUSTOM_LEAGUE_ONBOARDING;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return EnterCashWalletKYCFlow_Source.UNRECOGNIZED;
  }
}

export function enterCashWalletKYCFlow_SourceToJSON(
  object: EnterCashWalletKYCFlow_Source
): string {
  switch (object) {
    case EnterCashWalletKYCFlow_Source.WALLET_CTA:
      return 'WALLET_CTA';
    case EnterCashWalletKYCFlow_Source.WALLET_DEPOSIT_HELPER:
      return 'WALLET_DEPOSIT_HELPER';
    case EnterCashWalletKYCFlow_Source.WALLET_WITHDRAW_HELPER:
      return 'WALLET_WITHDRAW_HELPER';
    case EnterCashWalletKYCFlow_Source.WALLET_FIAT_ONLY_ADD_FUNDS_CTA:
      return 'WALLET_FIAT_ONLY_ADD_FUNDS_CTA';
    case EnterCashWalletKYCFlow_Source.WALLET_FIAT_ONLY_WITHDRAW_CTA:
      return 'WALLET_FIAT_ONLY_WITHDRAW_CTA';
    case EnterCashWalletKYCFlow_Source.SETTINGS_CASH_AND_ETH_RECEIVED_PAYMENT_HELPER:
      return 'SETTINGS_CASH_AND_ETH_RECEIVED_PAYMENT_HELPER';
    case EnterCashWalletKYCFlow_Source.SETTINGS_CASH_ONLY_RECEIVED_PAYMENT_HELPER:
      return 'SETTINGS_CASH_ONLY_RECEIVED_PAYMENT_HELPER';
    case EnterCashWalletKYCFlow_Source.SETTINGS_CASH_COMPETITION_REWARDS_HELPER:
      return 'SETTINGS_CASH_COMPETITION_REWARDS_HELPER';
    case EnterCashWalletKYCFlow_Source.LIST_A_CARD:
      return 'LIST_A_CARD';
    case EnterCashWalletKYCFlow_Source.FIRST_CASH_REWARD:
      return 'FIRST_CASH_REWARD';
    case EnterCashWalletKYCFlow_Source.MIGRATION_MODAL:
      return 'MIGRATION_MODAL';
    case EnterCashWalletKYCFlow_Source.REJECTED_KYC_NOTIFICATION:
      return 'REJECTED_KYC_NOTIFICATION';
    case EnterCashWalletKYCFlow_Source.REJECTED_KYC_EMAIL:
      return 'REJECTED_KYC_EMAIL';
    case EnterCashWalletKYCFlow_Source.ACCEPT_TRADE_OFFER:
      return 'ACCEPT_TRADE_OFFER';
    case EnterCashWalletKYCFlow_Source.COUNTEROFFER_TRADE_OFFER:
      return 'COUNTEROFFER_TRADE_OFFER';
    case EnterCashWalletKYCFlow_Source.CUSTOM_LEAGUE_ONBOARDING:
      return 'CUSTOM_LEAGUE_ONBOARDING';
    default:
      return 'UNKNOWN';
  }
}

export enum EnterCashWalletKYCFlow_Target {
  OWNER = 0,
  VALIDATED_OWNER = 1,
  OPTIONAL_VALIDATED_OWNER = 2,
  UNRECOGNIZED = -1,
}

export function enterCashWalletKYCFlow_TargetFromJSON(
  object: any
): EnterCashWalletKYCFlow_Target {
  switch (object) {
    case 0:
    case 'OWNER':
      return EnterCashWalletKYCFlow_Target.OWNER;
    case 1:
    case 'VALIDATED_OWNER':
      return EnterCashWalletKYCFlow_Target.VALIDATED_OWNER;
    case 2:
    case 'OPTIONAL_VALIDATED_OWNER':
      return EnterCashWalletKYCFlow_Target.OPTIONAL_VALIDATED_OWNER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return EnterCashWalletKYCFlow_Target.UNRECOGNIZED;
  }
}

export function enterCashWalletKYCFlow_TargetToJSON(
  object: EnterCashWalletKYCFlow_Target
): string {
  switch (object) {
    case EnterCashWalletKYCFlow_Target.OWNER:
      return 'OWNER';
    case EnterCashWalletKYCFlow_Target.VALIDATED_OWNER:
      return 'VALIDATED_OWNER';
    case EnterCashWalletKYCFlow_Target.OPTIONAL_VALIDATED_OWNER:
      return 'OPTIONAL_VALIDATED_OWNER';
    default:
      return 'UNKNOWN';
  }
}

export interface ExitCashWalletKYCFlow {
  interactionContext: string;
  sport: Sport;
  kycExitStep: ExitCashWalletKYCFlow_ExitStep;
  cashWalletStatus: CashWalletStatus;
  fiatCurrency: string;
}

export enum ExitCashWalletKYCFlow_ExitStep {
  ACTIVATE_YOUR_CASH_WALLET = 0,
  WHATS_NEW = 1,
  INTRO = 2,
  TELL_US_ABOUT_YOU = 3,
  CHOOSE_CURRENCY = 4,
  ACTIVATION_SUCCESS = 5,
  CHOOSE_DOCUMENT = 6,
  UPLOAD = 7,
  HANDLE_ID_REVIEW_ERROR = 8,
  DOCUMENT_UNDER_REVIEW = 9,
  REVIEW_INFO_BEFORE_ADDING_ID = 10,
  REVIEW_INFORMATION = 11,
  UNRECOGNIZED = -1,
}

export function exitCashWalletKYCFlow_ExitStepFromJSON(
  object: any
): ExitCashWalletKYCFlow_ExitStep {
  switch (object) {
    case 0:
    case 'ACTIVATE_YOUR_CASH_WALLET':
      return ExitCashWalletKYCFlow_ExitStep.ACTIVATE_YOUR_CASH_WALLET;
    case 1:
    case 'WHATS_NEW':
      return ExitCashWalletKYCFlow_ExitStep.WHATS_NEW;
    case 2:
    case 'INTRO':
      return ExitCashWalletKYCFlow_ExitStep.INTRO;
    case 3:
    case 'TELL_US_ABOUT_YOU':
      return ExitCashWalletKYCFlow_ExitStep.TELL_US_ABOUT_YOU;
    case 4:
    case 'CHOOSE_CURRENCY':
      return ExitCashWalletKYCFlow_ExitStep.CHOOSE_CURRENCY;
    case 5:
    case 'ACTIVATION_SUCCESS':
      return ExitCashWalletKYCFlow_ExitStep.ACTIVATION_SUCCESS;
    case 6:
    case 'CHOOSE_DOCUMENT':
      return ExitCashWalletKYCFlow_ExitStep.CHOOSE_DOCUMENT;
    case 7:
    case 'UPLOAD':
      return ExitCashWalletKYCFlow_ExitStep.UPLOAD;
    case 8:
    case 'HANDLE_ID_REVIEW_ERROR':
      return ExitCashWalletKYCFlow_ExitStep.HANDLE_ID_REVIEW_ERROR;
    case 9:
    case 'DOCUMENT_UNDER_REVIEW':
      return ExitCashWalletKYCFlow_ExitStep.DOCUMENT_UNDER_REVIEW;
    case 10:
    case 'REVIEW_INFO_BEFORE_ADDING_ID':
      return ExitCashWalletKYCFlow_ExitStep.REVIEW_INFO_BEFORE_ADDING_ID;
    case 11:
    case 'REVIEW_INFORMATION':
      return ExitCashWalletKYCFlow_ExitStep.REVIEW_INFORMATION;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ExitCashWalletKYCFlow_ExitStep.UNRECOGNIZED;
  }
}

export function exitCashWalletKYCFlow_ExitStepToJSON(
  object: ExitCashWalletKYCFlow_ExitStep
): string {
  switch (object) {
    case ExitCashWalletKYCFlow_ExitStep.ACTIVATE_YOUR_CASH_WALLET:
      return 'ACTIVATE_YOUR_CASH_WALLET';
    case ExitCashWalletKYCFlow_ExitStep.WHATS_NEW:
      return 'WHATS_NEW';
    case ExitCashWalletKYCFlow_ExitStep.INTRO:
      return 'INTRO';
    case ExitCashWalletKYCFlow_ExitStep.TELL_US_ABOUT_YOU:
      return 'TELL_US_ABOUT_YOU';
    case ExitCashWalletKYCFlow_ExitStep.CHOOSE_CURRENCY:
      return 'CHOOSE_CURRENCY';
    case ExitCashWalletKYCFlow_ExitStep.ACTIVATION_SUCCESS:
      return 'ACTIVATION_SUCCESS';
    case ExitCashWalletKYCFlow_ExitStep.CHOOSE_DOCUMENT:
      return 'CHOOSE_DOCUMENT';
    case ExitCashWalletKYCFlow_ExitStep.UPLOAD:
      return 'UPLOAD';
    case ExitCashWalletKYCFlow_ExitStep.HANDLE_ID_REVIEW_ERROR:
      return 'HANDLE_ID_REVIEW_ERROR';
    case ExitCashWalletKYCFlow_ExitStep.DOCUMENT_UNDER_REVIEW:
      return 'DOCUMENT_UNDER_REVIEW';
    case ExitCashWalletKYCFlow_ExitStep.REVIEW_INFO_BEFORE_ADDING_ID:
      return 'REVIEW_INFO_BEFORE_ADDING_ID';
    case ExitCashWalletKYCFlow_ExitStep.REVIEW_INFORMATION:
      return 'REVIEW_INFORMATION';
    default:
      return 'UNKNOWN';
  }
}

export interface ClickMarketTrendsLink {
  interactionContext: string;
  sport: Sport;
  tileType: ClickMarketTrendsLink_TileType;
  playerSlug?: string | undefined;
  scarcity?: string | undefined;
  serialNumber?: number | undefined;
  gameWeek?: number | undefined;
  heuristic: ClickMarketTrendsLink_ForYouHeuristic;
  so5LeagueSlug?: string | undefined;
  userSlug?: string | undefined;
}

export enum ClickMarketTrendsLink_TileType {
  CARD = 0,
  PLAYER = 1,
  TITLE = 2,
  UNRECOGNIZED = -1,
}

export function clickMarketTrendsLink_TileTypeFromJSON(
  object: any
): ClickMarketTrendsLink_TileType {
  switch (object) {
    case 0:
    case 'CARD':
      return ClickMarketTrendsLink_TileType.CARD;
    case 1:
    case 'PLAYER':
      return ClickMarketTrendsLink_TileType.PLAYER;
    case 2:
    case 'TITLE':
      return ClickMarketTrendsLink_TileType.TITLE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ClickMarketTrendsLink_TileType.UNRECOGNIZED;
  }
}

export function clickMarketTrendsLink_TileTypeToJSON(
  object: ClickMarketTrendsLink_TileType
): string {
  switch (object) {
    case ClickMarketTrendsLink_TileType.CARD:
      return 'CARD';
    case ClickMarketTrendsLink_TileType.PLAYER:
      return 'PLAYER';
    case ClickMarketTrendsLink_TileType.TITLE:
      return 'TITLE';
    default:
      return 'UNKNOWN';
  }
}

export enum ClickMarketTrendsLink_ForYouHeuristic {
  HOT_STREAK = 0,
  POPULAR = 1,
  FRIENDS_RECENT_PURCHASES = 2,
  WINNING_LINEUP = 3,
  INCOMPLETE_CARD_COLLECTION = 4,
  UNAVAILABLE_PLAYERS_SUBSTITUES = 5,
  TOP_PLAYERS = 6,
  UNRECOGNIZED = -1,
}

export function clickMarketTrendsLink_ForYouHeuristicFromJSON(
  object: any
): ClickMarketTrendsLink_ForYouHeuristic {
  switch (object) {
    case 0:
    case 'HOT_STREAK':
      return ClickMarketTrendsLink_ForYouHeuristic.HOT_STREAK;
    case 1:
    case 'POPULAR':
      return ClickMarketTrendsLink_ForYouHeuristic.POPULAR;
    case 2:
    case 'FRIENDS_RECENT_PURCHASES':
      return ClickMarketTrendsLink_ForYouHeuristic.FRIENDS_RECENT_PURCHASES;
    case 3:
    case 'WINNING_LINEUP':
      return ClickMarketTrendsLink_ForYouHeuristic.WINNING_LINEUP;
    case 4:
    case 'INCOMPLETE_CARD_COLLECTION':
      return ClickMarketTrendsLink_ForYouHeuristic.INCOMPLETE_CARD_COLLECTION;
    case 5:
    case 'UNAVAILABLE_PLAYERS_SUBSTITUES':
      return ClickMarketTrendsLink_ForYouHeuristic.UNAVAILABLE_PLAYERS_SUBSTITUES;
    case 6:
    case 'TOP_PLAYERS':
      return ClickMarketTrendsLink_ForYouHeuristic.TOP_PLAYERS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ClickMarketTrendsLink_ForYouHeuristic.UNRECOGNIZED;
  }
}

export function clickMarketTrendsLink_ForYouHeuristicToJSON(
  object: ClickMarketTrendsLink_ForYouHeuristic
): string {
  switch (object) {
    case ClickMarketTrendsLink_ForYouHeuristic.HOT_STREAK:
      return 'HOT_STREAK';
    case ClickMarketTrendsLink_ForYouHeuristic.POPULAR:
      return 'POPULAR';
    case ClickMarketTrendsLink_ForYouHeuristic.FRIENDS_RECENT_PURCHASES:
      return 'FRIENDS_RECENT_PURCHASES';
    case ClickMarketTrendsLink_ForYouHeuristic.WINNING_LINEUP:
      return 'WINNING_LINEUP';
    case ClickMarketTrendsLink_ForYouHeuristic.INCOMPLETE_CARD_COLLECTION:
      return 'INCOMPLETE_CARD_COLLECTION';
    case ClickMarketTrendsLink_ForYouHeuristic.UNAVAILABLE_PLAYERS_SUBSTITUES:
      return 'UNAVAILABLE_PLAYERS_SUBSTITUES';
    case ClickMarketTrendsLink_ForYouHeuristic.TOP_PLAYERS:
      return 'TOP_PLAYERS';
    default:
      return 'UNKNOWN';
  }
}

export interface ClickAddMoney {
  wallet: Wallet;
  currency?: FiatCurrency | undefined;
}

export interface SelectDepositMethod {
  method: DepositMethod;
}

export interface ClickReviewDeposit {
  method: DepositMethod;
  amount: number;
  currency: FiatCurrency;
  baseAmount?: number | undefined;
}

export interface ViewAddFundsToFiatWallet {
  currency: FiatCurrency;
  baseAmount?: number | undefined;
}

export interface ClickConfirmDesposit {
  method: DepositMethod;
  amount: number;
  currency: FiatCurrency;
}

export interface Display3ds {}

export interface ClickPaypalDepositFromPayment {
  canDeposit: boolean;
}

export interface SendDirectOffer {
  offerId: string;
}

export interface AcceptDirectOffer {
  offerId: string;
}

export interface ShowEnterPhoneNumber {}

export interface SubmitPhoneNumber {
  resend?: boolean | undefined;
}

export interface ShowEnterVerificationCode {}

export interface SubmitPhoneVerificationCode {}

export interface ClickLobbyLiveOpsTile {
  tileId: string;
  tileTitle: string;
  tilePosition: number;
}

export interface EnterFromAndroidMarketplaceTab {
  interactionContext: string;
  sport: Sport;
  appBuildVersion: string;
  userSlug?: string | undefined;
}

export interface ClickHomepageCryptoWalletIcon {}

export interface ClickHomepageCryptoWalletProvider {
  provider: string;
}

export interface ClickNotification {
  name: string;
}

export interface ClickGemPackage {
  eurAmount: number;
  gemAmount: number;
}

export interface ClickBuyPack {
  packSlug: string;
}

export interface OpenBuyGemsDialog {
  context: OpenBuyGemsDialog_Context;
}

export enum OpenBuyGemsDialog_Context {
  GEMS_BALANCE = 0,
  PACK_DIALOG = 1,
  UNRECOGNIZED = -1,
}

export function openBuyGemsDialog_ContextFromJSON(
  object: any
): OpenBuyGemsDialog_Context {
  switch (object) {
    case 0:
    case 'GEMS_BALANCE':
      return OpenBuyGemsDialog_Context.GEMS_BALANCE;
    case 1:
    case 'PACK_DIALOG':
      return OpenBuyGemsDialog_Context.PACK_DIALOG;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OpenBuyGemsDialog_Context.UNRECOGNIZED;
  }
}

export function openBuyGemsDialog_ContextToJSON(
  object: OpenBuyGemsDialog_Context
): string {
  switch (object) {
    case OpenBuyGemsDialog_Context.GEMS_BALANCE:
      return 'GEMS_BALANCE';
    case OpenBuyGemsDialog_Context.PACK_DIALOG:
      return 'PACK_DIALOG';
    default:
      return 'UNKNOWN';
  }
}

export interface ClickNextInDiscountScreen {}

export interface ClickViewPlayersInDiscountScreen {}

export interface ClickSkipInDiscountScreen {}

export interface ClickScoutInDiscountScreen {}

function createBaseReferralCardBought(): ReferralCardBought {
  return {
    referralId: '',
    sport: 0,
    cardSlugs: [],
    cardSlug: '',
    playerSlugs: [],
    playerSlug: '',
    positions: [],
    season: 0,
    teamSlug: '',
    cardCount: 0,
    limited: 0,
    rare: 0,
    superRare: 0,
    unique: 0,
    auctionId: '',
    primaryOfferId: '',
    count: 0,
    ethAmount: 0,
    eurAmount: 0,
    conversionCreditEthAmount: 0,
    conversionCreditEurAmount: 0,
    fiatPayment: false,
    secondary: false,
    footballCardsBought: 0,
    baseballCardsBought: 0,
    nbaCardsBought: 0,
  };
}

export const ReferralCardBought = {
  fromJSON(object: any): ReferralCardBought {
    return {
      referralId: isSet(object.referral_id) ? String(object.referral_id) : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      cardSlugs: Array.isArray(object?.card_slugs)
        ? object.card_slugs.map((e: any) => String(e))
        : [],
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      playerSlugs: Array.isArray(object?.player_slugs)
        ? object.player_slugs.map((e: any) => String(e))
        : [],
      playerSlug: isSet(object.player_slug) ? String(object.player_slug) : '',
      positions: Array.isArray(object?.positions)
        ? object.positions.map((e: any) => String(e))
        : [],
      season: isSet(object.season) ? Number(object.season) : 0,
      teamSlug: isSet(object.team_slug) ? String(object.team_slug) : '',
      cardCount: isSet(object.card_count) ? Number(object.card_count) : 0,
      limited: isSet(object.limited) ? Number(object.limited) : 0,
      rare: isSet(object.rare) ? Number(object.rare) : 0,
      superRare: isSet(object.super_rare) ? Number(object.super_rare) : 0,
      unique: isSet(object.unique) ? Number(object.unique) : 0,
      auctionId: isSet(object.auction_id) ? String(object.auction_id) : '',
      primaryOfferId: isSet(object.primary_offer_id)
        ? String(object.primary_offer_id)
        : '',
      count: isSet(object.count) ? Number(object.count) : 0,
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      conversionCreditEthAmount: isSet(object.conversion_credit_eth_amount)
        ? Number(object.conversion_credit_eth_amount)
        : 0,
      conversionCreditEurAmount: isSet(object.conversion_credit_eur_amount)
        ? Number(object.conversion_credit_eur_amount)
        : 0,
      fiatPayment: isSet(object.fiat_payment)
        ? Boolean(object.fiat_payment)
        : false,
      secondary: isSet(object.secondary) ? Boolean(object.secondary) : false,
      footballCardsBought: isSet(object.football_cards_bought)
        ? Number(object.football_cards_bought)
        : 0,
      baseballCardsBought: isSet(object.baseball_cards_bought)
        ? Number(object.baseball_cards_bought)
        : 0,
      nbaCardsBought: isSet(object.nba_cards_bought)
        ? Number(object.nba_cards_bought)
        : 0,
    };
  },

  toJSON(message: ReferralCardBought): unknown {
    const obj: any = {};
    message.referralId !== undefined && (obj.referral_id = message.referralId);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    if (message.cardSlugs) {
      obj.card_slugs = message.cardSlugs.map(e => e);
    } else {
      obj.card_slugs = [];
    }
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    if (message.playerSlugs) {
      obj.player_slugs = message.playerSlugs.map(e => e);
    } else {
      obj.player_slugs = [];
    }
    message.playerSlug !== undefined && (obj.player_slug = message.playerSlug);
    if (message.positions) {
      obj.positions = message.positions.map(e => e);
    } else {
      obj.positions = [];
    }
    message.season !== undefined && (obj.season = Math.round(message.season));
    message.teamSlug !== undefined && (obj.team_slug = message.teamSlug);
    message.cardCount !== undefined &&
      (obj.card_count = Math.round(message.cardCount));
    message.limited !== undefined &&
      (obj.limited = Math.round(message.limited));
    message.rare !== undefined && (obj.rare = Math.round(message.rare));
    message.superRare !== undefined &&
      (obj.super_rare = Math.round(message.superRare));
    message.unique !== undefined && (obj.unique = Math.round(message.unique));
    message.auctionId !== undefined && (obj.auction_id = message.auctionId);
    message.primaryOfferId !== undefined &&
      (obj.primary_offer_id = message.primaryOfferId);
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.conversionCreditEthAmount !== undefined &&
      (obj.conversion_credit_eth_amount = message.conversionCreditEthAmount);
    message.conversionCreditEurAmount !== undefined &&
      (obj.conversion_credit_eur_amount = message.conversionCreditEurAmount);
    message.fiatPayment !== undefined &&
      (obj.fiat_payment = message.fiatPayment);
    message.secondary !== undefined && (obj.secondary = message.secondary);
    message.footballCardsBought !== undefined &&
      (obj.football_cards_bought = Math.round(message.footballCardsBought));
    message.baseballCardsBought !== undefined &&
      (obj.baseball_cards_bought = Math.round(message.baseballCardsBought));
    message.nbaCardsBought !== undefined &&
      (obj.nba_cards_bought = Math.round(message.nbaCardsBought));
    return obj;
  },
};

function createBaseReferralCompleted(): ReferralCompleted {
  return {
    referralId: '',
    sport: 0,
    footballCardsBought: 0,
    baseballCardsBought: 0,
    nbaCardsBought: 0,
  };
}

export const ReferralCompleted = {
  fromJSON(object: any): ReferralCompleted {
    return {
      referralId: isSet(object.referral_id) ? String(object.referral_id) : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      footballCardsBought: isSet(object.football_cards_bought)
        ? Number(object.football_cards_bought)
        : 0,
      baseballCardsBought: isSet(object.baseball_cards_bought)
        ? Number(object.baseball_cards_bought)
        : 0,
      nbaCardsBought: isSet(object.nba_cards_bought)
        ? Number(object.nba_cards_bought)
        : 0,
    };
  },

  toJSON(message: ReferralCompleted): unknown {
    const obj: any = {};
    message.referralId !== undefined && (obj.referral_id = message.referralId);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.footballCardsBought !== undefined &&
      (obj.football_cards_bought = Math.round(message.footballCardsBought));
    message.baseballCardsBought !== undefined &&
      (obj.baseball_cards_bought = Math.round(message.baseballCardsBought));
    message.nbaCardsBought !== undefined &&
      (obj.nba_cards_bought = Math.round(message.nbaCardsBought));
    return obj;
  },
};

function createBaseReferralRewardSent(): ReferralRewardSent {
  return {
    referralId: '',
    sport: 0,
    cardSlug: '',
    playerSlug: '',
    positions: [],
    scarcity: '',
    season: 0,
    serialNumber: 0,
    teamSlug: '',
    position: '',
    conversionCreditEurAmount: 0,
    conversionCreditPercentageDiscount: 0,
    conversionCreditDuration: 0,
  };
}

export const ReferralRewardSent = {
  fromJSON(object: any): ReferralRewardSent {
    return {
      referralId: isSet(object.referral_id) ? String(object.referral_id) : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
      playerSlug: isSet(object.player_slug) ? String(object.player_slug) : '',
      positions: Array.isArray(object?.positions)
        ? object.positions.map((e: any) => String(e))
        : [],
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : '',
      season: isSet(object.season) ? Number(object.season) : 0,
      serialNumber: isSet(object.serial_number)
        ? Number(object.serial_number)
        : 0,
      teamSlug: isSet(object.team_slug) ? String(object.team_slug) : '',
      position: isSet(object.position) ? String(object.position) : '',
      conversionCreditEurAmount: isSet(object.conversion_credit_eur_amount)
        ? Number(object.conversion_credit_eur_amount)
        : 0,
      conversionCreditPercentageDiscount: isSet(
        object.conversion_credit_percentage_discount
      )
        ? Number(object.conversion_credit_percentage_discount)
        : 0,
      conversionCreditDuration: isSet(object.conversion_credit_duration)
        ? Number(object.conversion_credit_duration)
        : 0,
    };
  },

  toJSON(message: ReferralRewardSent): unknown {
    const obj: any = {};
    message.referralId !== undefined && (obj.referral_id = message.referralId);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    message.playerSlug !== undefined && (obj.player_slug = message.playerSlug);
    if (message.positions) {
      obj.positions = message.positions.map(e => e);
    } else {
      obj.positions = [];
    }
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.season !== undefined && (obj.season = Math.round(message.season));
    message.serialNumber !== undefined &&
      (obj.serial_number = Math.round(message.serialNumber));
    message.teamSlug !== undefined && (obj.team_slug = message.teamSlug);
    message.position !== undefined && (obj.position = message.position);
    message.conversionCreditEurAmount !== undefined &&
      (obj.conversion_credit_eur_amount = Math.round(
        message.conversionCreditEurAmount
      ));
    message.conversionCreditPercentageDiscount !== undefined &&
      (obj.conversion_credit_percentage_discount = Math.round(
        message.conversionCreditPercentageDiscount
      ));
    message.conversionCreditDuration !== undefined &&
      (obj.conversion_credit_duration = Math.round(
        message.conversionCreditDuration
      ));
    return obj;
  },
};

function createBaseClickListAgain(): ClickListAgain {
  return { cardSlug: '' };
}

export const ClickListAgain = {
  fromJSON(object: any): ClickListAgain {
    return {
      cardSlug: isSet(object.card_slug) ? String(object.card_slug) : '',
    };
  },

  toJSON(message: ClickListAgain): unknown {
    const obj: any = {};
    message.cardSlug !== undefined && (obj.card_slug = message.cardSlug);
    return obj;
  },
};

function createBaseUserFlagToggled(): UserFlagToggled {
  return { flag: '' };
}

export const UserFlagToggled = {
  fromJSON(object: any): UserFlagToggled {
    return {
      flag: isSet(object.flag) ? String(object.flag) : '',
    };
  },

  toJSON(message: UserFlagToggled): unknown {
    const obj: any = {};
    message.flag !== undefined && (obj.flag = message.flag);
    return obj;
  },
};

function createBaseClickTrade(): ClickTrade {
  return { interactionContext: '', offerId: '' };
}

export const ClickTrade = {
  fromJSON(object: any): ClickTrade {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      offerId: isSet(object.offer_id) ? String(object.offer_id) : '',
    };
  },

  toJSON(message: ClickTrade): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.offerId !== undefined && (obj.offer_id = message.offerId);
    return obj;
  },
};

function createBaseChooseSignupSport(): ChooseSignupSport {
  return { sport: 0 };
}

export const ChooseSignupSport = {
  fromJSON(object: any): ChooseSignupSport {
    return {
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ChooseSignupSport): unknown {
    const obj: any = {};
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseChooseSignupManagerName(): ChooseSignupManagerName {
  return {};
}

export const ChooseSignupManagerName = {
  fromJSON(_: any): ChooseSignupManagerName {
    return {};
  },

  toJSON(_: ChooseSignupManagerName): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseFailSignupManagerName(): FailSignupManagerName {
  return {};
}

export const FailSignupManagerName = {
  fromJSON(_: any): FailSignupManagerName {
    return {};
  },

  toJSON(_: FailSignupManagerName): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickSignupByEmail(): ClickSignupByEmail {
  return {};
}

export const ClickSignupByEmail = {
  fromJSON(_: any): ClickSignupByEmail {
    return {};
  },

  toJSON(_: ClickSignupByEmail): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseFailSignupByEmail(): FailSignupByEmail {
  return {};
}

export const FailSignupByEmail = {
  fromJSON(_: any): FailSignupByEmail {
    return {};
  },

  toJSON(_: FailSignupByEmail): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickSignupByOAuth(): ClickSignupByOAuth {
  return { provider: '' };
}

export const ClickSignupByOAuth = {
  fromJSON(object: any): ClickSignupByOAuth {
    return {
      provider: isSet(object.provider) ? String(object.provider) : '',
    };
  },

  toJSON(message: ClickSignupByOAuth): unknown {
    const obj: any = {};
    message.provider !== undefined && (obj.provider = message.provider);
    return obj;
  },
};

function createBaseSubmitSignUpForm(): SubmitSignUpForm {
  return { interactionContext: '' };
}

export const SubmitSignUpForm = {
  fromJSON(object: any): SubmitSignUpForm {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
    };
  },

  toJSON(message: SubmitSignUpForm): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    return obj;
  },
};

function createBaseClearMarketFilters(): ClearMarketFilters {
  return { interactionContext: '', sport: 0, filtersCount: 0 };
}

export const ClearMarketFilters = {
  fromJSON(object: any): ClearMarketFilters {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      filtersCount: isSet(object.filters_count)
        ? Number(object.filters_count)
        : 0,
    };
  },

  toJSON(message: ClearMarketFilters): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.filtersCount !== undefined &&
      (obj.filters_count = Math.round(message.filtersCount));
    return obj;
  },
};

function createBaseRemoveMarketFilterChip(): RemoveMarketFilterChip {
  return { interactionContext: '', sport: 0, filterName: '', filterValue: '' };
}

export const RemoveMarketFilterChip = {
  fromJSON(object: any): RemoveMarketFilterChip {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      filterName: isSet(object.filter_name) ? String(object.filter_name) : '',
      filterValue: isSet(object.filter_value)
        ? String(object.filter_value)
        : '',
    };
  },

  toJSON(message: RemoveMarketFilterChip): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.filterName !== undefined && (obj.filter_name = message.filterName);
    message.filterValue !== undefined &&
      (obj.filter_value = message.filterValue);
    return obj;
  },
};

function createBaseSetDefaultMarketFilters(): SetDefaultMarketFilters {
  return { interactionContext: '', sport: 0, filters: {} };
}

export const SetDefaultMarketFilters = {
  fromJSON(object: any): SetDefaultMarketFilters {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      filters: isObject(object.filters)
        ? Object.entries(object.filters).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {}
          )
        : {},
    };
  },

  toJSON(message: SetDefaultMarketFilters): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    obj.filters = {};
    if (message.filters) {
      Object.entries(message.filters).forEach(([k, v]) => {
        obj.filters[k] = v;
      });
    }
    return obj;
  },
};

function createBaseSetDefaultMarketFilters_FiltersEntry(): SetDefaultMarketFilters_FiltersEntry {
  return { key: '', value: '' };
}

export const SetDefaultMarketFilters_FiltersEntry = {
  fromJSON(object: any): SetDefaultMarketFilters_FiltersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: SetDefaultMarketFilters_FiltersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },
};

function createBaseClearDefaultMarketFilters(): ClearDefaultMarketFilters {
  return { interactionContext: '', sport: 0 };
}

export const ClearDefaultMarketFilters = {
  fromJSON(object: any): ClearDefaultMarketFilters {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClearDefaultMarketFilters): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseConfirmPurchaseForm(): ConfirmPurchaseForm {
  return {
    interactionContext: '',
    ethAmount: 0,
    eurAmount: 0,
    fiatPayment: false,
    sport: 0,
    offerId: '',
    isV2: undefined,
  };
}

export const ConfirmPurchaseForm = {
  fromJSON(object: any): ConfirmPurchaseForm {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      ethAmount: isSet(object.eth_amount) ? Number(object.eth_amount) : 0,
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      fiatPayment: isSet(object.fiat_payment)
        ? Boolean(object.fiat_payment)
        : false,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      offerId: isSet(object.offer_id) ? String(object.offer_id) : '',
      isV2: isSet(object.is_v2) ? Boolean(object.is_v2) : undefined,
    };
  },

  toJSON(message: ConfirmPurchaseForm): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.ethAmount !== undefined && (obj.eth_amount = message.ethAmount);
    message.eurAmount !== undefined && (obj.eur_amount = message.eurAmount);
    message.fiatPayment !== undefined &&
      (obj.fiat_payment = message.fiatPayment);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.offerId !== undefined && (obj.offer_id = message.offerId);
    message.isV2 !== undefined && (obj.is_v2 = message.isV2);
    return obj;
  },
};

function createBaseClickAnnouncementLink(): ClickAnnouncementLink {
  return { announcementId: '', announcementTitle: '', linkHref: '' };
}

export const ClickAnnouncementLink = {
  fromJSON(object: any): ClickAnnouncementLink {
    return {
      announcementId: isSet(object.announcement_id)
        ? String(object.announcement_id)
        : '',
      announcementTitle: isSet(object.announcement_title)
        ? String(object.announcement_title)
        : '',
      linkHref: isSet(object.link_href) ? String(object.link_href) : '',
    };
  },

  toJSON(message: ClickAnnouncementLink): unknown {
    const obj: any = {};
    message.announcementId !== undefined &&
      (obj.announcement_id = message.announcementId);
    message.announcementTitle !== undefined &&
      (obj.announcement_title = message.announcementTitle);
    message.linkHref !== undefined && (obj.link_href = message.linkHref);
    return obj;
  },
};

function createBaseCreateSavedSearch(): CreateSavedSearch {
  return { name: '', filters: {}, interactionContext: '', sport: 0 };
}

export const CreateSavedSearch = {
  fromJSON(object: any): CreateSavedSearch {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      filters: isObject(object.filters)
        ? Object.entries(object.filters).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {}
          )
        : {},
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: CreateSavedSearch): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    obj.filters = {};
    if (message.filters) {
      Object.entries(message.filters).forEach(([k, v]) => {
        obj.filters[k] = v;
      });
    }
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseCreateSavedSearch_FiltersEntry(): CreateSavedSearch_FiltersEntry {
  return { key: '', value: '' };
}

export const CreateSavedSearch_FiltersEntry = {
  fromJSON(object: any): CreateSavedSearch_FiltersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: CreateSavedSearch_FiltersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },
};

function createBaseSelectSavedSearch(): SelectSavedSearch {
  return { name: '', filters: {}, interactionContext: '', sport: 0 };
}

export const SelectSavedSearch = {
  fromJSON(object: any): SelectSavedSearch {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      filters: isObject(object.filters)
        ? Object.entries(object.filters).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {}
          )
        : {},
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: SelectSavedSearch): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    obj.filters = {};
    if (message.filters) {
      Object.entries(message.filters).forEach(([k, v]) => {
        obj.filters[k] = v;
      });
    }
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseSelectSavedSearch_FiltersEntry(): SelectSavedSearch_FiltersEntry {
  return { key: '', value: '' };
}

export const SelectSavedSearch_FiltersEntry = {
  fromJSON(object: any): SelectSavedSearch_FiltersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: SelectSavedSearch_FiltersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },
};

function createBaseDeleteSavedSearch(): DeleteSavedSearch {
  return { name: '', filters: {}, interactionContext: '', sport: 0 };
}

export const DeleteSavedSearch = {
  fromJSON(object: any): DeleteSavedSearch {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      filters: isObject(object.filters)
        ? Object.entries(object.filters).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {}
          )
        : {},
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: DeleteSavedSearch): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    obj.filters = {};
    if (message.filters) {
      Object.entries(message.filters).forEach(([k, v]) => {
        obj.filters[k] = v;
      });
    }
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseDeleteSavedSearch_FiltersEntry(): DeleteSavedSearch_FiltersEntry {
  return { key: '', value: '' };
}

export const DeleteSavedSearch_FiltersEntry = {
  fromJSON(object: any): DeleteSavedSearch_FiltersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: DeleteSavedSearch_FiltersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },
};

function createBaseSwitchStackedView(): SwitchStackedView {
  return { stacked: false, sport: 0 };
}

export const SwitchStackedView = {
  fromJSON(object: any): SwitchStackedView {
    return {
      stacked: isSet(object.stacked) ? Boolean(object.stacked) : false,
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: SwitchStackedView): unknown {
    const obj: any = {};
    message.stacked !== undefined && (obj.stacked = message.stacked);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickStack(): ClickStack {
  return {
    playerSlug: '',
    scarcity: '',
    season: 0,
    teamSlug: '',
    interactionContext: '',
    sport: 0,
  };
}

export const ClickStack = {
  fromJSON(object: any): ClickStack {
    return {
      playerSlug: isSet(object.player_slug) ? String(object.player_slug) : '',
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : '',
      season: isSet(object.season) ? Number(object.season) : 0,
      teamSlug: isSet(object.team_slug) ? String(object.team_slug) : '',
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickStack): unknown {
    const obj: any = {};
    message.playerSlug !== undefined && (obj.player_slug = message.playerSlug);
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.season !== undefined && (obj.season = Math.round(message.season));
    message.teamSlug !== undefined && (obj.team_slug = message.teamSlug);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickMarketTrend(): ClickMarketTrend {
  return {
    timeframe: '',
    trend: '',
    slug: '',
    interactionContext: '',
    sport: 0,
  };
}

export const ClickMarketTrend = {
  fromJSON(object: any): ClickMarketTrend {
    return {
      timeframe: isSet(object.timeframe) ? String(object.timeframe) : '',
      trend: isSet(object.trend) ? String(object.trend) : '',
      slug: isSet(object.slug) ? String(object.slug) : '',
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickMarketTrend): unknown {
    const obj: any = {};
    message.timeframe !== undefined && (obj.timeframe = message.timeframe);
    message.trend !== undefined && (obj.trend = message.trend);
    message.slug !== undefined && (obj.slug = message.slug);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseLoadMarketTrends(): LoadMarketTrends {
  return {
    timeframe: '',
    sales: [],
    onTheRise: [],
    volumes: [],
    interactionContext: '',
    sport: 0,
  };
}

export const LoadMarketTrends = {
  fromJSON(object: any): LoadMarketTrends {
    return {
      timeframe: isSet(object.timeframe) ? String(object.timeframe) : '',
      sales: Array.isArray(object?.sales)
        ? object.sales.map((e: any) => String(e))
        : [],
      onTheRise: Array.isArray(object?.on_the_rise)
        ? object.on_the_rise.map((e: any) => String(e))
        : [],
      volumes: Array.isArray(object?.volumes)
        ? object.volumes.map((e: any) => String(e))
        : [],
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: LoadMarketTrends): unknown {
    const obj: any = {};
    message.timeframe !== undefined && (obj.timeframe = message.timeframe);
    if (message.sales) {
      obj.sales = message.sales.map(e => e);
    } else {
      obj.sales = [];
    }
    if (message.onTheRise) {
      obj.on_the_rise = message.onTheRise.map(e => e);
    } else {
      obj.on_the_rise = [];
    }
    if (message.volumes) {
      obj.volumes = message.volumes.map(e => e);
    } else {
      obj.volumes = [];
    }
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseUserCategoryUpdated(): UserCategoryUpdated {
  return { category: '' };
}

export const UserCategoryUpdated = {
  fromJSON(object: any): UserCategoryUpdated {
    return {
      category: isSet(object.category) ? String(object.category) : '',
    };
  },

  toJSON(message: UserCategoryUpdated): unknown {
    const obj: any = {};
    message.category !== undefined && (obj.category = message.category);
    return obj;
  },
};

function createBaseKycDocumentSubmitted(): KycDocumentSubmitted {
  return { country: '', documentType: '' };
}

export const KycDocumentSubmitted = {
  fromJSON(object: any): KycDocumentSubmitted {
    return {
      country: isSet(object.country) ? String(object.country) : '',
      documentType: isSet(object.document_type)
        ? String(object.document_type)
        : '',
    };
  },

  toJSON(message: KycDocumentSubmitted): unknown {
    const obj: any = {};
    message.country !== undefined && (obj.country = message.country);
    message.documentType !== undefined &&
      (obj.document_type = message.documentType);
    return obj;
  },
};

function createBaseKycDocumentRefused(): KycDocumentRefused {
  return { country: '', documentType: '', reasonType: '' };
}

export const KycDocumentRefused = {
  fromJSON(object: any): KycDocumentRefused {
    return {
      country: isSet(object.country) ? String(object.country) : '',
      documentType: isSet(object.document_type)
        ? String(object.document_type)
        : '',
      reasonType: isSet(object.reason_type) ? String(object.reason_type) : '',
    };
  },

  toJSON(message: KycDocumentRefused): unknown {
    const obj: any = {};
    message.country !== undefined && (obj.country = message.country);
    message.documentType !== undefined &&
      (obj.document_type = message.documentType);
    message.reasonType !== undefined && (obj.reason_type = message.reasonType);
    return obj;
  },
};

function createBaseFailCardRegistration(): FailCardRegistration {
  return { cardType: '', interactionContext: '', sport: 0 };
}

export const FailCardRegistration = {
  fromJSON(object: any): FailCardRegistration {
    return {
      cardType: isSet(object.card_type) ? String(object.card_type) : '',
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: FailCardRegistration): unknown {
    const obj: any = {};
    message.cardType !== undefined && (obj.card_type = message.cardType);
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseClickReferralBanner(): ClickReferralBanner {
  return { interactionContext: '', sport: 0 };
}

export const ClickReferralBanner = {
  fromJSON(object: any): ClickReferralBanner {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickReferralBanner): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseCampaignPageVisited(): CampaignPageVisited {
  return { url: '', fingerprint: '', identifierType: '' };
}

export const CampaignPageVisited = {
  fromJSON(object: any): CampaignPageVisited {
    return {
      url: isSet(object.url) ? String(object.url) : '',
      fingerprint: isSet(object.fingerprint) ? String(object.fingerprint) : '',
      identifierType: isSet(object.identifier_type)
        ? String(object.identifier_type)
        : '',
    };
  },

  toJSON(message: CampaignPageVisited): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    message.fingerprint !== undefined &&
      (obj.fingerprint = message.fingerprint);
    message.identifierType !== undefined &&
      (obj.identifier_type = message.identifierType);
    return obj;
  },
};

function createBaseClickScoutCards(): ClickScoutCards {
  return { interactionContext: '', sport: 0, playerSlug: '' };
}

export const ClickScoutCards = {
  fromJSON(object: any): ClickScoutCards {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      playerSlug: isSet(object.player_slug) ? String(object.player_slug) : '',
    };
  },

  toJSON(message: ClickScoutCards): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.playerSlug !== undefined && (obj.player_slug = message.playerSlug);
    return obj;
  },
};

function createBaseClickSeeAllAvailableCards(): ClickSeeAllAvailableCards {
  return { interactionContext: '', sport: 0, eventName: '', eventType: '' };
}

export const ClickSeeAllAvailableCards = {
  fromJSON(object: any): ClickSeeAllAvailableCards {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      eventName: isSet(object.event_name) ? String(object.event_name) : '',
      eventType: isSet(object.event_type) ? String(object.event_type) : '',
    };
  },

  toJSON(message: ClickSeeAllAvailableCards): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.eventName !== undefined && (obj.event_name = message.eventName);
    message.eventType !== undefined && (obj.event_type = message.eventType);
    return obj;
  },
};

function createBaseClickSeeOtherListingsFromEndedAuction(): ClickSeeOtherListingsFromEndedAuction {
  return {};
}

export const ClickSeeOtherListingsFromEndedAuction = {
  fromJSON(_: any): ClickSeeOtherListingsFromEndedAuction {
    return {};
  },

  toJSON(_: ClickSeeOtherListingsFromEndedAuction): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseViewBlogPost(): ViewBlogPost {
  return { slug: '' };
}

export const ViewBlogPost = {
  fromJSON(object: any): ViewBlogPost {
    return {
      slug: isSet(object.slug) ? String(object.slug) : '',
    };
  },

  toJSON(message: ViewBlogPost): unknown {
    const obj: any = {};
    message.slug !== undefined && (obj.slug = message.slug);
    return obj;
  },
};

function createBaseReadBlogPost(): ReadBlogPost {
  return { slug: '' };
}

export const ReadBlogPost = {
  fromJSON(object: any): ReadBlogPost {
    return {
      slug: isSet(object.slug) ? String(object.slug) : '',
    };
  },

  toJSON(message: ReadBlogPost): unknown {
    const obj: any = {};
    message.slug !== undefined && (obj.slug = message.slug);
    return obj;
  },
};

function createBaseClickHelp(): ClickHelp {
  return { interactionContext: '', sport: 0 };
}

export const ClickHelp = {
  fromJSON(object: any): ClickHelp {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
    };
  },

  toJSON(message: ClickHelp): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    return obj;
  },
};

function createBaseViewHelpCenterArticle(): ViewHelpCenterArticle {
  return { id: '', locale: '', title: '' };
}

export const ViewHelpCenterArticle = {
  fromJSON(object: any): ViewHelpCenterArticle {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      locale: isSet(object.locale) ? String(object.locale) : '',
      title: isSet(object.title) ? String(object.title) : '',
    };
  },

  toJSON(message: ViewHelpCenterArticle): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.locale !== undefined && (obj.locale = message.locale);
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },
};

function createBaseClickOpenMobileApp(): ClickOpenMobileApp {
  return { interactionContext: '', sport: 0, platform: '' };
}

export const ClickOpenMobileApp = {
  fromJSON(object: any): ClickOpenMobileApp {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      platform: isSet(object.platform) ? String(object.platform) : '',
    };
  },

  toJSON(message: ClickOpenMobileApp): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.platform !== undefined && (obj.platform = message.platform);
    return obj;
  },
};

function createBaseClosePromoteMobileApp(): ClosePromoteMobileApp {
  return { interactionContext: '', sport: 0, platform: '' };
}

export const ClosePromoteMobileApp = {
  fromJSON(object: any): ClosePromoteMobileApp {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      platform: isSet(object.platform) ? String(object.platform) : '',
    };
  },

  toJSON(message: ClosePromoteMobileApp): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.platform !== undefined && (obj.platform = message.platform);
    return obj;
  },
};

function createBaseClickManagerProgressionCategory(): ClickManagerProgressionCategory {
  return {
    interactionContext: '',
    sport: 0,
    categoryName: '',
    claimableTasks: 0,
  };
}

export const ClickManagerProgressionCategory = {
  fromJSON(object: any): ClickManagerProgressionCategory {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      categoryName: isSet(object.category_name)
        ? String(object.category_name)
        : '',
      claimableTasks: isSet(object.claimable_tasks)
        ? Number(object.claimable_tasks)
        : 0,
    };
  },

  toJSON(message: ClickManagerProgressionCategory): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.categoryName !== undefined &&
      (obj.category_name = message.categoryName);
    message.claimableTasks !== undefined &&
      (obj.claimable_tasks = Math.round(message.claimableTasks));
    return obj;
  },
};

function createBaseClickManagerProgressionTask(): ClickManagerProgressionTask {
  return { interactionContext: '', sport: 0, taskName: '', categoryName: '' };
}

export const ClickManagerProgressionTask = {
  fromJSON(object: any): ClickManagerProgressionTask {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      taskName: isSet(object.task_name) ? String(object.task_name) : '',
      categoryName: isSet(object.category_name)
        ? String(object.category_name)
        : '',
    };
  },

  toJSON(message: ClickManagerProgressionTask): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.taskName !== undefined && (obj.task_name = message.taskName);
    message.categoryName !== undefined &&
      (obj.category_name = message.categoryName);
    return obj;
  },
};

function createBaseEnterCashWalletKYCFlow(): EnterCashWalletKYCFlow {
  return {
    interactionContext: '',
    sport: 0,
    source: 0,
    target: 0,
    cashWalletStatus: 0,
    fiatCurrency: '',
  };
}

export const EnterCashWalletKYCFlow = {
  fromJSON(object: any): EnterCashWalletKYCFlow {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      source: isSet(object.source)
        ? enterCashWalletKYCFlow_SourceFromJSON(object.source)
        : 0,
      target: isSet(object.target)
        ? enterCashWalletKYCFlow_TargetFromJSON(object.target)
        : 0,
      cashWalletStatus: isSet(object.cash_wallet_status)
        ? cashWalletStatusFromJSON(object.cash_wallet_status)
        : 0,
      fiatCurrency: isSet(object.fiat_currency)
        ? String(object.fiat_currency)
        : '',
    };
  },

  toJSON(message: EnterCashWalletKYCFlow): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.source !== undefined &&
      (obj.source = enterCashWalletKYCFlow_SourceToJSON(message.source));
    message.target !== undefined &&
      (obj.target = enterCashWalletKYCFlow_TargetToJSON(message.target));
    message.cashWalletStatus !== undefined &&
      (obj.cash_wallet_status = cashWalletStatusToJSON(
        message.cashWalletStatus
      ));
    message.fiatCurrency !== undefined &&
      (obj.fiat_currency = message.fiatCurrency);
    return obj;
  },
};

function createBaseExitCashWalletKYCFlow(): ExitCashWalletKYCFlow {
  return {
    interactionContext: '',
    sport: 0,
    kycExitStep: 0,
    cashWalletStatus: 0,
    fiatCurrency: '',
  };
}

export const ExitCashWalletKYCFlow = {
  fromJSON(object: any): ExitCashWalletKYCFlow {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      kycExitStep: isSet(object.kyc_exit_step)
        ? exitCashWalletKYCFlow_ExitStepFromJSON(object.kyc_exit_step)
        : 0,
      cashWalletStatus: isSet(object.cash_wallet_status)
        ? cashWalletStatusFromJSON(object.cash_wallet_status)
        : 0,
      fiatCurrency: isSet(object.fiat_currency)
        ? String(object.fiat_currency)
        : '',
    };
  },

  toJSON(message: ExitCashWalletKYCFlow): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.kycExitStep !== undefined &&
      (obj.kyc_exit_step = exitCashWalletKYCFlow_ExitStepToJSON(
        message.kycExitStep
      ));
    message.cashWalletStatus !== undefined &&
      (obj.cash_wallet_status = cashWalletStatusToJSON(
        message.cashWalletStatus
      ));
    message.fiatCurrency !== undefined &&
      (obj.fiat_currency = message.fiatCurrency);
    return obj;
  },
};

function createBaseClickMarketTrendsLink(): ClickMarketTrendsLink {
  return {
    interactionContext: '',
    sport: 0,
    tileType: 0,
    playerSlug: undefined,
    scarcity: undefined,
    serialNumber: undefined,
    gameWeek: undefined,
    heuristic: 0,
    so5LeagueSlug: undefined,
    userSlug: undefined,
  };
}

export const ClickMarketTrendsLink = {
  fromJSON(object: any): ClickMarketTrendsLink {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      tileType: isSet(object.tile_type)
        ? clickMarketTrendsLink_TileTypeFromJSON(object.tile_type)
        : 0,
      playerSlug: isSet(object.player_slug)
        ? String(object.player_slug)
        : undefined,
      scarcity: isSet(object.scarcity) ? String(object.scarcity) : undefined,
      serialNumber: isSet(object.serial_number)
        ? Number(object.serial_number)
        : undefined,
      gameWeek: isSet(object.game_week) ? Number(object.game_week) : undefined,
      heuristic: isSet(object.heuristic)
        ? clickMarketTrendsLink_ForYouHeuristicFromJSON(object.heuristic)
        : 0,
      so5LeagueSlug: isSet(object.so5_league_slug)
        ? String(object.so5_league_slug)
        : undefined,
      userSlug: isSet(object.user_slug) ? String(object.user_slug) : undefined,
    };
  },

  toJSON(message: ClickMarketTrendsLink): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.tileType !== undefined &&
      (obj.tile_type = clickMarketTrendsLink_TileTypeToJSON(message.tileType));
    message.playerSlug !== undefined && (obj.player_slug = message.playerSlug);
    message.scarcity !== undefined && (obj.scarcity = message.scarcity);
    message.serialNumber !== undefined &&
      (obj.serial_number = Math.round(message.serialNumber));
    message.gameWeek !== undefined &&
      (obj.game_week = Math.round(message.gameWeek));
    message.heuristic !== undefined &&
      (obj.heuristic = clickMarketTrendsLink_ForYouHeuristicToJSON(
        message.heuristic
      ));
    message.so5LeagueSlug !== undefined &&
      (obj.so5_league_slug = message.so5LeagueSlug);
    message.userSlug !== undefined && (obj.user_slug = message.userSlug);
    return obj;
  },
};

function createBaseClickAddMoney(): ClickAddMoney {
  return { wallet: 0, currency: undefined };
}

export const ClickAddMoney = {
  fromJSON(object: any): ClickAddMoney {
    return {
      wallet: isSet(object.wallet) ? walletFromJSON(object.wallet) : 0,
      currency: isSet(object.currency)
        ? fiatCurrencyFromJSON(object.currency)
        : undefined,
    };
  },

  toJSON(message: ClickAddMoney): unknown {
    const obj: any = {};
    message.wallet !== undefined && (obj.wallet = walletToJSON(message.wallet));
    message.currency !== undefined &&
      (obj.currency =
        message.currency !== undefined
          ? fiatCurrencyToJSON(message.currency)
          : undefined);
    return obj;
  },
};

function createBaseSelectDepositMethod(): SelectDepositMethod {
  return { method: 0 };
}

export const SelectDepositMethod = {
  fromJSON(object: any): SelectDepositMethod {
    return {
      method: isSet(object.method) ? depositMethodFromJSON(object.method) : 0,
    };
  },

  toJSON(message: SelectDepositMethod): unknown {
    const obj: any = {};
    message.method !== undefined &&
      (obj.method = depositMethodToJSON(message.method));
    return obj;
  },
};

function createBaseClickReviewDeposit(): ClickReviewDeposit {
  return { method: 0, amount: 0, currency: 0, baseAmount: undefined };
}

export const ClickReviewDeposit = {
  fromJSON(object: any): ClickReviewDeposit {
    return {
      method: isSet(object.method) ? depositMethodFromJSON(object.method) : 0,
      amount: isSet(object.amount) ? Number(object.amount) : 0,
      currency: isSet(object.currency)
        ? fiatCurrencyFromJSON(object.currency)
        : 0,
      baseAmount: isSet(object.base_amount)
        ? Number(object.base_amount)
        : undefined,
    };
  },

  toJSON(message: ClickReviewDeposit): unknown {
    const obj: any = {};
    message.method !== undefined &&
      (obj.method = depositMethodToJSON(message.method));
    message.amount !== undefined && (obj.amount = message.amount);
    message.currency !== undefined &&
      (obj.currency = fiatCurrencyToJSON(message.currency));
    message.baseAmount !== undefined && (obj.base_amount = message.baseAmount);
    return obj;
  },
};

function createBaseViewAddFundsToFiatWallet(): ViewAddFundsToFiatWallet {
  return { currency: 0, baseAmount: undefined };
}

export const ViewAddFundsToFiatWallet = {
  fromJSON(object: any): ViewAddFundsToFiatWallet {
    return {
      currency: isSet(object.currency)
        ? fiatCurrencyFromJSON(object.currency)
        : 0,
      baseAmount: isSet(object.base_amount)
        ? Number(object.base_amount)
        : undefined,
    };
  },

  toJSON(message: ViewAddFundsToFiatWallet): unknown {
    const obj: any = {};
    message.currency !== undefined &&
      (obj.currency = fiatCurrencyToJSON(message.currency));
    message.baseAmount !== undefined && (obj.base_amount = message.baseAmount);
    return obj;
  },
};

function createBaseClickConfirmDesposit(): ClickConfirmDesposit {
  return { method: 0, amount: 0, currency: 0 };
}

export const ClickConfirmDesposit = {
  fromJSON(object: any): ClickConfirmDesposit {
    return {
      method: isSet(object.method) ? depositMethodFromJSON(object.method) : 0,
      amount: isSet(object.amount) ? Number(object.amount) : 0,
      currency: isSet(object.currency)
        ? fiatCurrencyFromJSON(object.currency)
        : 0,
    };
  },

  toJSON(message: ClickConfirmDesposit): unknown {
    const obj: any = {};
    message.method !== undefined &&
      (obj.method = depositMethodToJSON(message.method));
    message.amount !== undefined && (obj.amount = message.amount);
    message.currency !== undefined &&
      (obj.currency = fiatCurrencyToJSON(message.currency));
    return obj;
  },
};

function createBaseDisplay3ds(): Display3ds {
  return {};
}

export const Display3ds = {
  fromJSON(_: any): Display3ds {
    return {};
  },

  toJSON(_: Display3ds): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickPaypalDepositFromPayment(): ClickPaypalDepositFromPayment {
  return { canDeposit: false };
}

export const ClickPaypalDepositFromPayment = {
  fromJSON(object: any): ClickPaypalDepositFromPayment {
    return {
      canDeposit: isSet(object.can_deposit)
        ? Boolean(object.can_deposit)
        : false,
    };
  },

  toJSON(message: ClickPaypalDepositFromPayment): unknown {
    const obj: any = {};
    message.canDeposit !== undefined && (obj.can_deposit = message.canDeposit);
    return obj;
  },
};

function createBaseSendDirectOffer(): SendDirectOffer {
  return { offerId: '' };
}

export const SendDirectOffer = {
  fromJSON(object: any): SendDirectOffer {
    return {
      offerId: isSet(object.offer_id) ? String(object.offer_id) : '',
    };
  },

  toJSON(message: SendDirectOffer): unknown {
    const obj: any = {};
    message.offerId !== undefined && (obj.offer_id = message.offerId);
    return obj;
  },
};

function createBaseAcceptDirectOffer(): AcceptDirectOffer {
  return { offerId: '' };
}

export const AcceptDirectOffer = {
  fromJSON(object: any): AcceptDirectOffer {
    return {
      offerId: isSet(object.offer_id) ? String(object.offer_id) : '',
    };
  },

  toJSON(message: AcceptDirectOffer): unknown {
    const obj: any = {};
    message.offerId !== undefined && (obj.offer_id = message.offerId);
    return obj;
  },
};

function createBaseShowEnterPhoneNumber(): ShowEnterPhoneNumber {
  return {};
}

export const ShowEnterPhoneNumber = {
  fromJSON(_: any): ShowEnterPhoneNumber {
    return {};
  },

  toJSON(_: ShowEnterPhoneNumber): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseSubmitPhoneNumber(): SubmitPhoneNumber {
  return { resend: undefined };
}

export const SubmitPhoneNumber = {
  fromJSON(object: any): SubmitPhoneNumber {
    return {
      resend: isSet(object.resend) ? Boolean(object.resend) : undefined,
    };
  },

  toJSON(message: SubmitPhoneNumber): unknown {
    const obj: any = {};
    message.resend !== undefined && (obj.resend = message.resend);
    return obj;
  },
};

function createBaseShowEnterVerificationCode(): ShowEnterVerificationCode {
  return {};
}

export const ShowEnterVerificationCode = {
  fromJSON(_: any): ShowEnterVerificationCode {
    return {};
  },

  toJSON(_: ShowEnterVerificationCode): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseSubmitPhoneVerificationCode(): SubmitPhoneVerificationCode {
  return {};
}

export const SubmitPhoneVerificationCode = {
  fromJSON(_: any): SubmitPhoneVerificationCode {
    return {};
  },

  toJSON(_: SubmitPhoneVerificationCode): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickLobbyLiveOpsTile(): ClickLobbyLiveOpsTile {
  return { tileId: '', tileTitle: '', tilePosition: 0 };
}

export const ClickLobbyLiveOpsTile = {
  fromJSON(object: any): ClickLobbyLiveOpsTile {
    return {
      tileId: isSet(object.tile_id) ? String(object.tile_id) : '',
      tileTitle: isSet(object.tile_title) ? String(object.tile_title) : '',
      tilePosition: isSet(object.tile_position)
        ? Number(object.tile_position)
        : 0,
    };
  },

  toJSON(message: ClickLobbyLiveOpsTile): unknown {
    const obj: any = {};
    message.tileId !== undefined && (obj.tile_id = message.tileId);
    message.tileTitle !== undefined && (obj.tile_title = message.tileTitle);
    message.tilePosition !== undefined &&
      (obj.tile_position = Math.round(message.tilePosition));
    return obj;
  },
};

function createBaseEnterFromAndroidMarketplaceTab(): EnterFromAndroidMarketplaceTab {
  return {
    interactionContext: '',
    sport: 0,
    appBuildVersion: '',
    userSlug: undefined,
  };
}

export const EnterFromAndroidMarketplaceTab = {
  fromJSON(object: any): EnterFromAndroidMarketplaceTab {
    return {
      interactionContext: isSet(object.interaction_context)
        ? String(object.interaction_context)
        : '',
      sport: isSet(object.sport) ? sportFromJSON(object.sport) : 0,
      appBuildVersion: isSet(object.app_build_version)
        ? String(object.app_build_version)
        : '',
      userSlug: isSet(object.user_slug) ? String(object.user_slug) : undefined,
    };
  },

  toJSON(message: EnterFromAndroidMarketplaceTab): unknown {
    const obj: any = {};
    message.interactionContext !== undefined &&
      (obj.interaction_context = message.interactionContext);
    message.sport !== undefined && (obj.sport = sportToJSON(message.sport));
    message.appBuildVersion !== undefined &&
      (obj.app_build_version = message.appBuildVersion);
    message.userSlug !== undefined && (obj.user_slug = message.userSlug);
    return obj;
  },
};

function createBaseClickHomepageCryptoWalletIcon(): ClickHomepageCryptoWalletIcon {
  return {};
}

export const ClickHomepageCryptoWalletIcon = {
  fromJSON(_: any): ClickHomepageCryptoWalletIcon {
    return {};
  },

  toJSON(_: ClickHomepageCryptoWalletIcon): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickHomepageCryptoWalletProvider(): ClickHomepageCryptoWalletProvider {
  return { provider: '' };
}

export const ClickHomepageCryptoWalletProvider = {
  fromJSON(object: any): ClickHomepageCryptoWalletProvider {
    return {
      provider: isSet(object.provider) ? String(object.provider) : '',
    };
  },

  toJSON(message: ClickHomepageCryptoWalletProvider): unknown {
    const obj: any = {};
    message.provider !== undefined && (obj.provider = message.provider);
    return obj;
  },
};

function createBaseClickNotification(): ClickNotification {
  return { name: '' };
}

export const ClickNotification = {
  fromJSON(object: any): ClickNotification {
    return {
      name: isSet(object.name) ? String(object.name) : '',
    };
  },

  toJSON(message: ClickNotification): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },
};

function createBaseClickGemPackage(): ClickGemPackage {
  return { eurAmount: 0, gemAmount: 0 };
}

export const ClickGemPackage = {
  fromJSON(object: any): ClickGemPackage {
    return {
      eurAmount: isSet(object.eur_amount) ? Number(object.eur_amount) : 0,
      gemAmount: isSet(object.gem_amount) ? Number(object.gem_amount) : 0,
    };
  },

  toJSON(message: ClickGemPackage): unknown {
    const obj: any = {};
    message.eurAmount !== undefined &&
      (obj.eur_amount = Math.round(message.eurAmount));
    message.gemAmount !== undefined &&
      (obj.gem_amount = Math.round(message.gemAmount));
    return obj;
  },
};

function createBaseClickBuyPack(): ClickBuyPack {
  return { packSlug: '' };
}

export const ClickBuyPack = {
  fromJSON(object: any): ClickBuyPack {
    return {
      packSlug: isSet(object.pack_slug) ? String(object.pack_slug) : '',
    };
  },

  toJSON(message: ClickBuyPack): unknown {
    const obj: any = {};
    message.packSlug !== undefined && (obj.pack_slug = message.packSlug);
    return obj;
  },
};

function createBaseOpenBuyGemsDialog(): OpenBuyGemsDialog {
  return { context: 0 };
}

export const OpenBuyGemsDialog = {
  fromJSON(object: any): OpenBuyGemsDialog {
    return {
      context: isSet(object.context)
        ? openBuyGemsDialog_ContextFromJSON(object.context)
        : 0,
    };
  },

  toJSON(message: OpenBuyGemsDialog): unknown {
    const obj: any = {};
    message.context !== undefined &&
      (obj.context = openBuyGemsDialog_ContextToJSON(message.context));
    return obj;
  },
};

function createBaseClickNextInDiscountScreen(): ClickNextInDiscountScreen {
  return {};
}

export const ClickNextInDiscountScreen = {
  fromJSON(_: any): ClickNextInDiscountScreen {
    return {};
  },

  toJSON(_: ClickNextInDiscountScreen): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickViewPlayersInDiscountScreen(): ClickViewPlayersInDiscountScreen {
  return {};
}

export const ClickViewPlayersInDiscountScreen = {
  fromJSON(_: any): ClickViewPlayersInDiscountScreen {
    return {};
  },

  toJSON(_: ClickViewPlayersInDiscountScreen): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickSkipInDiscountScreen(): ClickSkipInDiscountScreen {
  return {};
}

export const ClickSkipInDiscountScreen = {
  fromJSON(_: any): ClickSkipInDiscountScreen {
    return {};
  },

  toJSON(_: ClickSkipInDiscountScreen): unknown {
    const obj: any = {};
    return obj;
  },
};

function createBaseClickScoutInDiscountScreen(): ClickScoutInDiscountScreen {
  return {};
}

export const ClickScoutInDiscountScreen = {
  fromJSON(_: any): ClickScoutInDiscountScreen {
    return {};
  },

  toJSON(_: ClickScoutInDiscountScreen): unknown {
    const obj: any = {};
    return obj;
  },
};

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
