export const defaultTypePolicies = {
  AcceptTermsMutationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Account: { keyFields: ["id"] },
  ActionReward: { keyFields: ["id"] },
  ActivitiesInterface: { keyFields: ["id"] },
  ActivityReactionType: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Age: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  AirdropPromotionalEvent: { keyFields: ["slug"] },
  Announcement: { keyFields: ["id"] },
  AnnouncementNotification: { keyFields: ["id"] },
  AnonymousUser: { keyFields: ["id"] },
  AnyCardInterface: { keyFields: ["slug"] },
  AnyCardReward: { keyFields: ["id"] },
  AnyCardSubscription: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  AnyContestantInterface: { keyFields: ["id"] },
  AnyConversionCreditReward: { keyFields: ["id"] },
  AnyGameInterface: { keyFields: ["id"] },
  AnyPlayerGameStatsInterface: { keyFields: ["id"] },
  AnyPlayerInterface: { keyFields: ["slug"] },
  AnyPlayerNewsUpdate: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  AnyRewardConfigInterface: { keyFields: ["id"] },
  AnyRewardInterface: { keyFields: ["id"] },
  AppearanceBoostShopItem: { keyFields: ["id"] },
  AppearanceRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ArenaTicketReward: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  AuctionDrop: { keyFields: ["id"] },
  AuctionNotification: { keyFields: ["id"] },
  AuctionReminder: { keyFields: ["id"] },
  AuthorizationRequest: { keyFields: ["id"] },
  AutoClaimNotification: { keyFields: ["id"] },
  AverageScoreOpenBoundaries: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  AverageScoresRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  BankAccountInterface: { keyFields: ["id"] },
  Banner: { keyFields: ["id"] },
  BannersSet: { keyFields: ["id"] },
  BaseballAverageStatWithValueType: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  BaseballCard: { keyFields: ["slug"] },
  BaseballContestantType: { keyFields: ["id"] },
  BaseballPlayer: { keyFields: ["slug"] },
  BaseballPlayerGameScore: { keyFields: ["id"] },
  BaseballTeamGameStats: { keyFields: ["id"] },
  BaseballUserSportProfile: { keyFields: ["id"] },
  BasketballAverageStatWithValueType: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  BasketballContestantType: { keyFields: ["id"] },
  BasketballPlayerGameScore: { keyFields: ["id"] },
  BasketballTeamGameStats: { keyFields: ["id"] },
  BenchSort: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Blueprint: { keyFields: ["id"] },
  BoostLevel: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Bucket: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CaBankAccount: { keyFields: ["id"] },
  Card: { keyFields: ["slug"] },
  CardBoost: { keyFields: ["id"] },
  CardCollection: { keyFields: ["slug"] },
  CardCollectionBonusThreshold: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardCollectionCard: { keyFields: ["id"] },
  CardCollectionCardScoreBreakdown: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardCollectionNotification: { keyFields: ["id"] },
  CardCollectionRequirement: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardCollectionScoreIncrease: { keyFields: ["id"] },
  CardCollectionScoringMatrix: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardCollectionSlot: { keyFields: ["id"] },
  CardCount: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardEditionCount: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardEditionsCount: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardLayout: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardNotification: { keyFields: ["id"] },
  CardPack: { keyFields: ["slug"] },
  CardPackGroup: { keyFields: ["id"] },
  CardPackReward: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardPacksRewardConfig: { keyFields: ["id"] },
  CardRewardConfig: { keyFields: ["id"] },
  CardSample: { keyFields: ["id"] },
  CardSeasonSupply: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardShardRewardConfig: { keyFields: ["id"] },
  CardShardThresholdsPromotionalEvent: { keyFields: ["slug"] },
  CardShardsChest: { keyFields: ["id"] },
  CardShardsChestThreshold: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardShardsChestThresholdProbability: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardShardsReward: { keyFields: ["id"] },
  CardStats: { keyFields: ["id"] },
  CardXPGradeThreshold: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CardsPreview: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Cart: { keyFields: ["id"] },
  CartItem: { keyFields: ["id"] },
  CheckoutCreditCardAuthorizationRequest: { keyFields: ["id"] },
  CheckoutRoot: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Club: { keyFields: ["slug"] },
  CoinReward: { keyFields: ["id"] },
  CoinRewardConfig: { keyFields: ["id"] },
  CommonAccount: { keyFields: ["id"] },
  CommonCardSwapShopItem: { keyFields: ["id"] },
  CommonDraftCampaign: { keyFields: ["slug"] },
  CommonDraftSwap: { keyFields: ["id"] },
  CommonPlayer: { keyFields: ["id"] },
  Competition: { keyFields: ["slug"] },
  CompetitionGames: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CompletablePromotionalEvent: { keyFields: ["slug"] },
  CompletablePromotionalEventInterface: { keyFields: ["slug"] },
  ComposeTeamBenchCard: { keyFields: ["id"] },
  ComposeTeamBenchCommonPlayer: { keyFields: ["id"] },
  ComposeTeamBenchObjectInterface: { keyFields: ["id"] },
  Config: { keyFields: ["id"] },
  ConfirmDeviceMutationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ConfirmEmailMutationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ConnectedOAuth: { keyFields: ["id"] },
  ConsumeEphemeralTokenMutationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ContentTileInterface: { keyFields: ["id"] },
  Contestant: { keyFields: ["id"] },
  Contract: { keyFields: ["id"] },
  ConversionCredit: { keyFields: ["id"] },
  ConversionCreditCampaign: { keyFields: ["id"] },
  ConversionCreditNotification: { keyFields: ["id"] },
  ConversionCreditRewardConfig: { keyFields: ["id"] },
  Country: { keyFields: ["slug"] },
  Counts: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CreateOrUpdateSo5LineupMutationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CreditCard: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  CurrentUser: { keyFields: ["slug"] },
  CustomBanner: { keyFields: ["id"] },
  DataPartner: { keyFields: ["slug"] },
  DateRangeSpecific: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  DecisiveActionNotification: { keyFields: ["id"] },
  DecisivePlayerPickerTask: { keyFields: ["id"] },
  DecisivePlayerPickerTaskAppearance: { keyFields: ["id"] },
  DecisivePurchaseNotification: { keyFields: ["id"] },
  Deck: { keyFields: ["slug"] },
  DeliverableItemInterface: { keyFields: ["slug"] },
  DeliverableItemOrder: { keyFields: ["id"] },
  DeliverableItemReward: { keyFields: ["id"] },
  DeliverableItemRewardConfig: { keyFields: ["id"] },
  DeliverableItemsRewardsOverview: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  DeliverableWithCustomVariantShopItem: { keyFields: ["id"] },
  DeliverableWithNoVariantShopItem: { keyFields: ["id"] },
  DepositPromotionalEvent: { keyFields: ["slug"] },
  DivisionChangeConfiguration: { keyFields: ["id"] },
  DraftablePlayer: { keyFields: ["id"] },
  DropChallengeTask: { keyFields: ["id"] },
  EligibilityRules: { keyFields: ["id"] },
  EmailSubscription: { keyFields: ["slug"] },
  EmailSubscriptionPreferences: { keyFields: ["slug"] },
  EmoteSentActivity: { keyFields: ["id"] },
  EngineConfiguration: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  EthBankWithdrawalIntent: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  EthDeposit: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  EthMigration: { keyFields: ["id"] },
  EthereumAccount: { keyFields: ["id"] },
  EthereumTransaction: { keyFields: ["id"] },
  Event: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ExchangeRate: { keyFields: ["id"] },
  ExperienceDeliverableItem: { keyFields: ["slug"] },
  ExperienceReward: { keyFields: ["id"] },
  ExperienceRewardConfig: { keyFields: ["id"] },
  ExternalDepositNotification: { keyFields: ["id"] },
  ExtraSwapShopItem: { keyFields: ["id"] },
  FaceLandmark: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FastWithdrawal: { keyFields: ["id"] },
  FastWithdrawalWithRates: { keyFields: ["id"] },
  FeaturedLeagues: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Fee: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Fiat: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FiatWalletAccount: { keyFields: ["id"] },
  FirstRivalsLineupSubmittedActivity: { keyFields: ["id"] },
  FootballAverageStatWithValueType: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballContestantType: { keyFields: ["id"] },
  FootballConversionCredit: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballManagerTask: { keyFields: ["id"] },
  FootballManagerTaskContentTile: { keyFields: ["id"] },
  FootballRewardShopItem: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalLeaderboardInterface: { keyFields: ["id"] },
  FootballRivalLeaderboardRankingInterface: { keyFields: ["id"] },
  FootballRivalsAggregatedArenaStory: { keyFields: ["id"] },
  FootballRivalsAllAroundScoreDetailsAction: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsAppearance: { keyFields: ["id"] },
  FootballRivalsArenaRequest: { keyFields: ["id"] },
  FootballRivalsChallenge: { keyFields: ["id"] },
  FootballRivalsChallengeContentTile: { keyFields: ["id"] },
  FootballRivalsChallenger: { keyFields: ["id"] },
  FootballRivalsConfig: { keyFields: ["id"] },
  FootballRivalsContestant: { keyFields: ["id"] },
  FootballRivalsCurrentManager: { keyFields: ["id"] },
  FootballRivalsDecisiveScoreDetailsAction: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsDivisionLeaderboard: { keyFields: ["id"] },
  FootballRivalsDivisionLeaderboardConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsDivisionLeaderboardDivisionConfig: { keyFields: ["id"] },
  FootballRivalsDivisionLeaderboardRankingReward: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsDivisionRanking: { keyFields: ["id"] },
  FootballRivalsDivisionSquadLeaderboardConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsDivisionSquadLeaderboardRankingReward: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsDraftableCard: { keyFields: ["id"] },
  FootballRivalsDraftableObjectInterface: { keyFields: ["id"] },
  FootballRivalsDraftablePlayer: { keyFields: ["id"] },
  FootballRivalsEloGroup: { keyFields: ["id"] },
  FootballRivalsGame: { keyFields: ["slug"] },
  FootballRivalsGameContentTile: { keyFields: ["id"] },
  FootballRivalsGameNotification: { keyFields: ["id"] },
  FootballRivalsInvite: { keyFields: ["id"] },
  FootballRivalsLeaderboard: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsLeagueGame: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsLineup: { keyFields: ["id"] },
  FootballRivalsLineupScoreDetails: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsLineupTactic: { keyFields: ["slug"] },
  FootballRivalsLineupTacticThreshold: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsManager: { keyFields: ["id"] },
  FootballRivalsManagerNotification: { keyFields: ["id"] },
  FootballRivalsManagerRecords: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsManagerSquadRanking: { keyFields: ["id"] },
  FootballRivalsManagersPreview: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsOnboardingLineupSimulation: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsPlayerScore: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsPlayerStat: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsPrivateRanking: { keyFields: ["id"] },
  FootballRivalsPromotionRelegationStory: { keyFields: ["id"] },
  FootballRivalsPromotionRelegationStoryInterface: { keyFields: ["id"] },
  FootballRivalsRanking: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsRankingDelta: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsRankingsPaginated: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FootballRivalsRivalry: { keyFields: ["id"] },
  FootballRivalsRoot: { keyFields: ["id"] },
  FootballRivalsSeason: { keyFields: ["slug"] },
  FootballRivalsSeasonSquad: { keyFields: ["id"] },
  FootballRivalsSquad: { keyFields: ["slug"] },
  FootballRivalsSquadMembershipChallenge: { keyFields: ["id"] },
  FootballRivalsSquadMembershipsLeaderboard: { keyFields: ["id"] },
  FootballRivalsSquadMembershipsLeaderboardRanking: { keyFields: ["id"] },
  FootballRivalsSquadPromotionRelegationStory: { keyFields: ["id"] },
  FootballRivalsSquadsLeaderboard: { keyFields: ["id"] },
  FootballRivalsSquadsLeaderboardRanking: { keyFields: ["id"] },
  FootballRivalsSuggestedManager: { keyFields: ["id"] },
  FootballRoot: { keyFields: ["id"] },
  FootballSo5NoCardRouteRoot: { keyFields: ["id"] },
  FootballTeamGameStats: { keyFields: ["id"] },
  FootballUserSportProfile: { keyFields: ["id"] },
  ForYouRoot: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  FullWithdrawalRequest: { keyFields: ["id"] },
  GalleryCards: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Game: { keyFields: ["id"] },
  GameOfBaseball: { keyFields: ["id"] },
  GameOfBasketball: { keyFields: ["id"] },
  GameWeeklyTask: { keyFields: ["id"] },
  GameWeeklyTaskInterface: { keyFields: ["id"] },
  GameWithCardsPreview: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  GameWithPlayerGameScoresPreview: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  GbBankAccount: { keyFields: ["id"] },
  GiphySentActivity: { keyFields: ["id"] },
  HeroBanner: { keyFields: ["id"] },
  HeroBannersSet: { keyFields: ["id"] },
  HighlightedStat: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  IbanBankAccount: { keyFields: ["id"] },
  ImportedSo5Appearance: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  InGameCurrencyBalance: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  InGameCurrencyNotification: { keyFields: ["id"] },
  InGameCurrencyPack: { keyFields: ["id"] },
  InGameCurrencyReward: { keyFields: ["id"] },
  InGameCurrencyRewardConfig: { keyFields: ["id"] },
  IncompleteCardCollection: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Injury: { keyFields: ["id"] },
  Inning: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  IntegerBySportType: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  JerseyDeliverableItem: { keyFields: ["slug"] },
  JerseyShopItem: { keyFields: ["id"] },
  JwtToken: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  KlarnaPayinConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  KycDocumentRequirement: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  KycRequestNotification: { keyFields: ["id"] },
  LeaderboardRewardsConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  LevelUpShopItem: { keyFields: ["id"] },
  LeveledUpCard: { keyFields: ["id"] },
  Loan: { keyFields: ["id"] },
  LoanNotification: { keyFields: ["id"] },
  LoanableConfigRoot: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  LoanableRoster: { keyFields: ["id"] },
  LoanedToken: { keyFields: ["id"] },
  LobbyTile: { keyFields: ["id"] },
  Location: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  LoomAccount: { keyFields: ["id"] },
  ManagerProgression: { keyFields: ["id"] },
  ManagerProgressionCategory: { keyFields: ["id"] },
  ManagerProgressionMission: { keyFields: ["id"] },
  ManagerProgressionMissionContentTile: { keyFields: ["id"] },
  ManagerProgressionNotification: { keyFields: ["id"] },
  ManagerProgressionTask: { keyFields: ["id"] },
  ManagerTaskInterface: { keyFields: ["id"] },
  ManagerTeam: { keyFields: ["id"] },
  MangopayApplePayAuthorizationRequest: { keyFields: ["id"] },
  MangopayBankAccountOwnerAddress: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  MangopayCreditCardAuthorizationRequest: { keyFields: ["id"] },
  MangopayKlarnaAuthorizationRequest: { keyFields: ["id"] },
  MangopayKycDocument: { keyFields: ["id"] },
  MangopayKycRequest: { keyFields: ["id"] },
  MangopayRemotePreAuthorizationStatus: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  MangopayRemoteTransactionStatus: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  MangopayRoot: { keyFields: ["id"] },
  MangopayUser: { keyFields: ["id"] },
  MangopayWalletTransferAuthorizationRequest: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  MangopayWithdrawal: { keyFields: ["id"] },
  MarketPromotionalEvent: { keyFields: ["slug"] },
  MarketRoot: { keyFields: ["id"] },
  MarketingNotification: { keyFields: ["id"] },
  MarketplacePreference: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  MarketplacePromotionalEvent: { keyFields: ["id"] },
  MarketplacePromotionalEvents: { keyFields: ["id"] },
  MarketplaceSportPreferences: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  MaximumCardsCountOwnedByUser: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Membership: { keyFields: ["id"] },
  MissedAppearance: { keyFields: ["id"] },
  MissedLineup: { keyFields: ["slug"] },
  MissedLineupNotification: { keyFields: ["id"] },
  MonetaryAmount: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  MonetaryReward: { keyFields: ["id"] },
  MonetaryRewardConfig: { keyFields: ["id"] },
  Mutation: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  NBACard: { keyFields: ["slug"] },
  NBAPlayer: { keyFields: ["slug"] },
  NBAUserSportProfile: { keyFields: ["id"] },
  NationalTeam: { keyFields: ["slug"] },
  Nationality: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  NoCardRoute: { keyFields: ["id"] },
  Node: { keyFields: ["id"] },
  NotificationInterface: { keyFields: ["id"] },
  NotificationPreference: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Notifications: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  OAuthAccessToken: { keyFields: ["id"] },
  OAuthApplication: { keyFields: ["id"] },
  OfferNotification: { keyFields: ["id"] },
  OnboardingCompetition: { keyFields: ["slug"] },
  OnboardingLeaderboard: { keyFields: ["id"] },
  OrderedPlayerGameScores: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  OrderedSo5Scores: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  OtherBankAccount: { keyFields: ["id"] },
  Owner: { keyFields: ["id"] },
  OwnerInterface: { keyFields: ["id"] },
  OwnerWithRates: { keyFields: ["id"] },
  PageInfo: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  PasswordEncryptedPrivateKey: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Payment: { keyFields: ["id"] },
  PaymentDepositStatus: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  PaymentMethod: { keyFields: ["id"] },
  PaymentRoot: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  PaypalAuthorizationRequest: { keyFields: ["id"] },
  PendingDeposit: { keyFields: ["id"] },
  PickablePlayer: { keyFields: ["id"] },
  Player: { keyFields: ["slug"] },
  PlayerGameScore: { keyFields: ["id"] },
  PlayerGameScoreInterface: { keyFields: ["id"] },
  PlayerGameScoreProjection: { keyFields: ["id"] },
  PlayerGameStats: { keyFields: ["id"] },
  PlayerGameStatsBaseball: { keyFields: ["id"] },
  PlayerGameStatsBasketball: { keyFields: ["id"] },
  PlayerHeightRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  PlayerInjury: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  PlayerRecommendation: { keyFields: ["id"] },
  PlayerWithSupply: { keyFields: ["slug"] },
  PlayingStatusOdds: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  PoolKeyShardsCount: { keyFields: ["slug"] },
  PostalAddress: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  PowerBreakdown: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  PowerPointsBreakdown: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  PreRegistrationData: { keyFields: ["id"] },
  PrivateAccount: { keyFields: ["id"] },
  PrivateEmptyAccount: { keyFields: ["id"] },
  PrivateFiatWalletAccount: { keyFields: ["id"] },
  PrivateKeyRecoveryOption: { keyFields: ["id"] },
  PrivateKeyRecoveryPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ProbabilisticBundle: { keyFields: ["id"] },
  ProbabilisticBundleConfig: { keyFields: ["id"] },
  ProbabilisticBundleConfigReward: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ProbabilisticBundleConfigSlot: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ProbabilisticBundleOpeningDisclaimer: { keyFields: ["slug"] },
  ProbabilisticBundleReward: { keyFields: ["id"] },
  ProbabilisticBundleRewardConfig: { keyFields: ["id"] },
  ProbabilisticBundleSlotArenaTicketItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotCardItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotCardShardItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ProbabilisticBundleSlotConversionCreditItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotDeliverableItem: { keyFields: ["id"] },
  ProbabilisticBundleSlotItemInterface: { keyFields: ["id"] },
  ProbabilisticBundleSlotRewardShopItem: { keyFields: ["id"] },
  ProbabilisticBundlesRewardsOverview: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ProbablePitchersForGameWeek: { keyFields: ["slug"] },
  ProbableRewardConfig: { keyFields: ["id"] },
  PromotionalEventCompletion: { keyFields: ["id"] },
  PromotionalEventInterface: { keyFields: ["slug"] },
  PublicUserInfoInterface: { keyFields: ["slug"] },
  Query: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  RaffleShopItem: { keyFields: ["id"] },
  RaffleShopItemReward: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Range: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  RarityCount: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Rates: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  RecommendedConversionCreditTask: { keyFields: ["id"] },
  Referral: { keyFields: ["id"] },
  ReferralChallengeStats: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ReferralConversionCreditConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ReferralLeaderboard: { keyFields: ["id"] },
  ReferralLeaderboardRanking: { keyFields: ["id"] },
  ReferralMilestoneReward: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ReferralPaginated: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ReferralProgramConfig: { keyFields: ["id"] },
  ReferralReward: { keyFields: ["id"] },
  ReferralRewardNotification: { keyFields: ["id"] },
  ReferralSpecialEvent: { keyFields: ["id"] },
  ReferralSpecialEventChallenge: { keyFields: ["id"] },
  ReferralTile: { keyFields: ["id"] },
  ReminderNotification: { keyFields: ["id"] },
  ReputationReward: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ResponsiveBanner: { keyFields: ["id"] },
  ResponsiveBannersSet: { keyFields: ["id"] },
  RevealableCardCampaign: { keyFields: ["slug"] },
  RevealableCardConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  RevealedCardsSummary: { keyFields: ["id"] },
  RevealedEditionsConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  RewardBoost: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  RewardCard: { keyFields: ["id"] },
  RewardConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  RewardDeliverableItem: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  RewardsOverview: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  RivalryStatus: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  RivalsGameEndedActivity: { keyFields: ["id"] },
  RivalsGameStartedActivity: { keyFields: ["id"] },
  RivalsSquadMembership: { keyFields: ["id"] },
  RivalsUserGroup: { keyFields: ["slug"] },
  RivalsUserGroupMembership: { keyFields: ["id"] },
  RivalsUserGroupTournament: { keyFields: ["id"] },
  SaleNotification: { keyFields: ["id"] },
  ScoringMatrix: { keyFields: ["id"] },
  ScreenshottableCard: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SealingConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SearchCards: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SearchFacet: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SearchFacetValue: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SearchPlayers: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SearchProcessingTime: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Season: { keyFields: ["id"] },
  SeasonBonus: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SeasonCount: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SeasonPicture: { keyFields: ["id"] },
  ShopItem: { keyFields: ["id"] },
  ShopItemInterface: { keyFields: ["id"] },
  ShopItemNotification: { keyFields: ["id"] },
  ShopItemReward: { keyFields: ["id"] },
  ShopItemRewardConfig: { keyFields: ["id"] },
  SignInMutationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SignInWithOAuthMutationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SignUpMutationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SignedCardPrice: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SkinShopItem: { keyFields: ["id"] },
  So5ActiveClubsRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5AgeRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Appearance: { keyFields: ["id"] },
  So5AppearanceInterface: { keyFields: ["id"] },
  So5AppearancePreview: { keyFields: ["id"] },
  So5AtLeastOfClubs: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5AtLeastOfCompetitions: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5AverageScoresRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Campaign: { keyFields: ["slug"] },
  So5CampaignSection: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5CampaignSectionItem: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5CaptainRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5CaptainScarcityRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5CommonDraftError: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5CommonDraftSwapError: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Competition: { keyFields: ["slug"] },
  So5CompetitionsRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Config: { keyFields: ["id"] },
  So5DeliverableItemReward: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Fixture: { keyFields: ["slug"] },
  So5FixturePreparing: { keyFields: ["slug"] },
  So5FixturePublicInterface: { keyFields: ["slug"] },
  So5Leaderboard: { keyFields: ["slug"] },
  So5LeaderboardContender: { keyFields: ["slug"] },
  So5LeaderboardGroup: { keyFields: ["slug"] },
  So5LeaderboardGroupInterface: { keyFields: ["slug"] },
  So5LeaderboardTask: { keyFields: ["id"] },
  So5LeaderboardTaskInterface: { keyFields: ["id"] },
  So5League: { keyFields: ["slug"] },
  So5LeagueTrack: { keyFields: ["slug"] },
  So5LeagueTrackGroup: { keyFields: ["slug"] },
  So5Lineup: { keyFields: ["id"] },
  So5LineupImport: { keyFields: ["id"] },
  So5LineupImportsBatch: { keyFields: ["id"] },
  So5LineupNotification: { keyFields: ["id"] },
  So5LineupPreview: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5MaximumPlayersAverageScoreRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5MinimumPlayersAverageScoreRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5NationalityRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5NoCardAppearance: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5NoCardDraftableAppearance: { keyFields: ["id"] },
  So5NoCardLineup: { keyFields: ["id"] },
  So5OnboardingPromotionalEvent: { keyFields: ["id"] },
  So5PlayerLoyaltyRewardBoost: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Podium: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5PotentialRewardConfigs: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Ranking: { keyFields: ["id"] },
  So5RankingsPaginated: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Rarity: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5RarityVaultRewardBoost: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Reward: { keyFields: ["slug"] },
  So5RewardCardConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5RewardConfig: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5RewardConfigLock: { keyFields: ["slug"] },
  So5RewardConfigWithEligibility: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5RewardExperience: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5RewardTargetConfig: { keyFields: ["slug"] },
  So5Root: { keyFields: ["id"] },
  So5RuleDescription: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5RuleDescriptionItem: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5RuleFeedback: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Rules: { keyFields: ["id"] },
  So5SameActiveClubRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5ScarcityRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Score: { keyFields: ["id"] },
  So5SeasonBonusRule: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5Tournament: { keyFields: ["id"] },
  So5Trophies: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5UserGroup: { keyFields: ["slug"] },
  So5UserGroupMembership: { keyFields: ["id"] },
  So5UserGroupMembershipsPaginated: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  So5UserGroupNotification: { keyFields: ["id"] },
  So5UserGroupTournament: { keyFields: ["id"] },
  SocialPictureDerivative: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SorarePrivateKey: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SortingType: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  SpecialEditionPromotionalEvent: { keyFields: ["slug"] },
  SpecialWeeklyBanner: { keyFields: ["slug"] },
  SportSeason: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  StageGroup: { keyFields: ["id"] },
  StarkexLimitOrderAuthorizationRequest: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  StarkexTransferAuthorizationRequest: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  StarkwareAccount: { keyFields: ["id"] },
  StarkwarePrivateAccount: { keyFields: ["id"] },
  StarkwareWithdrawal: { keyFields: ["id"] },
  StartingLineupRevealedActivity: { keyFields: ["id"] },
  StatCustomScoring: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  StatScore: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Stats: { keyFields: ["id"] },
  Story: { keyFields: ["id"] },
  StoryInterface: { keyFields: ["id"] },
  StripeCreditCardAuthorizationRequest: { keyFields: ["id"] },
  Subscription: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Substitute: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  Suspension: { keyFields: ["id"] },
  SwappablePlayer: { keyFields: ["id"] },
  Task: { keyFields: ["id"] },
  TaskAppearanceInterface: { keyFields: ["id"] },
  TaskInterface: { keyFields: ["id"] },
  TaskNotification: { keyFields: ["id"] },
  TeamFormation: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  TeamGameStatsInterface: { keyFields: ["id"] },
  TeamInterface: { keyFields: ["slug"] },
  TeamsPlayingNextGameWeek: { keyFields: ["slug"] },
  ThreeDimensionalAsset: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ThreeDimensionalCardLayout: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ThresholdStreakTaskCurrentThresholdStatus: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ThresholdStreakTaskThreshold: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ThresholdsStreakTask: { keyFields: ["id"] },
  TicketsDeliverableItem: { keyFields: ["slug"] },
  TitleBodyNotificationMobileRendering: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  TitleBodyNotificationRendering: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  TokenAuction: { keyFields: ["id"] },
  TokenBid: { keyFields: ["id"] },
  TokenDeposit: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  TokenMonetaryReward: { keyFields: ["id"] },
  TokenMyBid: { keyFields: ["id"] },
  TokenOffer: { keyFields: ["id"] },
  TokenOfferSide: { keyFields: ["id"] },
  TokenOwner: { keyFields: ["id"] },
  TokenPrice: { keyFields: ["id"] },
  TokenPrimaryOffer: { keyFields: ["id"] },
  TokenRoot: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  TokenWithdrawal: { keyFields: ["id"] },
  TopGainers: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  TopVolume: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  TournamentCalendarStage: { keyFields: ["id"] },
  TournamentEligibility: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  TransferRequest: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  TrophiesSummary: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  UnblockEmailPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  UsBankAccount: { keyFields: ["id"] },
  User: { keyFields: ["slug"] },
  UserAccountEntry: { keyFields: ["id"] },
  UserAward: { keyFields: ["slug"] },
  UserAwardNotification: { keyFields: ["id"] },
  UserCardCollection: { keyFields: ["id"] },
  UserCardCollectionSlot: { keyFields: ["slug"] },
  UserCardsCount: { keyFields: ["slug"] },
  UserDevice: { keyFields: ["id"] },
  UserDeviceEvent: { keyFields: ["id"] },
  UserError: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  UserFlag: { keyFields: ["id"] },
  UserGroupCreatedActivity: { keyFields: ["id"] },
  UserGroupInterface: { keyFields: ["slug"] },
  UserGroupMemberJoinedActivity: { keyFields: ["id"] },
  UserGroupMembershipInterface: { keyFields: ["id"] },
  UserGroupRankedActivity: { keyFields: ["id"] },
  UserOwner: { keyFields: ["id"] },
  UserProfile: { keyFields: ["id"] },
  UserProfileBadge: { keyFields: ["slug"] },
  UserRankingInterface: { keyFields: ["id"] },
  UserRelation: { keyFields: ["id"] },
  UserRelationNotification: { keyFields: ["id"] },
  UserSettings: { keyFields: ["id"] },
  UserShopItem: { keyFields: ["id"] },
  UserSource: { keyFields: ["id"] },
  UserSportProfileInterface: { keyFields: ["id"] },
  UserSportStatistic: { keyFields: ["id"] },
  UserWallet: { keyFields: ["id"] },
  UserWithSubscriptionSlug: { keyFields: ["slug"] },
  Validity: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ValueBoundaries: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  ValueOpenBoundaries: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  VariantStockCount: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  VerificationSession: { keyFields: ["id"] },
  WearableDeliverableItem: { keyFields: ["slug"] },
  WinningLineup: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  WithNotifications: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  WithSubscriptionsInterface: { keyFields: ["slug"] },
  Withdrawal: { keyFields: ["id"] },
  WithdrawalWithRates: { keyFields: ["id"] },
  XPCooldownPromotionalEvent: { keyFields: ["slug"] },
  acceptOfferPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  acceptSo5NoCardDraftableAppearancePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  acceptSo5NoCardLineupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  activateWalletRecoveryPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  addAuctionReminderPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  addDevicePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  addEmailToListPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  addToCartPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  addTokensToDeckPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  addWalletRecoveryPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  approveMigratorPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  bidPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  blockUserOffersPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  buyCardPackPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  buyDeliverableShopItemPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  buyShopItemPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  cancelEthBankWithdrawalsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  cancelFastWithdrawalPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  cancelMobilePurchasePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  cancelOfferPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  cancelWithdrawalPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  celebrateActivityPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  changePasswordPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  checkPhoneNumberVerificationCodePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimAnyRewardPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimConversionCreditPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimDeliverableItemOrderPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimFootballManagerTaskPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimManagerProgressionTaskPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimOnboardingEnergyGiftPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimPromotionalEventAirdropPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimReferralRewardPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimRewardPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimRewardsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimStarterPackPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  claimTaskPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  completeDropChallengePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  confirmDeviceWithCodePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  confirmSo5LineupImportsBatchPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  confirmSo5LineupsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  confirmSo5NoCardLineupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  consumeCardBoosterPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createCardAuthorizationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createCardRegistrationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createCardWithdrawalPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createCheckoutCardAuthorizationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createCommonDraftSwapPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createDeckPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createDepositBankAccountMutationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createDirectOfferPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createDraftAndSo5LineupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createEphemeralLinkPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createEthBankWithdrawalIntentPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createEthDepositPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createEthMigrationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createEthVaultPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createFastWithdrawalPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createFiatPayerPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createFiatWalletPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createFiatWithdrawalPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createIdentityVerificationSessionPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createJwtTokenPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createOnboardingSo5LineupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createOrUpdateSingleBuyOfferMinPricePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createSingleSaleOfferPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createSo5LineupImportsBatchPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createSo5UserGroupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createSubscriptionPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createWalletPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createWireTransferDepositPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createWithdrawalBankAccountPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  createWithdrawalPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  deactivateWalletRecoveryPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  declareManagerProgressionTaskPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  declineSo5LineupImportsBatchPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  declineSo5NoCardLineupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  deleteDeckPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  deletePostalAddressPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  deleteSo5LineupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  deleteSo5UserGroupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  deleteSubscriptionPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  deleteUserSo5LineupsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  deleteWithdrawalBankAccountPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  depositWithApplePayPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  destroyAccountPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  destroyReferralPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  detachPaymentMethodPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  disable2faPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  disconnectOmniauthProviderPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  dropAndAddCardsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  editDeckPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  enable2faPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  fetchAuthorizationStatusPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  fetchEncryptedPrivateKeyPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  fetchTransactionStatusPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  footballRivalsAppearanceSubstitutePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  footballRivalsArenaRequestCreatePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  footballRivalsGameSendNudgePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  footballRivalsLineupUpsertPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  footballRivalsRemindGamePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  footballRivalsUpdateFavoriteTeamPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  generateAccountingExtractPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  generateOtpBackupCodesPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  generateOtpProvisioningUriPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  generateSo5NoCardLineupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  hideMyFeaturedSo5LeaderboardGroupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  joinSo5UserGroupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  levelUpCardWithXpCurrencyPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  levelUpCardsWithXpCurrencyPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  linkEthereumAddressPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  loadPreviousLineupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  loadSuggestedLineupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  markCardShardsChestUnlockedThresholdAsSeenPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  markItemAsSeenPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  markNotificationsAsReadPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  markStoriesAsReadPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  matchUserContactsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  prepareAcceptOfferPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  prepareAcceptTradeWithWalletAndCreditCardPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  prepareBidPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  prepareBuyInGameCurrencyPackWithCreditCardPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  prepareCardWithdrawalPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  prepareDepositPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  prepareEthDepositPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  prepareFastWithdrawalPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  prepareIdealDepositPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  prepareOfferPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  preparePaypalDepositPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  prepareTokenDepositPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  probabilisticBundlesOpenPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  reactActivityPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  refreshCartPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  rejectOfferPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  relayCallPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  removeAuctionReminderPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  removeFromCartPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  removeTokenFromDeckPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  removeUserFromSo5UserGroupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  reportTelemetryPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  requestReceiptPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  requestSo5NoCardDraftableAppearancePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  requestSo5NoCardLineupEligibleLeaderboardsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  resendConfirmationInstructionsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  resendVerificationCodePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  resetPrivateKeyPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  resetSo5NoCardDraftedAppearancesPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  restoreWalletPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  revealBlueprintsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  revokeDevicePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  revokeOAuthAccessTokenForApplicationPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  revokeOAuthAccessTokenPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  saveForLaterPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  sealCardsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  sendWalletRecoveryPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  setDeviceTokenPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  setLiveActivityTokenPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  setTokenTradeStatusPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  signOutFromAllDevicesPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  spendCardShardsChestPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  stopAutoBidPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  unblockFiatWalletPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  unblockUserOffersPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  unlockProModePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  unsealCardsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateCardFiltersPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateExternalEthDepositPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateFavoriteTeamPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateFiatWalletPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateHighlightedCardPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateManagerTeamPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updatePartnerOffersAgreementPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateSo5UserGroupPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateSportProfilePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateSubscriptionPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateUserEmailPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateUserProfilePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  updateUserSettingsPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  upsertCommonDraftPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  upsertTaskAppearancesPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  validateApplePayMerchantSessionPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  validateFiatWalletPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  verifyMobilePurchasePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  verifyPhoneNumberPayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
  visitCampaignPagePayload: {
    keyFields: false,
    merge: (existing: any, incoming: any, { mergeObjects }: any) => mergeObjects(existing, incoming),
  },
};
