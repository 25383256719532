import {
  Currency,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import {
  ETH_DECIMAL_PLACES,
  RoundingMode,
  fromWei,
  toWei,
} from '@sorare/core/src/lib/wei';

export const useEthBalance = () => {
  const { currentUser, currency } = useCurrentUserContext();

  const roundedWeiValue = toWei(
    fromWei(
      currentUser?.availableBalance || 0n,
      ETH_DECIMAL_PLACES,
      RoundingMode.ROUND_DOWN
    )
  );

  const { main, exponent } = useAmountWithConversion({
    monetaryAmount: {
      referenceCurrency: SupportedCurrency.WEI,
      wei: roundedWeiValue,
    },
  });
  const hideBalance = currentUser?.userSettings?.hideBalance;

  return {
    hideBalance,
    balanceInFiat: currency === Currency.ETH ? exponent : main,
    balanceInEth: currency === Currency.ETH ? main : exponent,
  };
};
